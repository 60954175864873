import React, { Component } from "react";
import styles from "../Bautista/Bautista.module.scss";
import Page from "../Page/Page";

class Bautista extends Component {
  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <p className={styles.intro_content}>
                Si me preguntasen “¿Qué tipo de iglesia son ustedes?” no dudaría en responder “¡Somos una iglesia
                Bautista!”. Creemos en verdades a las que algunas veces se les ha llamado “Distintivos Bautistas”.
                <br />
                <br />
                También contestaría que somos una “iglesia Reformada” puesto que apoyamos las grandes doctrinas de la
                Reforma respecto a la salvación del hombre. En este sentido no me molesta que a nuestra iglesia se le
                identifique como una iglesia “Reformada Bautista”, y deseo hablar del tema “¿Qué es una Iglesia
                Reformada Bautista?”
              </p>
            </div>
          </div>
        </div>
        <div className={styles.doctrineGrid_start}>
          <div className={styles.doctrineGrid_2}>
            <h4 className={styles.doctrine_title}>I. LAS ESCRITURAS</h4>
            <p className={styles.doctrine_content}>
              Primeramente, una Iglesia Reformada Bautista es una iglesia local que reconoce la autoridad suprema de las
              Sagradas Escrituras. En cuestión de la fe, esto es, en la cosas que creemos, y de la práctica, esto es, en
              las cosas que hacemos, nuestra única autoridad es la Palabra de Dios. Si algo, ya sea de fe o de práctica,
              es contrario a la Biblia, entonces, no importa quién alegue, no importa qué buenos argumentos de emitan a
              su favor, no podemos aprobarlo.
              <br />
              <br />
              Reconocemos que en el funcionamiento de una iglesia local habrá cuestiones para las cuales no exista un
              mandamiento bíblico específico. Por ejemplo, tomemos como ilustración a la secretaria de una iglesia. Será
              difícil encontrar un capítulo y un versículo que especifique que se debe tener una, pero reconocemos que
              tales cosas son necesarias, y son de acuerdo al principio bíblico que especifica que todas las cosas se
              deben hacer decentemente y en orden.
              <br />
              <br />
              Aún así, afirmamos enfáticamente que cuando no hay una órden bíblica específica para algo, no vamos a ver
              a tal cosa o situación como sagrada y obligatoria. Cuando la Palabra de Dios no justifica algo, no vamos a
              estar bajo esclavitud de esa situación o cosa, pero cuando las Escrituras claramente llaman a algo,
              ninguna consideración nos hará hacer lo contrario. Deseamos tener nuestras consciencias marcadas por los
              límites de la Palabra de Dios, pues creemos que ésta es la verdadera libertad. Creo que una serie de
              asuntos que se dan en algunas iglesias Bautistas de hoy en día no tienen bases bíblicas verdaderas. Son
              parte de la iglesia porque se introdujeron hace varios años y ahora son parte de la “tradición Bautista”.
              De hecho mucha gente da por sentado que son bíblicas, pero si se les reta a encontrar las evidencias
              bíblicas de tales costumbres, tendrían problemas para entontrarlas.
              <br />
              <br />
              En otras áreas hay cosas que las Escrituras claramente ordenan pero que han sido abandonadas por la
              mayoría de las iglesias Bautistas modernas, y debemos hacer un llamado para que se adopten de nuevo. La
              cuestión de la gobernación por ancianos es un ejemplo de este punto. Las iglesias Bautistas solían tener
              ancianos; actualmente en la mayoría de las iglesias Bautistas esto ya no se practica. Pero creemos que si
              vamos a ser un verdadero modelo de las iglesias del Nuevo Testamento tenemos que retomar el concepto de
              gobernación por ancianos. La Escritura lo presenta. ¡Nosotros debemos tenerlo!
              <br />
              <br />
              Así que los Bautistas Reformados no se gobiernan por la tradición, ni por la opinión de los hombres, ni
              por los sentimientos, ni por el pragmatismo, sino solamente por la Palabra de Dios. Creemos en la
              autoridad de la Escritura, y deseamos en nuestra vida como iglesia ser modelo conforme a la Palabra de
              Dios. Debemos siempre pedir y buscar que Dios intensifique nuestro entendimiento de Su Palabra, y debemos
              estar siempre prestos a cambiar cualquiera de nuestras prácticas si es que está fuera de los límites de
              las Escrituras. La actitud que dice “No importa lo que la Biblia dice, así siempre lo hemos hecho” es para
              nosotros espantosa, y en efecto, pecadora. Debe ser “para la ley y el testimonio”, o ¿qué dicen las
              Escrituras?
            </p>
          </div>
          <div className={styles.rbcr_spanish}></div>
        </div>
        <div className={styles.doctrineGrid}>
          <div className={styles.membership}></div>
          <div className={styles.doctrineGrid_1}>
            <h4 className={styles.doctrine_titleWhite}>II. LA PREDICACION</h4>
            <p className={styles.doctrine_contentWhite}>
              En segundo lugar, los Bautistas Reformados creen en la pre-eminencia de la predicación de la Palabra de
              Dios. Creemos que la predicación de la Biblia debe tener el lugar central en nuestros servicios. ¡Creemos
              que nada puede o debe tomar el lugar de la predicación de la Palabra!
              <br />
              <br />
              Nuestra convicción es que la iglesia de Cristo ha sufrido porque ha degradado la predicación de la
              Palabra. Creemos que los seminarios y colegios bíblicos deben pre-eminentemente ser instituciones que
              preparen y estimulen a predicadores. Creemos que en cualquier lugar la gente de Dios debe ser alentada y
              animada a orar que el Señor dote hombres con el don de la predicación, y que Él dé predicadores a Sus
              iglesias, grandes predicadores, muchos predicadores. Creemos que hay una gran necesidad en las iglesias de
              Cristo de reconocer la importancia de la predicación de la Palabra de Dios, y que hombres jóvenes deber de
              ser animados a estudiar teología, historia de la iglesia, y los sermones de grandes predicadores del
              pasado, y que deben trabajar duro para llegar a ser buenos expositores de la Biblia.
            </p>
          </div>
        </div>
        <div className={styles.doctrineGrid_end}>
          <div className={styles.doctrineGrid_2}>
            <h4 className={styles.doctrine_title}>III. LAS DOCTRINAS DE GRACIA</h4>
            <p className={styles.doctrine_content}>
              En tercer lugar, los Bautistas Reformados declaran su creencia sin temor alguno en aquellas doctrinas que
              algunas veces son llamadas Las Doctrinas de Gracia. Con esta expresión queremos especificar las doctrinas
              de la depravación total, elección incondicional, expiación definitiva, llamado eficaz, y la perseveración
              de los santos. Nos regocijamos en estas grandiosas verdades que sostienen la soberanía de Dios en la
              salvación del hombre, y que tan gloriosamente afirman la realidad central de que la salvación es sólo por
              gracia, ¡y que la salvación es del Señor!
              <br />
              <br />
              Nos gozamos en que las doctrinas de gracia están enlistadas en la Segunda Confesión de Fe de Londres de
              1689 y en muchas otras confesiones Bautistas de la historia. Declaramos que en 1861 cuando Charles
              Spurgeon abrió el gran Tabernáculo Metropolitano en Londres, Inglaterra, celebró la ocasión teniendo
              invitados de renombre que predicaron sermones acerca de estas doctrinas. Y aún así, no es que creemos en
              estas doctrinas porque Spurgeon o cualquier otro bautista las predicó. Tampoco las creemos por el hecho de
              que estén enlistadas en las confesiones bautistas históricas, aunque nos regocijamos que así sea, sino que
              las creemos porque están claramente presentadas en las Sagradas Escrituras.
              <br />
              <br />
              Reconocemos que vivimos en una época en la que estas verdades fundamentales son ignoradas, e incluso
              descaradamente negadas por muchos que profesan el nombre de “evangélicos” y “Bautistas”. Sabemos que son
              verdades poco populares, pero son verdades, y las recibimos y nos regocijamos en ellas.
              <br />
              <br />
              ¡También nos gustaría enfatizar que no sólo las creemos sino que además sostenemos que deben de ser
              claramente predicadas y enseñadas desde el púlpito!
              <br />
              <br />
              Hoy vivimos la trágica situación en que los hombres dicen desde el púlpito que creen en las doctrinas de
              gracia pero que rehúsan predicarlas y enseñarlas a su gente. El resultado es que las iglesias están llenas
              de gente que ignora las grandes verdades de las Escrituras (y de la fe bautista de la historia), y por lo
              tanto, se empapa de verdades totalmente opuestas – las cuales reciben por la radio y publicaciones
              religiosas. Si un hombre llega a predicar las doctrinas de gracia a tales congregaciones, se generan
              escándalos y oposiciones. Esto es trágico, pero común. Creemos que en nuestros días se necesitan las
              doctrinas de gracia y que nuestra gente necesita ser instruída en ellas.
            </p>
          </div>
          <div className={styles.membership_2}></div>
        </div>
        <div className={styles.doctrineGrid}>
          <div className={styles.membership_3}></div>
          <div className={styles.doctrineGrid_1}>
            <h4 className={styles.doctrine_titleWhite}>IV. EVANGELISMO</h4>
            <p className={styles.doctrine_contentWhite}>
              A continuación nos gustaría afirmar que los Bautistas Reformados creemos en la necesidad y responsabilidad
              de evangelizar. Ya no tenemos más simpatía por el Hiper-calvinismo que por el Arminianismo.
              <br />
              <br />
              No creemos que hay incongruencia entre la soberanía de Dios en la salvación de Sus escogidos y Su
              mandamiento de predicar el Evangelio a toda criatura. Si pareciera haber alguna dificultad en nuestras
              mentes al compaginar cualquiera de las verdades de Su Palabra es por lo entenebrecido de nuestro
              entendimiento, y creemos que nuestro deber es obedecer la Palabra, la entendamos o no. ¡Nosotros creemos
              en el evangelismo!
              <br />
              <br />
              Ahora, es verdad que no creemos mucho de lo que sucede bajo el nombre de ‘evangelismo’ en pleno siglo 20.
              Creemos que mucho de lo que hoy en día es llamado ‘evangelismo’ es poco más que psicología y mercadeo.
              Estamos espantados por las acciones superficiales que se dan bajo ese nombre; estamos horrorizados por las
              presiones, trucos y planes calculados para producir “decisiones” y estadísticas impresionantes; tales
              actos causan tremendos estragos en el alma de los hombres. ¡No!
              <br />
              <br />
              El hecho de que creamos en el evangelismo no significa que vayamos a cooperar con cada proyecto que lleve
              ese nombre. ¡Creemos que en el evangelismo, como en todo lo demás, como hemos dicho antes, debemos ser
              gobernados por la Palabra de Dios! Aún así, insistimos que creemos en el evangelismo, y nuestra oración es
              que Dios nos mantenga conscientes de la necesidad de evangelizar. Que Dios nos dé la carga de la
              responsabilidad de evangelizar, sabiendo que es para Su gloria y para la salvación de los hombres.
              <br />
              <br />
              Creemos que es nuestra responsabilidad dar a conocer el evangelio primeramente a nuestra comunidad, a
              Canadá, y al mundo entero. Creemos en las misiones, nacionales y foráneas, y creemos que debemos de buscar
              las almas de los hombres empleando métodos compatibles con la Palabra de Dios.
            </p>
          </div>
        </div>
        <div className={styles.doctrineGrid_end}>
          <div className={styles.doctrineGrid_2}>
            <h4 className={styles.doctrine_title}>V. ADORACION</h4>
            <p className={styles.doctrine_content}>
              Por último, permítanme afirmar que una iglesia Reformada Bautista es una iglesia local con un serio
              enfoque en la adoración. El Dios que alabamos es un Dios de majestad, gloria y santidad. Y el Dios de la
              Biblia es Aquel a quien los ángeles del cielo constantemente claman “Santo, Santo, Santo”; le adoran de
              día y de noche; Él es grande y merecedor de toda alabanza. Creemos que cuando nos congregamos para alabar
              este grandioso y glorioso Dios de la Biblia debemos hacerlo con reverencia y santo respeto. ¡Creemos que
              debemos sentir un TEMOR REVERENCIAL en nuestros corazones cuando nos reunimos a adorar a este Dios!
              <br />
              <br />
              Tú dices, “Pero seguramente debe de haber gozo también”. Sí, efectivamente, estamos de acuerdo, pero
              además debe de ser un gozo que sea en el Señor, no un gozo que surga de los “sentimientos”, sino uno que
              surga del conocimiento de Dios, un gozo moderado y controlado por la reverencia.
              <br />
              <br />
              Creemos que hay un mundo de diferencia entre un servicio “muerto” y un servicio serio, espiritual. El
              primero no es deseado, el segundo sí. Ahora, dado este deseo de adorar al Señor de manera reverencial,
              creemos que cualquier cosa que le reste mérito no debe de ser aceptada entre nosotros. La frivolidad (o
              informalidad) y las niñerías están fuera de lugar y son incongruentes con la adoración a Dios.
              <br />
              <br />
              También creemos que la música en la iglesia debe de ser gobernada por el gran hecho central de Aquel a
              quien adoramos. Mucha de la música que invade las iglesias hoy en día parece poco más que una imitación
              del mundo. Hay muy poca diferencia entre la música que se toca en la iglesia y la que se presenta en la
              televisión o en espectáculos mundanos – claro, excepto que las palabras que se cantan son “religiosas” más
              que “seculares”. Pero el espíritu que prolifera es el del mundo; la atracción es para la carne. Ésto
              aborrecemos y rechazamos; ésto no tiene lugar en el centro de adoración a Dios. Lo que es sagrado no debe
              de ser prostituído y empleado como entretenimiento. Si la gente quiere divertirse, pues que sus sentidos
              se agudicen yendo a lugares seculares de diversión para que se entretengan; que no pretendan que están
              adorando o en un servicio en donde la diversión está a la órden del día. ¡No! Cuando nos reunimos a adorar
              queremos dejar el mundo afuera, queremos apelar no a la carne sino a los sentidos; queremos no la
              sofisticación del mundo sino la sencillez de Cristo. ¡Oh, que cuando adoremos sintamos el temor de Dios en
              nuestras almas! ¡Oh, que veamos algo de la gloria vista por Isaías y por los siervos de Dios del mundo
              antiguo!
              <br />
              <br />
              Esta es, pues, la clase de iglesia que buscamos construir. Otras cosas pudieron haberse mencionado, pero
              sólo hemos procurado tocar algunos de los puntos básicos.
              <br />
              <br />
              Que Dios levante muchas iglesias sobre toda tierra y sobre la faz del mundo que deseen las mismas
              doctrinas y que luchen por ellas. ¡Que Dios se agrade en visitar a su gente de nuevo con lluvias de
              bendiciones para que el Señor sea glorificado en y a través de Su Iglesia!
            </p>
          </div>
          <div className={styles.membership_4}></div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page
        permalink="spanish-ministry/bautista/"
        header="¿Qué es una Iglesia Bautista Reformada?"
        content={this.content()}
      />
    );
  }
}

export default Bautista;
