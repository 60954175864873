import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import rrulePlugin from "@fullcalendar/rrule";
import ReactTooltip from "react-tooltip";
import * as moment from "moment-timezone";

import Page from "../Page/Page";
import { EventsService } from "../../services/EventsService";
import "./Calendar.scss";

class Calendar extends Component {
  eventsService = new EventsService();
  calendarComponentRef = React.createRef();
  timeZone = "America/Los_Angeles";
  timeFormat = "MMM Do @ hh:mmA";
  state = {
    calendar: null,
    calendarWeekends: true,
    calendarEvents: {
      events: [
        {
          title: "Spanish Worship",
          rrule: {
            freq: "weekly",
            interval: 1,
            byweekday: ["su"],
            dtstart: "1900-01-01T09:00:00",
            until: "2999-01-01"
          }
        },
        {
          title: "Sunday School",
          rrule: {
            freq: "weekly",
            interval: 1,
            byweekday: ["su"],
            dtstart: "1900-01-01T09:30:00",
            until: "2999-01-01"
          }
        },
        {
          title: "Morning Worship",
          rrule: {
            freq: "weekly",
            interval: 1,
            byweekday: ["su"],
            dtstart: "1900-01-01T11:00:00",
            until: "3000-01-01"
          }
        },
        {
          title: "Evening Worship",
          rrule:
            "DTSTART:19000101T163000Z\nRRULE:FREQ=WEEKLY;INTERVAL=1;UNTIL=30000101;BYDAY=SU\nEXRULE:FREQ=MONTHLY;BYSETPOS=-1;BYDAY=SU;INTERVAL=1"
        },
        {
          title: "Prayer Meeting",
          rrule: {
            freq: "weekly",
            interval: 1,
            byweekday: ["we"],
            dtstart: "1900-01-01T19:00:00",
            until: "3000-01-01"
          }
        }
      ]
    }
  };

  async componentDidMount() {
    const events = await this.eventsService.getAll();
    const calendarEvents = this.state.calendarEvents.events;

    events.map((event) => {
      const newEvent = {
        id: event.id,
        title: event.title,
        start: this.formatDate(event.start_date),
        formattedStart: this.formatDate(event.start_date, this.timeFormat)
      };

      if (event.url) {
        newEvent.url = event.url;
      }

      if (event.end_date) {
        newEvent.end = this.formatDate(event.end_date);
        newEvent.formattedEnd = this.formatDate(event.end_date, this.timeFormat);
      }

      calendarEvents.push(newEvent);
    });

    this.setState({ calendarEvents });
  }

  handleEventPositioned(info) {
    const { event } = info;
    let displayDate = "";
    let url = event.url ? `<a href=${event.url}>${event.url}</a>` : "";

    if (event.start && !event.extendedProps.formattedStart) {
      displayDate = moment(event.start).format("MMM Do @ hh:mmA");
    }

    if (event.extendedProps.formattedStart) {
      displayDate = event.extendedProps.formattedStart;
    }

    if (event.extendedProps.formattedEnd) {
      displayDate = `${displayDate} - ${event.extendedProps.formattedEnd}`;
    }

    const htmlEvent = `
        <div>
            <h5>${event.title}</h5>
            <p>
                ${displayDate}<br />
                ${url}
            </p>
        </div>
    `;

    info.el.setAttribute("data-tip", htmlEvent);
    ReactTooltip.rebuild();
  }

  formatDate(date, format = "") {
    const originalDate = new Date(date);
    const offset = moment.tz(originalDate, this.timeZone)._offset;

    if (format) {
      return moment
        .tz(new Date(originalDate.setMinutes(originalDate.getMinutes() - Math.abs(offset))), this.timeZone)
        .format(format);
    }

    return moment
      .tz(new Date(originalDate.setMinutes(originalDate.getMinutes() - Math.abs(offset))), this.timeZone)
      .format();
  }

  getDefaultView() {
    return window.innerWidth < 600 ? "listWeek" : "dayGridMonth";
  }

  content() {
    return (
      <div className="calendar">
        <div className="container">
          <FullCalendar
            defaultView={this.getDefaultView()}
            header={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,listWeek"
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
            ref={this.calendarComponentRef}
            weekends={this.state.calendarWeekends}
            events={this.state.calendarEvents}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: "short"
            }}
            eventTextColor="#fff"
            progressiveEventRendering={true}
            showNonCurrentDates={false}
            eventPositioned={this.handleEventPositioned}
          />
        </div>
        <ReactTooltip effect="solid" clickable={true} html={true} delayHide={300} />
      </div>
    );
  }

  render() {
    return <Page permalink="calendar/" header="Calendar" content={this.content()} />;
  }
}

export default Calendar;
