import React, { Component } from "react";
import styles from "../SpanishConstitution/SpanishConstitution.module.scss";
import Page from "../Page/Page";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ScrollAnimation from "react-animate-on-scroll";

class SpanishConstitution extends Component {
  state = {
    currentArticle: "article1"
  };

  showArticle(article) {
    document.getElementById(this.state.currentArticle).style.display = "none";
    document.getElementById(article).style.display = "block";
    this.setState({ currentArticle: article });
  }

  hideArticles() {
    for (let i = 2; i < 13; i++) {
      document.getElementById(`article${i}`).style.display = "none";
    }
  }

  componentDidMount() {
    this.hideArticles();
  }

  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <p className={styles.intro_content}>
                Como iglesia con la encomienda del cuidado espiritual de los miembros, al igual que la administración de
                las ofrendas y los diezmos, RBCR ha adoptado ésta constitución con el propósito de hacer posible que la
                iglesia funcione de manera ordenada. Adoptada en 1995, ésta constitución nos ha servido bien como una
                guía efectiva en la gestión de asuntos de la RBCR.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.container}>
            <div className={styles.articleGrid}>
              <ScrollAnimation animateIn="fadeIn">
                <div className={styles.articleLinks}>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article1")}>ARTÍCULO 1 – NOMBRE</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article2")}>ARTÍCULO 2 – PROPÓSITO</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article3")}>ARTÍCULO 3 – AFILIACIÓN</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article4")}>ARTÍCULO 4 – PACTO</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article5")}>ARTÍCULO 5 – ARTICULOS DE FE</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article6")}>ARTÍCULO 6 – MEMBRESÍA</h4>
                  </AnchorLink>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <div className={styles.articleLinks}>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article7")}>ARTÍCULO 7 – DISCIPLINA DE LA IGLESIA</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article8")}>ARTÍCULO 8 – ORDENANZAS</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article9")}>ARTÍCULO 9 – OFICIALES</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article10")}>ARTÍCULO 10 – OFICIALES LEGALES</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article11")}>
                      ARTÍCULO 11 – SESIONES DE NEGOCIO DE LA IGLESIA
                    </h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article12")}>ARTÍCULO 12 – AUTORIDAD CONSTITUCIONAL</h4>
                  </AnchorLink>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className={styles.articleContent}>
          <div id="articles" className={styles.container}>
            <div id="article1">
              <h4 className="header">ARTÍCULO 1 – NOMBRE</h4>
              <p>El nombre de esta iglesia será Iglesia Bautista Reformada de Riverside.</p>
            </div>
            <div id="article2">
              <h4 className="header">ARTÍCULO 2 – PROPÓSITO</h4>
              <p>
                El propósito de esta iglesia es glorificar al Dios de las Escrituras manteniendo y promoviendo Su
                adoración tanto individual como congregacionalmente, evangelizando pecadores, y edificando a Sus santos.
                Por lo tanto, estamos comprometidos con la proclamación de la Ley perfecta de Dios y de su glorioso
                Evangelio de Su gracia a través de todo el mundo, con la defensa de aquella “fe que de una vez para
                siempre fue entregada a los santos” (Judas 3), y con la celebración pura y fiel de las ordenanzas del
                Nuevo Pacto.
              </p>
            </div>
            <div id="article3">
              <h4 className="header">ARTÍCULO 3 – AFILIACIÓN</h4>
              <p className={styles.indent}>
                <b>3.1 AUTORIDAD.</b> Nosotros no reconocemos autoridad eclesiástica aparte de nuestro Señor Jesucristo,
                quien es Cabeza de la Iglesia (Ef. 5:23) y quien dirige los asuntos de la iglesia a través de Ancianos
                escogidos y ordenados de acuerdo a los preceptos de la Santa Escritura (Hch 14:21-23; 1 Tim 3:1-7; Tito
                1:5-9). Los Ancianos mismos en todo tiempo y en todas sus actividades están bajo la autoridad de la
                Santa Escritura.
              </p>
              <p className={styles.indent}>
                <b>3.2 COOPERACIÓN.</b> La iglesia puede cooperar y lo hace con otras iglesias del mismo sentir en
                asuntos de interés y preocupación mutua (2 Cor 1:11; 8:18-24; Flp.4:15-19). Podemos buscar apoyo y
                consejo (Prob 11:14; 18:1) de otras Iglesias en asuntos de interés especial nuestro, pero ninguna
                decisión de alguna iglesia o grupo de iglesias en ningún momento podrá ser reconocida como de carácter
                obligatorio para esta iglesia (Hch 14:21-23).
              </p>
              <p className={styles.indent}>
                <b>3.3 ASOCIACIONES.</b> Un tipo especial de compañerismo con otras iglesias es la membresía formal en
                una asociación de iglesias. Siguiendo la recomendación de los Ancianos, se podrá ingresar a tales
                afiliaciones con el consentimiento y aprobación explícita de la congregación (Hch 15:22, 2 Cor 8:19). Se
                podrá salir de tales asociaciones siguiendo el mismo procedimiento.
              </p>
            </div>
            <div id="article4">
              <h4 className="header">ARTÍCULO 4 – PACTO</h4>
              <p className={styles.indent}>
                <b>4.1 DECLARACIÓN INTRODUCTORIA.</b> Dios por su gracia ha entrado en una relación de pacto con Su
                pueblo creyente (Jer 31:31-34; 32:40; Heb. 8:7-13; 10:16, 17; 13:20,21). Su sangre es la sangre del
                Nuevo Pacto, la cual infaliblemente asegura todos los beneficios del pacto para todo el pueblo de Dios
                ((Mat 26:26-28; Heb 13:20-21). Dios en el Nuevo Pacto nos ha hecho miembros unos de otros (Rom 12:4; 5;
                1 Cor 12:12-27; Efe4:25). Por lo tanto, nosotros tenemos responsabilidades de pacto para con cada uno,
                así como para con Dios. Dios ha prometido en este pacto escribir Sus leyes en nuestros corazones y
                causar en nosotros el que andemos en Sus caminos (o sea, nos ha dado el poder para cumplir con nuestras
                responsabilidades del pacto). La motivación y capacidad para obedecer las leyes de Dios surgen del
                sacrificio expiatorio de Jesucristo, quien, por Su muerte, satisfizo la ira santa de Dios que era
                contraria a nosotros debido a nuestros pecados, y por Su resurrección nos ha engendrado de nuevo a una
                esperanza viva (1 Ped 1:3). Es por la intervención del Espíritu Santo que nosotros obedecemos, en
                gratitud de amor por la justicia de Cristo delante de Dios. Nosotros obedecemos con la confianza de que
                el propósito de la muerte de Cristo será cumplido en nosotros (eso es, “para que la justa exigencia de
                la ley fuese cumplida en nosotros” [Rom 8:4a], y que para que seamos un pueblo “celoso de buenas obras”
                [Tito 2:14]). Los párrafos siguientes son un resumen de lo que creemos que son nuestras
                responsabilidades de pacto hacia Dios y hacia unos a otros. Este resumen forma la base para dar y
                recibir instrucción para nosotros mismos y para nuestras familias.
              </p>
              <p className={styles.indent}>
                <b>4.2 RESUMEN DE NUESTRAS RESPONSABILIDADES DE PACTO (LAS LEYES ESCRITAS EN NUESTROS CORAZONES)</b>
              </p>
              <p className={styles.indent2}>
                <b>4.2.1</b> Nosotros acordamos adorar solamente al Dios único y verdadero, Padre, Hijo y Espíritu
                Santo, quien se reveló Él mismo a nosotros en las Escrituras del Antiguo y Nuevo Testamento. Nosotros
                declararemos Su gloria a las naciones. Nosotros no tendremos otros dioses delante de Él.
              </p>
              <p className={styles.indent2}>
                <b>4.2.2</b> Nosotros acordamos adorar a Dios en la manera en la que Él designó y excluir de nuestra
                adoración cualquier cosa que Él no haya designado.
              </p>
              <p className={styles.indent2}>
                <b>4.2.3</b> Nosotros acordamos no usar el nombre de Dios en vano o usarlo descuidadamente, sino caminar
                en las sendas de justicia a causa de Su nombre.
              </p>
              <p className={styles.indent2}>
                <b>4.2.4</b> Nosotros acordamos suspender nuestras propias labores en el Día del Señor, si ellas no son
                actividades de piedad, o necesidad, así como santificar positivamente el día con disciplinas
                espirituales de adoración pública y privada.
              </p>
              <p className={styles.indent2}>
                <b>4.2.5</b> Nosotros acordamos honrar y obedecer, dentro de los límites de la Escritura, a todas
                nuestras autoridades, ya sea en la familia, iglesia, estado, o negocio; y, si nosotros somos la
                autoridad, acordamos tratar razonablemente y en amor a nuestros subordinados y de esa manera enseñarles
                por palabra y ejemplo a temer a Dios y a guardar Sus mandamientos.
              </p>
              <p className={styles.indent2}>
                <b>4.2.6</b> Nosotros acordamos evitar cualquier cosa que tienda a destruirnos o a destruir a nuestros
                prójimos y acordamos involucrarnos en todo esfuerzo lícito que preserve nuestras propias vidas y las
                vidas de los demás, especialmente estando listos a la reconciliación y exhortación fiel en la iglesia.
              </p>
              <p className={styles.indent2}>
                <b>4.2.7</b> Nosotros acordamos mantener nuestros cuerpos en santidad como instrumentos unidos a Cristo
                y en donde mora el Espíritu Santo y evitar toda impureza de pensamiento, palabra o acción.
              </p>
              <p className={styles.indent2}>
                <b>4.2.8</b> Nosotros acordamos ser diligentes en nuestras vocaciones, para que podamos proveer para
                nuestra familia, evitando robar tiempo, dinero o bienes y para que podamos dar a aquél que tiene
                necesidad.
              </p>
              <p className={styles.indent2}>
                <b>4.2.9</b> Nosotros acordamos promover seriamente la verdad entre los hombres y evitar cualquier cosa
                que distorsione la verdad o difame el buen nombre de nuestro prójimo.
              </p>
              <p className={styles.indent2}>
                <b>4.2.10</b> Nosotros acordamos estar en completo contentamiento con nuestra propia condición en la
                vida, regocijarnos en el progreso de nuestro prójimo, y evitar envidiar a nuestro prójimo o codiciar
                algo que le pertenece.
              </p>
            </div>
            <div id="article5">
              <h4 className="header">ARTÍCULO 5 – ARTICULOS DE FE</h4>
              <p>
                Nosotros adoptamos como la expresión más completa de nuestra fe la Confesión de Fe Bautista de Londres
                de 1689 (con excepción de las afirmaciones respecto a la salvación del incapacitado mentalmente [10.3] y
                la identidad del anticristo [26.4]). La autoridad definitiva en todos los asuntos de fe, orden y moral
                es y debe de ser solamente la Biblia, tal verdad es claramente expuesta en el artículo inicial de la
                misma Confesión. Este documento histórico es, sin embargo, un resumen excelente de “las cosas que entre
                nosotros han sido ciertísimas”, y que encontramos que es de ayuda en la controversia, confirmación para
                la fe, y medio de edificación en la justicia. Aquí los miembros de nuestra iglesia tendrán un compendio
                de teología sistemática con referencias bíblicas que les ayudará a estar listos para dar razón de la
                esperanza que está en ellos (1 Ped 3:15).
              </p>
            </div>
            <div id="article6">
              <h4 className="header">ARTÍCULO 6 – MEMBRESÍA</h4>
              <p className={styles.indent}>
                <b>6.1 JUSTIFICACIÓN DE LA MEMBRESÍA.</b> El Nuevo Testamento demanda de todos los cristianos compromiso
                formal, activo, solemne, voluntario y permanente a Jesucristo, a Su verdad y a Su pueblo. Un compromiso
                verdadero del cristiano al Señor Jesucristo debe incluir y es inseparable de un compromiso a la verdad
                de Cristo y al pueblo de Cristo. Tal compromiso a Cristo, Su verdad y Su pueblo requiere de manera
                ordinaria un compromiso formal, activo, solemne, voluntario y permanente de membresía a la iglesia en
                una iglesia local por las razones bíblicas a continuación:
              </p>
              <p className={styles.indent2}>
                <b>6.1.1 La Gran Comisión.</b> El cumplimiento con la Gran Comisión de Cristo requiere la membresía a la
                iglesia. De acuerdo a la Gran Comisión de Cristo (Mat 28:18-20) hay una conexión inseparable entre el
                hacer discípulos, el bautizarles y el enseñarles. Los Apóstoles llevaron a cabo esta comisión agregando
                discípulos bautizados a iglesias locales. Fue por lo tanto en las iglesias locales donde los discípulos
                bautizados fueron enseñados todo lo que Cristo ordenó (Hch 2:38-47, 1 Cor 4:17). Con la excepción de la
                cual no se sabe mucho del eunuco de Etiopia, no existe nada en el Nuevo Testamento acerca de hombres o
                mujeres creyentes que no hayan sido miembros de iglesias locales.
              </p>
              <p className={styles.indent2}>
                <b>6.1.2 El Patrón bíblico.</b> El Nuevo Testamento presenta la iglesia local como un grupo distinto de
                individuos los cuales pueden ser contados (Hch 2:41-42; 4:4); pueden ser agregados o excluidos del mismo
                (Hch 2:47; 5:14; 9:26); pueden ser llamados a seleccionar lideres y representantes de entre ellos mismos
                (Hch 6:1-6; 2 Cor 8:19, 23; Hch 15:22); pueden reunirse oficialmente todos juntos (Hch 14:27; 15:22);
                pueden llevar a cabo la disciplina de la iglesia por consenso mutuo (Mat 18:17; 1 Cor.5:4, 13; 2 Cor
                2:6); y pueden conmemorar la Cena del Señor como congregación reunidos todos juntos (1 Cor 11:17-20,
                33-34). Entonces por todo lo anterior, hay justificación bíblica clara para la existencia y
                mantenimiento cuidadoso de una membresía de iglesia local que implica un compromiso formal, activo,
                solemne, voluntario y permanente. Esta justificación bíblica nos obliga a tener gran cuidado en mantener
                una membresía bíblicamente ordenada.
              </p>
              <p className={styles.indent2}>
                <b>6.1.3 Cuidado Pastoral.</b> Es solamente dentro del contexto de la iglesia local con miembros y
                obispos identificables que los mandatos bíblicos respecto a la relación de pastores y pueblo pueden ser
                cumplidas (1 Tes 5:12-13; Heb 13:17).
              </p>
              <p className={styles.indent}>
                <b>6.2 REQUISITOS PARA LA MEMBRESÍA</b>
              </p>
              <p className={styles.indent2}>
                <b>6.2.1 Requisitos Generales.</b> Para ser elegibles para la membresía, un hombre o una mujer (Hch
                5:14; 8:3, 12) deberá mostrar arrepentimiento hacia Dios y los frutos de ello (Hch. 26:20), así como esa
                fe hacia nuestro Señor Jesucristo (Hch. 20:21) que produce obras de piedad (Efe. 2:8-10; Sant 2:18,22).
                Tal persona deberá ser bautizada como creyente; y a un grado satisfactorio para los Ancianos expresar
                estar de acuerdo de manera significativa con el propósito (como está establecido en el Articulo 2),
                Pacto (como está establecido en el Articulo 4), Confesión (como está establecido en el Articulo 5), y
                gobierno de esta iglesia (1 Cor. 16:1-2; 2 Cor. 8:1-8). Además, tal persona no debe estar bajo
                disciplina correctiva bíblicamente fundamentada (Mat 18:17, 18; 1 Cor 5:11-13; 2 Tes 3:6, 14, 15; 3 Jn
                9, 10; 2 Cor 2:6-8) de una iglesia genuina.
              </p>
              <p className={styles.indent2}>
                <b>6.2.2 Conformidad con la Doctrina y forma de gobierno.</b> No es requisito para ningún discípulo el
                dominar en conocimiento las confesiones de la iglesia antes de que él sea admitido a la membresía de la
                iglesia. Tal requisito violaría el orden de Mateo 28:19,20 el cual nos instruye a discipular, bautizar y
                luego a enseñar al discípulo bautizado a guardar todas las cosas que Cristo enseñó. Es necesario, sin
                embargo, que todo discípulo que aplica para membresía manifieste una disposición a ser enseñado y que
                esté de acuerdo de manera sustancial con lo que él ya sabe en relación a la doctrina y la forma de
                gobierno de la iglesia. Los miembros de la iglesia deben estar en sumisión al gobierno ordenado de la
                iglesia a la cual ellos pertenecen (Heb 13:17). Aquél que no puede de manera inteligente y libremente
                someterse al gobierno de una iglesia no debe pertenecer a esa iglesia. Cualquiera que está en un
                desacuerdo sustancial con la constitución o confesiones de la iglesia no puede someterse de manera
                consistente al ministerio de enseñanza de la iglesia. Por lo tanto, el admitir tal persona a la
                membresía en esta iglesia no sería sabio ni bíblico (Efe 4:3). Si alguien que ya es miembro de la
                iglesia en algún momento concluye que él ya no está cumpliendo con los requisitos de la membresía,
                entonces él está obligado a informar a los Ancianos de esto.
              </p>
              <p className={styles.indent}>
                <b>6.3 TIPOS DE MEMBRESÍA</b>
              </p>
              <p className={styles.indent2}>
                <b>6.3.1 Membresía regular.</b> Todo los que son recibidos a la membresía de la iglesia de acuerdo a los
                procedimientos presentados en la sección 6.4, que continúan asistiendo regularmente a las reuniones
                establecidas de la iglesia, y quienes no están bajo disciplina correctiva de la iglesia como esta
                presentada en el Articulo 7, podrán ser considerados miembros regulares en plena comunión con la iglesia
                con todos los derechos y privilegios de la membresía en la iglesia (Hch 2:37-47).
              </p>
              <p className={styles.indent2}>
                <b>6.3.2 Membresía temporal.</b> Las personas que lleguen a vivir a nuestra área por un período limitado
                de tiempo (tal como estudiantes, personal militar, personas con trabajos especiales asignados) pueden
                ser recibidas o quitadas de la membresía de la iglesia por las mismas razones y de la misma manera que
                las personas que residan de manera permanente en nuestra área. Si tal persona es miembro ya de una
                iglesia en el lugar de su residencia permanente, tal persona no requerirá renunciar a la membresía de su
                iglesia de donde vive, sino que se le considerará miembro temporal mientras se encuentre entre nosotros,
                disfrutando de todos los derechos y privilegios y sujeto a todas las responsabilidades, obligaciones y
                disciplinas de la membresía regular. Cuando tal persona termine su período de residencia temporal
                estando en plena comunión con la iglesia quedará libre para unirse a la comunión de su iglesia de donde
                vive y no será considerado más miembro de ésta iglesia (véase: Hch 18:27; Rom 16:1,2; 2 Cor. 3:1ss; Col
                4:10; 3 Jn 5-10).
              </p>
              <p className={styles.indent2}>
                <b>6.3.3 Membresía asociada.</b> Los miembros regulares que se mudan lejos de nuestra área y que no
                puedan encontrar otra iglesia local a la cual ellos puedan por motivos de conciencia unirse, podrán
                solicitar que su membresía permanezca en esta iglesia como miembros asociados. Tales personas deberán
                tener comunicación constante con la iglesia para poder mantener su membresía de asociado. No obstante,
                se les anima a que busquen diligentemente una iglesia en la cual ellos puedan unirse en otro lugar. A
                discreción de los Ancianos, una membresía de asociado puede ser otorgada a inválidos, obreros
                Cristianos, y otros quienes tengan una relación con la iglesia que conlleve circunstancias inusuales.
              </p>
              <p className={styles.indent}>
                <b>6.4 RECEPCIÓN A LA MEMBRESÍA</b>
              </p>
              <p className={styles.indent2}>
                <b>6.4.1 Aplicación.</b> Una persona que desea ser miembro de la iglesia debe de aplicar con los
                Ancianos y solicitar ser entrevistado por ellos. Durante la entrevista los Ancianos procurarán
                determinar si la persona tiene una profesión de fe en Cristo creíble, si ha sido bautizado de acuerdo a
                las Escrituras, si está en acuerdo sustancialmente con la Confesión y con la Constitución de la iglesia,
                si es capaz de asumir responsabilidades y obligaciones propias de la membresía de la iglesia, si tiene
                el deseo de apoyar de todo corazón el ministerio de la iglesia, y si está dispuesto a someterse al
                gobierno y disciplina de la iglesia. La membresía podría ser negada a aquellas personas que, según el
                juicio de los Ancianos, no estén calificadas en alguna de éstas áreas (Hch 9:26-27; 10:47 con 11:2-18;
                11:23).
              </p>
              <p className={styles.indent2}>
                <b>6.4.2 Membresías anteriores.</b> Si solicitante es o ha sido miembro de otra iglesia, se hará un
                esfuerzo especial para determinar el estado de la persona en aquella iglesia anterior y sus razones para
                dejarla (Hch 15: 1-2 con 24-25). Si dicha iglesia pone objeción la cual los Ancianos consideran valida,
                la aplicación podrá ser negada a discreción de los Ancianos.
              </p>
              <p className={styles.indent2}>
                <b>6.4.3 Procedimiento.</b> Si los Ancianos están satisfechos con los requisitos cumplidos por el
                solicitante para la membresía, ellos anunciarán el nombre a la congregación por al menos 4 Días del
                Señor consecutivos en las reuniones establecidas de la iglesia. Este período de tiempo es con el
                propósito de dar a los miembros la oportunidad de plantear preguntas u objeciones en relación a si el
                solicitante cumple con los requisitos (Hch 9:26-29). Se espera que los miembros consideren esto un deber
                personal de la mayor seriedad posible. Se espera que los miembros expresen en privado a los Ancianos
                todas las preguntas u objeciones que no hayan sido resueltas aún, después de que haya habido contacto
                personal con el solicitante (Mat 18:15ss; Lev 19:16-17). Si ninguna objeción que los Ancianos consideren
                valida es expresada, el solicitante será recibido a la membresía por decisión de los Ancianos. Este
                recibimiento será anunciado públicamente en la próxima reunión establecida de la iglesia (Mat 3:6-12; 1
                Jn 4:1; Apc 2:2). Los Ancianos pueden posponer la recepción de la persona a la membresía hasta que una
                investigación correspondiente pueda realizarse en relación a objeciones las cuales según el juicio de
                ellos sean lo suficientemente serias.
              </p>
              <p className={styles.indent}>
                <b>6.5 TERMINACIÓN DE LA MEMBRESÍA</b>
              </p>
              <p className={styles.indent2}>
                <b>6.5.1 Tipos de terminación.</b>
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.1 Por muerte física.</b> Cuando un miembro de la iglesia haya dejado de estar entre nosotros
                por muerte, su nombre será transferido al archivo de antiguos miembros.
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.2 Por transferencia</b>
              </p>
              <p className={styles.indent4}>
                <b>6.5.1.2.1 Base y procedimiento para la transferencia.</b> Porque la norma del Nuevo Testamento para
                Cristianos es que ellos sean miembros de iglesias de Cristo locales verdaderas, y porque la salud
                espiritual de los creyentes está en peligro cuando ellos no están comprometidos de esa manera a una
                iglesia, todo Cristiano que deja la membresía de esta iglesia debe buscar ser miembro mediante
                transferencia a otra iglesia de Cristo verdadera. Por lo tanto, si un miembro que está en plena comunión
                con la iglesia cuya conducta no justifique disciplina correctiva desea dejar la membresía de esta
                iglesia, se le instará encarecidamente irse de una manera ordenada informando el deseo en privado a los
                Ancianos junto con las razones por las que se va, y entregar una solicitud a las Ancianos para la
                transferencia de la membresía a otra iglesia de Cristo verdadera (Hch 18:27).
              </p>
              <p className={styles.indent4}>
                <b>6.5.1.2.2 Prolongación de la transferencia.</b> Cuando tal miembro saliente no haya aún escogido una
                iglesia apropiada a la cual pueda él transferirse, los Ancianos podrán proveerle, si él así lo desea, un
                período de transición el cual le permitirá al miembro saliente decidir a donde desea él ser transferido.
                Este estatus de transición será concedido para que continúe siempre y cuando que él miembro saliente
                mantenga contacto regular con los Ancianos, no prolongue innecesariamente el proceso de transición, y no
                tenga una conducta que requiera de la aplicación de la disciplina de la iglesia.
              </p>
              <p className={styles.indent4}>
                <b>6.5.1.2.3 Carta de transferencia.</b> Cuando sea solicitado, los Ancianos podrán transferir un
                miembro saliente que está en plena comunión con la iglesia a la comunión de otra iglesia. Una carta de
                transferencia será enviada a los oficiales correspondientes de la iglesia a la cual el miembro desea
                transferirse. No se dará dicha carta a un miembro que esté en ese momento bajo la disciplina correctiva
                de esta iglesia. Los Ancianos podrán negar la entrega de una carta de transferencia a alguna iglesia que
                según el juicio de ellos no sea fiel a la “fe que de una vez para siempre fue entregada a los santos”
                (Judas 3) o que no ejerza cuidado piadoso sobre sus miembros.
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.3 Por renuncia.</b> Se ingresa a la membresía y es iniciada la membresía de manera conjunta;
                por el compromiso voluntario del individuo solicitante y con la aceptación de la iglesia (como se
                describe en la sección 6.4). Asimismo, los miembros no pueden terminar su membresía de manera unilateral
                bajo ninguna circunstancia. Como regla general, un miembro puede voluntariamente renunciar a la
                membresía en esta iglesia. Una renuncia ofrecida para frustrar o entorpecer los procedimientos de la
                disciplina correctiva no es válida, y la iglesia podrá proceder con censura pública, imposición de
                restricciones, o expulsión de acuerdo a los procedimientos delineados en el Articulo 7 (Hch 15:24; 1 Jn
                2:18-19 con 2 Jn 7-11).
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.4 Por Exclusión.</b> Si un miembro deja de asistir fielmente a las reuniones establecidas de la
                iglesia (sección 6.7.1) sin mostrar causa justificable, o si después de cambiarse a otro lugar de
                residencia deja de mantener contacto vital con la iglesia, él podrá ser excluido de la membresía según
                los Ancianos juzguen pertinente. En tales casos, los Ancianos tratarán de contactar a la persona para
                rectificar y resolver la situación (Heb 13:17). Si estos esfuerzos no son efectivos, los Ancianos
                informarán a esta persona (cuando sea posible) y a la congregación que él no ya no es miembro. Si una
                persona no siendo culpable de herejía, escándalo inmoral, o división, renuncia a su compromiso de
                cumplir con alguno de los requisitos de la membresía mencionados en la sección 6.7, o deja, sin causa
                justificable, de practicar alguno de ellos como patrón de vida, y aún así desea permanecer en la
                membresía, rechazando renunciar voluntariamente, él podrá ser excluido, pero sólo después de repetidas
                amonestaciones de los Ancianos (2 Tim 2:24-26). En tales casos los Ancianos anunciarán a la congregación
                en alguna reunión establecida la intención de ellos de excluir a la persona. Se concederá tiempo para
                preguntas y objeciones que deberán ser expresadas en privado a los Ancianos por cualquier miembro. Si
                ninguna objeción que los Ancianos consideren valida es expresada, la persona será excluida por acción de
                los Ancianos. Se informará a la congregación y a la persona sobre ésta acción.
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.5 Por expulsión.</b> De acuerdo a la enseñanza de la Santa Escritura, una iglesia debe de
                apartar de su comunión y membresía visible a cualquier persona que enseñe o insista en sostener falsa
                doctrina herética, que de manera descarada o persistente se conduzca de manera contraria a su profesión
                de cristiano, o que persista en perturbar la unidad, paz o pureza de la iglesia (Mat 18:15ss; Rom 16:17;
                1 Cor 5;1ss; Tito 3:10-11). El procedimiento que se debe seguir para tal expulsión está presentado en la
                sección 7.2.4 de esta Constitución.
              </p>
              <p className={styles.indent2}>
                <b>6.5.2 Implicaciones de la Terminación.</b>
              </p>
              <p className={styles.indent3}>
                <b>6.5.2.1 Divulgación a los santos.</b> La Iglesia Bautista Reformada de Riverside no existe aislada
                sino que es parte de la iglesia universal de Cristo, compuesta por todas las iglesias verdaderas. En
                consecuencia, comunicación franca y directa entre las iglesias es vital para la pureza, paz, edificación
                y unidad de la iglesia universal. Por lo tanto, los Ancianos pueden, a discreción de ellos, divulgar a
                los miembros de esta iglesia y a otras iglesias las circunstancias en las cuales la membresía de una
                persona fue terminada (Hch 15:24; 1 Tim 1:20; 2 Tim 2:17; 4:10; 1 Jn 2:18, 19).
              </p>
              <p className={styles.indent3}>
                <b>6.5.2.2 Divulgación a la sociedad.</b> Además, la Iglesia Bautista Reformada de Riverside no existe
                aislada de la sociedad en general. En consecuencia, esta iglesia tiene la obligación moral a la sociedad
                tanto de actuar con integridad como de mantener su testimonio (2 Cor 8:20-21). Por lo tanto, los
                Ancianos pueden, a discreción de ellos, divulgar a otras personas fuera de los círculos eclesiásticos
                mencionados arriba las circunstancias en las cuales la membresía de una persona fue terminada (Lev 5:1;
                Prov 29:24; 1 Ped 4:15).
              </p>
              <p className={styles.indent3}>
                <b>6.5.2.3 Divisiones en la terminación.</b> La terminación de la membresía no da licencia a quienes
                hayan sido miembros de sembrar discordia, esparcir enseñanzas o reportes falsos, o comportarse en alguna
                manera que atente contra la paz y unidad de esta iglesia o de la iglesia universal. En consecuencia,
                cuando se ha establecido que una persona que fue miembro se comporta causando división, los Ancianos
                podrán emitir cualquier clase de advertencias que consideren apropiadas para mantener y preservar la paz
                y armonía de esta iglesia y de la iglesia universal (Hch 15:24-31; Rom 16:17-20; 1 Tim 1:19-20; 2 Tim
                2:16-18; 4:10; 1 Jn 2:18-19).
              </p>
              <p className={styles.indent}>
                <b>6.6 PRIVILEGIOS DE LA MEMBRESÍA.</b> En el orden de Dios, el compromiso normalmente constituye el
                camino a la posesión de privilegios. Por lo tanto, la membresía en esa iglesia incluye los siguientes
                privilegios:
              </p>
              <p className={styles.indent2}>
                <b>6.6.1</b> Participación en la Cena del Señor (Hch 2:41-42; 1 Cor 11:23-26);
              </p>
              <p className={styles.indent2}>
                <b>6.6.2</b> Asistencia a las reuniones de negocio de la iglesia, y participación apropiada, y votación
                en ellas (Hch 6:11-6 [cf. Hch 2:41; 4:4; 5:13-14]; 1 Cor 5:4-7 [cf. 1 Cor 1:2]);
              </p>
              <p className={styles.indent2}>
                <b>6.6.3</b> Colaboración en el extendimiento del Reino de Dios en los ministerios de la iglesia (según
                como sea apropiado a los dones, gracia y llamamiento de cada uno) (Rom 12:3-21; Efe 4:7; 11-12; 16; 1
                Ped 4:10-11);
              </p>
              <p className={styles.indent2}>
                <b>6.6.4</b> Recepción del obispado y cuidado comprometido de los Ancianos de la iglesia (Hch 20:28; 1
                Ped. 5:1-3);
              </p>
              <p className={styles.indent2}>
                <b>6.6.5</b> Recepción del cuidado comprometido y disciplina (cuando sea necesaria) de la membresía de
                la iglesia (Hch 6:1-2 [cf Hch 2:41; 5:13-14; 9:26]; 1 Cor. 5: 4-5 [cf. 1 Cor. 1:2]; Gal. 6:10).
              </p>
              <p className={styles.indent}>
                <b>6.7 COMPROMISO DE LOS MIEMBROS AL CUERPO</b>
              </p>
              <p className={styles.indent2}>
                <b>6.7.1 Asistencia a las reuniones de la iglesia.</b> Puesto que todos los miembros regulares y
                temporales creen en la santidad del Día del Señor y el privilegio y deber especial de la adoración
                congregacional, y puesto que la iglesia es un cuerpo que por necesidad debe tener sus miembros presentes
                para poder funcionar; los miembros de esta iglesia deberán procurar reunirse juntos en el Día del Señor
                en la hora designada para la adoración, oración, enseñanza y predicación de las Escrituras, y la
                observancia de los sacramentos de bautismo y la Cena del Señor. La Providencia puede ocasionalmente
                impedir a algunos de la participación; sin embargo, generalmente esto sería la excepción y no la norma.
                (Hch 2:42; 12:5, Heb 10:24-25, 1 Cor.11:18, 20, 1 Cor.12:12-27).
              </p>
              <p className={styles.indent2}>
                Los Ancianos pueden convocar reuniones especiales cuando ellos crean que hay la necesidad para que
                alguna ocasión la requiera. Los miembros deberán procurar participar en la vida del cuerpo estando
                presentes en estas reuniones (Mat 18:17, Hch 14:27).
              </p>
              <p className={styles.indent2}>
                <b>6.7.2 Apoyo financiero.</b> Porque es claramente enseñado en las Escrituras que los Cristianos deben
                de apoyar financieramente la obra del Señor dando proporcional y sistemáticamente a través de la iglesia
                local (Gen 14:19-20 con Heb 7:4; Mal 3:8-10; 1 Cor 16:1-2; 2 Cor 8,9), se espera que todos los miembros
                de esta iglesia cumplan con esta regla de la Escritura. Se insta firmemente el diezmo (diez por ciento
                del ingreso neto personal de cada uno) como una expresión de adoración y la norma bíblica para la
                contribución básica de cada miembro, a la cual se le debe de agregar las donaciones y ofrendas de
                acuerdo a la capacidad y a la disposición de corazón de cada uno (Gen 14:19-20 con Heb 7:4; Exod 36:2-7;
                2 Cor 8:1-5; 1 Tim 6:17-19).
              </p>
              <p className={styles.indent2}>
                <b>6.7.3 Fomento de la paz y la edificación.</b> Considerando que la iglesia es representada en la
                Escritura como un cuerpo con muchos miembros, cada miembro teniendo su propia función en particular y a
                la vez teniendo interés en la salud y protección de todo el cuerpo (1 Cor.12:12-27; Efe. 4:4, 11-16),
                esta iglesia espera que cada uno de sus miembros busque el bienestar para todo el cuerpo. Como miembros
                de esta iglesia debemos procurar activamente el cultivar conocernos unos con otros y mantener
                transparencia mutua y honestidad para que podamos estar más capacitados para orar unos por otros; amar,
                consolar y animar unos a otros; y ayudar unos a otros materialmente según haya necesidad que así
                requiera (Gal 6:10; Efe 4:25; 1 Jn 3:16-18). Además, debemos discretamente confesar nuestras faltas unos
                a otros (Stg 5:16), amonestarnos y animarnos fielmente unos a otros (1 Tes 5:14; Heb 3:12-13; 10:24-25),
                y abstenernos de toda difamación y chisme (Prov. 10:31-32; 26:20-22). Los Ancianos podrán determinar que
                algunos asuntos de negocio congregacional son tan delicados que se espera que los miembros mantengan
                tales asuntos confidencialmente. (Prov 11:13).
              </p>
              <p className={styles.indent2}>
                <b>6.7.4 Apoyo y sumisión al liderazgo.</b> Todos los que llegan a ser miembros de esta iglesia se
                espera que reconozcan y se sometan a los obispos o Ancianos de la iglesia. El apoyar a los siervos de
                Dios requiere de orar por ellos y por sus labores (Efe 6:18-19); de cultivar el conocimiento personal
                con ellos, de amarles y tenerles en alta estima a causa de su labor (1 Tes 5:12-13); estando con ellos y
                no abandonándoles en sus aflicciones ni en todas las causas buenas (2 Tim 1:15-18); y defendiéndoles en
                vez de prejuzgarles y dañarles su buena reputación (Hch 23:5; 1 Tim 5:19). El someterse a los siervos de
                Dios conlleva el imitar sus virtudes de gracia cristianas, principios de piedad y fe así como ellos
                también imitan a Cristo (1 Cor 11:1; Heb 13:7; 1 Ped 5:3); recibiendo sus enseñanzas de buena gana, con
                toda disposición y siendo aconsejables de espíritu, pero siempre teniendo como la lealtad mayor la
                Palabra de Dios (Hch 17:11; 1 Tes 2:13); humildemente prestando atención a los reproches y advertencias
                bíblicas de ellos como escuchando a quienes han sido designados para velar por las almas que les fueron
                confiadas y quienes están comprometidos en laborar para presentar las almas perfectas y maduras en
                Cristo (Col 1:28); procurando y considerando cuidadosamente el consejo de ellos teniéndoles en mente
                como aquellos a quienes el Señor tuvo por fieles (1 Cor 7:10, 25); y aceptando y obedeciendo en amor las
                decisiones de ellos en relación a la política de la congregación en la casa de Dios, la cual es Su
                iglesia (1 Tes 5:12-13; 1 Tim 3:5, Heb 13:17) sin crear división aún cuando se esté en desacuerdo
                personalmente con el juicio de ellos. Esta sumisión deberá incluir el estar dispuestos a programar una
                reunión de supervisión pastoral con uno o más Ancianos cuando sea solicitada.
              </p>
              <p className={styles.indent2}>
                <b>6.7.5 Una vida cristiana devota.</b> Todos los que entren a la membresía de esta iglesia se espera
                que anden de manera digna del Señor (Efe 4:1ss). Por lo tanto, se espera que cada miembro practique y
                cultive la devoción en las áreas a continuación:
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.1 Devoción personal.</b> Se espera que cada miembro haga uso de los varios medios de gracia
                privados tales como la oración en privado diariamente (Sal.55:1; Dan 6:10; Mt 6:6-13); meditación y
                lectura de manera diaria y habitual de la Palabra de Dios (Sal 1:2; 119:11,97); y la observancia de
                manera cuidadosa y espiritual del Día del Señor (Gen 2:1-3; Ex 20:8-11; Isa 58:13-14; Luc 2:27; Hch
                20:7; 1 Cor 16:2; Apc 1:10).
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.2 Vida Familiar.</b> La iglesia espera que sus miembros obedezcan las enseñanzas de la
                Escritura respecto a la vida y gobierno del hogar. Como la cabeza del hogar designada por Dios, el
                esposo debe de gobernar sobre su familia con amor y gentileza, pero también con sabiduría y firmeza (Efe
                5:25ss; 1 Tim 3:4-5; 1 Ped 3:7). La esposa debe estar en sujeción de su esposo en todas las cosas de
                acuerdo al mandato de la Escritura (Efe 5:22-24; 1 Ped 3:1-6). La Biblia enseña claramente que el hombre
                y la mujer son iguales en su dignidad con la que fueron creados, en su naturaleza de depravación, y en
                sus privilegios relacionados con la redención. La subordinación funcional de la esposa cristiana no
                implica inferioridad. El liderazgo del esposo en el hogar debe ser tal que permita el desarrollo pleno
                de los dones y virtudes de gracia de la esposa (Prov 31:10-31). El esposo y la esposa deben de criar a
                sus hijos en la instrucción y amonestación del Señor (Efe 6:1-4). Esto incluye el dar un buen ejemplo
                delante de ellos, instruyéndoles consistentemente desde las Escrituras (Deut 6:4-9), y con disciplina
                firme y en amor y sabia, incluyendo castigo físico y regaño verbal cuando sea necesario (Prov. 13:24;
                22:15; 29:15; Heb. 12:7-8).
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.3 Evangelismo personal.</b> Es el deber de cada cristiano, como individuo y como miembro de una
                iglesia local, laborar en oración, palabra y acción para la extensión del Reino de Dios en círculos
                siempre crecientes, comenzando en el hogar y extendiéndose hasta lo último de la tierra (Isa 54:1-3; Hch
                1:8). Por lo tanto, se espera que cada miembro de esta iglesia reconozca en oración y aproveche cada
                oportunidad para dar testimonio de su fe en Cristo, tanto en una conducta cristiana como por el
                testimonio de sus palabras (Col 4:5-6; 1 Ped 3:14-15).
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.4 Libertad cristiana.</b> Se requiere de cada miembro de la iglesia el dar en su vida diaria
                obediencia leal a todos los preceptos morales establecidos en la Palabra de Dios (Rom 8:3,4). Si Dios no
                condenó o prohibió alguna práctica en Su Palabra, el cristiano está en libertad de participar en ella.
                El ejercer la libertad Cristiana, sin embargo, debe en todo tiempo ser gobernado por un deseo vehemente
                en caminar en el temor de Dios y de glorificar a Él en todas las cosas (1 Cor 10:31; 1 Ped 1:15-19), una
                consideración en amor por las conciencias de hermanos más débiles (Rom 15:1-31; 1 Cor 8:7-12), una
                compasión por el perdido (1 Cor 9:19-22), y una estimación ferviente por la salud de la propia alma de
                uno (Rom 13:14; 1 Cor 6:12; 9:24-27; Gal 5:22-24; 1 Ped 2:16).
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.5 Separación del Mundo.</b> Dios nunca tuvo el propósito de que la gloriosa bendición de la
                libertad Cristiana que nosotros disfrutamos se convirtiera en excusa para cubrir nuestra mundanalidad
                (Gal 5:13; 1 Ped 2:16). Al contrario, hemos sido liberados del yugo de nuestros pecados pasados para que
                podamos ser un pueblo distinto de este presente siglo malo (Gal 1:4) y apartados para Dios (Lev 18:1-30;
                Tito 2:11-14; 1 Ped 1:14-16). Asimismo, hemos sido llamados para identificar y aborrecer las actitudes y
                prácticas de este tiempo que son contrarias a la voluntad de Dios (Rom 12:9; Efe 4:17-22; 5:7-12; 1 Tes
                5:21-22; Tito 2:12; 3:3; 1 Ped 4:3-4). También hemos sido llamados personalmente para resistir que la
                influencia de una sociedad que rechaza a Dios nos amolde (Prov 1:10-19; Rom 12:1-2; Stg 1:27). Por lo
                tanto, se espera que todos los miembros de esta iglesia se separen de las practicas y actitudes malignas
                de tal sociedad mientras que a la vez viven en y ministren a ella (1 Cor 5:9-11).
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.1 Las cosas de esta vida.</b> Se nos espera que resistamos la actitud mundana de considerar
                las cosas de esta vida como algo de primordial importancia (1 Jn 2:15-17). Esto significa que no debemos
                estar tan absorbidos con el trabajo, posesiones materiales, política, recreaciones, o alguna otra
                persona o cosa que esté en rivalidad con nuestro principal vinculo que es Cristo (Mat 6:24).
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.2 El relativismo y los valores.</b> No debemos adoptar los valores distorsionados de este
                mundo, tal como: el pensar que el valor propio esté basado en las posesiones, posición social, legado
                familiar, o nuestra contribución a la sociedad. También significa que no debemos adoptar el relativismo
                moral del mundo, especialmente respecto a la santidad de la vida y la verdad.
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.3 Rol de género.</b> Se espera que nosotros resistamos a la actitud mundana acerca de la
                masculinidad y femininidad. Esto significa que debemos de rechazar las nociones carnales de que los
                roles del hombre y la mujer son arbitrarios y reversibles; de que la orientación sexual es asunto de
                preferencia en vez de moralidad; y de que el matrimonio es opresivo a menos que sea con igualdad de
                roles. Esto también significa que debemos pensar que un rol primario de la esposa es el de ser una
                ayudante a su esposo (Gen 2:18), su esfera básica (aunque no necesariamente exclusiva) de trabajo el del
                hogar (Prov 31:10-31; 1 Tim 5:14; Tito 2:4-5), y su estado como esposa y madre debe ser considerado de
                nobleza y honor, en vez de degradante y de insatisfacción. Significa también que nosotros debemos de
                pensar que un rol del esposo no sólo es el de proveedor y protector (Efe. 5:23, 28; 1 Tim 5:8), sino
                también el de alguien que da instrucción personal criando a sus hijos (Efe 6:4; 1 Tes2:11; Heb 12:9) e
                instruye con consideración a su esposa (1 Ped 3:7; Gen 18:6-8).
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.4 Vicios.</b> Se espera que nosotros no nos entreguemos a ninguno de los vicios que nos
                rodean: borrachera, drogadicción, glotonería, el ver material pornográfico, fornicación, homosexualidad
                y otros pecados similares.
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.5 Relaciones.</b> Respecto a la influencia del mundo, se espera que nosotros nos abstengamos
                de involucrarnos con los impíos, tal como estar en compañía de ellos de manera frecuente e intima (Prov
                12:26; Stg 4:4), siguiendo algún interés romántico en tales personas (Jueces 16:4-5; 1 Reyes 11:1-4, 9;
                Prov 2:16-17; 6:23-25), ni contrayendo matrimonio con ninguna de ellas (2 Cor 6:14; 1 Cor 7:39). De
                igual manera, se espera que nosotros busquemos cuidadosamente discernir y resistir cualquier influencia
                maligna de esta sociedad corrompida sobre nuestras almas y nuestras familias, ya sea ejercida a través
                de la televisión, videos, películas, música, libros, escuela o por algún otro medio (Rom 12:1-2; 1 Ped
                2:11-12).
              </p>
              <p className={styles.indent}>
                <b>6.8 REGISTROS DE MEMBRESÍA.</b> Los Ancianos deberán llevar un archivo que contenga todos los
                miembros pasados y presentes. Este archivo deberá tener tres divisiones: miembros regulares, miembros
                asociados y miembros anteriores. El archivo de los miembros anteriores deberá incluir la fecha y la
                razón por la que la membresía de la iglesia fue terminada, así como cualquier otra información necesaria
                (ver Sección 6.5).
              </p>
            </div>
            <div id="article7">
              <h4 className="header">ARTÍCULO 7 – DISCIPLINA DE LA IGLESIA</h4>
              <p className={styles.indent}>
                <b>7.1 DISCIPLINA FORMATIVA.</b> Cada discípulo de Cristo es disciplinado por Él personalmente y sin que
                intervengan mediadores (Hch 5:1-11; 1 Cor 11:29-32; 1 Tes 4:6; Heb. 12:5-11; Apoc 2:22-23); y con
                mediación a través de la iglesia (Mat 18:15-20; 1 Tes 5:12-15; Heb 3:12-13; 10:24-25). La sumisión mutua
                de unos a otros y a los obispos quienes el Señor ha puesto sobre Su iglesia (1 Ped 5:5) tendrá como
                resultado la santificación de cada miembro individualmente y de todo el cuerpo de la iglesia
                colectivamente. Sin embargo, hay ocasiones cuando la disciplina informal sola es insuficiente y la
                disciplina correctiva formal llega a ser necesaria.
              </p>
              <p className={styles.indent}>
                <b>7.2 DISCIPLINA CORRECTIVA</b>
              </p>
              <p className={styles.indent2}>
                <b>7.2.1 Declaración general.</b> La disciplina correctiva llega a ser necesaria cuando doctrina
                herética, o conducta desordenada, inmoral o escandalosa aparece entre los miembros de la iglesia. Como
                regla general y cuando sea viable, se debe hacer esfuerzo en resolver dificultades, corregir error, y
                quitar la ofensa a través de consejo y amonestación antes de que pasos más serios sean tomados (Gal 6:1;
                Stg 5:19-20). Los principios dados en Mateo 18:15-20, Romanos 16:17-20, 1 Corintios 5:1-13, 2
                Tesalonicenses. 3:6-15, 1 Timoteo 5:19-22, 6:3-5 y Tito 3:10 deben ser obedecidos y aplicados
                cuidadosamente apropiadamente en cada caso de disciplina correctiva. En algunos casos, la amonestación
                pública y/o arrepentimiento público podrá ser requerido (Mat 18:17; 1 Tim 5:20). En otros casos, algunos
                de los privilegios de la membresía podrán ser suspendidos y restricciones correspondientes impuestas
                (Rom 16:17-20; 2 Tes 3:14-15). En los casos más extremos la expulsión de la membresía de la iglesia
                podrá ser lo que se tenga que hacer (Mat 18:17; Rom 16:17; 1 Cor 5:1-13; 1 Tim 1:20; Tito 3:10). Todos
                los miembros de la iglesia están obligados a someterse a la decisión de la iglesia y hacerla cumplir
                como corresponde en actos de disciplina correctiva.
              </p>
              <p className={styles.indent2}>
                Puesto que la iglesia es una institución espiritual y religiosa, los castigos (2 Cor 2:6) implementados
                por la iglesia en la disciplina correctiva son también espirituales. Estos castigos incluyen reprensión
                verbal pública (Mat 18:17; 1 Tim 5:20), evitar contacto social y el retirar el compañerismo
                distintivamente cristiano (Rom 16:17; 1 Cor 5:9-11; 2 Tes 3:6, 14) y el eliminar de la membresía de la
                iglesia (Mat 18:17; 1 Cor 5:13). Estas acciones tienen la intención de efectuar arrepentimiento mediante
                un sentido de tristeza y vergüenza (2 Cor 2:7; 2 Tes 3:14). Sin embargo, la iglesia no tiene el derecho
                de confiscar bienes, revocar derechos conyugales, o infligir castigo físico de ningún tipo. No obstante,
                un miembro culpable de acciones criminales podrá ser entregado a las autoridades civiles de acuerdo a la
                norma de la Escritura (Rom 12:17b; 13:1-7; Tito 3:1; 1 Ped 4:15).
              </p>
              <p className={styles.indent2}>
                Los objetivos de la disciplina correctiva son siempre para la gloria de Dios, el bienestar y la pureza
                de la iglesia (1 Cor 5:6; 1 Tim 5:20) y la restauración y crecimiento espiritual de la persona bajo
                disciplina (1 Cor 5:5, 2 Cor 2:5-8; 1 Tim 1:20).
              </p>
              <p className={styles.indent2}>
                <b>7.2.2 Censura o reprensión pública.</b> La reprensión pública consiste en un esfuerzo pastoral
                delante de la iglesia reunida, para llamar a un miembro no arrepentido de la iglesia al arrepentimiento
                por su pecado que es demasiado grave como para encubrirlo con el manto del amor; o para lidiar con un
                pecado grave aún cuando pueda haber arrepentimiento. Los Ancianos podrán administrar censura pública
                cuando según el criterio de ellos, alguna mala conducta pública (Gal 2:11-14; 1 Tim 5:20), patrón de
                conducta de pecado (Tito 1:12-13), o error doctrinal serio (Tito 1:10-13) sea una amenaza significativa
                para la santidad, unidad o testimonio de la congregación. Aquellos que humildemente reciban la palabra
                de reprensión pública, reconozcan y confiesen su pecado, y muestren una vida transformada (Prov 28:13)
                deberán ser después reconocidos públicamente por su arrepentimiento devoto (2 Cor 7:7-11). Si la
                reprensión no es acatada, más disciplina podrá ser impuesta.
              </p>
              <p className={styles.indent2}>
                <b>7.2.3 Suspensión de privilegios.</b> Ciertas conductas malas de parte del miembro dañan tanto la
                unidad, santidad y testimonio de la iglesia que el Señor demanda reprensión pública acompañada de la
                suspensión de algunos de los privilegios de membresía de acuerdo al tipo y gravedad de la falta cometida
                (Rom 16:17-20; 2 Tes 3:6-15). En todos los casos de suspensión el ofensor, es decir, la persona bajo
                disciplina, es aún considerada como hermano en Cristo y como miembro de la iglesia. Reprensión severa
                debe ser expresada (Mat 18:17) y se debe hacer cumplir la suspensión (Rom 16:17-20; 2 Tes 3:6-15) por la
                iglesia entera, y no solamente por los Ancianos que representan la iglesia. Por lo tanto, cuando sea
                adecuado, los Ancianos recomendarán en una sesión de negocios de la iglesia debidamente convocada que el
                miembro ofensor sea suspendido. Los Ancianos serán específicos al dar a conocer las razones de la
                disciplina, los privilegios que serán revocados, y la restricción que será impuesta. Para ser válida, el
                acto de suspensión debe tener la aprobación de al menos dos tercios de los miembros presentes y la
                votación de ellos. Con el interés de mantener un ambiente de paz y santidad, los Ancianos tendrán el
                derecho, a discreción de ellos, de imponer una suspensión temporal a algún miembro durante el intervalo
                breve entre el tiempo en que se da a conocer la determinación de recomendar la suspensión y la votación
                de la congregación. Un miembro bajo suspensión será tratado por la congregación de acuerdo a la
                aplicación específica del principio general de no contacto social (Rom 16:17-20; 2 Tes 3:14-15)
                determinado por los Ancianos. Aquellos que en humildad se sometan a la disciplina impuesta serán después
                perdonados, tendrán sus privilegios restaurados, y serán públicamente recibidos otra vez para estar en
                plena comunión con la iglesia (Matt. 18:15; 2 Cor. 2:5-11).
              </p>
              <p className={styles.indent2}>
                Las categorías generales de pecado que demandan suspensión están a continuación:
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.1 Ofensor obstinado en privado</b> (Matt. 18:15-17). Cuando una falta en privado permanece sin
                resolver aún después cuando se ha seguido el método prescrito por nuestro Señor en Mateo 18:15-16, y se
                orado en la gracia por ello, esa ofensa en privado es considerada una falta agravada. Los hermanos
                involucrados deberán traer el asunto a los Ancianos quienes al juzgar que el asunto es serio y no
                pudiendo persuadir al hermano a arrepentirse, habrán de reportar la situación a la iglesia, y recomendar
                que el hermano obstinado sea suspendido (Mat 18:17; Rom 16:17). Si aún después de un período de
                suspensión, la persona sigue persiste en su pecado, la expulsión podrá ser iniciada de acuerdo al
                procedimiento explicado en la sección 7.2.4 (Mat 18:17b).
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.2 Comportamiento o enseñanza que causa división</b> (Rom 16:17-20; Tito 3:10). Cuando un
                miembro deliberadamente persiste en la propagación de error doctrinal serio contrario a la Escritura o a
                nuestra Confesión, o intenta sembrar discordia entre los miembros contraria a la Escritura o a esta
                Constitución, esa persona podrá ser suspendida como una persona facciosa. Ya que cada miembro es
                responsable de ayudar a preservar la unidad del Espíritu (Efe 4:1ss), nadie debe de encubrir tal
                comportamiento de división, sino reprenderlo, y descubrirlo informando a los Ancianos (Deut 13:6s; 1 Cor
                1:10,11). En el momento en que los Ancianos se den cuenta de tal comportamiento de división, ellos deben
                de confrontarlo con mansedumbre y paciencia de acuerdo a la Palabra de Dios (1 Cor 1:10ss; Tito 3:10).
                Si aún después de haber recibido varias amonestaciones de los Ancianos, un miembro persiste en tal
                conducta, los Ancianos reportarán la amonestación a la iglesia y recomendarán que el hermano que está
                causando división sea suspendido. Si aún después de un período de suspensión, la persona continua sin
                arrepentirse, la expulsión podrá ser iniciada de acuerdo al procedimiento explicado en la sección 7.2.4.
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.3 Comportamiento desordenado</b> (2 Tes 3:6-16; 1 Tes 4:11-12; 5:14). Algunas conductas y
                doctrinas son consideradas desordenadas. Si un miembro deliberadamente persiste en una conducta la cual
                muestra una indiferencia flagrante o pública para el orden designado por Dios para toda la humanidad en
                las ordenanzas de la creación tal como: trabajo, Día de descanso o matrimonio (Gen 2:1-3, 15, 18-24; Exd
                20:8-11; Mat 19:4-6; 1 Cor 7:1-17, 39; 2 Tes 3:6-15; 1 Tim 5:8; Tito 2:5), se le podrá suspender como un
                hombre desordenado (2 Tes 3:6). De la misma manera, un miembro podrá ser suspendido como hombre
                desordenado si él deliberadamente persiste en conducta contraria al orden establecido por Cristo para Su
                iglesia en la Escritura (1 Cor 11:17-34; 14:37-40; 1 Tim 3:14, 15) como está reconocido por nuestra
                iglesia en ésta Constitución. En el momento en que los Ancianos se den cuenta que a pesar de las
                amonestaciones de la disciplina formativa (1 Tes 5:14), un miembro se está comportando desordenadamente,
                ellos deberán confrontarle en mansedumbre y paciencia de acuerdo a la Palabra de Dios (2 Tes 3:14-15).
                Se debe distinguir entre la conducta desordenada y una conducta traicionera. Un miembro desordenado no
                debe ser inmediatamente expulsado. Más bien él debe ser suspendido de acuerdo a las indicaciones de 2
                Tesalonicenses 3:6-15 y no considerarse como enemigo sino amonestarle como a un hermano. Sin embargo, si
                aún después de recibir tal amonestación de los Ancianos, un miembro persiste en su comportamiento, los
                Ancianos deberán reportar la situación a la iglesia y recomendar que el hermano desordenado sea
                suspendido (2 Tes 3:14-15). Si aún después del período de suspensión, la persona continua sin
                arrepentirse, la expulsión podrá ser iniciada de acuerdo al procedimiento explicado en la sección 7.2.4.
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.4 Un pecado escandaloso.</b> Si un miembro ha pecado de manera escandalosa pero muestra señales
                esperanzadoras de arrepentimiento, incluyendo sumisión a los Ancianos, no se justificaría la expulsión.
                Sin embargo, podrá ser aún prudente suspenderle por un tiempo para que él se dé comprenda la gravedad de
                la falta y manifieste claramente arrepentimiento (Mat 3:8), para que deshonra no sea traída al Nombre de
                Cristo ni a la iglesia (Isa 52:5; Ezeq 36:20-21; Rom 2:24), y para que otros no sean alentados a pecar
                (1 Tim 5:20). Si no hay frutos dignos de arrepentimiento presentes, los Ancianos podrán recomendar a la
                iglesia en fecha posterior que esta persona sea expulsada de acuerdo al procedimiento explicado en la
                sección 7.2.4.
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.5 Disciplina de la iglesia por desacato.</b> Si una persona es acusada o se sospecha de una
                falta que demande disciplina correctiva, y aún así se ausenta él mismo de las reuniones de la iglesia, o
                se niega a reunirse con los Ancianos para que el asunto sea investigado, tal persona podrá ser
                suspendida (Mat 18:17; Num 16:1-12, 23-27). Los Ancianos podrán recomendar a la iglesia en fecha
                posterior que esta persona sea expulsada de acuerdo al procedimiento explicado en la sección 7.2.4.
              </p>
              <p className={styles.indent2}>
                <b>7.2.4. Expulsión</b>
              </p>
              <p className={styles.indent3}>
                <b>7.2.4.1 Ocasiones que demandan excomunicación.</b> Además de la expulsión de aquellos que han sido
                previamente suspendidos, algunas manifestaciones de pecado (doctrinal o ético) son tan graves y
                horribles por el tipo de pecado que las acciones preliminares como la reprensión pública y la suspensión
                resultan inapropiadas. En tales casos, el miembro culpable se le podrá expulsar inmediatamente por la
                iglesia (1 Cor 5:1-5). Esta medida drástica se deberá aplicar tanto cuando rebeldía agravada es
                descubierta como cuando no haya señales esperanzadoras de arrepentimiento. Esta medida drástica tiene la
                intención de sacar de la persona que ha quebrantado la ley su apego al pecado, y llevarla a un
                arrepentimiento sincero y duradero (1 Cor 5:5). Por lo tanto, los Ancianos habiendo hecho esfuerzos
                intensos pero sin éxito para traer el ofensor a un arrepentimiento verdadero y ser reformado, deberán
                reportar la situación a la iglesia y recomendar que el ofensor sea excomunicado.
              </p>
              <p className={styles.indent3}>
                <b>7.2.4.2 El consentimiento de la iglesia en la excomunicación.</b> Todos los actos de expulsión
                deberán ser ejecutados por los que están presentes reunidos (Mat 18:17; 1 Cor. 5:4). Para ser válido, un
                acto de expulsión debe contar con la aprobación de al menos dos tercios de los miembros presentes y
                contar con su votación en una sesión de negocios de la iglesia debidamente convocada.
              </p>
              <p className={styles.indent3}>
                <b>7.2.4.3 Después de la suspensión.</b> Los miembros que han sido suspendidos podrán ser expulsados si
                ellos continúan sin arrepentirse.
              </p>
              <p className={styles.indent2}>
                <b>7.2.5 Restauración.</b> Los propósitos de la disciplina de la iglesia son para restaurar a un hermano
                o hermana caída y para mantener la iglesia pura. Por lo tanto, es el deber de la iglesia perdonar y
                restaurar a la membresía completa a un miembro suspendido o expulsado que muestra una evidencia
                satisfactoria de su arrepentimiento (2 Cor 2:6-8; Mat 18:18-20). Esto se deberá llevar a cabo en una
                sesión de negocios de la iglesia debidamente convocada por una mayoría de dos tercios de los miembros
                presentes y con su votación.
              </p>
            </div>
            <div id="article8">
              <h4 className="header">ARTÍCULO 8 – ORDENANZAS</h4>
              <p className={styles.indent}>
                <b>8.1 DECLARACIÓN GENERAL.</b> Hay dos ordenanzas de significado especial que nuestro Señor nos ordenó
                que guardemos, específicamente, bautismo y la Cena del Señor. Ninguna de ellas otorga mérito para
                salvación, ni tampoco imparten gracia al que lo hace a través del agua del bautismo ni a través del pan
                y la copa de la cena. Estas ordenanzas no son medios de “gracia especial”, sino que estas ordenanzas son
                especiales “medios de gracia” y ayuda poderosa para la fe de los creyentes que participen en ellas.
              </p>
              <p className={styles.indent}>
                <b>8.2 BAUTISMO</b>
              </p>
              <p className={styles.indent2}>
                <b>8.2.1 Sus candidatos.</b> Sólo discípulos que hayan confesado a nuestro Señor Jesucristo son
                candidatos apropiados para el bautismo, y todas estas personas deberán ser bautizadas y unidas a la
                iglesia (Mat 28:19-21; Hch 2:38, 41, 47; 5:13, 14).
              </p>
              <p className={styles.indent2}>
                <b>8.2.2 Su necesidad para la membresía.</b> El creer que el bautismo en agua es la señal ordenada por
                Dios de la unión personal de cada uno con Cristo en Su muerte, sepultura, y resurrección, y la puerta a
                la entrada a la comunidad visible del pueblo de Dios. Normalmente nosotros recibiremos a la membresía de
                la iglesia sólo aquellos que hayan sido bautizados como creyentes “en el nombre del Padre y del Hijo y
                del Espíritu Santo” (Mat 28:19).
              </p>
              <p className={styles.indent2}>
                <b>8.2.3 Su modo.</b> La inmersión en agua es el modo bíblico de bautismo, esto es requisito para su
                debida administración, y es el único modo en que deberá ser administrado por esta iglesia.
              </p>
              <p className={styles.indent}>
                <b>8.3 LA CENA DEL SEÑOR</b>
              </p>
              <p className={styles.indent}>
                Así como el bautismo es la ordenanza inicial por la cual entra uno a la iglesia visible, y debe
                realizarse solamente una vez por cada creyente, la Cena del Señor debe de celebrarse frecuentemente por
                la iglesia reunida (1 Cor 11:26). Aunque esta ordenanza es una ordenanza santísima y debe de realizarse
                con solemnidad y dignidad, el pan y la copa de la cena son y deberán permanecer solamente símbolos del
                cuerpo quebrantado y la sangre derramada de nuestro Señor Jesucristo. Para mantener la pureza de esta
                ordenanza, los Ancianos procurarán fielmente asegurar que únicamente los creyentes verdaderos sean
                admitidos a la mesa.
              </p>
            </div>
            <div id="article9">
              <h4 className="header">ARTÍCULO 9 – OFICIALES</h4>
              <p className={styles.indent}>
                <b>9.1 DECLARACION GENERAL</b>
              </p>
              <p className={styles.indent}>
                Solamente Jesucristo es la Cabeza de Su Iglesia (Col 1:18). Él ha ordenado que iglesias independientes
                deban de ser gobernadas por Él mismo a través de oficiales a quienes Él ha designado, quienes están
                dotados de dones y virtudes de gracia necesarias para llevar a cabo su trabajo. Cristo ha ordenado que
                iglesias locales sean administradas por Ancianos y Diáconos. Aparte de estos dos oficios las Escrituras
                no reconocen ningún otro oficio que continúe al día de hoy (Filp. 1:1; 1 Tim 3:1-13).
              </p>
              <p className={styles.indent}>
                <b>9.2 PREREQUISITOS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.2.1 Conformidad.</b> Cualquier individuo apartado para uno de estos oficios debe conscientemente
                ser capaz de afirmar su acuerdo con la Confesión de Fe y la Constitución de la iglesia. Si él alguna vez
                cambia su posición, él estará bajo la obligación moral y espiritual de hacer saber de este cambio a los
                Ancianos inmediatamente de una manera ordenada.
              </p>
              <p className={styles.indent2}>
                <b>9.2.2 Género.</b> Si bien reconocemos los dones valiosos que Dios ha dado a las mujeres y su ayuda
                valiosa que ellas pueden dar a los oficiales de la iglesia (Rom 16:1-16; Filp 4:3; 1 Tim 3:11), la
                Biblia prohíbe a las mujeres el tener ya sea el oficio de Diacono o Anciano en la iglesia (1 Cor
                14:33b-35; 1 Tim 2:8-15; 3:1-7). Por lo tanto, las mujeres no serán nominadas, elegidas, u ordenadas a
                cualquiera de estos oficios en la iglesia. Es también contrario a la Escritura que alguna mujer esté a
                la cabeza de liderazgo en una reunión formal de la iglesia cuando hombres adultos se encuentren
                presentes, ya sea dirigiendo la oración, dirigiendo la adoración, leyendo la Escritura, dirigiendo el
                canto, o administrando los sacramentos, o ministrando la Palabra de Dios (1 Cor 14:33b-35; 1 Tim
                2:8-15). Puesto que es también una violación a las Escrituras que una mujer ejerza autoridad sobre un
                hombre en las cosas espirituales fuera de la reunión de la iglesia, ninguna mujer deberá ser designada a
                una función de enseñanza o de autoridad en un ministerio de la iglesia donde los hombres adultos
                estarían regularmente bajo el ministerio de ella. No obstante, nosotros reconocemos y animamos los dones
                valiosos y ayuda de las mujeres en la instrucción formal a los hijos y a otras mujeres (Tito 2:3-5), en
                la instrucción informal incluso a hombres (1 Cor 11:5; Hch 18:26), y en el ministerio diaconal y
                especialmente en los ministerios de misericordia de la iglesia (1 Tim 5:9-10).
              </p>
              <p className={styles.indent}>
                <b>9.3 ANCIANOS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.3.1 Terminología.</b> Aquellos que han sido llamados por Dios a gobernar y enseñar en la iglesia
                son llamados Ancianos, Pastores u Obispos. Estos tres títulos son intercambiables y son para designar a
                un mismo oficio en la iglesia del Nuevo Testamento (Hch 20:17, 28: Efe 4:11,12; Tito 1:5, 7).
              </p>
              <p className={styles.indent2}>
                <b>9.3.2 Requisitos.</b> Cualquiera que desee el oficio de Anciano debe dar evidencia al pueblo de Dios
                cumplir con los requisitos personales, del hogar y ministeriales que son presentados en la Escritura (1
                Tim 3:1-7; Tito 1:5-9).
              </p>
              <p className={styles.indent2}>
                <b>9.3.3 Autoridad.</b> Ya que la autoridad de los Ancianos de la iglesia es autoridad humana ejercida
                en la casa de Dios, tiene tanto prerrogativas altas como importantes limitaciones:
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.1 Es autoridad limitada.</b> La autoridad de los Ancianos no incluye el derecho a tomar ciertas
                decisiones de manera unilateral. En las decisiones mayores de la vida de la iglesia (tales como aquellas
                que tienen que ver con la admisión de nuevos miembros, disciplina correctiva, el reconocimiento de
                oficiales, y la venta o compra del edificio o propiedad de una iglesia), la iglesia local en conjunto
                tiene voz (Hch 6:2-6; 9:26; 1 Cor 5:4-5; 13; 2 Cor 2:6). Sin embargo, los Ancianos deberán proveer
                liderazgo definitivo a la iglesia en la toma de tales decisiones.
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.2 Es autoridad local.</b> La autoridad de los Ancianos está limitada a la esfera de la iglesia
                local. Por consiguiente, ellos no podrán demandar castigos por el pecado que estén fuera de una
                disciplina eclesiástica bíblica, no invadirán las esferas bíblicamente definidas de otras autoridades
                humanas divinamente ordenadas (esposos, padres, autoridades civiles, y patrones laborales), y no darán
                ordenes al pueblo de Dios en relación a asuntos que no estén especificados en la Escritura más que para
                ordenar la casa de Dios mediante la aplicación de Su Palabra (Mat 22:21; Luc 12:13-14; Hch 20:28; Rom
                13: 1-7; 1 Cor 7:25-28, 35-40; Efe. 5:22-6:9; 1 Ped 5:2-3).
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.3 Es autoridad que rinde cuentas.</b> La autoridad de Ancianos está supeditada por el hecho de
                que ellos mismos son miembros de la iglesia local. Aunque los Ancianos son pastores sobre el rebaño,
                ellos son también miembros del rebaño. Por lo tanto, cada Anciano en lo individual tiene derecho a los
                mismos privilegios, está obligado a las mismas responsabilidades y está sujeto a la misma disciplina
                como todos los otros miembros de la iglesia. De esta manera, cada Anciano está bajo la supervisión de
                sus compañeros Ancianos pero también rinde cuentas a toda la iglesia en su conjunto (Mat 18:15-17;
                23:8-9; Gal 2:11; 3 Jn 1, 9-10).
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.4 Es una autoridad compartida.</b> La autoridad de cada Anciano es la misma. De esta manera,
                cada Anciano tiene igual autoridad en la iglesia. Aunque los dones que se tienen y las funciones
                realizadas pueden variar de Anciano a Anciano, esta diversidad no debe debilitar la paridad real entre
                los Ancianos (Hch 20:17, 28; Gal 2:11; 1 Ped 5:1-2; 1 Tim 5:17).
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.5 Es autoridad real.</b> Las Escrituras designan a los Ancianos como aquellos que “gobiernan”
                en la iglesia (1 Tim 5:17; Heb 13:17; nótese también los títulos bíblicos y funciones del oficio). Por
                lo tanto, el pueblo de Dios está obligado a someterse cuando tal autoridad es ejercida bíblicamente.
              </p>
              <p className={styles.indent2}>
                <b>9.3.4 Visitas pastorales.</b> Un aspecto crucial de los deberes de los Ancianos es el velar
                personalmente por el rebaño de Dios. El cumplir con este deber deberá incluir el reunirse de manera
                regular y sistemáticamente con cada miembro de la iglesia por lo menos anualmente, excepto cuando sea
                imposible físicamente debido a la distancia.
              </p>
              <p className={styles.indent2}>
                <b>9.3.5 Remuneración.</b> Los Ancianos serán mantenidos para sus necesidades materiales y sin que
                tengan que ocuparse de las preocupaciones de otra vocación de acuerdo a los recursos, las necesidades y
                capacidades de la iglesia y a la dirección de Cristo Su cabeza (1 Cor 9:4-14; 1 Tim 5:17-18).
              </p>
              <p className={styles.indent2}>
                <b>9.3.6 Pluralidad.</b> Aunque una pluralidad de Ancianos es una norma del Nuevo Testamento para cada
                iglesia, el Nuevo Testamento no especifica el número de Ancianos que cada iglesia debe tener, ni tampoco
                dicta el tiempo de lo que debe durar el Anciano en su puesto. Alguien verdaderamente llamado a este
                puesto es usualmente llamado de por vida. Él es un regalo de Cristo para la iglesia, y los regalos de
                Dios son sin arrepentimiento después. Solamente cuando un Anciano falla en cumplir con las
                calificaciones escriturales necesarias para su oficio él mismo se descalifica para ser un Anciano. Un
                Anciano podrá con honor renunciar a su oficio por un tiempo si él está impedido providencialmente para
                llevar a cabo sus deberes pastorales apropiadamente. Él podrá, a discreción de los Ancianos, ser
                reexaminado por la congregación, y volver a asumir su cargo cuando aquellos impedimentos hayan sido
                quitados.
              </p>
              <p className={styles.indent2}>
                <b>9.3.7 Ausencia de Anciano.</b> Si el cargo de oficio está vacante, los Diáconos podrán convocar a una
                reunión de la iglesia en la cual alguno de los miembros habrá de presidir. En esta reunión la iglesia,
                por votación, se colocará a sí misma bajo la guía temporal de los Ancianos de una iglesia similar
                Bautista Reformada. Esta guía se mantendrá en efecto hasta que sea quitada por votación congregacional.
              </p>
              <p className={styles.indent}>
                <b>9.4 DIACONOS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.4.1 Propósito.</b> El oficio de Diacono fue ordenado por los Apóstoles para facilitarle a los
                ministros de la Palabra el concentrarse en las funciones especificas de su cargo, específicamente la
                predicación, la enseñanza, la consejería y la oración (Hch 6:1-6; 20:20, 31; Efe 4:11-13). Los diáconos
                son responsables principalmente para administrar los asuntos de misericordia y de negocios de la
                iglesia.
              </p>
              <p className={styles.indent2}>
                <b>9.4.2 Requisitos.</b> Los requisitos para el oficio de Diacono son casi idénticos a los del oficio de
                Anciano (1 Tim 3:1-13; Tito 1:5). La única diferencia que contrasta entre la lista de requisitos de los
                dos oficios es que los Ancianos deben ser “aptos para enseñar” (1 Tim 3:2).
              </p>
              <p className={styles.indent2}>
                <b>9.4.3 Relación con los Ancianos.</b> La Escritura indica que los Ancianos tienen autoridad sobre
                asuntos diaconales (Hch 6:2-4; 11:30). Por lo tanto, los diáconos deben de cumplir con los deberes de su
                cargo cooperando con y en sujeción a los Ancianos.
              </p>
              <p className={styles.indent2}>
                <b>9.4.4 Número.</b> El número de Diáconos no será fijo. La iglesia escogerá tantos diáconos como sean
                necesarios de entre los varones que den evidencia de cumplir con los requisitos bíblicos para el cargo
                (Hch 6:1-6; 1 Tim 3:8-13).
              </p>
              <p className={styles.indent}>
                <b>9.5 DESIGNACIÓN DE LOS OFICIALES</b>
              </p>
              <p className={styles.indent2}>
                <b>9.5.1 Declaración general.</b> La designación de los Ancianos y los Diáconos es prerrogativa del
                Señor Jesucristo solamente. Sin embargo, él ha ordenado que cada iglesia local ejerza la responsabilidad
                de reconocer a aquellos que Él está designando para ser Ancianos y Diáconos en esa iglesia en
                particular. Los Ancianos y Diáconos están ordenados, o designados al cargo mediante la imposición de
                manos por el grupo de Ancianos (Hch 6:6; 1 Tim 4:14). Esta es una expresión de aprobación de la cual los
                Ancianos son responsables (1 Tim 5:22). Por lo tanto, cada oficial debe de tener la aprobación, no
                solamente de la iglesia en conjunto, sino del grupo de Ancianos en particular. La designación del Señor
                de un individuo para cualquier de estos oficios es reconocida mediante la posesión de aquellas virtudes
                de gracia y dones del individuo que son requeridos por la Escritura para ese oficio en particular y su
                convicción propia de que el Señor lo está llamando para ministrar en ese cargo (1 Tim 3:1-7). El
                reconocimiento de los oficiales es un asunto de tal importancia que nunca debe ser buscado sin haber
                estado antes esperando a Dios en mucha oración, sin un escrutinio honesto de los pasajes relevantes de
                la Escritura, y una evaluación franca de aquellos que están siendo considerados. Cada miembro de la
                iglesia tiene una responsabilidad espiritual de estar informado inteligentemente respecto a estos
                asuntos.
              </p>
              <p className={styles.indent2}>
                <b>9.5.2 Procedimiento de la designación.</b> El reconocimiento de aquellos a quienes el Señor ha
                designado para tener algún oficio en esta iglesia es ejecutado en tres pasos: nominación, elección y
                ordenación.
              </p>
              <p className={styles.indent3}>
                <b>9.5.2.1 Nominación.</b> Las nominaciones para cualquiera de los dos oficios son realizadas por el
                grupo de Ancianos. Al menos una vez al año en la sesión de negocios anual una boleta de recomendación
                será llevada a votación. En esta boleta cada miembro con derecho a votar podrá escribir el nombre de uno
                o más miembros varones y el oficio para el cual el votante cree que el miembro a nominar cumple con los
                requisitos. Aquellos hombres nombrados con el veinticinco por ciento o más de los miembros serán
                entonces considerados por los Ancianos. Si los Ancianos están de acuerdo en que los nominados son
                adecuados, ellos serán notificados y se les preguntarán si ellos aceptan ser nominados.
              </p>
              <p className={styles.indent3}>
                <b>9.5.2.2 Elección.</b> Toda reunión de iglesia para la elección de los oficiales será anunciada por
                cuatro Días del Señor consecutivos previos al día de la reunión para la elección. Se debatirá sobre los
                nominados y serán puestos a votación por separado. Durante el tiempo del debate el nominado que está
                bajo consideración y los miembros de su familia inmediata tendrán que salir de la presencia de la
                asamblea. Los requisitos bíblicos serán leídos y explicados, y las cualidades del nominado serán
                abiertamente analizadas en el temor de Dios y con el debido respeto por la reputación del nominado.
                Respecto al voto, la iglesia deberá procurar la unidad de en un mismo sentir, pero si tal unidad no
                llegase a darse, entonces no menos de tres cuartos de las boletas emitidas deberán ser requeridas para
                la elección. Este voto tomará lugar con boletas escritas posterior a una discusión completa y libre
                orientada a los pasajes relevantes en la Escritura.
              </p>
              <p className={styles.indent3}>
                <b>9.5.2.3 Ordenación.</b> Después de la elección de un oficial deberá haber un tiempo reservado en el
                servicio de adoración regular durante el cual el oficial deberá ser ordenado con la imposición de manos
                del grupo de Ancianos. Este acto solemne debe siempre estar acompañado por las oraciones especiales de
                toda la iglesia (Hch 13:1-3). La colocación de las manos de los Ancianos significará su aprobación de un
                oficial elegido.
              </p>
              <p className={styles.indent3}>
                <b>9.5.2.4 Presidencia.</b> Los Ancianos escogerán uno de entre ellos para servir como presidente de sus
                reuniones. Los Diáconos deberán hacer lo mismo.
              </p>
              <p className={styles.indent}>
                <b>9.6 REVISIÓN DE LOS OFICIALES</b>
              </p>
              <p className={styles.indent2}>
                <b>9.6.1 Revisión regular.</b> Deberá haber una revisión del desempeño de cada oficial de acuerdo a los
                requisitos bíblicos para ese cargo cuatro años después de su ordenación en la iglesia y cada cuarto año
                posterior mientras él ocupe el cargo en esta iglesia. El procedimiento para la revisión está explicado
                en la sección 9.6.3.
              </p>
              <p className={styles.indent2}>
                <b>9.6.2 Revisión prematura.</b> Podrá haber razones que requieran que un oficial sea revisado antes del
                tiempo programado regularmente. Tal reunión para revisión deberá ser convocada por una mayoría de los
                Ancianos (o una mayoría de los demás Ancianos en el caso que sea un Anciano). Los miembros podrán
                también solicitar tal reunión. Esta solicitud sólo podrá ser hecha después de que dos o tres testigos (1
                Tim 5:19) se hayan reunido con el oficial en un intento de resolver los asuntos en cuestión. La
                solicitud deberá ser presentada por escrito con las firmas de un tercio de los miembros con derecho a
                votar y que estén en plena comunión con la iglesia. La solicitud deberá ser presentada a los Ancianos,
                quienes a su vez harán el anuncio correspondiente para la reunión. Tal reunión para revisión también
                habrá de seguir el procedimiento de la sección 9.6.3.
              </p>
              <p className={styles.indent2}>
                <b>9.6.3 Procedimiento para revisión.</b> Toda reunión para revisión de un oficial será anunciada por
                cuatro Días del Señor consecutivos antes de llevarse a cabo. Deberá haber una discusión y una votación
                con una boleta por escrito en relación al oficial que está bajo revisión. Durante el debate, el oficial
                que está bajo revisión y los miembros de su familia inmediata deberán salir de la presencia de la
                asamblea. Los requisitos bíblicos deberán ser leídos y explicados, y las cualidades del oficial
                analizadas abiertamente en el temor de Dios y con el debido respeto por la reputación del oficial. Todo
                miembro que sugiera públicamente en dicha reunión que el oficial que está siendo revisado no está
                calificado para su cargo deberá haber hablado antes con el oficial y haber informado a los Ancianos de
                la iglesia acerca de sus inquietudes (1 Tim 5:19). También él deberá presentar fundamento bíblico y
                basado en hechos para sus inquietudes en la reunión de revisión. Así como es incorrecto para una iglesia
                el mantener a un oficial que no está bíblicamente calificado, así también es rebelión contra la Cabeza
                de la iglesia el rechazar a un oficial por razones que no tengan fundamento bíblico. Adicionalmente,
                cualquier oficial de quien tales inquietudes sean expresadas, si él así lo desea, se le permitirá
                regresar a la reunión y defenderse él mismo. Respecto al voto, la iglesia deberá buscar la unidad de
                pensamiento, pero si tal unidad no llegase a darse, no menos de tres cuartos de estas boletas emitidas
                deberán ser requeridas para la confirmación de un oficial en su cargo. Todo oficial que no alcance a ser
                confirmado no tendrá más cargo en la iglesia.
              </p>
              <p className={styles.indent2}>
                <b>9.6.4 Renuncia.</b> Un oficial podrá renunciar a su cargo sin prejuicio si él lo hace en una manera
                ordenada y por razones buenas y validas. Esta renuncia junto con sus razones y la fecha en la que él
                desea que su renuncia sea efectiva deberá ser entregada por escrito a los Ancianos de la iglesia.
              </p>
              <p className={styles.indent}>
                <b>9.7 APOYO COMPLETO DE LOS ANCIANOS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.7.1 Consideraciones generales.</b> Aunque todos los Ancianos son iguales en su autoridad que les da
                su cargo, no todos los Ancianos poseen cualidades que justifiquen apoyo financiero completo en su cargo.
                La Biblia enseña que la habilidad especial de gobernar la iglesia y especialmente la habilidad de
                enseñar y predicar públicamente son dones dignos de apoyo completo (1 Cor 9:1-14; Gal 6:6; 1 Tim 5:17).
                Por consiguiente, antes de comprometerse a proveer apoyo completo, la iglesia deberá saber si un Anciano
                o nominado al cargo de Anciano posee dones ministeriales especiales y que él se distingue en el uso de
                estos dones para beneficio de la iglesia, según haya posibilidades de acuerdo a sus oportunidades. Se
                deberá tener precaución especial al dar apoyo completo a un Anciano por las razones a continuación: (1)
                El apoyo completo implica dejar su vocación secular, la cual, según el interés del Reino de Cristo y de
                su familia, podría ser una ocupación en la que él tenga una posición con más ventajas; (2) significa una
                porción mayor de la mayordomía financiera de la iglesia, sobre la cual su Cabeza pedirá cuentas; y (3)
                un Anciano que cuenta con apoyo completo tiene una influencia mayor sobre la iglesia, para bien o para
                mal. Las disposiciones de esta sección aplican a cualquier proporción de apoyo financiero que sea
                requerida a causa del aumento de ministerio que impida un trabajo de tiempo completo de un Anciano en
                una vocación secular.
              </p>
              <p className={styles.indent2}>
                <b>9.7.2 Procedimientos para el apoyo completo.</b> Los Ancianos podrán recomendar a la iglesia que un
                Anciano existente o nominado al cargo de Anciano sea apoyado completamente.
              </p>
              <p className={styles.indent3}>
                <b>9.7.2.1 Procedimiento para el apoyo completo a un nominado.</b> En el caso de un nominado, el apoyo
                completo podrá ser considerado en conjunto con la consideración de sus cualidades para el cargo de
                Anciano. En tal caso, los Ancianos informarán a la iglesia de la recomendación de ellos cuando la sesión
                de negocios para este propósito sea anunciada. No es necesario que haya un debate y votación para
                elección al cargo y para el apoyo completo en su cargo por separado.
              </p>
              <p className={styles.indent3}>
                <b>9.7.2.2 Procedimiento para el apoyo completo a un Anciano existente.</b> En caso de que un Anciano
                existente esté siendo recomendado para apoyo completo, una reunión de la iglesia para considerar esta
                recomendación será anunciada por cuatro Días del Señor consecutivos antes de que se lleve a cabo la
                reunión. Tal recomendación podrá ser considerada en conjunto con la revisión del Anciano en cuestión. No
                es necesario que haya un debate y votación para elección al cargo y para el apoyo completo en su cargo
                por separado.
              </p>
              <p className={styles.indent3}>
                <b>9.7.2.3 Conducta de la reunión para apoyo completo.</b> Durante toda reunión donde el apoyo completo
                sea considerado, atención especial debe ser dada a la enseñanza relevante de la Escritura (1 Cor 9:1-14;
                Gal 6:6; 1 Tim 5:17). Durante la discusión la persona bajo consideración y los miembros de su familia
                inmediata deberán salir de la presencia de la asamblea. Tal discusión debe en todo tiempo reflejar el
                temor de Dios, verdad en sus declaraciones, y la seriedad que corresponde a la gravedad de este asunto.
                Todo voto para apoyo completo requiere no menos de tres cuartos de las boletas emitidas para su
                aprobación.
              </p>
              <p className={styles.indent3}>
                <b>9.7.2.4 Revisión del apoyo completo.</b> El apoyo completo de los Ancianos así como su continuación
                en el cargo estará sujeto a revisión (ver sección 9.6). Normalmente, una revisión del apoyo completo
                podrá llevarse a cabo en conjunto con la revisión del desempeño del Anciano según los requisitos para su
                cargo, ya sea en intervalos regulares o en reuniones especiales de revisión. Sin embargo, las
                circunstancias podrían darse en las que el apoyo completo de un Anciano necesite ser revisado como un
                asunto aparte de la continuación en su cargo. En tales casos, una revisión deberá seguir los
                procedimientos aplicables descritos arriba. La continuación del apoyo completo requerirá no menos de
                tres cuartos de las boletas emitidas.
              </p>
            </div>
            <div id="article10">
              <h4 className="header">ARTÍCULO 10 – OFICIALES LEGALES</h4>
              <p className={styles.indent}>
                <b>10.1 PROPOSITO.</b> Para estar en conformidad con la ley del Estado de California que gobierna las
                organizaciones sin fines de lucro, esta iglesia tendrá tales oficiales como la ley lo requiere para
                representar la iglesia ante el estado.
              </p>
              <p className={styles.indent}>
                <b>10.2 Requisitos.</b> Los oficiales legales deberán ser miembros regulares que estén en plena comunión
                con la iglesia. (Sección 6.3.1).
              </p>
              <p className={styles.indent}>
                <b>10.3 AUTORIDAD.</b> Los oficiales legales laboran bajo la autoridad de los Ancianos (o sus
                designados). Ya que su oficio no está bíblicamente mandado, ellos no tienen rol en el gobierno de los
                asuntos de esta iglesia.
              </p>
              <p className={styles.indent}>
                <b>10.4 DESIGNACIÓN.</b> La designación de los oficiales legales será responsabilidad de los Ancianos.
                La duración de su término en el cargo será de un año, pero podrá ser renovado por tiempo indefinido.
              </p>
            </div>
            <div id="article11">
              <h4 className="header">ARTÍCULO 11 – SESIONES DE NEGOCIO DE LA IGLESIA</h4>
              <p className={styles.indent}>
                <b>11.1 DECLARACIÓN GENERAL.</b> Todas las reuniones congregacionales serán convocadas por los Ancianos.
                Habrá una sesión de negocios anual de la iglesia al finalizar Enero para escuchar reportes, para la
                votación de la boleta de recomendación de los Ancianos (sección 9.5.2.1), y para hablar de otros
                negocios que los Ancianos traigan al pleno de la iglesia. Podrán ser convocadas sesiones de negocio
                especiales en otras fechas a discreción de los Ancianos.
              </p>
              <p className={styles.indent}>
                <b>11.2 AVISO DE LAS REUNIONES.</b> Aviso de todas las reuniones congregacionales en las cuales negocios
                serán tratados se anunciará en los servicios regulares por al menos (2) domingos consecutivos. Otras
                sesiones de negocio en las cuales no haya asuntos que tratar por votación podrán ser convocadas a
                discreción de los Ancianos sin previo aviso.
              </p>
              <p className={styles.indent}>
                <b>11.3 QUÓRUM.</b> Cincuenta por ciento de la votación de la membresía constituirá el quórum para
                tratar asuntos.
              </p>
              <p className={styles.indent}>
                <b>11.4 PRESIDENCIA.</b> Los Ancianos designarán a uno de ellos para presidir en todas las sesiones de
                negocios.
              </p>
              <p className={styles.indent}>
                <b>11.5 VOTACIÓN.</b> Todos los miembros regulares y temporales que hayan alcanzado la edad de dieciocho
                años a menos que hayan sido suspendidos por la iglesia podrán constituir la membresía con derecho a
                votación de la iglesia. Todos los miembros votantes deben de considerar su presencia a una reunión de la
                iglesia debidamente convocada con la misma seriedad con la que ellos considerarían su asistencia a los
                servicios de adoración establecidos. Los Ancianos determinarán si acaso serán permitidos votos de
                miembros ausentes según sea cada caso cuando los miembros hagan saber la razón de su ausencia. La
                unanimidad de corazón y de mente deberá en todo tiempo ser procurada y pedida en oración (Hch 6.5), pero
                cuando tal unanimidad no es lograda, no menos de una mayoría de dos tercios de los que tienen derecho a
                votar podrán hacer una resolución valida. En otros asuntos en los cuales la Constitución requiera una
                proporción diferente de votos, se hará caso omiso de esta cantidad de dos tercios y se seguirá la
                declaración explícitamente expresada en la Constitución respecto a esas categorías de negocios.
              </p>
            </div>
            <div id="article12">
              <h4 className="header">ARTÍCULO 12 – AUTORIDAD CONSTITUCIONAL</h4>
              <p className={styles.indent}>
                <b>12.1 EXTENSIÓN.</b> La Constitución, como cualquier otro documento no inspirado, no es infalible. Sin
                embargo, sí refleja un intento sincero y profundo de aplicar las Escrituras en el orden de la vida de
                esta iglesia local. Además, nosotros como miembros de esta iglesia, incluyendo los Ancianos, nos
                comprometemos solemnemente a seguir esta Constitución en el orden de la vida de esta iglesia (ver el
                Preámbulo). Por lo tanto, las demandas del noveno mandamiento, y la santidad de la verdad en general,
                demandan que los Ancianos y todos los demás miembros de esta iglesia cumplamos nuestro compromiso mutuo.
              </p>
              <p className={styles.indent}>
                <b>12.2 LIMITACIONES.</b> Solamente cuando debamos obedecer a Dios en lugar de lo que establece esta
                Constitución podremos ignorar sus demandas (Hch 5:29). Si en algún momento un miembro de esta iglesia se
                da cuenta que el comprometerse con esta Constitución sería violar algún principio bíblico él deberá
                hacerlo saber a los Ancianos. Si los Ancianos concluyen que el principio bíblico demanda el ignorar algo
                establecido por esta Constitución, ellos estarán obligados a comunicar esto junto con la o las razones
                con que llegaron a su conclusión a la iglesia dentro del lapso de un mes en una reunión de la iglesia
                propiamente convocada. Además, las enmiendas relevantes a esta Constitución deberán ser puestas a
                consideración de la iglesia y resueltas de acuerdo a lo establecido en la sección 12.3 dentro del lapso
                de un año después de esta reunión informativa.
              </p>
              <p className={styles.indent}>
                <b>12.3 ENMIENDAS.</b> Las enmiendas a esta Constitución podrán ser adoptadas por tres cuartos de la
                mayoría de los miembros presentes y votantes en una reunión de la iglesia debidamente convocada. Las
                enmiendas propuestas deberán ser distribuidas a la congregación en forma escrita por lo menos dos
                semanas antes de esta reunión.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page
        permalink="about-rbcr/constitucion/"
        header="Constitución de la Iglesia Bautista Reformada de Riverside"
        content={this.content()}
      />
    );
  }
}

export default SpanishConstitution;
