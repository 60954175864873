import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import styles from "../Confession/Confession.module.scss";
import Page from "../Page/Page";
import UpButton from "../ScrollToTop/Button";

class Confession extends Component {
  state = {
    showScrollButton: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll(event) {
    this.setState({ showScrollButton: window.scrollY > 1050 });
  }

  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <p className={styles.intro_content}>
                THE BAPTIST CONFESSION OF FAITH OF 1689
                <br />
                <b>WITH SCRIPTURE PROOFS</b>
                <br />
                Put forth by the Elders and Brethren of many Congregations of Christians <br />
                <b>
                  (baptized upon profession of their faith) in London and the Country.
                  <br />
                  <br />
                  “…for with the heart man believes, resulting in righteousness,
                  <br />
                  and with the mouth he confesses, resulting in salvation.”
                  <br />
                  Romans 10:10
                </b>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.contents}>
          <div className={styles.container}>
            <h3 id="contents" className={styles.article_title}>
              CONTENTS
            </h3>
            <hr />
            <div className={styles.articleGrid}>
              <AnchorLink offset={() => 150} href="#Chapter Titles">
                <button className={styles.contentsBtn}>Chapters</button>
              </AnchorLink>
              <AnchorLink offset={() => 150} href="#Foreward">
                <button className={styles.contentsBtn}>Foreward</button>
              </AnchorLink>
              <AnchorLink offset={() => 150} href="#Baptist Confession">
                <button className={styles.contentsBtn}>Confession</button>
              </AnchorLink>
              <AnchorLink offset={() => 150} href="#Ending Statement">
                <button className={styles.contentsBtn}>Statements</button>
              </AnchorLink>
            </div>
            <div className={styles.contents}>
              <div>
                <div id="chapters" className={styles.article_text}>
                  <a id="Chapter Titles"></a>
                  <h3 className={styles.article_title}>CHAPTER TITLES</h3>
                  <hr />
                  <p>
                    <span>
                      1. <AnchorLink href="#Ch.1">Of the Holy Scriptures</AnchorLink>
                    </span>
                    <br />
                    <span>
                      2. <AnchorLink href="#Ch.2">Of God and the Holy Trinity</AnchorLink>
                    </span>
                    <br />
                    <span>
                      3. <AnchorLink href="#Ch.3">Of God's Decree</AnchorLink>
                    </span>
                    <br />
                    <span>
                      4. <AnchorLink href="#Ch.4">Of Creation</AnchorLink>
                    </span>
                    <br />
                    <span>
                      5. <AnchorLink href="#Ch.5">Of Divine Providence</AnchorLink>
                    </span>
                    <br />
                    <span>
                      6. <AnchorLink href="#Ch.6">Of the Fall of Man, of Sin, and of the Punishment thereof</AnchorLink>
                    </span>
                    <br />
                    <span>
                      7. <AnchorLink href="#Ch.7">Of God's Covenant</AnchorLink>
                    </span>
                    <br />
                    <span>
                      8. <AnchorLink href="#Ch.8">Of Christ the Mediator</AnchorLink>
                    </span>
                    <br />
                    <span>
                      9. <AnchorLink href="#Ch.9">Of Free Will</AnchorLink>
                    </span>
                    <br />
                    <span>
                      10. <AnchorLink href="#Ch.10">Of Effectual Calling</AnchorLink>
                    </span>
                    <br />
                    <span>
                      11. <AnchorLink href="#Ch.11">Of Justification</AnchorLink>
                    </span>
                    <br />
                    <span>
                      12. <AnchorLink href="#Ch.12">Of Adoption</AnchorLink>
                    </span>
                    <br />
                    <span>
                      13. <AnchorLink href="#Ch.13">Of Sanctification</AnchorLink>
                    </span>
                    <br />
                    <span>
                      14. <AnchorLink href="#Ch.14">Of Saving Faith</AnchorLink>
                    </span>
                    <br />
                    <span>
                      15. <AnchorLink href="#Ch.15">Of Repentance unto Life and Salvation</AnchorLink>
                    </span>
                    <br />
                    <span>
                      16. <AnchorLink href="#Ch.16">Of Good Works</AnchorLink>
                    </span>
                    <br />
                    <span>
                      17. <AnchorLink href="#Ch.17">Of the Perseverance of the Saints</AnchorLink>
                    </span>
                    <br />
                    <span>
                      18. <AnchorLink href="#Ch.18">Of the Assurance of Grace and Salvation</AnchorLink>
                    </span>
                    <br />
                    <span>
                      19. <AnchorLink href="#Ch.19">Of the Law of God</AnchorLink>
                    </span>
                    <br />
                    <span>
                      20. <AnchorLink href="#Ch.20">Of the Gospel and the Extent of Grace thereof</AnchorLink>
                    </span>
                    <br />
                    <span>
                      21. <AnchorLink href="#Ch.21">Of Christian Liberty and Liberty of Conscience</AnchorLink>
                    </span>
                    <br />
                    <span>
                      22. <AnchorLink href="#Ch.22">Of Religious Worship and the Sabbath Day</AnchorLink>
                    </span>
                    <br />
                    <span>
                      23. <AnchorLink href="#Ch.23">Of Lawful Oaths and Vows</AnchorLink>
                    </span>
                    <br />
                    <span>
                      24. <AnchorLink href="#Ch.24">Of the Civil Magistrate</AnchorLink>
                    </span>
                    <br />
                    <span>
                      25. <AnchorLink href="#Ch.25">Of Marriage</AnchorLink>
                    </span>
                    <br />
                    <span>
                      26. <AnchorLink href="#Ch.26">Of the Church</AnchorLink>
                    </span>
                    <br />
                    <span>
                      27. <AnchorLink href="#Ch.27">Of the Communion of Saints</AnchorLink>
                    </span>
                    <br />
                    <span>
                      28. <AnchorLink href="#Ch.28">Of Baptism and the Lord's Supper</AnchorLink>
                    </span>
                    <br />
                    <span>
                      29. <AnchorLink href="#Ch.29">Of Baptism</AnchorLink>
                    </span>
                    <br />
                    <span>
                      30. <AnchorLink href="#Ch.30">Of the Lord's Supper</AnchorLink>
                    </span>
                    <br />
                    <span>
                      31. <AnchorLink href="#Ch.31">Of the State of Man after Death, and of the Resurrection of the Dead</AnchorLink>
                    </span>
                    <br />
                    <span>
                      32. <AnchorLink href="#Ch.32">Of the Last Judgment</AnchorLink>
                    </span>
                  </p>
                  <a id="Foreward" name="Foreward"></a>
                  <h3 className={styles.article_title}>FOREWORD</h3>
                  <hr />
                  <div className={styles.article_text}>
                    <p>
                      <span>
                        <br />
                        ‘I have thought it right to reprint in a cheap form this excellent list of doctrines, which were
                        subscribed to by the Baptist Ministers in the year 1689.  We need a banner because of the truth;
                        it may be that this small volume may aid the cause of the glorious gospel by testifying plainly
                        what are its leading doctrines . . . May the Lord soon restore unto His Zion a pure language,
                        and may her watchmen see eye to eye.’  So wrote the young C.H. Spurgeon, then in the second year
                        of his ministry at New Park Street Chapel, Southwark, in a preface addressed to All the
                        Household of Faith, who rejoice in the glorious doctrines of Free Grace with which he prefixed
                        this Confession when he published it in October, 1855.
                      </span>
                      <br />
                      <span>
                        <br />
                        The Confession itself was first compiled by the Elders and Brethren of many congregations of
                        Christians, baptized upon their profession of faith, in London and the country (as they then
                        described themselves) in the year 1677.  It was based upon, and drew its inspiration from the
                        Confession drawn up by the Westminster Assembly of Divines a generation earlier, and indeed
                        differs only from it in its teaching upon those matters, such as baptism, the Lord's Supper, and
                        church government, upon which among the Reformed churches the Baptists differ from the
                        Presbyterians.  For fear of persecution, the compilers of the 1677 Confession did not subscribe
                        their names to it, but when, in September, 1689, following the Revolution of the previous year,
                        the Ministers and Messengers of the churches were able to meet in more peaceful times,
                        thirty-seven of them, including all the most eminent Baptist ministers of the day, set their
                        names to the recommendation with which it was circulated among the churches.  Thereafter for
                        between 150 and 200 years it remained the definitive Confession of Faith of the Particular (or
                        Calvinistic) Baptist churches of england and Wales.
                      </span>
                      <br />
                      <span>
                        <br />
                        Mr. Spurgeon did not, however, when he republished this Confession, merely preface it with
                        certain words of general commendation.  He also addressed to his own church at New Park Street
                        some practical words of advice as to how they should use the Confession.  These are still
                        relevant today.
                      </span>
                      <br />
                      <span>
                        <br />
                        ‘This little volume,’ he wrote, ‘is not issued as an authoritative rule, or code of faith,
                        whereby you are to be fettered, but as an assistance to you in controversy, a confirmation in
                        faith, and a means of edification in righteousness.  Here the younger members of our church will
                        have a body of divinity in small compass, and by means of the scriptural proofs, will be ready
                        to give a reason for the hope that is in them.
                      </span>
                      <br />
                      <span>
                        <br />
                        Be not ashamed of your faith; remember it is the ancient gospel of the martyrs, confessors,
                        reformers, and saints.  Above all, it is the truth of God, against which all the gates of Hell
                        cannot prevail.  Let your lives adorn your faith, let your example adorn your creed.  Above all
                        live in Christ Jesus, and walk in Him, giving credence to no teaching but that which is
                        manifestly approved of Him, and owned by the Holy Spirit.  Cleave fast to the Word of God which
                        is here mapped out for you.’  This new edition of the Confession is sent out as a private
                        venture by a small group of Baptists who are convinced that it has a message for this generation
                        and believe its publication to be long overdue.  They hope it will achieve a wide circulation
                        among the churches, and receive the close study which they believe it will richly repay.
                      </span>
                      <br />
                      <span>
                        <br />
                        In England during the 1630’s and the 1640’s Congregationalists and Baptists of Calvinistic
                        persuasion emerged from the Church of England.  Their early existence was marked by repeated
                        cycles of persecution at the hands of the established religion of crown and Parliament.  The
                        infamous Clarendon Code was adopted in the 1660’s to crush all dissent from the official
                        religion of the state.  Periods of rigorous application and intervals of relaxation of these
                        coercive acts haunted Presbyterians, Congregationalists, and Baptists alike.
                      </span>
                      <br />
                      <span>
                        <br />
                        Presbyterians and Congregationalists suffered less than did Baptists under
                        <br />
                        this harassment.  No little reason for their relative success in resisting government tyranny
                        was their united front of doctrinal agreement.  All Presbyterians stood by their Westminster
                        Confession of 1646.  Congregationalists adopted virtually the same articles of faith in the
                        Savoy Confession of 1658.  Feeling their substantial unity with paedobaptists suffering under
                        the same cruel injustice, Calvinistic Baptists met to publish their substantial harmony with
                        them in doctrine.
                      </span>
                      <br />
                      <span>
                        <br />A circular letter was sent to particular Baptist churches in England and Wales asking each
                        assembly to send representatives to a meeting in London in 1677.  A confession consciously
                        modeled after the Westminster Confession of Faith was approved and published.  It has ever since
                        born the name of the Second London Confession.  The First London Confession had been issued by
                        seven Baptist congregations of London in 1644.  That first document had been drawn up to
                        distinguish newly organized Calvinistic Baptists from the Arminian Baptists and the
                        Anabaptists.  Because this second London Confession was drawn up in dark hours of oppression, it
                        was issued anonymously.
                      </span>
                      <br />
                      <span>
                        <br />A preface to the original publication of 1677 says in part: “. . . It is now many years
                        since diverse of us . . . did conceive ourselves under a necessity of publishing a Confession of
                        our Faith, for the information and satisfaction of those that did not thoroughly understand what
                        our principles were, of had entertained prejudices against our profession . . . This was first
                        put forth about the year 1643, in the  name of seven congregations then gathered in London . .
                        .”  (These early Baptists were conscious that the 1644 Calvinistic Baptist Confession predated
                        the 1646 Presbyterian Confession and the 1658 Congregationalist Confession).
                      </span>
                      <br />
                      <span>
                        <br />
                        “Forasmuch as this confession is not now commonly to be had; and also that many others have
                        since embraced the same truth which is owned therein; it was judged necessary by us to join
                        together in giving a testimony to the world of our firm adhering to those wholesome principles .
                        . .”
                      </span>
                    </p>
                  </div>
                  <a id="Baptist Confession"></a>
                  <h3 className={styles.article_title}>THE BAPTIST CONFESSION OF FAITH</h3>
                  <hr />
                  <p className={styles.article_title}>
                    <a id="Ch.1"></a>
                    <strong>
                      <span>CHAPTER 1; OF THE HOLY SCRIPTURES</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1.  The Holy Scripture is the only sufficient, certain, and infallible rule of all
                      saving knowledge, faith, and obedience,<strong>1</strong> although the light of nature, and the
                      works of creation and providence do so far manifest the goodness, wisdom, and power of God, as to
                      leave men inexcusable; yet they are not sufficient to give that knowledge of God and His will
                      which is necessary unto salvation.<strong>2</strong>  Therefore it pleased the Lord at sundry
                      times and in diversified manners to reveal Himself, and to declare (that) His will unto His
                      church;<strong>3</strong> and afterward for the better preserving and propagating of the truth,
                      and for the more sure establishment and comfort of the church against the corruption of the flesh,
                      and the malice of Satan, and of the world, to commit the same wholly unto writing; which makes the
                      Holy Scriptures to be most necessary, those former ways of God's revealing His will unto His
                      people being now completed.<strong>4</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> 2 Tim. 3:15-17;Isa. 8:20; Luke 16:29,31; Eph. 2:20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Rom. 1:19-21, 2:14,15; Psalm 19:1-3
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Heb. 1:1
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Prov. 22:19-21; Rom. 15:4; 2 Pet. 1:19,20
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Under the name of Holy Scripture, or the Word of God written, are now contained all
                      the books of the Old and New Testaments, which are these:
                    </span>
                  </p>
                  <p>
                    <span>OF THE OLD TESTAMENT:</span>
                  </p>
                  <table border="0" width="80%">
                    <tbody>
                      <tr>
                        <td>
                          <span>Genesis</span>
                          <br />
                          <span>Exodus</span>
                          <br />
                          <span>Leviticus</span>
                          <br />
                          <span>Numbers</span>
                          <br />
                          <span>Deuteronomy</span>
                          <br />
                          <span>Joshua</span>
                          <br />
                          <span>Judges</span>
                          <br />
                          <span>Ruth</span>
                          <br />
                          <span>1 Samuel</span>
                          <br />
                          <span>2 Samuel</span>
                        </td>
                        <td>
                          <span>1 Kings</span>
                          <br />
                          <span>2 Kings</span>
                          <br />
                          <span>1 Chronicles</span>
                          <br />
                          <span>2 Chronicles</span>
                          <br />
                          <span>Ezra</span>
                          <br />
                          <span>Nehemiah</span>
                          <br />
                          <span>Ester</span>
                          <br />
                          <span>Job</span>
                          <br />
                          <span>Psalms</span>
                          <br />
                          <span>Proverbs</span>
                        </td>
                        <td>
                          <span>Ecclesiastes</span>
                          <br />
                          <span>The Song of</span>
                          <br />
                          <span>  Solomon</span>
                          <br />
                          <span>Isaiah</span>
                          <br />
                          <span>Jeremiah</span>
                          <br />
                          <span>Lamentations</span>
                          <br />
                          <span>Ezekiel</span>
                          <br />
                          <span>Daniel</span>
                          <br />
                          <span>Hosea</span>
                          <br />
                          <span>Joel</span>
                        </td>
                        <td>
                          <span>Amos</span>
                          <br />
                          <span>Obadiah</span>
                          <br />
                          <span>Jonah</span>
                          <br />
                          <span>Micah</span>
                          <br />
                          <span>Nahum</span>
                          <br />
                          <span>Habakkuk</span>
                          <br />
                          <span>Zephaniah</span>
                          <br />
                          <span>Haggai</span>
                          <br />
                          <span>Zechariah</span>
                          <br />
                          <span>Malachi</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <span>OF THE NEW TESTAMENT:</span>
                  </p>
                  <table border="0" width="60%">
                    <tbody>
                      <tr>
                        <td>
                          <span>Matthew</span>
                          <br />
                          <span>Mark</span>
                          <br />
                          <span>Luke</span>
                          <br />
                          <span>John</span>
                          <br />
                          <span>Acts</span>
                          <br />
                          <span>Romans</span>
                          <br />
                          <span>1 Corinthians</span>
                          <br />
                          <span>2 Corinthians</span>
                          <br />
                          <span>Galatians</span>
                        </td>
                        <td>
                          <span>Ephesians</span>
                          <br />
                          <span>Philippians</span>
                          <br />
                          <span>Colossians</span>
                          <br />
                          <span>1 Thessalonians</span>
                          <br />
                          <span>2 Thessalonians</span>
                          <br />
                          <span>1 Timothy</span>
                          <br />
                          <span>2 Timothy</span>
                          <br />
                          <span>Titus</span>
                          <br />
                          <span>Philemon</span>
                        </td>
                        <td>
                          <span>Hebrews</span>
                          <br />
                          <span>James</span>
                          <br />
                          <span>1 Peter</span>
                          <br />
                          <span>2 Peter</span>
                          <br />
                          <span>1 John</span>
                          <br />
                          <span>2 John</span>
                          <br />
                          <span>3 John</span>
                          <br />
                          <span>Jude</span>
                          <br />
                          <span>Revelation</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <span>
                      All of which are given by the inspiration of God, to be the rule of faith and life.
                      <strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> 2 Tim. 3:16
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. The books commonly called Apocrypha, not being of divine inspiration, are no part of
                      the canon or rule of the Scripture, and, therefore, are of no authority to the church of God, nor
                      to be any otherwise approved or made use of than other human writings.<strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Luke 24:27,44; Rom. 3:2
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. The authority of the Holy Scripture, for which it ought to be believed, depends not
                      upon the testimony of any man or church, but wholly upon God (who is truth itself), the author
                      thereof; therefore it is to be received because it is the Word of God.<strong>7</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 2 Pet. 1:19-21; 2 Tim. 3:16; 2 Thess. 2:13; 1 John 5:9
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. We may be moved and induced by the testimony of the church of God to a high and
                      reverent esteem of the Holy Scriptures; and the heavenliness of the matter, the efficacy of the
                      doctrine, and the majesty of the style, the consent of all the parts, the scope of the whole
                      (which is to give all glory to God), the full discovery it makes of the only way of man's
                      salvation, and many other incomparable excellencies, and entire perfections thereof, are arguments
                      whereby it does abundantly evidence itself to be the Word of God; yet notwithstanding, our full
                      persuasion and assurance of the infallible truth, and divine authority thereof, is from the inward
                      work of the Holy Spirit bearing witness by and with the Word in our hearts.<strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> John 16:13,14; 1 Cor. 2:10-12; 1 John 2:20,27
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. The whole counsel of God concerning all things necessary for His own glory, man's
                      salvation, faith and life, is either expressly set down or necessarily contained in the Holy
                      Scripture: unto which nothing at any time is to be added, whether by new revelation of the Spirit,
                      or traditions of men.<strong>9</strong>  Nevertheless, we acknowledge the inward illumination of
                      the Spirit of God to be necessary for the saving understanding of such things as are revealed in
                      the Word,<strong>10</strong> and that there are some circumstances concerning the worship of God,
                      and government of the church, common to human actions and societies, which are to be ordered by
                      the light of nature and Christian prudence, according to the general rules of the Word, which are
                      always to be observed.<strong>11</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> 2 Tim. 3:15-17; Gal. 1:8,9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> John 6:45; 1 Cor. 2:9-12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> 1 Cor. 11:13,14; 1 Cor. 14:26,40
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. All things in Scripture are not alike plain in themselves, nor alike clear unto all;
                      <strong>12</strong> yet those things which are necessary to be known, believed and observed for
                      salvation, are so clearly propounded and opened in some place of Scripture or other, that not only
                      the learned, but the unlearned, in a due use of ordinary means, may attain to a sufficient
                      understanding of them.<strong>13</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> 2 Pet. 3:16
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Ps. 19:7; Psalm 119:130
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 8. The Old Testament in Hebrew (which was the native language of the people of God of
                      old),<strong>14</strong> and the New Testament in Greek (which at the time of the writing of it
                      was most generally known to the nations), being immediately inspired by God, and by His singular
                      care and providence kept pure in all ages, are therefore authentic; so as in all controversies of
                      religion, the church is finally to appeal to them.<strong>15</strong>  But because these original
                      tongues are not known to all the people of God, who have a right unto, and interest in the
                      Scriptures, and are commanded in the fear of God to read,<strong>16</strong> and search them,
                      <strong>17</strong> therefore they are to be translated into the vulgar language of every nation
                      unto which they come,<strong>18</strong> that the Word of God dwelling plentifully in all, they
                      may worship Him in an acceptable manner, and through patience and comfort of the Scriptures may
                      have hope.<strong>19</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Rom. 3:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Isa. 8:20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Acts 15:15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> John 5:39
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> 1 Cor. 14:6,9,11,12,24,28
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> Col. 3:16
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 9. The infallible rule of interpretation of Scripture is the Scripture itself; and
                      therefore when there is a question about the true and full sense of any Scripture (which are not
                      many, but one), it must be searched by other places that speak more clearly.<strong>20</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> 2 Pet. 1:20, 21; Acts 15:15, 16
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 10. The supreme judge, by which all controversies of religion are to be determined, and
                      all decrees of councils, opinions of ancient writers, doctrines of men, and private spirits, are
                      to be examined, and in whose sentence we are to rest, can be no other but the Holy Scripture
                      delivered by the Spirit, into which Scripture so delivered, our faith is finally resolved.
                      <strong>21</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>21</strong> Matt. 22:29, 31, 32; Eph. 2:20; Acts 28:23
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.2"></a>
                    <strong>
                      <span>CHAPTER 2; OF GOD AND OF THE HOLY TRINITY</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The Lord our God is but one only living and true God;<strong>1</strong> whose
                      subsistence is in and of Himself,<strong>2</strong> infinite in being and perfection; whose
                      essence cannot be comprehended by any but Himself;<strong>3</strong> a most pure spirit,
                      <strong>4</strong> invisible, without body, parts, or passions, who only hath immortality,
                      dwelling in the light which no man can approach unto;<strong>5</strong> who is immutable,
                      <strong>6</strong> immense,<strong>7</strong> eternal,<strong>8</strong> incomprehensible,
                      almighty,<strong>9</strong> every way infinite, most holy,<strong>10</strong> most wise, most
                      free, most absolute; working all things according to the counsel of His own immutable and most
                      righteous will,<strong>11</strong> for His own glory;<strong>12</strong> most loving, gracious,
                      merciful, long-suffering, abundant in goodness and truth, forgiving iniquity, transgression, and
                      sin; the rewarder of them that diligently seek Him,<strong>13</strong> and withal most just and
                      terrible in His judgments,<strong>14</strong> hating all sin,<strong>15</strong> and who will by
                      no means clear the guilty.<strong>16</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> 1 Cor. 8:4,6; Deut. 6:4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Jer. 10:10; Isa. 48:12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Exod. 3:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> John 4:24
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> 1 Tim. 1:17; Deut. 4:15,16
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Mal. 3:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 Kings 8:27; Jer. 23:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Ps. 90:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Gen. 17:1
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Isa. 6:3
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Ps. 115:3; Isa. 46:10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Prov. 16:4; Rom. 11:36
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Exod. 34:6,7; Heb. 11:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Neh. 9:32,33
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Ps. 5:5,6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Exod. 34:7; Nahum 1:2,3
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. God, having all life,<strong>17</strong> glory,<strong>18</strong> goodness,
                      <strong>19</strong> blessedness, in and of Himself, is alone in and unto Himself all-sufficient,
                      not standing in need of any creature which He hath made, nor deriving any glory from them,
                      <strong>20</strong> but only manifesting His own glory in, by, unto, and upon them; He is the
                      alone fountain of all being, of whom, through whom, and to whom are all things,<strong>21</strong>{" "}
                      and He hath most sovereign dominion over all creatures, to do by them, for them, or upon them,
                      whatsoever Himself pleases;<strong>22</strong> in His sight all things are open and manifest,
                      <strong>23</strong> His knowledge is infinite, infallible, and independent upon the creature, so
                      as nothing is to Him contingent or uncertain;<strong>24</strong> He is most holy in all His
                      counsels, in all His works,<strong>25</strong> and in all His commands; to Him is due from angels
                      and men, whatsoever worship,<strong>26</strong> service, or obedience, as creatures they owe unto
                      the Creator, and whatever He is further pleased to require of them.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> John 5:26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> Ps. 148:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> Ps. 119:68
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> Job 22:2,3
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>21</strong> Rom. 11:34-36
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>22</strong> Dan. 4:25,34,35
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>23</strong> Heb. 4:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>24</strong> Ezek. 11:5; Acts 15:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>25</strong> Ps. 145:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>26</strong> Rev. 5:12-14
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. In this divine and infinite Being there are three subsistences, the Father, the Word
                      or Son, and Holy Spirit,<strong>27</strong> of one substance, power, and eternity, each having the
                      whole divine essence, yet the essence undivided:<strong>28</strong> the Father is of none, neither
                      begotten nor proceeding; the Son is eternally begotten of the Father;<strong>29</strong> the Holy
                      Spirit proceeding from the Father and the Son;<strong>30</strong> all infinite, without beginning,
                      therefore but one God, who is not to be divided in nature and being, but distinguished by several
                      peculiar relative properties and personal relations; which doctrine of the Trinity is the
                      foundation of all our communion with God, and comfortable dependence on Him.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>27</strong> 1 John 5:7; Matt. 28:19; 2 Cor. 13:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>28</strong> Exod. 3:14; John 14:11; I Cor. 8:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>29</strong> John 1:14,18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>30</strong> John 15:26; Gal. 4:6
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.3"></a>
                    <strong>
                      <span>CHAPTER 3; OF GOD’S DECREE</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. God hath decreed in himself, from all eternity, by the most wise and holy counsel of
                      His own will, freely and unchangeably, all things, whatsoever comes to pass;<strong>1</strong> yet
                      so as thereby is God neither the author of sin nor hath fellowship with any therein;
                      <strong>2</strong> nor is violence offered to the will of the creature, nor yet is the liberty or
                      contingency of second causes taken away, but rather established;<strong>3</strong> in which
                      appears His wisdom in disposing all things, and power and faithfulness in accomplishing His
                      decree.<strong>4</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Isa. 46:10; Eph. 1:11; Heb. 6:17; Rom. 9:15,18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> James 1:13; 1 John 1:5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Acts 4:27,28; John 19:11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Num. 23:19; Eph. 1:3-5
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Although God knoweth whatsoever may or can come to pass, upon all supposed
                      conditions,<strong>5</strong> yet hath He not decreed anything, because He foresaw it as future,
                      or as that which would come to pass upon such conditions.<strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Acts 15:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Rom. 9:11,13,16,18
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. By the decree of God, for the manifestation of His glory, some men and angels are
                      predestinated, or foreordained to eternal life through Jesus Christ,<strong>7</strong> to the
                      praise of His glorious grace;<strong>8</strong> others being left to act in their sin to their
                      just condemnation, to the praise of His glorious justice.<strong>9</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> I Tim. 5:21; Matt. 25:34
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Eph. 1:5,6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Rom. 9:22,23; Jude 4
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. These angels and men thus predestinated and foreordained, are particularly and
                      unchangeably designed, and their number so certain and definite, that it cannot be either
                      increased or diminished.<strong>10</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> 2 Tim. 2:19; John 13:18
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. Those of mankind that are predestinated to life, God, before the foundation of the
                      world was laid, according to His eternal and immutable purpose, and the secret counsel and good
                      pleasure of His will, hath chosen in Christ unto everlasting glory, out of His mere free grace and
                      love,<strong>11</strong> without any other thing in the creature as a condition or cause moving
                      Him thereunto.<strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Eph. 1:4, 9, 11; Rom. 8:30; 2 Tim. 1:9; I Thess. 5:9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Rom. 9:13,16; Eph. 2:5,12
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. As God hath appointed the elect unto glory, so He hath, by the eternal and most free
                      purpose of His will, foreordained all the means thereunto;<strong>13</strong> wherefore they who
                      are elected, being fallen in Adam, are redeemed by Christ,<strong>14</strong> are effectually
                      called unto faith in Christ, by His Spirit working in due season, are justified, adopted,
                      sanctified,<strong>15</strong> and kept by His power through faith unto salvation;
                      <strong>16</strong> neither are any other redeemed by Christ, or effectually called, justified,
                      adopted, sanctified, and saved, but the elect only.<strong>17</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> 1 Pet. 1:2; 2; Thess. 2:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> 1 Thess. 5:9, 10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Rom. 8:30; 2 Thess. 2:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> 1 Pet. 1:5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> John 10:26, 17:9, 6:64
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. The doctrine of the high mystery of predestination is to be handled with special
                      prudence and care, that men attending the will of God revealed in His Word, and yielding obedience
                      thereunto, may, from the certainty of their effectual vocation, be assured of their eternal
                      election;<strong>18</strong> so shall this doctrine afford matter of praise,<strong>19</strong>{" "}
                      reverence, and admiration of God, and of humility,<strong>20</strong> diligence, and abundant
                      consolation to all that sincerely obey the gospel.<strong>21</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> 1 Thess. 1:4,5; 2 Pet. 1:10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> Eph. 1:6; Rom. 11:33
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> Rom. 11:5,6,20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>21</strong> Luke 10:20
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.4"></a>
                    <strong>
                      <span>CHAPTER 4; OF CREATION</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. In the beginning it pleased God the Father, Son, and Holy Spirit,<strong>1</strong>{" "}
                      for the manifestation of the glory of His eternal power,<strong>2</strong> wisdom, and goodness,
                      to create or make the world, and all things therein, whether visible or invisible, in the space of
                      six days, and all very good.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> John 1:2,3; Heb. 1:2; Job 26:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Rom. 1:20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Col. 1:16; Gen. 1:31
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. After God had made all other creatures, He created man, male and female,
                      <strong>4</strong> with reasonable and immortal souls,<strong>5</strong> rendering them fit unto
                      that life to God for which they were created; being made after the image of God, in knowledge,
                      righteousness, and true holiness;<strong>6</strong> having the law of God written in their hearts,
                      <strong>7</strong> and power to fulfill it, and yet under a possibility of transgressing, being
                      left to the liberty of their own will, which was subject to change.<strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Gen. 1:27
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Gen. 2:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Eccles. 7:29; Gen. 1;26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Rom. 2:14,15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Gen. 3:6
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Besides the law written in their hearts, they received a command not to eat of the
                      tree of knowledge of good and evil,<strong>9</strong> which while they kept, they were happy in
                      their communion with God, and had dominion over the creatures.<strong>10</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Gen. 2:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Gen. 1:26,28
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.5"></a>
                    <strong>
                      <span>CHAPTER 5; OF DIVINE PROVIDENCE</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. God the good Creator of all things, in His infinite power and wisdom does uphold,
                      direct, dispose, and govern all creatures and things,<strong>1</strong> from the greatest even to
                      the least,<strong>2</strong> by His most wise and holy providence, to the end for the which they
                      were created, according unto His infallible foreknowledge, and the free and immutable counsel of
                      His own will; to the praise of the glory of His wisdom, power, justice, infinite goodness, and
                      mercy.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Heb. 1:3; Job 38:11; Isa. 46:10,11; Ps. 135:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Matt. 10:29-31
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Eph. 1;11
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Although in relation to the foreknowledge and decree of God, the first cause, all
                      things come to pass immutably and infallibly;<strong>4</strong> so that there is not anything
                      befalls any by chance, or without His providence;<strong>5</strong> yet by the same providence He
                      ordered them to fall out according to the nature of second causes, either necessarily, freely, or
                      contingently.<strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Acts 2:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Prov. 16:33
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Gen. 8:22
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. God, in his ordinary providence makes use of means,<strong>7</strong> yet is free to
                      work without,<strong>8</strong> above,<strong>9</strong> and against them<strong>10</strong> at
                      His pleasure.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Acts 27:31, 44; Isa. 55:10, 11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Hosea 1:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Rom. 4:19-21
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Dan. 3:27
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. The almighty power, unsearchable wisdom, and infinite goodness of God, so far
                      manifest themselves in His providence, that His determinate counsel extends itself even to the
                      first fall, and all other sinful actions both of angels and men;<strong>11</strong> and that not
                      by a bare permission, which also He most wisely and powerfully binds, and otherwise orders and
                      governs,<strong>12</strong> in a manifold dispensation to His most holy ends;<strong>13</strong>{" "}
                      yet so, as the sinfulness of their acts proceeds only from the creatures, and not from God, who,
                      being most holy and righteous, neither is nor can be the author or approver of sin.
                      <strong>14</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Rom. 11:32-34; 2 Sam. 24:1; 1 Chron. 21:1
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> 2 Kings 19:28; Ps. 76:10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Gen. 1:20; Isa. 10:6,7,12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Ps. 1;21; 1 John 2:16
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. The most wise, righteous, and gracious God does often times leave for a season His
                      own children to manifold temptations and the corruptions of their own hearts, to chastise them for
                      their former sins, or to discover unto them the hidden strength of corruption and deceitfulness of
                      their hearts, that they may be humbled; and to raise them to a more close and constant dependence
                      for their support upon Himself; and to make them more watchful against all future occasions of
                      sin, and for other just and holy ends.<strong>15</strong>  So that whatsoever befalls any of His
                      elect is by His appointment, for His glory, and their good.<strong>16</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> 2 Chron. 32:25,26,31; 2 Cor. 12:7-9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Rom. 8:28
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. As for those wicked and ungodly men whom God, as the righteous judge, for former sin
                      does blind and harden;<strong>17</strong> from them He not only withholds His grace, whereby they
                      might have been enlightened in their understanding, and wrought upon their hearts;
                      <strong>18</strong> but sometimes also withdraws the gifts which they had,<strong>19</strong> and
                      exposes them to such objects as their corruption makes occasion of sin;<strong>20</strong> and
                      withal, gives them over to their own lusts, the temptations of the world, and the power of Satan,
                      <strong>21</strong> whereby it comes to pass that they harden themselves, under those means which
                      God uses for the softening of others.<strong>22</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> Rom. 1;24-26,28, 11:7,8
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> Deut. 29:4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> Matt. 13:12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> Deut. 2:30; 2 Kings 8:12,13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>21</strong> Ps. 81:11,12; 2 Thess. 2:10-12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>22</strong> Exod. 8:15,32; Isa. 6:9,10; 1 Pet. 2:7,8
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. As the providence of God does in general reach to all creatures, so after a more
                      special manner it takes care of His church, and disposes of all things to the good thereof.
                      <strong>23</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>23</strong> 1 Tim. 4:10; Amos 9:8,9; Isa. 43:3-5
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.6"></a>
                    <strong>
                      <span>CHAPTER 6; OF THE FALL OF MAN, OF SIN, AND OF THE PUNISHMENT THEREOF</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Although God created man upright and perfect, and gave him a righteous law, which had
                      been unto life had he kept it, and threatened death upon the breach thereof,<strong>1</strong> yet
                      he did not long abide in this honor;  Satan using the subtlety of the serpent to subdue Eve, then
                      by her seducing Adam, who, without any compulsion, did willfully transgress the law of their
                      creation, and the command given to them, in eating the forbidden fruit,<strong>2</strong> which
                      God was pleased, according to His wise and holy counsel to permit, having purposed to order it to
                      His own glory.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Gen. 2:16,17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Gen. 3:12,13; 2 Cor. 11:3
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Our first parents, by this sin, fell from their original righteousness and communion
                      with God, and we in them whereby death came upon all:<strong>3</strong> all becoming dead in sin,
                      <strong>4</strong> and wholly defiled in all the faculties and parts of soul and body.
                      <strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Rom. 3:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom 5:12, etc.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Titus 1:15; Gen. 6:5; Jer. 17:9; Rom. 3:10-19
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. They being the root, and by God's appointment, standing in the room and stead of all
                      mankind, the guilt of the sin was imputed, and corrupted nature conveyed, to all their posterity
                      descending from them by ordinary generation,<strong>6</strong> being now conceived in sin,
                      <strong>7</strong> and by nature children of wrath,<strong>8</strong> the servants of sin, the
                      subjects of death,<strong>9</strong> and all other miseries, spiritual, temporal, and eternal,
                      unless the Lord Jesus set them free.<strong>10</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Rom. 5:12-19; 1 Cor. 15:21,22,45,49
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Ps. 51:5; Job 14:4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Eph. 2:3
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Rom. 6:20, 5:12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Heb. 2:14,15; 1 Thess. 1:10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. From this original corruption, whereby we are utterly indisposed, disabled, and made
                      opposite to all good, and wholly inclined to all evil,<strong>11</strong> do proceed all actual
                      transgressions.<strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Rom. 8:7; Col. 1:21
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> James 1:14,15; Matt. 15:19
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. The corruption of nature, during this life, does remain in those that are
                      regenerated;<strong>13</strong> and although it be through Christ pardoned and mortified, yet both
                      itself, and the first motions thereof, are truly and properly sin.<strong>14</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Rom. 7:18,23; Eccles. 7:20; 1 John 1:8
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Rom. 7:23-25; Gal. 5:17
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.7"></a>
                    <strong>
                      <span>CHAPTER 7; OF GOD’S COVENANT</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The distance between God and the creature is so great, that although reasonable
                      creatures do owe obedience to Him as their creator, yet they could never have attained the reward
                      of life but by some voluntary condescension on God's part, which He hath been pleased to express
                      by way of covenant.<strong>1</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Luke 17:10; Job 35:7,8
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Moreover, man having brought himself under the curse of the law by his fall, it
                      pleased the Lord to make a covenant of grace,<strong>2</strong> wherein He freely offers unto
                      sinners life and salvation by Jesus Christ, requiring of them faith in Him, that they may be
                      saved;<strong>3</strong> and promising to give unto all those that are ordained unto eternal life,
                      His Holy Spirit, to make them willing and able to believe.<strong>4</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Gen. 2:17; Gal. 3:10; Rom. 3:20,21
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Rom. 8:3; Mark 16:15,16; John 3:16;
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Ezek. 36:26,27; John 6:44,45; Ps. 110:3
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. This covenant is revealed in the gospel; first of all to Adam in the promise of
                      salvation by the seed of the woman,<strong>5</strong> and afterwards by farther steps, until the
                      full discovery thereof was completed in the New Testament;<strong>6</strong> and it is founded in
                      that eternal covenant transaction that was between the Father and the Son about the redemption of
                      the elect;<strong>7</strong> and it is alone by the grace of this covenant that all the posterity
                      of fallen Adam that ever were saved did obtain life and blessed immortality, man being now utterly
                      incapable of acceptance with God upon those terms on which Adam stood in his state of innocency.
                      <strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Gen. 3:15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Heb. 1:1
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 2 Tim. 1:9; Titus 1:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Heb. 11;6,13; Rom. 4:1,2, &amp;c.; Acts 4:12; John 8:56
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.8"></a>
                    <strong>
                      <span>CHAPTER 8; OF CHRIST THE MEDIATOR</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. It pleased God, in His eternal purpose, to choose and ordain the Lord Jesus, His only
                      begotten Son, according to the covenant made between them both, to be the mediator between God and
                      man;<strong>1</strong> the prophet,<strong>2</strong> priest,<strong>3</strong> and king;
                      <strong>4</strong> head and savior of the church,<strong>5</strong> the heir of all things,
                      <strong>6</strong> and judge of the world;<strong>7</strong> unto whom He did from all eternity
                      give a people to be His seed and to be by Him in time redeemed, called, justified, sanctified, and
                      glorified.<strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Isa. 42:1; 1 Pet. 1:19,20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Acts 3:22
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Heb. 5:5,6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Ps. 2:6; Luke 1:33
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Eph. 1:22,23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Heb. 1:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Acts 17:31
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Isa. 53:10; John 17:6; Rom. 8:30
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. The Son of God, the second person in the Holy Trinity, being very and eternal God,
                      the brightness of the Father's glory, of one substance and equal with Him who made the world, who
                      upholds and governs all things He has made, did, when the fullness of time was complete, take upon
                      Him man's nature, with all the essential properties and common infirmities of it,
                      <strong>9</strong> yet without sin;<strong>10</strong> being conceived by the Holy Spirit in the
                      womb of the Virgin Mary, the Holy Spirit coming down upon her: and the power of the Most High
                      overshadowing her; and so was made of a woman of the tribe of Judah, of the seed of Abraham and
                      David according to the Scriptures;<strong>11</strong> so that two whole, perfect, and distinct
                      natures were inseparably joined together in one person, without conversion, composition, or
                      confusion; which person is very God and very man, yet one Christ, the only mediator between God
                      and man.<strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> John 1:14; Gal. 4;4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Rom. 8:3; Heb. 2:14,16,17, 4:15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Matt. 1:22, 23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Luke 1:27,31,35; Rom. 9:5; 1 Tim. 2:5
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. The Lord Jesus, in His human nature thus united to the divine, in the person of the
                      Son, was sanctified and anointed with the Holy Spirit above measure,<strong>13</strong> having in
                      Him all the treasures of wisdom and knowledge;<strong>14</strong> in whom it pleased the Father
                      that all fullness should dwell,<strong>15</strong> to the end that being holy, harmless,
                      undefiled,<strong>16</strong> and full of grace and truth,<strong>17</strong> He might be
                      throughly furnished to execute the office of mediator and surety;<strong>18</strong> which office
                      He took not upon himself, but was thereunto called by His Father;<strong>19</strong> who also put
                      all power and judgement in His hand, and gave Him commandment to execute the same.
                      <strong>20</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Ps. 45:7; Acts 10:38; John 3:34
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Col. 2:3
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Col. 1:19
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Heb. 7:26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> John 1:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> Heb. 7:22
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> Heb. 5:5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> John 5:22,27; Matt. 28:18; Acts 2;36
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. This office the Lord Jesus did most willingly undertake,<strong>21</strong> which
                      that He might discharge He was made under the law,<strong>22</strong> and did perfectly fulfill
                      it, and underwent the punishment due to us, which we should have born and suffered,
                      <strong>23</strong> being made sin and a curse for us;<strong>24</strong> enduring most grievous
                      sorrows in His soul, and most painful sufferings in His body;<strong>25</strong> was crucified,
                      and died, and remained in the state of the dead, yet saw no corruption:<strong>26</strong> on the
                      third day He arose from the dead<strong>27</strong> with the same body in which He suffered,
                      <strong>28</strong> with which He also ascended into heaven,<strong>29</strong> and there sits at
                      the right hand of His Father making intercession,<strong>30</strong> and shall return to judge men
                      and angels at the end of the world.<strong>31</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>21</strong> Ps. 40:7,8; Heb. 10:5-10; John 10:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>22</strong> Gal 4:4; Matt. 3:15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>23</strong> Gal. 3:13; Isa. 53:6; 1 Pet. 3:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>24</strong> 2 Cor. 5:21
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>25</strong> Matt. 26:37,38; Luke 22:44; Matt. 27:46
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>26</strong> Acts 13:37
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>27</strong> 1 Cor. 15:3,4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>28</strong> John 20:25,27
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>29</strong> Mark 16:19; Acts 1:9-11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>30</strong> Rom. 8:34; Heb. 9:24
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>31</strong> Acts 10:42; Rom. 14:9,10; Acts 1:11; 2 Pet. 2:4
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. The Lord Jesus, by His perfect obedience and sacrifice of Himself, which He through
                      the eternal Spirit once offered up to God, has fully satisfied the justice of God,
                      <strong>32</strong> procured reconciliation, and purchased an everlasting inheritance in the
                      kingdom of heaven, for all those whom the Father has given unto Him.<strong>33</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>32</strong> Heb. 9:14, 10:14; Rom. 3:25,26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>33</strong> John 17:2; Heb. 9:15
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. Although the price of redemption was not actually paid by Christ until after His
                      incarnation, yet the virtue, efficacy, and benefit thereof were communicated to the elect in all
                      ages, successively from the beginning of the world, in and by those promises, types, and
                      sacrifices wherein He was revealed, and signified to be the seed which should bruise the serpent's
                      head;<strong>34</strong> and the Lamb slain from the foundation of the world,<strong>35</strong>{" "}
                      being the same yesterday, and today and for ever.<strong>36</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>34</strong> 1 Cor. 4:10; Heb. 4:2; 1 Pet. 1:10, 11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>35</strong> Rev. 13:8
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>36</strong> Heb. 13:8
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. Christ, in the work of mediation, acts according to both natures, by each nature
                      doing that which is proper to itself; yet by reason of the unity of the person, that which is
                      proper to one nature is sometimes in Scripture, attributed to the person denominated by the other
                      nature.<strong>37</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>37</strong> John 3:13; Acts 20:28
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 8. To all those for whom Christ has obtained eternal redemption, He does certainly and
                      effectually apply and communicate the same, making intercession for them;<strong>38</strong>{" "}
                      uniting them to Himself by His Spirit, revealing to them, in and by His Word, the mystery of
                      salvation, persuading them to believe and obey,<strong>39</strong> governing their hearts by His
                      Word and Spirit,<strong>40</strong> and overcoming all their enemies by His almighty power and
                      wisdom,<strong>41</strong> in such manner and ways as are most consonant to His wonderful and
                      unsearchable dispensation; and all of free and absolute grace, without any condition foreseen in
                      them to procure it.<strong>42</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>38</strong> John 6:37, 10:15,16, 17:9; Rom. 5:10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>39</strong> John 17:6; Eph. 1:9; 1 John 5:20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>40</strong> Rom. 8:9,14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>41</strong> Ps. 110:1; 1 Cor. 15:25,26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>42</strong> John 3:8; Eph. 1:8
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 9. This office of mediator between God and man is proper only to Christ, who is the
                      prophet, priest, and king of the church of God; and may not be either in whole, or any part
                      thereof, transferred from Him to any other.<strong>43</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>43</strong> Tim. 2:5
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 10. This number and order of offices is necessary; for in respect of our ignorance, we
                      stand in need of His prophetical office;<strong>44</strong> and in respect of our alienation from
                      God, and imperfection of the best of our services, we need His priestly office to reconcile us and
                      present us acceptable unto God;<strong>45</strong> and in respect to our averseness and utter
                      inability to return to God, and for our rescue and security from our spiritual adversaries, we
                      need His kingly office to convince, subdue, draw, uphold, deliver, and preserve us to His heavenly
                      kingdom.<strong>46</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>44</strong> John 1:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>45</strong> Col. 1:21; Gal. 5:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>46</strong> John 16:8; Ps. 110:3; Luke 1:74,75
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.9"></a>
                    <strong>
                      <span>CHAPTER 9; OF FREE WILL</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. God has endued the will of man with that natural liberty and power of acting upon
                      choice, that it is neither forced, nor by any necessity of nature determined to do good or evil.
                      <strong>1</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Matt. 17:12; James 1:14; Deut. 30:19
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Man, in his state of innocency, had freedom and power to will and to do that which
                      was good and well-pleasing to God,<strong>2</strong> but yet was unstable, so that he might fall
                      from it.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Eccles. 7:29
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Gen. 3:6
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Man, by his fall into a state of sin, has wholly lost all ability of will to any
                      spiritual good accompanying salvation;<strong>4</strong> so as a natural man, being altogether
                      averse from that good, and dead in sin,<strong>5</strong> is not able by his own strength to
                      convert himself, or to prepare himself thereunto.<strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom. 5:6, 8:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Eph. 2:1,5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Titus 3:3-5; John 6:44
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. When God converts a sinner, and translates him into the state of grace, He frees him
                      from his natural bondage under sin,<strong>7</strong> and by His grace alone enables him freely to
                      will and to do that which is spiritually good;<strong>8</strong> yet so as that by reason of his
                      remaining corruptions, he does not perfectly, nor only will, that which is good, but does also
                      will that which is evil.<strong>9</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Col. 1:13; John 8:36
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Phil. 2:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Rom. 7:15,18,19,21,23
                    </span>
                  </p>
                  <p>
                    <span>Paragraph 5. This will of man is made perfectly and immutably free to good alone in</span>{" "}
                    <span>
                      the state of glory only.<strong>10</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Eph. 4:13
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.10"></a>
                    <strong>
                      <span>CHAPTER 10; OF EFFECTUAL CALLING</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Those whom God hath predestinated unto life, He is pleased in His appointed, and
                      accepted time, effectually to call,<strong>1</strong> by His Word and Spirit, out of that state of
                      sin and death in which they are by nature, to grace and salvation by Jesus Christ;
                      <strong>2</strong> enlightening their minds spiritually and savingly to understand the things of
                      God;<strong>3</strong> taking away their heart of stone, and giving to them a heart of flesh;
                      <strong>4</strong> renewing their wills, and by His almighty power determining them to that which
                      is good, and effectually drawing them to Jesus Christ;<strong>5</strong> yet so as they come most
                      freely, being made willing by His grace.<strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Rom. 8:30, 11:7; Eph. 1:10,11; 2 Thess. 2:13,14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Eph. 2:1-6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Acts 26:18; Eph. 1:17,18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Ezek. 36:26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Deut. 30:6; Ezek. 36:27; Eph. 1:19
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Ps. 110:3; Cant. 1:4
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. This effectual call is of God's free and special grace alone, not from anything at
                      all foreseen in man, nor from any power or agency in the creature,<strong>7</strong> being wholly
                      passive therein, being dead in sins and trespasses, until being quickened and renewed by the Holy
                      Spirit;<strong>8</strong> he is thereby enabled to answer this call, and to embrace the grace
                      offered and conveyed in it, and that by no less power than that which raised up Christ from the
                      dead.<strong>9</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 2 Tim. 1:9; Eph. 2:8
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> 1 Cor. 2:14; Eph. 2:5; John 5:25
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Eph. 1:19, 20
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Elect infants dying in infancy are regenerated and saved by Christ through the
                      Spirit;<strong>10</strong> who works when, and where, and how He pleases;<strong>11</strong> so
                      also are all elect persons, who are incapable of being outwardly called by the ministry of the
                      Word.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> John 3:3, 5, 6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> John 3:8
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. Others not elected, although they may be called by the ministry of the Word, and may
                      have some common operations of the Spirit,<strong>12</strong> yet not being effectually drawn by
                      the Father, they neither will nor can truly come to Christ, and therefore cannot be saved:
                      <strong>13</strong> much less can men that do not receive the Christian religion be saved; be they
                      never so diligent to frame their lives according to the light of nature and the law of that
                      religion they do profess.<strong>14</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Matt. 22:14, 13:20,21; Heb 6:4,5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> John 6:44,45,65; 1 John 2:24,25
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Acts 4:12; John 4:22, 17:3
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.11"></a>
                    <strong>
                      <span>CHAPTER 11; OF JUSTIFICATION</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Those whom God effectually calls, he also freely justifies,<strong>1</strong> not by
                      infusing righteousness into them, but by pardoning their sins, and by accounting and accepting
                      their persons as righteous;<strong>2</strong> not for anything wrought in them, or done by them,
                      but for Christ's sake alone;<strong>3</strong> not by imputing faith itself, the act of believing,
                      or any other evangelical obedience to them, as their righteousness; but by imputing Christ's
                      active obedience unto the whole law, and passive obedience in his death for their whole and sole
                      righteousness by faith,<strong>4</strong> which faith they have not of themselves; it is the gift
                      of God.<strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Rom. 3:24, 8:30
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Rom. 4:5-8, Eph. 1:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> 1 Cor. 1:30,31, Rom. 5:17-19
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Phil. 3:8,9; Eph. 2:8-10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> John 1:12, Rom. 5:17
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Faith thus receiving and resting on Christ and his righteousness, is the alone
                      instrument of justification;<strong>6</strong> yet is not alone in the person justified, but is
                      ever accompanied with all other saving graces, and is no dead faith, but works by love.
                      <strong>7</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Rom. 3:28
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Gal.5:6, James 2:17,22,26
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Christ, by his obedience and death, did fully discharge the debt of all those who are
                      justified; and did, by the sacrifice of himself in the blood of his cross, undergoing in their
                      stead the penalty due to them, make a proper, real, and full satisfaction to God’s justice in
                      their behalf;<strong>8</strong> yet, in as much as he was given by the Father for them, and his
                      obedience and satisfaction accepted in their stead, and both freely, not for anything in them,
                      <strong>9</strong> their justification is only of free grace, that both the exact justice and rich
                      grace of God might be glorified in the justification of sinners.<strong>10</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Heb. 10:14; 1 Pet. 1:18,19; Isa. 53:5,6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Rom. 8:32; 2 Cor. 5:21
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Rom. 3:26; Eph. 1:6,7, 2:7
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. God did from all eternity decree to justify all the elect,<strong>11</strong> and
                      Christ did in the fullness of time die for their sins, and rise again for their justification;
                      <strong>12</strong> nevertheless, they are not justified personally, until the Holy Spirit in time
                      does actually apply Christ to them.<strong>13</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Gal. 3:8, 1 Pet. 1:2, 1 Tim. 2:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Rom. 4:25
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Col. 1:21,22, Titus 3:4-7
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. God continues to forgive the sins of those that are justified,<strong>14</strong> and
                      although they can never fall from the state of justification,<strong>15</strong> yet they may, by
                      their sins, fall under God’s fatherly displeasure;<strong>16</strong> and in that condition they
                      usually do not have the light of his countenance restored to them, until  they humble themselves,
                      beg pardon, and renew their faith and repentance.<strong>17</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Matt. 6:12, 1 John 1:7,9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> John 10:28
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Ps. 89:31-33
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> Ps. 32:5, Ps. 51, Matt. 26:75
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. The justification of believers under the Old Testament was, in all these respects,
                      one and the same with the justification of believers under the New Testament.<strong>18</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> Gal. 3:9; Rom. 4:22-24
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.12"></a>
                    <strong>
                      <span>CHAPTER 12; OF ADOPTION</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. All those that are justified, God conferred, in and for the sake of his only Son
                      Jesus Christ, to make partakers of the grace of adoption,<strong>1</strong> by which they are
                      taken into the number, and enjoy the liberties and privileges of the children of God,
                      <strong>2</strong> have his name put on them,<strong>3</strong> receive the spirit of adoption,
                      <strong>4</strong> have access to the throne of grace with boldness, are enabled to cry Abba,
                      Father,<strong>5</strong> are pitied,<strong>6</strong> protected,<strong>7</strong> provided for,
                      <strong>8</strong> and chastened by him as by a Father,<strong>9</strong> yet never cast off,
                      <strong>10</strong> but sealed to the day of redemption,<strong>11</strong> and inherit the
                      promises as heirs of everlasting salvation.<strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Eph. 1:5; Gal. 4:4,5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> John 1:12; Rom. 8:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> 2 Cor. 6:18; Rev. 3:12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom. 8:15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Gal. 4:6; Eph. 2:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Ps. 103:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Prov. 14:26; 1 Pet. 5:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Heb. 12:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Isa. 54:8, 9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Lam. 3:31
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Eph. 4:30
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Heb. 1:14, 6:12
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.13"></a>
                    <strong>
                      <span>CHAPTER 13; OF SANCTIFICATION</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. They who are united to Christ, effectually called, and regenerated, having a new
                      heart and a new spirit created in them through the virtue of Christ's death and resurrection, are
                      also farther sanctified, really and personally,<strong>1</strong> through the same virtue, by his
                      Word and Spirit dwelling in them;<strong>2</strong> the dominion of the whole body of sin is
                      destroyed,<strong>3</strong> and the several lusts of it are more and more weakened and mortified,
                      <strong>4</strong> and they more and more quickened and strengthened in all saving graces,
                      <strong>5</strong> to the practice of all true holiness, without which no man shall see the Lord.
                      <strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Acts 20:32; Rom. 6:5,6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> John 17:17; Eph. 3:16-19; 1 Thess. 5:21-23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Rom. 6:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Gal. 5:24
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Col. 1:11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> 2 Cor. 7:1; Heb. 12:14
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. This sanctification is throughout the whole man,<strong>7</strong> yet imperfect in
                      this life; there abides still some remnants of corruption in every part,<strong>8</strong>{" "}
                      wherefrom arises a continual and irreconcilable war; the flesh lusting against the Spirit, and the
                      Spirit against the flesh.<strong>9</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 Thess. 5:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Rom. 7:18, 23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Gal. 5:17; 1 Pet. 2:11
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. In which war, although the remaining corruption for a time may much prevail,
                      <strong>10</strong> yet, through the continual supply of strength from the sanctifying Spirit of
                      Christ, the regenerate part does overcome;<strong>11</strong> and so the saints grow in grace,
                      perfecting holiness in the fear of God, pressing after an heavenly life, in evangelical obedience
                      to all the commands which Christ as Head and King, in his Word has prescribed to them.
                      <strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Rom. 7:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Rom. 6:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Eph. 4:15,16; 2 Cor. 3:18, 7:1
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.14"></a>
                    <strong>
                      <span>CHAPTER 14; OF SAVING FAITH</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The grace of faith, whereby the elect are enabled to believe to the saving of their
                      souls, is the work of the Spirit of Christ in their hearts,<strong>1</strong> and is ordinarily
                      wrought by the ministry of the Word;<strong>2</strong> by which also, and by the administration of
                      baptism and the Lord's supper, prayer, and other means appointed of God, it is increased and
                      strengthened.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> 2 Cor. 4:13; Eph. 2:8
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Rom. 10:14,17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Luke 17:5; 1 Pet. 2:2; Acts 20:32
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. By this faith a Christian believes to be true whatsoever is revealed in the Word for
                      the authority of God himself,<strong>4</strong> and also apprehends an excellency therein above
                      all other writings and all things in the world,<strong>5</strong> as it bears forth the glory of
                      God in his attributes, the excellency of Christ in his nature and offices, and the power and
                      fullness of the Holy Spirit in his workings and operations: and so is enabled to cast his soul
                      upon the truth consequently believed;<strong>6</strong> and also acts differently upon that which
                      each particular passage thereof contains; yielding obedience to the commands,<strong>7</strong>{" "}
                      trembling at the threatenings,<strong>8</strong> and embracing the promises of God for this life
                      and that which is to come;<strong>9</strong> but the principle acts of saving faith have immediate
                      relation to Christ, accepting, receiving, and resting upon him alone for justification,
                      sanctification, and eternal life, by virtue of the covenant of grace.<strong>10</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Acts 24:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Ps. 19:7-10, 69:72
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> 2 Tim. 1:12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> John 15:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Isa. 116:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Heb. 11:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> John 1:12; Acts 16:31; Gal:20; Acts 15:11
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. This faith, although it be in different stages, and may be weak or strong,
                      <strong>11</strong> yet it is in the least degree of it different in the kind or nature of it, as
                      is all other saving grace, from the faith and common grace of temporary believers;
                      <strong>12</strong> and therefore, though it may be many times assailed and weakened, yet it gets
                      the victory,<strong>13</strong> growing up in many to the attainment of a full assurance through
                      Christ,<strong>14</strong> who is both the author and finisher of our faith.<strong>15</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Heb. 5:13,14; Matt. 6:30; Rom. 4:19,20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> 2 Pet. 1:1
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Eph. 6:16; 1 John 5:4,5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Heb. 6:11,12; Col. 2:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Heb. 12:2
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.15"></a>
                    <strong>
                      <span>CHAPTER 15; OF REPENTANCE UNTO LIFE AND SALVATION</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Such of the elect that are converted at riper years, having sometime lived in the
                      state of nature, and therein served divers pleasures, God in their effectual calling gives them
                      repentance to life.<strong>1</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Titus 3:2-5
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Whereas there is none that does good and does not sin,<strong>2</strong> and the best
                      of men may, through the power and deceitfulness of their corruption dwelling in them, with the
                      prevalency of temptation, fall in to great sins and provocations; God has, in the covenant of
                      grace, mercifully provided that believers so sinning and falling be renewed through repentance
                      unto salvation.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Eccles. 7:20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Luke 22:31,32
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. This saving repentance is an evangelical grace,<strong>4</strong> whereby a person,
                      being by the Holy Spirit made sensible of the manifold evils of his sin, does, by faith in Christ,
                      humble himself for it with godly sorrow, detestation of it, and self-abhorrancy,<strong>5</strong>{" "}
                      praying for pardon and strength of grace, with a purpose and endeavor, by supplies of the Spirit,
                      to walk before God unto all well-pleasing in all things.<strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Zech. 12:10; Acts 11:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Ezek. 36:31; 2 Cor. 7:11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Ps. 119:6,128
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. As repentance is to be continued through the whole course of our lives, upon the
                      account of the body of death, and the motions thereof, so it is every man’s duty to repent of his
                      particular known sins particularly.<strong>7</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Luke 19:8; 1 Tim. 1:13,15
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. Such is the provision which God has made through Christ in the covenant of grace for
                      the preservation of believers unto salvation, that although there is no sin so small but it
                      deserves damnation,<strong>8</strong> yet there is no sin so great that it shall bring damnation
                      to them that repent,<strong>9</strong> which makes the constant preaching of repentance necessary.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Rom. 6:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Isa. 1:16-18, 55:7
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.16"></a>
                    <strong>
                      <span>CHAPTER 16; OF GOOD WORKS</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Good works are only such as God has commanded in his Holy Word,<strong>1</strong> and
                      not such as without the warrant thereof are devised by men out of blind zeal, or upon any pretense
                      of good intentions.<strong>2</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Mic. 6:8; Heb. 13:21
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Matt. 15:9; Isa. 29:13
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. These good works, done in obedience to God’s commandments, are the fruits and
                      evidences of a true and lively faith;<strong>3</strong> and by them believers manifest their
                      thankfulness,<strong>4</strong> strengthen their assurance,<strong>5</strong> edify their
                      brethren, adorn the profession of the gospel,<strong>6</strong> stop the mouths of the
                      adversaries, and glory God,<strong>7</strong> whose workmanship they are, created in Christ Jesus
                      thereunto,<strong>8</strong> that having their fruit unto holiness they may have the end eternal
                      life.<strong>9</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> James 2:18,22
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Ps. 116:12,13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> 1 John 2:3,5; 2 Pet. 1:5-11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Matt. 5:16
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 Tim. 6:1; 1 Pet. 2:15; Phil. 1:11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Eph. 2:10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Rom 6:22
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Their ability to do good works is not all of themselves, but wholly from the Spirit
                      of Christ;<strong>10</strong> and that they may be enabled thereunto, besides the graces they have
                      already received, there is necessary an actual influence of the same Holy Spirit, to work in them
                      and to will and to do of his good pleasure;<strong>11</strong> yet they are not bound to perform
                      any duty, unless upon a special motion of the Spirit, but they ought to be diligent in stirring up
                      the grace of God that is in them.<strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> John 15:4,5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> 2 Cor. 3:5; Phil. 2:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Phil. 2:12; Heb. 6:11,12; Isa. 64:7
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. They who in their obedience attain to the greatest height which is possible in this
                      life, are so far from being able to supererogate, and to do more than God requires, as that they
                      fall short of much which in duty they are bound to do.<strong>13</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Job 9:2, 3; Gal. 5:17; Luke 17:10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. We cannot by our best works merit pardon of sin or eternal life at the hand of God,
                      by reason of the great disproportion that is between them and the glory to come, and the infinite
                      distance that is between us and God, whom by them we can neither profit nor satisfy for the debt
                      of our former sins;<strong>14</strong> but when we have done all we can, we have done but our
                      duty, and are unprofitable servants; and because they are good they proceed from his Spirit,
                      <strong>15</strong> and as they are wrought by us they are defiled and mixed with so much weekness
                      and imperfection, that they cannot endure the severity of God’s punishment.<strong>16</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Rom. 3:20; Eph. 2:8,9; Rom. 4:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Gal. 5:22,23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Isa. 64:6; Ps. 43:2
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. Yet notwithstanding the persons of believers being accepted through Christ, their
                      good works also are accepted in him;<strong>17</strong> not as thought they were in this life
                      wholly unblamable and unreprovable in God’s sight, but that he, looking upon them in his Son, is
                      pleased to accept and reward that which is sincere, although accompanied with many weaknesses and
                      imperfection.<strong>18</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> Eph. 1:5; 1 Pet. 1:5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> Matt. 25:21,23; Heb. 6:10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. Works done by unregenerate men, although for the matter of them they may things which
                      God commands, and of good use both to themselves and to others;<strong>19</strong> yet because
                      they proceed not from a heart purified by faith,<strong>20</strong> nor are done in a right manner
                      according to the Word,<strong>21</strong> nor to a right end, the glory of God,<strong>22</strong>{" "}
                      they are therfore sinful, and cannot please God, nor make a man meet to receive the grace from
                      God,<strong>23</strong> and yet their neglect fo them is more sinful and displeasing to God.
                      <strong>24</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> 2 Kings 10:30; 1 Kings 21:27,29
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> Gen. 4:5; Heb. 11:4,6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>21</strong> 1 Cor. 13:1
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>22</strong> Matt. 6:2,5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>23</strong> Amos 5:21,22; Rom. 9:16; Titus 3:5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>24</strong> Job 21:14,15; Matt. 25:41-43
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.17"></a>
                    <strong>
                      <span>CHAPTER 17; OF THE PERSEVERANCE OF THE  SAINTS</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Those whom God has accepted in the beloved, effectually called and sanctified by his
                      Spirit, and given the precious faith of his elect unto, can neither totally nor finally fall from
                      the state of grace, but shall certainly persevere therein to the end, and be eternally saved,
                      seeing the gifts and callings of God are without repentance, from which source he still begets and
                      nourishes in them faith, repentance, love, joy, hope, and all the graces of the Spirit unto
                      immortality;<strong>1</strong> and though many storms and floods arise and beat against them, yet
                      they shall never be able to take them off that foundation and rock which by faith they are
                      fastened upon; notwithstanding, through unbelief and the temptations of Satan, the sensible sight
                      of the light and love of God may for a time be clouded and obscured from them,<strong>2</strong>{" "}
                      yet he is still the same, and they shall be sure to be kept by the power of God unto salvation,
                      where they shall enjoy their purchased possession, they being engraved upon the palm of his hands,
                      and their names having been written in the book of life from all eternity.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> John 10:28,29; Phil. 1:6; 2 Tim. 2:19; 1 John 2:19
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Ps. 89:31,32; 1 Cor. 11:32
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Mal. 3:6
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. This perseverance of the saints depends not upon their own free will, but upon the
                      immutability of the decree of election,<strong>4</strong> flowing from the free and unchangeable
                      love of God the Father, upon the efficacy of the merit and intercession of Jesus Christ and union
                      with him,<strong>5</strong> the oath of God,<strong>6</strong> the abiding of his Spirit, and the
                      seed of God within them,<strong>7</strong> and the nature of the covenant of grace;
                      <strong>8</strong> from all which ariseth also the certainty and infallibility thereof.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom. 8:30, 9:11,16
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Rom. 5:9, 10; John 14:19
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Heb. 6:17,18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 John 3:9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Jer. 32:40
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. And though they may, through the temptation of Satan and of the world, the prevalency
                      of corruption remaining in them, and the neglect of means of their preservation, fall into
                      grievous sins, and for a time continue therein,<strong>9</strong> whereby they incur God's
                      displeasure and grieve his Holy Spirit,<strong>10</strong> come to have their graces and comforts
                      impaired,<strong>11</strong> have their hearts hardened, and their consciences wounded,
                      <strong>12</strong> hurt and scandalize others, and bring temporal judgments upon themselves,
                      <strong>13</strong> yet shall they renew their repentance and be preserved through faith in Christ
                      Jesus to the end.<strong>14</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Matt. 26:70,72,74
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Isa. 64:5,9; Eph. 4:30
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Ps. 51:10,12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Ps. 32:3,4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> 2 Sam. 12:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Luke 22:32,61,62
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.18"></a>
                    <strong>
                      <span>CHAPTER 18; OF THE ASSURANCE OF GRACE AND SALVATION</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Although temporary believers and other unregenerate men, may vainly deceive
                      themselves with false hopes and carnal presumptions of being in the favor of God and in a state of
                      salvation, which hope of theirs shall perish;<strong>1</strong> yet such as truly believe in the
                      Lord Jesus, and love him in sincerity, endeavouring to walk in all good conscience before him, may
                      in this life be certainly assured that they are in the state of grace, and may rejoice in the hope
                      of the glory of God,<strong>2</strong> which hope shall never make them ashamed.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Job 8:13,14; Matt. 7:22,23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> 1 John 2:3, 3:14,18,19,21,24, 5:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Rom. 5:2,5
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. This certainty is not a bare conjectural and probable persuasion grounded upon a
                      fallible hope, but an infallible assurance of faith,<strong>4</strong> founded on the blood and
                      righteousness of Christ revealed in the Gospel;<strong>5</strong> and also upon the inward
                      evidence of those graces of the Spirit unto which promises are made,<strong>6</strong> and on the
                      testimony of the Spirit of adoption, witnessing with our spirits that we are the children of God;
                      <strong>7</strong> and, as a fruit thereof, keeping the heart both humble and holy.
                      <strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Heb. 6:11,19
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Heb. 6:17,18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> 2 Pet. 1:4,5,10,11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Rom. 8:15,16
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> 1 John 3:1-3
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. This infallible assurance does not so belong to the essence of faith, but that a true
                      believer may wait long, and struggle with many difficulties before he be partaker of it;
                      <strong>9</strong> yet being enabled by the Spirit to know the things which are freely given him
                      of God, he may, without extraordinary revelation, in the right use of means, attain thereunto:
                      <strong>10</strong> and therefore it is the duty of every one to give all diligence to make his
                      calling and election sure, that thereby his heart may be enlarged in peace and joy in the Holy
                      Spirit, in love and thankfulness to God, and in strength and cheerfulness in the duties of
                      obedience, the proper fruits of this assurance;<strong>11</strong> -so far is it from inclining
                      men to looseness.<strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Isa. 50:10; Ps. 88; Ps. 77:1-12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> 1 John 4:13; Heb. 6:11,12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Rom. 5:1,2,5, 14:17; Ps. 119:32
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Rom. 6:1,2; Titus 2:11,12,14
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. True believers may have the assurance of their salvation divers ways shaken,
                      diminished, and intermitted; as by negligence in preserving of it,<strong>13</strong> by falling
                      into some special sin which wounds the conscience and grieves the Spirit;<strong>14</strong> by
                      some sudden or vehement temptation,<strong>15</strong> by God's withdrawing the light of his
                      countenance, and suffering even such as fear him to walk in darkness and to have no light,
                      <strong>16</strong> yet are they never destitute of the seed of God<strong>17</strong> and life of
                      faith,<strong>18</strong> that love of Christ and the brethren, that sincerity of heart and
                      conscience of duty out of which, by the operation of the Spirit, this assurance may in due time be
                      revived,<strong>19</strong> and by the which, in the meantime, they are preserved from utter
                      despair.<strong>20</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Cant. 5:2,3,6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Ps. 51:8,12,14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Ps. 116:11; 77:7,8, 31:22
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Ps. 30:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> 1 John 3:9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> Luke 22:32
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> Ps. 42:5,11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> Lam. 3:26-31
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.19"></a>
                    <strong>
                      <span>CHAPTER 19; OF THE LAW OF GOD</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. God gave to Adam a law of universal obedience written in his heart, and a particular
                      precept of not eating the fruit of the tree of knowledge of good and evil;<strong>1</strong> by
                      which he bound him and all his posterity to personal, entire, exact, and perpetual obedience;
                      <strong>2</strong> promised life upon the fulfilling, and threatened death upon the breach of it,
                      and endued him with power and ability to keep it.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Gen. 1:27; Eccles. 7:29
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Rom. 10:5
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Gal. 3:10,12
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. The same law that was first written in the heart of man continued to be a perfect
                      rule of righteousness after the fall,<strong>4</strong> and was delivered by God upon Mount Sinai,
                      in ten commandments, and written in two tables, the four first containing our duty towards God,
                      and the other six, our duty to man.<strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom. 2:14,15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Deut. 10:4
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Besides this law, commonly called moral, God was pleased to give to the people of
                      Israel ceremonial laws, containing several typical ordinances, partly of worship, prefiguring
                      Christ, his graces, actions, sufferings, and benefits;<strong>6</strong> and partly holding forth
                      divers instructions of moral duties,<strong>7</strong> all which ceremonial laws being appointed
                      only to the time of reformation, are, by Jesus Christ the true Messiah and only law-giver, who was
                      furnished with power from the Father for that end abrogated and taken away.<strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Heb. 10:1; Col. 2:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 Cor. 5:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Col. 2:14,16,17; Eph. 2:14,16
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. To them also he gave sundry judicial laws, which expired together with the state of
                      that people, not obliging any now by virtue of that institution; their general equity only being
                      of modern use.<strong>9</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> 1 Cor. 9:8-10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. The moral law does for ever bind all, as well justified persons as others, to the
                      obedience thereof,<strong>10</strong> and that not only in regard of the matter contained in it,
                      but also in respect of the authority of God the Creator, who gave it;<strong>11</strong> neither
                      does Christ in the Gospel any way dissolve, but much strengthen this obligation.
                      <strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Rom. 13:8-10; James 2:8,10-12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> James 2:10,11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Matt. 5:17-19; Rom. 3:31
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. Although true believers are not under the law as a covenant of works, to be thereby
                      justified or condemned,<strong>13</strong> yet it is of great use to them as well as to others, in
                      that as a rule of life, informing them of the will of God and their duty, it directs and binds
                      them to walk accordingly; discovering also the sinful pollutions of their natures, hearts, and
                      lives, so as examining themselves thereby, they may come to further conviction of, humiliation
                      for, and hatred against, sin;<strong>14</strong> together with a clearer sight of the need they
                      have of Christ and the perfection of his obedience; it is likewise of use to the regenerate to
                      restrain their corruptions, in that it forbids sin; and the threatenings of it serve to show what
                      even their sins deserve, and what afflictions in this life they may expect for them, although
                      freed from the curse and unallayed rigour thereof.  The promises of it likewise show them God's
                      approbation of obedience, and what blessings they may expect upon the performance thereof, though
                      not as due to them by the law as a covenant of works; so as man's doing good and refraining from
                      evil, because the law encourages to the one and deters from the other, is no evidence of his being
                      under the law and not under grace.<strong>15</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Rom. 6:14; Gal. 2:16; Rom. 8:1, 10:4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Rom. 3:20, 7:7, etc.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Rom. 6:12-14; 1 Pet. 3:8-13
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. Neither are the aforementioned uses of the law contrary to the grace of the Gospel,
                      but do sweetly comply with it,<strong>16</strong> the Spirit of Christ subduing and enabling the
                      will of man to do that freely and cheerfully which the will of God, revealed in the law, requires
                      to be done.<strong>17</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Gal. 3:21
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> Ezek. 36:27
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.20"></a>
                    <strong>
                      <span>CHAPTER 20; OF THE GOSPEL AND OF THE EXTENT OF THE GRACE THEREOF</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The covenant of works being broken by sin, and made unprofitable unto life, God was
                      pleased to give forth the promise of Christ, the seed of the woman, as the means of calling the
                      elect, and begetting in them faith and repentance;<strong>1</strong> in this promise the gospel,
                      as to the substance of it, was revealed, and [is] therein effectual for the conversion and
                      salvation of sinners.<strong>2</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Gen. 3:15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Rev. 13:8
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. This promise of Christ, and salvation by him, is revealed only by the Word of God;
                      <strong>3</strong> neither do the works of creation or providence, with the light of nature, make
                      discovery of Christ, or of grace by him, so much as in a general or obscure way;<strong>4</strong>{" "}
                      much less that men destitute of the revelation of Him by the promise or gospel, should be enabled
                      thereby to attain saving faith or repentance.<strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Rom. 1;17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom. 10:14,15,17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Prov. 29:18; Isa. 25:7; 60:2,3
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. The revelation of the gospel to sinners, made in divers times and by sundry parts,
                      with the addition of promises and precepts for the obedience required therein, as to the nations
                      and persons to whom it is granted, is merely of the sovereign will and good pleasure of God;
                      <strong>6</strong> not being annexed by virtue of any promise to the due improvement of men's
                      natural abilities, by virtue of common light received without it, which none ever made, or can do
                      so;<strong>7</strong> and therefore in all ages, the preaching of the gospel has been granted unto
                      persons and nations, as to the extent or straitening of it, in great variety, according to the
                      counsel of the will of God.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Ps. 147:20; Acts 16:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Rom. 1:18-32
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. Although the gospel be the only outward means of revealing Christ and saving grace,
                      and is, as such, abundantly sufficient thereunto; yet that men who are dead in trespasses may be
                      born again, quickened or regenerated, there is moreover necessary an effectual insuperable work of
                      the Holy Spirit upon the whole soul, for the producing in them a new spiritual life;
                      <strong>8</strong> without which no other means will effect their conversion unto God.
                      <strong>9</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Ps. 110:3; 1 Cor. 2:14; Eph. 1:19,20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> John 6:44; 2 Cor. 4:4,6
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.21"></a>
                    <strong>
                      <span>CHAPTER 21; OF CHRISTIAN LIBERTY AND LIBERTY OF CONSCIENCE</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The liberty which Christ has purchased for believers under the gospel, consists in
                      their freedom from the guilt of sin, the condemning wrath of God, the severity and curse of the
                      law,<strong>1</strong> and in their being delivered from this present evil world,
                      <strong>2</strong> bondage to Satan,<strong>3</strong> and dominion of sin,<strong>4</strong> from
                      the evil of afflictions,<strong>5</strong> the fear and sting of death, the victory of the grave,
                      <strong>6</strong> and everlasting damnation:<strong>7</strong> as also in their free access to
                      God, and their yielding obedience unto Him, not out of slavish fear,<strong>8</strong> but a
                      child-like love and willing mind.<strong>9</strong> All which were common also to believers under
                      the law for the substance of them;<strong>10</strong> but under the New Testament the liberty of
                      Christians is further enlarged, in their freedom from the yoke of a ceremonial law, to which the
                      Jewish church was subjected, and in greater boldness of access to the throne of grace, and in
                      fuller communications of the free Spirit of God, than believers under the law did ordinarily
                      partake of.<strong>11</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Gal. 3:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Gal. 1:4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Acts 26:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom. 8:3
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Rom. 8:28
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> 1 Cor. 15:54-57
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 2 Thess. 1:10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Rom. 8:15;
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Luke 1:73-75; 1 John 4:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Gal. 3;9,14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> John 7:38,39; Heb. 10:19-21
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. God alone is Lord of the conscience,<strong>12</strong> and has left it free from the
                      doctrines and commandments of men which are in any thing contrary to his word, or not contained in
                      it.<strong>13</strong>  So that to believe such doctrines, or obey such commands out of
                      conscience, is to betray true liberty of conscience;<strong>14</strong> and the requiring of an
                      implicit faith, an absolute and blind obedience, is to destroy liberty of conscience and reason
                      also.<strong>15</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> James 4:12; Rom. 14:4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Acts 4:19,29; 1 Cor. 7:23; Matt. 15:9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Col. 2:20,22,23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> 1 Cor. 3:5; 2 Cor. 1:24
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. They who upon pretence of Christian liberty do practice any sin, or cherish any
                      sinful lust, as they do thereby pervert the main design of the grace of the gospel to their own
                      destruction,<strong>16</strong> so they wholly destroy the end of Christian liberty, which is,
                      that being delivered out of the hands of all our enemies, we might serve the Lord without fear, in
                      holiness and righeousness before Him, all the days of our lives.<strong>17</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Rom. 6:1,2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> Gal. 5:13; 2 Pet. 2:18,21
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.22"></a>
                    <strong>
                      <span>CHAPTER 22; OF RELIGIOUS WORSHIP AND THE SABBATH DAY</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The light of nature shows that there is a God, who has lordship and sovereignty over
                      all; is just, good and does good to all; and is therefore to be feared, loved, praised, called
                      upon, trusted in, and served, with all the heart and all the soul, and with all the might.
                      <strong>1</strong>  But the acceptable way of worshipping the true God, is instituted by himself,
                      <strong>2</strong> and so limited by his own revealed will, that he may not be worshipped
                      according to the imagination and devices of men, nor the suggestions of Satan, under any visible
                      representations, or any other way not prescribed in the Holy Scriptures.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Jer. 10:7; Mark 12:33
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Deut. 12:32
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Exod. 20:4-6
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Religious worship is to be given to God the Father, Son, and Holy Spirit, and to him
                      alone;<strong>4</strong> not to angels, saints, or any other creatures;<strong>5</strong> and
                      since the fall, not without a mediator,<strong>6</strong> nor in the mediation of any other but
                      Christ alone.<strong>7</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Matt. 4:9,10; John 6:23; Matt. 28:19
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Rom. 1:25; Col. 2:18; Rev. 19:10
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> John 14:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 Tim. 2:5
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Prayer, with thanksgiving, being one part of natural worship, is by God required of
                      all men.<strong>8</strong>  But that it may be accepted, it is to be made in the name of the Son,9
                      by the help of the Spirit,<strong>10</strong> according to his will;<strong>11</strong> with
                      understanding, reverence, humility, fervency, faith, love, and perseverance; and when with others,
                      in a known tongue.<strong>12</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Ps. 95:1-7, 65:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> John 14:13,14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Rom. 8:26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> 1 John 5:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> 1 Cor. 14:16,17
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. Prayer is to be made for things lawful, and for all sorts of men living, or that
                      shall live hereafter;<strong>13</strong> but not for the dead,<strong>14</strong> nor for those of
                      whom it may be known that they have sinned the sin unto death.<strong>15</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> 1 Tim. 2:1,2; 2 Sam. 7:29
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> 2 Sam. 12:21-23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> 1 John 5:16
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. The reading of the Scriptures,<strong>16</strong> preaching, and hearing the Word of
                      God,<strong>17</strong> teaching and admonishing one another in psalms, hymns, and spiritual
                      songs, singing with grace in our hearts to the Lord;<strong>18</strong> as also the administration
                      of baptism,<strong>19</strong> and the Lord's supper,<strong>20</strong> are all parts of
                      religious worship of God, to be performed in obedience to him, with understanding, faith,
                      reverence, and godly fear; moreover, solemn humiliation, with fastings,<strong>21</strong> and
                      thanksgivings, upon special occasions, ought to be used in an holy and religious manner.
                      <strong>22</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> 1 Tim. 4:13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> 2 Tim. 4:2; Luke 8:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> Col. 3:16; Eph. 5:19
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> Matt. 28:19,20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> 1 Cor. 11:26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>21</strong> Esther 4:16; Joel 2:12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>22</strong> Exod. 15:1-19, Ps. 107
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. Neither prayer nor any other part of religious worship, is now under the gospel, tied
                      unto, or made more acceptable by any place in which it is performed, or towards which it is
                      directed; but God is to be worshipped everywhere in spirit and in truth;<strong>23</strong> as in
                      private families<strong>24</strong> daily,<strong>25</strong> and in secret each one by himself;
                      <strong>26</strong> so more solemnly in the public assemblies, which are not carelessly nor
                      wilfully to be neglected or forsaken, when God by his word or providence calls thereunto.
                      <strong>27</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>23</strong> John 4:21; Mal. 1:11; 1 Tim. 2:8
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>24</strong> Acts 10:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>25</strong> Matt. 6:11; Ps. 55:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>26</strong> Matt. 6:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>27</strong> Heb. 10:25; Acts 2:42
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. As it is the law of nature, that in general a proportion of time, by God's
                      appointment, be set apart for the worship of God, so by his Word, in a positive moral, and
                      perpetual commandment, binding all men, in all ages, he has particularly appointed one day in
                      seven for a sabbath to be kept holy unto him,<strong>28</strong> which from the beginning of the
                      world to the resurrection of Christ was the last day of the week, and from the resurrection of
                      Christ was changed into the first day of the week, which is called the Lord's Day:
                      <strong>29</strong> and is to be continued to the end of the world as the Christian Sabbath, the
                      observation of the last day of the week being abolished.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>28</strong> Exod. 20:8
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>29</strong> 1 Cor. 16:1,2; Acts 20:7; Rev. 1:10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 8. The sabbath is then kept holy unto the Lord, when men, after a due preparing of their
                      hearts, and ordering their common affairs aforehand, do not only observe a holy rest all day, from
                      their own works, words and thoughts, about their worldly employment and recreations,
                      <strong>30</strong> but are also taken up the whole time in the public and private exercises of
                      his worship, and in the duties of necessity and mercy.<strong>31</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>30</strong> Isa. 58:13; Neh. 13:15-22
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>31</strong> Matt. 12:1-13
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.23"></a>
                    <strong>
                      <span>CHAPTER 23; OF LAWFUL OATHS AND VOWS</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. A lawful oath is a part of religious worship, wherein the person swearing in truth,
                      righteousness, and judgment, solemnly calls God to witness what he swears,<strong>1</strong> and
                      to judge him according to the truth or falseness thereof.<strong>2</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Exod. 20:7; Deut. 10:20; Jer. 4:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> 2 Chron. 6:22, 23
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. The name of God only is that by which men ought to swear; and therein it is to be
                      used, with all holy fear and reverence; therefore to swear vainly or rashly by that glorious and
                      dreadful name, or to swear at all by any other thing, is sinful, and to be abhorred;
                      <strong>3</strong> yet as in matter of weight and moment, for confirmation of truth, and ending
                      all strife, an oath is warranted by the word of God;<strong>4</strong> so a lawful oath being
                      imposed by lawful authority in such matters, ought to be taken.<strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Matt. 5:34,37; James 5:12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Heb. 6:16; 2 Cor. 1:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Neh. 13:25
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Whosoever takes an oath warranted by the word of God, ought duly to consider the
                      weightiness of so solemn an act, and therein to avouch nothing but what he knows to be truth; for
                      that by rash, false, and vain oaths, the Lord is provoked, and for them this land mourns.
                      <strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Lev. 19:12; Jer. 23:10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. An oath is to be taken in the plain and common sense of the words, without
                      equivocation or mental reservation.<strong>7</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Ps. 24:4
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. A vow, which is not to be made to any creature, but to God alone, is to be made and
                      performed with all religious care and faithfulness;<strong>8</strong> but popish monastical vows
                      of perpetual single life,<strong>9</strong> professed poverty,<strong>10</strong> and regular
                      obedience, are so far from being degrees of higher perfection, that they are superstitious and
                      sinful snares, in which no Christian may entangle himself.<strong>11</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Ps. 76:11; Gen. 28:20-22
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> 1 Cor. 7:2,9
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Eph. 4:28
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Matt. 19:1
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.24"></a>
                    <strong>
                      <span>CHAPTER 24; OF THE CIVIL MAGISTRATE</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. God, the supreme Lord and King of all the world, has ordained civil magistrates to be
                      under him, over the people, for his own glory and the public good; and to this end has armed them
                      with the power of the sword, for defence and encouragement of them that do good, and for the
                      punishment of evil doers.<strong>1</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Rom. 13:1-4
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. It is lawful for Christians to accept and execute the office of a magistrate when
                      called thereunto; in the management whereof, as they ought especially to maintain justice and
                      peace,<strong>2</strong> according to the wholesome laws of each kingdom and commonwealth, so for
                      that end they may lawfully now, under the New Testament, wage war upon just and necessary
                      occasions.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> 2 Sam. 23:3; Ps. 82:3,4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Luke 3:14
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. Civil magistrates being set up by God for the ends aforesaid; subjection, in all
                      lawful things commanded by them, ought to be yielded by us in the Lord, not only for wrath, but
                      for conscience’ sake;<strong>4</strong> and we ought to make supplications and prayers for kings
                      and all that are in authority, that under them we may live a quiet and peaceable life, in all
                      godliness and honesty.<strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom. 13:5-7; 1 Pet. 2:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> 1 Tim. 2:1,2
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.25"></a>
                    <strong>
                      <span>CHAPTER 25; OF MARRIAGE</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Marriage is to be between one man and one woman; neither is it lawful for any man to
                      have more than one wife, nor for any woman to have more than one husband at the same time.
                      <strong>1</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Gen. 2:24; Mal. 2:15; Matt. 19:5,6
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Marriage was ordained for the mutual help of husband and wife,<strong>2</strong> for
                      the increase of mankind with a legitimate issue,<strong>3</strong> and the preventing of
                      uncleanness.<strong>4</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Gen. 2:18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Gen. 1:28
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> 1 Cor. 7:2,9
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. It is lawful for all sorts of people to marry, who are able with judgment to give
                      their consent;<strong>5</strong> yet it is the duty of Christians to marry in the Lord;
                      <strong>6</strong> and therefore such as profess the true religion, should not marry with
                      infidels, or idolaters; neither should such as are godly, be unequally yoked, by marrying with
                      such as are wicked in their life, or maintain damnable heresy.<strong>7</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Heb. 13:4; 1 Tim. 4:3
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> 1 Cor. 7:39
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Neh. 13:25-27
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. Marriage ought not to be within the degrees of consanguinity or affinity, forbidden
                      in the Word;<strong>8</strong> nor can such incestuous marriages ever be made lawful, by any law
                      of man or consent of parties, so as those persons may live together as man and wife.
                      <strong>9</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Lev. 18
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Mark 6:18; 1 Cor. 5:1
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.26"></a>
                    <strong>
                      <span>CHAPTER 26; OF THE CHURCH</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The catholic or universal church, which (with respect to the internal work of the
                      Spirit and truth of grace) may be called invisible, consists of the whole number of the elect,
                      that have been, are, or shall be gathered into one, under Christ, the head thereof; and is the
                      spouse, the body, the fulness of him that fills all in all.<strong>1</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Heb. 12:23; Col. 1:18; Eph. 1:10,22,23, 5:23,27,32
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. All persons throughout the world, professing the faith of the gospel, and obedience
                      unto God by Christ according unto it, not destroying their own profession by any errors everting
                      the foundation, or unholiness of conversation, are and may be called visible saints;
                      <strong>2</strong> and of such ought all particular congregations to be constituted.
                      <strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> 1 Cor. 1:2; Acts 11:26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Rom. 1:7; Eph. 1:20-22
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. The purest churches under heaven are subject to mixture and error;<strong>4</strong>{" "}
                      and some have so degenerated as to become no churches of Christ, but synagogues of Satan;
                      <strong>5</strong> nevertheless Christ always has had, and ever shall have a kingdom in this
                      world, to the end thereof, of such as believe in him, and make profession of his name.
                      <strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> 1 Cor. 5; Rev. 2,3
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Rev. 18:2; 2 Thess. 2:11,12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Matt. 16:18; Ps. 72:17, 102:28; Rev. 12:17
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. The Lord Jesus Christ is the Head of the church, in whom, by the appointment of the
                      Father, all power for the calling, institution, order or government of the church, is invested in
                      a supreme and sovereign manner;<strong>7</strong> neither can the Pope of Rome in any sense be
                      head thereof, but is that antichrist, that man of sin, and son of perdition, that exalts himself
                      in the church against Christ, and all that is called God; whom the Lord shall destroy with the
                      brightness of his coming.<strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Col. 1:18; Matt. 28:18-20; Eph. 4:11,12
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> 2 Thess. 2:2-9
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. In the execution of this power wherewith he is so intrusted, the Lord Jesus calls out
                      of the world unto himself, through the ministry of his word, by his Spirit, those that are given
                      unto him by his Father,<strong>9</strong> that they may walk before him in all the ways of
                      obedience, which he prescribes to them in his word.<strong>10</strong>  Those thus called, he
                      commands to walk together in particular societies, or churches, for their mutual edification, and
                      the due performance of that public worship, which he requires of them in the world.
                      <strong>11</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> John 10:16; John 12:32
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Matt. 28:20
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> Matt. 18:15-20
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. The members of these churches are saints by calling, visibly manifesting and
                      evidencing (in and by their profession and walking) their obedience unto that call of Christ;
                      <strong>12</strong> and do willingly consent to walk together, according to the appointment of
                      Christ; giving up themselves to the Lord, and one to another, by the will of God, in professed
                      subjection to the ordinances of the Gospel.<strong>13</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> Rom. 1:7; 1 Cor. 1:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> Acts 2:41,42, 5:13,14; 2 Cor. 9:13
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. To each of these churches therefore gathered, according to his mind declared in his
                      word, he has given all that power and authority, which is in any way needful for their carrying on
                      that order in worship and discipline, which he has instituted for them to observe; with commands
                      and rules for the due and right exerting, and executing of that power.<strong>14</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>14</strong> Matt. 18:17, 18; 1 Cor. 5:4, 5, 5:13, 2 Cor. 2:6-8
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 8. A particular church, gathered and completely organized according to the mind of
                      Christ, consists of officers and members; and the officers appointed by Christ to be chosen and
                      set apart by the church (so called and gathered), for the peculiar administration of ordinances,
                      and execution of power or duty, which he intrusts them with, or calls them to, to be continued to
                      the end of the world, are bishops or elders, and deacons.<strong>15</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>15</strong> Acts 20:17, 28; Phil. 1:1
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 9. The way appointed by Christ for the calling of any person, fitted and gifted by the
                      Holy Spirit, unto the office of bishop or elder in a church, is, that he be chosen thereunto by
                      the common suffrage of the church itself;<strong>16</strong> and solemnly set apart by fasting and
                      prayer, with imposition of hands of the eldership of the church, if there be any before
                      constituted therein;<strong>17</strong> and of a deacon that he be chosen by the like suffrage,
                      and set apart by prayer, and the like imposition of hands.<strong>18</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>16</strong> Acts 14:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>17</strong> 1 Tim. 4:14
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>18</strong> Acts 6:3,5,6
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 10. The work of pastors being constantly to attend the service of Christ, in his
                      churches, in the ministry of the word and prayer, with watching for their souls, as they that must
                      give an account to Him;<strong>19</strong> it is incumbent on the churches to whom they minister,
                      not only to give them all due respect, but also to communicate to them of all their good things
                      according to their ability,<strong>20</strong> so as they may have a comfortable supply, without
                      being themselves entangled in secular affairs;<strong>21</strong> and may also be capable of
                      exercising hospitality towards others;<strong>22</strong> and this is required by the law of
                      nature, and by the express order of our Lord Jesus, who has ordained that they that preach the
                      Gospel should live of the Gospel.<strong>23</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>19</strong> Acts 6:4; Heb. 13:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>20</strong> 1 Tim. 5:17,18; Gal. 6:6,7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>21</strong> 2 Tim. 2:4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>22</strong> 1 Tim. 3:2
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>23</strong> 1 Cor. 9:6-14
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 11. Although it be incumbent on the bishops or pastors of the churches, to be instant in
                      preaching the word, by way of office, yet the work of preaching the word is not so peculiarly
                      confined to them but that others also gifted and fitted by the Holy Spirit for it, and approved
                      and called by the church, may and ought to perform it.<strong>24</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>24</strong> Acts 11:19-21; 1 Pet. 4:10,11
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 12. As all believers are bound to join themselves to particular churches, when and where
                      they have opportunity so to do; so all that are admitted unto the privileges of a church, are also
                      under the censures and government thereof, according to the rule of Christ.<strong>25</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>25</strong> 1 Thess. 5:14; 2 Thess. 3:6,14,15
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 13. No church members, upon any offence taken by them, having performed their duty
                      required of them towards the person they are offended at, ought to disturb any church-order, or
                      absent themselves from the assemblies of the church, or administration of any ordinances, upon the
                      account of such offence at any of their fellow members, but to wait upon Christ, in the further
                      proceeding of the church.<strong>26</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>26</strong> Matt. 18:15-17; Eph. 4:2,3
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 14. As each church, and all the members of it, are bound to pray continually for the
                      good and prosperity of all the churches of Christ,<strong>27</strong> in all places, and upon all
                      occasions to further every one within the bounds of their places and callings, in the exercise of
                      their gifts and graces, so the churches, when planted by the providence of God, so as they may
                      enjoy opportunity and advantage for it, ought to hold communion among themselves, for their peace,
                      increase of love, and mutual edification.<strong>28</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>27</strong> Eph. 6:18; Ps. 122:6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>28</strong> Rom. 16:1,2; 3 John 8-10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 15. In cases of difficulties or differences, either in point of doctrine or
                      administration, wherein either the churches in general are concerned, or any one church, in their
                      peace, union, and edification; or any member or members of any church are injured, in or by any
                      proceedings in censures not agreeable to truth and order: it is according to the mind of Christ,
                      that many churches holding communion together, do, by their messengers, meet to consider, and give
                      their advice in or about that matter in difference, to be reported to all the churches concerned;
                      <strong>29</strong> howbeit these messengers assembled, are not intrusted with any church-power
                      properly so called; or with any jurisdiction over the churches themselves, to exercise any
                      censures either over any churches or persons; or to impose their determination on the churches or
                      officers.<strong>30</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>29</strong> Acts 15:2,4,6,22,23,25
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>30</strong> 2 Cor. 1:24; 1 John 4:1
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.27"></a>
                    <strong>
                      <span>CHAPTER 27; OF THE COMMUNION OF THE SAINTS</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. All saints that are united to Jesus Christ, their head, by his Spirit, and faith,
                      although they are not made thereby one person with him, have fellowship in his graces, sufferings,
                      death, resurrection, and glory;<strong>1</strong>  and, being united to one another in love, they
                      have communion in each others gifts and graces,<strong>2</strong> and are obliged to the
                      performance of such duties, public and private, in an orderly way, as do conduce to their mutual
                      good, both in the inward and outward man.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> 1 John 1:3; John 1:16; Phil. 3:10; Rom. 6:5,6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Eph. 4:15,16; 1 Cor. 12:7; 3:21-23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> 1 Thess. 5:11,14; Rom. 1:12; 1 John 3:17,18; Gal. 6:10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Saints by profession are bound to maintain a holy fellowship and communion in the
                      worship of God, and in performing such other spiritual services as tend to their mutual
                      edification;<strong>4</strong> as also in relieving each other in outward things according to
                      their several abilities, and necessities;<strong>5</strong> which communion, according to the rule
                      of the gospel, though especially to be exercised by them, in the relation wherein they stand,
                      whether in families,<strong>6</strong> or churches,<strong>7</strong> yet, as God offers
                      opportunity, is to be extended to all the household of faith, even all those who in every place
                      call upon the name of the Lord Jesus; nevertheless their communion one with another as saints,
                      does not take away or infringe the title or propriety which each man has in his goods and
                      possessions.<strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Heb. 10:24,25, 3:12,13
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Acts 11:29,30
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Eph. 6:4
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 Cor. 12:14-27
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Acts 5:4; Eph. 4:28
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.28"></a>
                    <strong>
                      <span>CHAPTER 28; OF BAPTISM AND THE LORD’S SUPPER</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Baptism and the Lord's Supper are ordinances of positive and sovereign institution,
                      appointed by the Lord Jesus, the only lawgiver, to be continued in his church to the end of the
                      world.<strong>1</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Matt. 28:19,20; 1 Cor. 11:26
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. These holy appointments are to be administered by those only who are qualified and
                      thereunto called, according to the commission of Christ.<strong>2</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Matt. 28:19; 1 Cor. 4:1
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.29"></a>
                    <strong>
                      <span>CHAPTER 29; OF BAPTISM</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. Baptism is an ordinance of the New Testament, ordained by Jesus Christ, to be unto
                      the party baptized, a sign of his fellowship with him, in his death and resurrection; of his being
                      engrafted into him;<strong>3</strong> of remission of sins;<strong>4</strong> and of giving up
                      into God, through Jesus Christ, to live and walk in newness of life.<strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Rom. 6:3-5; Col. 2:12; Gal. 3:27
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Mark 1:4; Acts 22:16
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Rom. 6:4
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. Those who do actually profess repentance towards God, faith in, and obedience to, our
                      Lord Jesus Christ, are the only proper subjects of this ordinance.<strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Mark 16:16; Acts 8:36,37, 2:41, 8:12, 18:8
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. The outward element to be used in this ordinance is water, wherein the party is to be
                      baptized, in the name of the Father, and of the Son, and of the Holy Spirit.<strong>7</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> Matt. 28:19, 20; Acts 8:38
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. Immersion, or dipping of the person in water, is necessary to the due administration
                      of this ordinance.<strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Matt. 3:16; John 3:23
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.30"></a>
                    <strong>
                      <span>CHAPTER 30; OF THE LORD’S SUPPER</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The supper of the Lord Jesus was instituted by him the same night wherein he was
                      betrayed, to be observed in his churches, unto the end of the world, for the perpetual
                      remembrance, and showing to all the world the sacrifice of himself in his death,<strong>1</strong>{" "}
                      confirmation of the faith of believers in all the benefits thereof, their spiritual nourishment,
                      and growth in him, their further engagement in, and to all duties which they owe to him; and to be
                      a bond and pledge of their communion with him, and with each other.<strong>2</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> 1 Cor. 11:23-26
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> 1 Cor. 10:16,17,21
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. In this ordinance Christ is not offered up to his Father, nor any real sacrifice made
                      at all for remission of sin of the quick or dead, but only a memorial of that one offering up of
                      himself by himself upon the cross, once for all;<strong>3</strong> and a spiritual oblation of all
                      possible praise unto God for the same.<strong>4</strong>  So that the popish sacrifice of the
                      mass, as they call it, is most abominable, injurious to Christ's own sacrifice the alone
                      propitiation for all the sins of the elect.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Heb. 9:25,26,28
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> 1 Cor. 11:24; Matt. 26:26,27
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. The Lord Jesus hath, in this ordinance, appointed his ministers to pray, and bless
                      the elements of bread and wine, and thereby to set them apart from a common to a holy use, and to
                      take and break the bread; to take the cup, and, they communicating also themselves, to give both
                      to the communicants.<strong>5</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> 1 Cor. 11:23-26, etc.
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 4. The denial of the cup to the people, worshipping the elements, the lifting them up,
                      or carrying them about for adoration, and reserving them for any pretended religious use, are all
                      contrary to the nature of this ordinance, and to the institution of Christ.<strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Matt. 26:26-28, 15:9, Exod. 20:4,5
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 5. The outward elements in this ordinance, duly set apart to the use ordained by Christ,
                      have such relation to him crucified, as that truly, although in terms used figuratively, they are
                      sometimes called by the names of the things they represent, in other words, the body and blood of
                      Christ,<strong>7</strong> albeit, in substance and nature, they still remain truly and only bread
                      and wine, as they were before.<strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 Cor. 11:27
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> 1 Cor. 11:26-28
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 6. That doctrine which maintains a change of the substance of bread and wine, into the
                      substance of Christ's body and blood, commonly called transubstantiation, by consecration of a
                      priest, or by any other way, is repugnant not to Scripture alone,<strong>9</strong> but even to
                      common sense and reason, overthrows the nature of the ordinance, and has been, and is, the cause
                      of manifold superstitions, yea, of gross idolatries.<strong>10</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Acts 3:21; Luke 14:6,39
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> 1 Cor. 11:24,25
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 7. Worthy receivers, outwardly partaking of the visible elements in this ordinance, do
                      then also inwardly by faith, really and indeed, yet not carnally and corporally, but spiritually
                      receive, and feed upon Christ crucified, and all the benefits of his death; the body and blood of
                      Christ being then not corporally or carnally, but spiritually present to the faith of believers in
                      that ordinance, as the elements themselves are to their outward senses.<strong>11</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>11</strong> 1 Cor. 10:16, 11:23-26
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 8. All ignorant and ungodly persons, as they are unfit to enjoy communion with Christ,
                      so are they unworthy of the Lord's table, and cannot, without great sin against him, while they
                      remain such, partake of these holy mysteries, or be admitted thereunto;<strong>12</strong> yea,
                      whosoever shall receive unworthily, are guilty of the body and blood of the Lord, eating and
                      drinking judgment to themselves.<strong>13</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>12</strong> 2 Cor. 6:14,15
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>13</strong> 1 Cor. 11:29; Matt. 7:6
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.31"></a>
                    <strong>
                      <span>CHAPTER 31; OF THE STATE OF MAN AFTER DEATH, AND OF THE RESURRECTION OF THE DEAD</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. The bodies of men after death return to dust, and see corruption;<strong>1</strong>{" "}
                      but their souls, which neither die nor sleep, having an immortal subsistence, immediately return
                      to God who gave them.<strong>2</strong>  The souls of the righteous being then made perfect in
                      holiness, are received into paradise, where they are with Christ, and behold the face of God in
                      light and glory, waiting for the full redemption of their bodies;<strong>3</strong> and the souls
                      of the wicked are cast into hell; where they remain in torment and utter darkness, reserved to the
                      judgment of the great day;<strong>4</strong> besides these two places, for souls separated from
                      their bodies, the Scripture acknowledgeth none.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Gen. 3:19; Acts 13:36
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> Eccles. 12:7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> Luke 23:43; 2 Cor. 5:1,6,8; Phil. 1:23; Heb. 12:23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Jude 6, 7; 1 Peter 3:19; Luke 16:23,24
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. At the last day, such of the saints as are found alive, shall not sleep, but be
                      changed;<strong>5</strong> and all the dead shall be raised up with the selfsame bodies, and none
                      other;<strong>6</strong> although with different qualities, which shall be united again to their
                      souls forever.<strong>7</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> 1 Cor. 15:51,52; 1 Thess. 4:17
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Job 19:26,27
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 1 Cor. 15:42,43
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. The bodies of the unjust shall, by the power of Christ, be raised to dishonour; the
                      bodies of the just, by his Spirit, unto honour, and be made conformable to his own glorious body.
                      <strong>8</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> Acts 24:15; John 5:28,29; Phil. 3:21
                    </span>
                  </p>
                  <p className={styles.article_title}>
                    <a id="Ch.32"></a>
                    <strong>
                      <span>CHAPTER 32; OF THE LAST JUDGMENT</span>
                    </strong>
                  </p>
                  <p>
                    <span>
                      Paragraph 1. God has appointed a day wherein he will judge the world in righteousness, by Jesus
                      Christ;<strong>1</strong> to whom all power and judgment is given of the Father; in which day, not
                      only the apostate angels shall be judged,<strong>2</strong> but likewise all persons that have
                      lived upon the earth shall appear before the tribunal of Christ, to give an account of their
                      thoughts, words, and deeds, and to receive according to what they have done in the body, whether
                      good or evil.<strong>3</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>1</strong> Acts 17:31; John 5:22,27
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>2</strong> 1 Cor. 6:3; Jude 6
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>3</strong> 2 Cor. 5:10; Eccles. 12:14; Matt. 12:36; Rom. 14:10,12; Matt. 25:32-46
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 2. The end of God's appointing this day, is for the manifestation of the glory of his
                      mercy, in the eternal salvation of the elect; and of his justice, in the eternal damnation of the
                      reprobate, who are wicked and disobedient;<strong>4</strong> for then shall the righteous go into
                      everlasting life, and receive that fulness of joy and glory with everlasting rewards, in the
                      presence of the Lord; but the wicked, who do not know God, and do not obey the gospel of Jesus
                      Christ, shall be cast aside into everlasting torments,<strong>5</strong> and punished with
                      everlasting destruction, from the presence of the Lord, and from the glory of his power.
                      <strong>6</strong>
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>4</strong> Rom. 9:22,23
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>5</strong> Matt. 25:21,34; 2 Tim. 4:8
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>6</strong> Matt. 25:46; Mark 9:48; 2 Thess. 1:7-10
                    </span>
                  </p>
                  <p>
                    <span>
                      Paragraph 3. As Christ would have us to be certainly persuaded that there shall be a day of
                      judgment, both to deter all men from sin,<strong>7</strong> and for the greater consolation of the
                      godly in their adversity,<strong>8</strong> so will he have the day unknown to men, that they may
                      shake off all carnal security, and be always watchful, because they know not at what hour the Lord
                      will come,<strong>9</strong> and may ever be prepared to say, Come Lord Jesus; come quickly.
                      <strong>10</strong>  Amen.
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>7</strong> 2 Cor. 5:10,11
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>8</strong> 2 Thess. 1:5-7
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>9</strong> Mark 13:35-37; Luke 12:35-40
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>10</strong> Rev. 22:20
                    </span>
                  </p>
                  <p> </p>
                  <center>
                    <p> </p>
                    <hr />
                    <p className={styles.article_title}>
                      <a id="Ending Statement"></a>
                      <strong>
                        <span>
                          <span>ENDING STATEMENT AND SIGNATORIES</span>
                        </span>
                      </strong>
                    </p>
                    <p> </p>
                  </center>
                  <p>
                    <span>
                           We the MINISTERS, and MESSENGERS of, and concerned for upwards of, one hundred BAPTIZED
                      CHURCHES, in England and Wales (denying Arminianisim), being met together in London, from the
                      third of the seventh month to the eleventh of the same, 1689, to consider of some things that
                      might be for the glory of God, and the good of these congregations, have thought meet (for the
                      satisfaction of all other Christians that differ from us in the point of Baptism) to recommend to
                      their perusal the confession of our faith, which confession we own, as containing the doctrine of
                      our faith and practice, and do desire that the members of our churches respectively do furnish
                      themselves therewith.
                    </span>
                  </p>
                  <p> </p>
                  <p>
                    <span>Hansard Knollys, Pastor Broken Wharf, London</span>{" "}
                    <span>William Kiffin, Pastor Devonshire-square, London</span>{" "}
                    <span>John Harris, Pastor, Joiner's Hall, London</span>{" "}
                    <span>William Collins, Pastor, Petty France, London</span>{" "}
                    <span>Hurcules Collins, Pastor, Wapping, London</span>{" "}
                    <span>Robert Steed, Pastor, Broken Wharf, London</span>{" "}
                    <span>Leonard Harrison,Pastor, Limehouse, London</span>{" "}
                    <span>George Barret, Pastor, Mile End Green, London</span>{" "}
                    <span>Isaac Lamb, Pastor, Pennington-street, London</span>{" "}
                    <span>Richard Adams, Minister, Shad Thames, Southwark</span>{" "}
                    <span>Benjamin Keach, Pastor, Horse-lie-down, Southwark</span>{" "}
                    <span>Andrew Gifford, Pastor, Bristol, Fryars, Som. &amp; Glouc.</span>{" "}
                    <span>Thomas Vaux, Pastor, Broadmead, Som. &amp; Glouc.</span>{" "}
                    <span>Thomas Winnel, Pastor, Taunton, Som. &amp; Glouc.</span>{" "}
                    <span>James Hitt, Preacher, Dalwood, Dorset</span>{" "}
                    <span>Richard Tidmarsh, Minister, Oxford City, Oxon</span>{" "}
                    <span>William Facey, Pastor, Reading, Berks</span>{" "}
                    <span>Samuel Buttall, Minister, Plymouth, Devon</span>{" "}
                    <span>Christopher Price, Minister, Abergayenny, Monmouth</span>{" "}
                    <span>Daniel Finch, Minister, Kingsworth, Herts</span>{" "}
                    <span>John Ball,  Minister, Tiverton, Devon</span>{" "}
                    <span>Edmond White, Pastor, Evershall, Bedford</span>{" "}
                    <span>William Prichard, Pastor, Blaenau, Monmouth</span>{" "}
                    <span>Paul Fruin, Minister, Warwick, Warwick</span>{" "}
                    <span>Richard Ring, Pastor, Southhampton, Hants</span>{" "}
                    <span>John Tomkins, Minister, Abingdon, Berks</span>{" "}
                    <span>Toby Willes, Pastor, Bridgewater, Somerset</span>{" "}
                    <span>John Carter, Pastor, Steventon, Bedford</span> <span>James Webb, Pastor, Devizes, Wilts</span>{" "}
                    <span>Richard Sutton, Pastor, Tring, Herts</span>{" "}
                    <span>Robert Knight, Pastor, Stukeley, Bucks</span>{" "}
                    <span>Edward Price, Pastor, Hereford City, Hereford</span>{" "}
                    <span>William Phipps, Pastor, Exon, Devon</span>{" "}
                    <span>William Hawkins, Pastor, Dimmock, Gloucester</span>{" "}
                    <span>Samuel Ewer, Pastor, Hemstead, Herts</span>{" "}
                    <span>Edward Man, Pastor, Houndsditch, London</span>{" "}
                    <span>Charles Archer, Pastor, Hock-Norton, Oxon</span>{" "}
                    <span>     In the name of and on the behalf of the whole assembly.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.showScrollButton ? styles.visible : styles.hidden}>
          <UpButton link="#contents" />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page
        permalink="about-rbcr/the-baptist-confession-of-faith-of-1689/"
        header="The Baptist Confession of Faith of 1689"
        content={this.content()}
      />
    );
  }
}

export default Confession;
