import BaseService from "./BaseService";

export class EventsService extends BaseService {
  async getAll() {
    try {
      return this.get(`/events`);
    } catch (e) {
      return {};
    }
  }
}
