import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Home/Home";
import About from "../About/About";
import Pastors from "../Pastors/Pastors";
import Preachers from "../Preachers/Preachers";
import Belief from "../Belief/Belief";
import Membership from "../Membership/Membership";
import Calendar from "../Calendar/Calendar";
import Constitution from "../Constitution/Constitution";
import Confession from "../Confession/Confession";
import Sermon from "../Sermon/Sermon";
import Sermons from "../Sermons/Sermons";
import Spanish from "../SpanishMinistry/Spanish";
import Videos from "../Videos/Videos";
import Contact from "../Contact/Contact";
import Bautista from "../Bautista/Bautista";
import SpanishConstitution from "../SpanishConstitution/SpanishConstitution";
import SpanishConfession from "../SpanishConfession/SpanishConfession";
import SpanishSermons from "../SpanishSermons/SpanishSermons";
import YouthConference from "../YouthConference/YouthConference";
import Giving from "../Giving/Giving";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import VBS from "../VBS/VBS";
import WomensConference from "../WomensConference/WomensConference";


class ApplicationRouter extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/about-rbcr/confession-de-fe/" component={SpanishConfession} />
          <Route path="/constitucion-de-la-iglesia-bautista-reformada-de-riverside/" component={SpanishConstitution} />
          <Route path="/que-es-una-iglesia-bautista-reformada/" component={Bautista} />
          <Route path="/sermones-en-español/" component={SpanishSermons} />
          <Route path="/about-rbcr/the-baptist-confession-of-faith-of-1689/" component={Confession} />
          <Route path="/about-rbcr/church-constitution/" component={Constitution} />
          <Route path="/about-rbcr/church-membership/" component={Membership} />
          <Route path="/about-rbcr/what-we-believe/" component={Belief} />
          <Route path="/about-rbcr/meet-our-pastors/" component={Pastors} />
          <Route path="/about-rbcr/" component={About} />
          <Route path="/contact-us/" component={Contact} />
          <Route path="/preacher/:slug/" component={Preachers} />
          <Route path="/videos/" component={Videos} />
          <Route path="/spanish/" component={Spanish} />
          <Route path="/sermons/:permalink/" component={Sermon} />
          <Route path="/sermons/" component={Sermons} />
          <Route path="/calendar/" component={Calendar} />
          {/* <Route path="/youth-conference/" component={YouthConference} /> */}
          <Route path="/giving/" component={Giving} />
          <Route path="/vbs/" component={VBS} />
          {/* <Route path="/womens-conference/" component={WomensConference} /> */}
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    );
  }
}

export default ApplicationRouter;
