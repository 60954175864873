import React, { Component } from "react";
import "./Preachers.scss";
import Page from "../Page/Page";
import { PreachersService } from "../../services/PreachersService";
import { Link } from "react-router-dom";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import Pagination from "@material-ui/lab/Pagination";

class Preachers extends Component {
  preachersService = new PreachersService();

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.match.params,
      preacher: "",
      sermons: [],
      count: ""
    };
  }

  async setSermons(page) {
    const { preacher, sermons, sermonCount } = await this.preachersService.getBySlug(this.state.slug, page);
    this.setState({ preacher, sermons, count: sermonCount });
  }

  async componentDidMount() {
    this.setSermons(1);
  }

  getInitials(preacher) {
    const initials = preacher.match(/\b\w/g);

    return `${initials.shift()}${initials.pop()}`;
  }

  getPreacherImage() {
    if (this.state.preacher.image) {
      return <Avatar aria-label="image" src={this.state.preacher.image} className="image" />;
    }

    return (
      <Avatar aria-label="initials" className="image">
        {this.getInitials(this.state.preacher.name)}
      </Avatar>
    );
  }

  handleChange = (event, value) => {
    this.setSermons(value);
    window.scrollTo(0, 0);
  };

  content() {
    const sermons = this.state.sermons.map((sermon, index) => (
      <Link to={`/sermons/${sermon.permalink}`} key={index}>
        <div className="sermon">
          <div className="title">{sermon.title}</div>
          <div className="date">
            {moment(sermon.date).format("MM/DD/YYYY")} ({sermon.service_name})
          </div>
          <div className="scripture">
            Scripture: <i>{sermon.bible_passage}</i>
          </div>
          <div className="preacher">
            {this.getPreacherImage()}
            <span>{sermon.preacher}</span>
            <div className="clear"></div>
          </div>
        </div>
      </Link>
    ));

    return (
      <div className="preachers">
        <div className="container">
          <div className="preacher-header">
            {this.state.preacher && this.getPreacherImage()}
            <div>
              <h2>{this.state.preacher.name}</h2>
            </div>
            <div className="clear"></div>
          </div>
          {sermons}
          <Pagination
            className="paginate"
            count={Math.ceil(this.state.count / 25)}
            color="primary"
            onChange={this.handleChange.bind(this)}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page
        permalink="preacher/robert-elliott/"
        header={this.state.preacher.name ? this.state.preacher.name : ""}
        content={this.content()}
      />
    );
  }
}

export default Preachers;
