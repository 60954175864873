import React, { Component } from "react";
import styles from "../Constitution/Constitution.module.scss";
import "../Constitution/Constitution.scss";
import Page from "../Page/Page";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ScrollAnimation from "react-animate-on-scroll";

class Constitution extends Component {
  state = {
    currentArticle: "article1"
  };

  showArticle(article) {
    document.getElementById(this.state.currentArticle).style.display = "none";
    document.getElementById(article).style.display = "block";
    this.setState({ currentArticle: article });
  }

  hideArticles() {
    for (let i = 2; i < 13; i++) {
      document.getElementById(`article${i}`).style.display = "none";
    }

    document.getElementById("appendix1").style.display = "none";
  }

  componentDidMount() {
    this.hideArticles();
  }

  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <p className={styles.intro_content}>
                As a church entrusted with the spiritual care of the members, as well as the dispersment of the tithes
                and offerings, RBCR has adopted this constitution in order to enable the church to operate in an orderly
                fashion. Adopted in 1995, this constitution has served us well as an effective guideline in handling the
                affairs of the RBCR.
                <br />
                <a
                  target="_blank"
                  href="https://rbcr-prod.s3-us-west-1.amazonaws.com/uploads/2020/11/24/constitution.pdf"
                >
                  PDF Version
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.container}>
            <div className={styles.articleGrid}>
              <ScrollAnimation animateIn="fadeIn">
                <div className={styles.articleLinks}>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article1")}>ARTICLE 1 – NAME</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article2")}>ARTICLE 2 – PURPOSE</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article3")}>ARTICLE 3 – AFFILIATION</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article4")}>ARTICLE 4 – COVENANT</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article5")}>ARTICLE 5 – ARTICLES OF FAITH</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article6")}>ARTICLE 6 – MEMBERSHIP</h4>
                  </AnchorLink>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                <div className={styles.articleLinks}>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article7")}>ARTICLE 7 – CHURCH DISCIPLINE</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article8")}>ARTICLE 8 – ORDINANCES</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article9")}>ARTICLE 9 – OFFICERS</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article10")}>ARTICLE 10 – LEGAL OFFICERS</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article11")}>ARTICLE 11 – CHURCH BUSINESS MEETINGS</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("article12")}>ARTICLE 12 – CONSTITUTIONAL AUTHORITY</h4>
                  </AnchorLink>
                  <AnchorLink offset={() => 150} href="#articles">
                    <h4 onClick={() => this.showArticle("appendix1")}>
                      APPENDIX 1 – STATEMENT ON MARRIAGE, GENDER, AND SEXUALITY
                    </h4>
                  </AnchorLink>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className={styles.articleContent}>
          <div id="articles" className={styles.container}>
            <div id="article1">
              <h4 className="header">ARTICLE 1 – NAME</h4>
              <p>The name of this church shall be the Reformed Baptist Church of Riverside.</p>
            </div>
            <div id="article2">
              <h4 className="header">ARTICLE 2 – PURPOSE</h4>
              <p>
                The purpose of this church is to glorify the God of the Scriptures by maintaining and promoting His
                worship both individually and corporately, by evangelizing sinners, and by edifying His saints.
                Therefore, we are committed to the proclamation of God’s perfect Law and of the glorious Gospel of His
                grace through all the world, to the defense of that “faith which was once delivered unto the saints”
                (Jude 3), and to the pure and faithful celebration of the ordinances of the New Covenant
              </p>
            </div>
            <div id="article3">
              <h4 className="header">ARTICLE 3 – AFFILIATION</h4>
              <p className={styles.indent}>
                <b>3.1 AUTHORITY.</b> We acknowledge no ecclesiastical authority other than our Lord Jesus Christ, who
                is Head of the Church (Eph. 5:23) and who directs the affairs of the church through Elders chosen and
                ordained according to the precepts of Holy Scripture (Acts 14:21-23; 1 Tim. 3:1-7; Titus 1:5-9). The
                Elders themselves at all times and in all their activities stand under the authority of Holy Scripture.
              </p>
              <p className={styles.indent}>
                <b>3.2 COOPERATION.</b> The church may and does cooperate with other like-minded churches in matters of
                mutual interest and concern (2 Cor.1:11; 8:18-24; Phil.4:15-19). We may seek the assistance and counsel
                (Prov. 11:14; 18:1) of other churches in matters of special concern to us, but the decision of no other
                church or group of churches shall at any time be acknowledged as binding on this church (Acts 14:
                21-23).
              </p>
              <p className={styles.indent}>
                <b>3.3 ASSOCIATIONS.</b> One special kind of fellowship and cooperation with other churches is formal
                membership in an association of churches. Upon recommendation of the Elders, such affiliations may be
                entered with the expressed consent and approval of the congregation (Acts 15:22, 2 Cor. 8:19).
                Withdrawal from such associations may be effected by the same.
              </p>
            </div>
            <div id="article4">
              <h4 className="header">ARTICLE 4 – COVENANT</h4>
              <p className={styles.indent}>
                <b>4.1 INTRODUCTORY STATEMENT.</b> God has graciously entered into a covenant relationship with His
                believing people (Jer 31:31-34; 32:40; Heb. 8:7-13; 10:16, 17; 13:20,21). Jesus Christ is the Mediator
                of the New Covenant (Heb. 8:6). His blood is the blood of the New Covenant, which infallibly secures all
                the benefits of the covenant for all of God’s people (Matt. 26:26-28; Heb. 13:20-21). God has in this
                New Covenant made us members one of another (Rom. 12:4; 5; 1 Cor. 12:12-27; Eph.4:25). Therefore, we
                have covenant responsibilities to each other, as well as to God. God has promised in this covenant to
                write His laws in our hearts and to cause us to walk in His ways (that is, to enable us to keep our
                covenant responsibilities). The motivation and ability to obey God’s laws spring from the atoning
                sacrifice of Jesus Christ, who, by His death, satisfied the holy wrath of God that was against us due to
                our sins, and by His resurrection has begotten us again to a living hope (1 Pet.1:3). It is by the
                enablement of the Holy Spirit that we obey, in loving gratitude for Christ’s righteousness before God.
                We obey with the confidence that the end of Christ’s death will be realized in us (that is, “that the
                righteous requirement of the law might be fulfilled in us” [Rom. 8:4a], and that we should be a people
                “zealous for good works” [Titus 2:14]). The following paragraphs are a summary of what we believe to be
                our covenant responsibilities toward God and toward one another. This summary forms the basis for our
                giving and receiving instruction for ourselves and for our families.
              </p>
              <p className={styles.indent}>
                <b>4.2 SUMMARY OF OUR COVENANT RESPONSIBILITIES (THE LAWS WRITTEN IN OUR HEARTS)</b>
              </p>
              <p className={styles.indent2}>
                <b>4.2.1</b> We agree to worship only the one true and living God, Father, Son and Holy Spirit, who has
                revealed Himself to us in the Scriptures of the Old and New Testaments. We will declare His glory to the
                nations. We will have no other gods before Him.
              </p>
              <p className={styles.indent2}>
                <b>4.2.2</b> We agree to worship God in His appointed way and to exclude from our worship anything that
                He has not appointed.
              </p>
              <p className={styles.indent2}>
                <b>4.2.3</b> We agree not to use the name of our God emptily or to take it upon ourselves carelessly,
                but to walk in the paths of righteousness for His name’s sake.
              </p>
              <p className={styles.indent2}>
                <b>4.2.4</b> We agree to cease from our own works on the Lord’s Day, if they are not works of piety, or
                necessity, and to positively sanctify the day by special exercises of public and private worship.
              </p>
              <p className={styles.indent2}>
                <b>4.2.5</b> We agree to honor and obey, within the bounds of Scripture, all our superiors, whether in
                family, church, state, or business; and, if we be superiors, to deal reasonably and lovingly with our
                subordinates and thus to teach them by word and example to fear God and keep His commandments.
              </p>
              <p className={styles.indent2}>
                <b>4.2.6</b> We agree to avoid whatever tends to destroy us or our neighbors and to engage vigorously in
                all lawful endeavors to preserve our own lives and the lives of others, especially by ready
                reconciliation and faithful exhortation in the church.
              </p>
              <p className={styles.indent2}>
                <b>4.2.7</b> We agree to possess our bodies in holiness as vessels joined to Christ and indwelt by the
                Holy Spirit and to avoid all uncleanness of thought, speech, or action.
              </p>
              <p className={styles.indent2}>
                <b>4.2.8</b> We agree to be diligent in our vocations, that we may provide for our own households, avoid
                theft of time, money, or goods, and that we may have to give to him who has need.
              </p>
              <p className={styles.indent2}>
                <b>4.2.9</b> We agree to earnestly promote truth among men and to avoid anything that would prejudice
                the truth or injure our neighbor’s good name.
              </p>
              <p className={styles.indent2}>
                <b>4.2.10</b> We agree to be fully content with our own condition in life, to rejoice in the advancement
                of our neighbor, and to avoid envying him or coveting anything that is his.
              </p>
            </div>
            <div id="article5">
              <h4 className="header">ARTICLE 5 – ARTICLES OF FAITH</h4>
              <p>
                We adopt as the fullest expression of our faith the London Baptist Confession of Faith of 1689
                (excepting the assertions regarding the salvation of the mentally incompetent [10:3] and the identity of
                the antichrist [26:4]). The ultimate authority in all matters of faith, order, and morals is and must be
                the Bible alone, which truth is clearly set forth in the opening article of the Confession itself. This
                historic document is, however, an excellent summary of “the things most surely believed among us,” and
                we find it to be an assistance in controversy, a confirmation in faith, and a means of edification in
                righteousness. Here the members of our church will have a body of Divinity in small compass and by means
                of Scriptural proofs will be ready to give a reason for the hope that is in them (1 Pet. 3:15).
              </p>
            </div>
            <div id="article6">
              <h4 className="header">ARTICLE 6 – MEMBERSHIP</h4>
              <p className={styles.indent}>
                <b>6.1 WARRANT FOR MEMBERSHIP.</b> The New Testament demands of all Christians, formal, open, solemn,
                voluntary and enduring commitment to Jesus Christ, to His truth and to His people. A true Christian’s
                commitment to the Lord Jesus Christ must include, and is inseparable from his commitment to Christ’s
                truth and to Christ’s people. Such a commitment to Christ, His truth and His people ordinarily requires
                a formal, open, solemn, voluntary and enduring commitment of church membership in a local church for the
                following biblical reasons:
              </p>
              <p className={styles.indent2}>
                <b>6.1.1 The Great Commission.</b> Fulfillment of Christ’s Great Commission requires church membership.
                According to the Great Commission of Christ (Matt. 28:18-20) there is an inseparable connection between
                making disciples, baptizing them and teaching them. The Apostles implemented this commission by
                gathering baptized disciples into local churches. It was therefore in local churches that baptized
                disciples were taught all that Christ commanded (Acts 2:38-47, 1 Cor.4:17). With the uncertain exception
                of the Ethiopian eunuch, the New Testament knows nothing of believing men and women who are not members
                of local churches.
              </p>
              <p className={styles.indent2}>
                <b>6.1.2 The Biblical Pattern.</b> The New Testament presents the local church as a distinct group of
                individuals which could be counted (Acts 2:41-42; 4:4); be added to or excluded from (Acts 2:47; 5:14;
                9:26); be called upon to select leaders and representatives from among itself (Acts 6:1-6; 2 Cor. 8:19,
                23; Acts 15:22); be officially gathered together (Acts 14:27; 15:22); carry out church discipline by
                common consent (Matt. 18:17; 1 Cor.5:4, 13; 2 Cor. 2:6); and observe the Lord’s Table as wholly present
                corporate assembly (1 Cor. 11:17-20, 33-34). There is therefore clear biblical warrant for the existence
                and careful maintenance of local church membership involving formal, open, solemn, voluntary and
                enduring commitment. This biblical warrant compels us to use great care in maintaining a
                biblically-ordered church membership.
              </p>
              <p className={styles.indent2}>
                <b>6.1.3 Pastoral Oversight.</b> It is only in the context of the local church with identifiable members
                and overseers that the Biblical injunctions regarding the relationship of pastors and people can be
                fulfilled (1 Thess. 5:12-13; Heb. 13:17).
              </p>
              <p className={styles.indent}>
                <b>6.2 REQUISITES FOR MEMBERSHIP</b>
              </p>
              <p className={styles.indent2}>
                <b>6.2.1 General Requirements.</b> To be eligible for membership, a man or woman (Acts 5:14; 8:3, 12)
                must demonstrate repentance toward God and the fruits thereof (Acts 26:20), as well as that faith toward
                our Lord Jesus Christ (Acts 20:21) which produces godly works (Eph.2:8-10; James 2:18, 22). He must be
                baptized as a believer; and to the satisfaction of the Elders express substantial agreement with the
                purpose (as stated in Article 2), Covenant (as stated in Article 4), Confession (as stated in Article
                5), and government of this church (1 Cor. 16:1-2; 2 Cor. 8:1-8). Furthermore, he must not be under the
                biblically warranted (Matt. 18:17, 18; 1 Cor.5:11-13; 2 Thess. 3:6, 14, 15; 3 John 9, 10; 2 Cor. 2:6-8)
                corrective discipline of a genuine church.
              </p>
              <p className={styles.indent2}>
                <b>6.2.2 Conformity of Doctrine and Polity.</b> Mastery of church confessions is not required of any
                disciple before he is admitted to church membership. Such a requirement would violate the order of Matt.
                28:19,20, which instructs us to disciple, to baptize, and then to teach the baptized disciple to observe
                all things whatsoever Christ has commanded. It is necessary, however, that any disciple applying for
                membership manifest a willingness to be taught and substantial agreement with what he already knows
                concerning the church’s doctrine and government. Church members must be in submission to the ordained
                rule of the church to which they belong (Heb. 13:17). He who cannot intelligently and freely submit to a
                church’s government should not belong to that church. Anyone who is in substantial disagreement with the
                constitution or confessions of the church could not be consistently submissive to the church’s teaching
                ministry. Therefore, to admit such a person to membership in this church would be unwise and
                unscriptural (Eph. 4:3). If one who is already a member of the church at any time concludes that he no
                longer satisfies the requirements for membership, he is under obligation to inform the Elders of that
                fact.
              </p>
              <p className={styles.indent}>
                <b>6.3 TYPES OF MEMBERSHIP</b>
              </p>
              <p className={styles.indent2}>
                <b>6.3.1 Regular Members.</b> All who are received into the membership of the church according to the
                procedures set forth in Section 6.4, who continue in regular attendance at the stated meetings of the
                church, and who do not come under the corrective discipline of the church as set forth in Article 7,
                shall be considered regular members in good standing and entitled to all the rights and privileges of
                membership in the church (Acts 2:37-47).
              </p>
              <p className={styles.indent2}>
                <b>6.3.2 Temporary Members.</b> Persons who come to live in our area for a limited period of time (e.g.,
                students, military personnel, persons on special work assignments) may be received into or removed from
                the membership of the church on the same basis and in the same manner as persons who have permanent
                residence in our area. If such a person is already a member of a church in his place of permanent
                residence, he need not be released from the membership of his home church, but will be regarded as a
                temporary member while in our midst, enjoying all the rights and privileges and subject to all the
                responsibilities, liabilities, and disciplines of regular membership. When such a person terminates his
                period of temporary residence in good standing he will be released to the fellowship of his home church
                and no longer be regarded as a member of this church (compare: Acts 18:27; Rom. 16:1,2; 2 Cor. 3:1f;
                Col, 4:10; 3 John 5-10).
              </p>
              <p className={styles.indent2}>
                <b>6.3.3 Associate Members.</b> Regular members who move away from our area and who cannot find another
                local church with which they can conscientiously unite will, at their request, be retained as associate
                members of this church. Such persons must retain regular communication with the church in order to
                maintain their associate membership. Nevertheless, they are urged to seek diligently a church with which
                they can unite elsewhere. An associate member shall not be allowed to vote in any business meeting of
                the church. At the discretion of the Elders, associate membership may be granted to invalids, Christian
                workers, and others whose relation to the church involves unusual circumstances.
              </p>
              <p className={styles.indent}>
                <b>6.4 RECEPTION INTO MEMBERSHIP</b>
              </p>
              <p className={styles.indent2}>
                <b>6.4.1 Application.</b> A person who desires to become a member of the church shall apply to the
                Elders and request to be interviewed by them. During the interview the Elders will seek to determine
                whether that person has a credible profession of faith in Christ, has been Scripturally baptized, is in
                substantial agreement with the confession and constitution of the church, is capable of assuming the
                responsibilities and liabilities of church membership, intends to give wholehearted support of its
                ministry, and is willing to submit to its government and discipline. Membership may be denied to those
                persons who, at the Elder’s discretion, are unqualified in any of these areas (Acts 9:26-27; 10:47 with
                11:2-18; 11:23).
              </p>
              <p className={styles.indent2}>
                <b>6.4.2 Prior Memberships.</b> If the applicant is or has been a member of another church, special
                effort will be made to determine the person’s standing in that church and his reasons for leaving (Acts
                15: 1-2 with 24-25). If said church raises an objection which the Elders consider valid, the applicant
                may be denied membership at the discretion of the Elders.
              </p>
              <p className={styles.indent2}>
                <b>6.4.3 Procedure.</b> If the Elders are satisfied that the applicant meets the requirements for
                membership, they shall announce the name to the congregation for at least four consecutive Lord’s Days
                at stated meetings of the church. This time period is for the purpose of enabling the members to raise
                any questions or objections concerning the applicant’s qualifications (Acts 9:26-29). Members are
                expected to consider this a personal duty of the most serious character. They are expected to voice
                privately to the Elders all questions or objections that have not yet been resolved, after personal
                contact has been made with the applicant (Matt, 18:15ff; Lev. 19:16-17). If no objection which the
                Elders consider to be valid is raised, the person will be received into the membership by decision of
                the Elders. Their reception will be publicly announced at the next stated meeting of the church. (Matt.
                3:6-12; 1 John 4:1; Rev. 2:2). The Elders may postpone the reception of the person into membership until
                proper investigation can be made concerning objections which in their judgment are sufficiently serious.
              </p>
              <p className={styles.indent}>
                <b>6.5 TERMINATION OF MEMBERSHIP</b>
              </p>
              <p className={styles.indent2}>
                <b>6.5.1 Types of Termination.</b>
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.1 By Physical Death.</b> When a member of the church is removed from our midst by death, his
                name shall be transferred to the file of former members.
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.2 By Transfer</b>
              </p>
              <p className={styles.indent4}>
                <b>6.5.1.2.1 Basis and Procedure for.</b> Because the New Testament norm for Christians is that they be
                members of true local churches of Christ, and because the spiritual health of believers is endangered
                when they are not thus committed to a church, any Christian who leaves the membership of this church
                should seek to do so by means of transfer to another true church of Christ. Therefore, if a church
                member in good standing whose conduct does not warrant corrective discipline desires to leave the
                membership of this church, he is strongly urged to leave in an orderly way by privately indicating that
                desire to the Elders along with his reasons for leaving, and by submitting a request to the Elders for a
                transfer of membership to another true church of Christ (Acts 18:27).
              </p>
              <p className={styles.indent4}>
                <b>6.5.1.2.2 Prolongation of.</b> When such a departing member has not yet chosen a suitable church to
                which he may transfer, the Elders may provide, if he wishes, for a transitional period which will allow
                the departing member to decide where he wishes to be transferred. Such a transitional status will be
                allowed to continue as long as the departing member maintains regular contact with the Elders, does not
                unnecessarily prolong the transitional process, and does not engage in conduct requiring the exercise of
                church discipline.
              </p>
              <p className={styles.indent4}>
                <b>6.5.1.2.3 Letter of.</b> When it is so requested, the elders may transfer a departing member of good
                standing to the fellowship of another church. A letter of transfer will be sent to the appropriate
                officer(s) of the church to which the member wishes to transfer. No such letter may be given to a member
                who is at the time under the corrective discipline of this church. The elders may refuse to grant a
                letter of transfer to any church which is in their judgment disloyal to “the faith which was once for
                all delivered unto the saints” (Jude 3) or which does not exercise godly care over its members.
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.3 By Resignation.</b> Membership in this church is entered into and initiated jointly, by
                voluntary commitment from the individual applicant and acceptance by the church (as described in Section
                6.4). Accordingly, members cannot terminate their membership unilaterally under all circumstances. As a
                general rule, a member may voluntarily resign from membership in this church. A resignation offered to
                frustrate or thwart the procedures of corrective discipline is not valid, and the church may proceed
                with public censure, imposition of strictures, or excommunication in accordance with the procedures
                outlined in Article 7 (Acts 15:24; 1 John 2:18-19 with 2 John 7-11).
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.4 By Exclusion.</b> If a member ceases to faithfully attend the stated meetings of the church
                (Section 6.7.1) without showing just cause, or if upon relocation ceases to maintain a vital contact
                with the church, he may be excluded from the membership at the discretion of the Elders. In such cases,
                the Elders shall try to contact the person to rectify and resolve the situation (Heb 13:17). If these
                efforts are ineffective, the Elders shall inform that person (when possible) and the congregation that
                he is no longer a member. If a person not guilty of heresy, scandalous immorality, or divisiveness,
                either renounces his commitment to keep any of the requirements of membership listed in Section 6.7, or
                ceases, without just cause, to practice any of them as a pattern of life, and yet wishes to remain in
                membership, refusing to resign voluntarily, he may be excluded, but only after repeated admonition from
                the Elders (2 Tim. 2:24-26). In such cases the Elders shall announce to the congregation at a stated
                meeting their intention to exclude the person. Time will be allowed for objections or questions to be
                raised privately with the Elders by any member. If no objection is raised which the Elders consider
                valid, the person will be excluded by action of the Elders. The congregation and the person shall be
                informed of this action.
              </p>
              <p className={styles.indent3}>
                <b>6.5.1.5 By Excommunication.</b> According to the teaching of Holy Scripture, a church must cut off
                from its fellowship and visible membership any person who teaches or insists on holding to false
                heretical doctrine, who blatantly or persistently conducts himself in a manner inconsistent with his
                Christian profession, or who persists in disturbing the unity, peace or purity of the church (Matt.
                18:15ff; Rom. 16:17; 1 Cor. 5;1ff; Titus 3:10-11). The procedure to be followed in such excommunication
                is set forth in Section 7.2.4 of this Constitution.
              </p>
              <p className={styles.indent2}>
                <b>6.5.2 Implications of Termination.</b>
              </p>
              <p className={styles.indent3}>
                <b>6.5.2.1 Disclosure to Saints.</b> The Reformed Baptist Church of Riverside does not exist in
                isolation from, but is part of the universal church of Christ, composed of all true churches.
                Accordingly, open and forthright communication among the churches is vital for the purity, peace,
                edification and unity of the church universal. Therefore, the Elders may, at their discretion, disclose
                to the members of this church and to other churches the circumstances under which a person’s membership
                was terminated (Acts 15:24; 1 Tim. 1:20; 2 Tim, 2:17; 4:10; 1 John 2:18, 19).
              </p>
              <p className={styles.indent3}>
                <b>6.5.2.2 Disclosure to Society.</b> In addition, the Reformed Baptist Church of Riverside does not
                exist in isolation from society at large. Accordingly, this church has a moral obligation to society
                both to act with integrity and to maintain its testimony (2 Cor. 8:20-21). Therefore, the elders may, at
                their discretion, disclose to other persons outside the ecclesiastical circles mentioned above the
                circumstances under which a person’s membership was terminated (Lev. 5:1; Prov. 29:24; 1 Pet. 4:15).
              </p>
              <p className={styles.indent3}>
                <b>6.5.2.3 Divisiveness in.</b> Termination of membership does not give license to former members to sow
                discord, spread false teachings or reports, or engage in any other behavior which threatens the peace
                and unity of this church or the church universal. Accordingly, when it is established that a former
                member is behaving divisively, the Elders may issue whatever warnings they deem appropriate to maintain
                and preserve the peace and harmony of this church and the church universal (Acts 15:24-31; Rom.
                16:17-20; 1 Tim. 1:19-20; 2 Tim. 2:16-18; 4:10; 1 John 2:18-19).
              </p>
              <p className={styles.indent}>
                <b>6.6 PRIVILEGES OF MEMBERSHIP.</b> In God’s order, commitment normally constitutes the pathway to the
                possession of privileges. Therefore, membership in this church includes the following privileges:
              </p>
              <p className={styles.indent2}>
                <b>6.6.1</b> Participation in the Lord’s Supper (Acts 2:41-42; 1 Cor. 11:23-26);
              </p>
              <p className={styles.indent2}>
                <b>6.6.2</b> Attendance at, appropriate participation in, and voting during church business
                meetings(Acts 6:11-6 [cf. Acts 2:41; 4:4; 5:13-14]; 1 Cor.5:4-7 [cf. 1 Cor. 1:2]);
              </p>
              <p className={styles.indent2}>
                <b>6.6.3</b> Laboring to extend God’s Kingdom in ministries of the church (as one’s gifts, graces and
                calling make appropriate) (Rom. 12:3-21; Eph. 4:7; 11-12; 16; 1 Pet. 4:10-11);
              </p>
              <p className={styles.indent2}>
                <b>6.6.4</b> Reception of the committed oversight and care of the Elders of the church (Acts 20:28; 1
                Pet. 5:1-3);
              </p>
              <p className={styles.indent2}>
                <b>6.6.5</b> Reception of the committed care and discipline (as needed) of the membership of the church
                (Acts 6:1-2 [cf Acts 2:41; 5:13-14; 9:26]; 1 Cor. 5: 4-5 [cf. 1 Cor. 1:2]; Gal. 6:10).
              </p>
              <p className={styles.indent}>
                <b>6.7 Members’ Commitment to the Body</b>
              </p>
              <p className={styles.indent2}>
                <b>6.7.1 Attendance at Church Meetings.</b> Since all regular and temporary members believe in the
                sanctity of the Lord’s Day and the special duty and privilege of corporate worship, and since the church
                is a body which of necessity must have its members present in order to function; the members of this
                church shall seek to come together on the Lord’s Day at the times appointed for worship, prayer,
                teaching and preaching from the Scriptures, and the observance of the sacraments of baptism and the
                Lord’s Table. Providence may occasionally hinder some from participation; however, generally this would
                be the exception and not the rule. (Acts 2:42, Acts 12:5, Hebrews 10:24-25, 1 Cor.11:18, 20, 1
                Cor.12:12-27).
              </p>
              <p className={styles.indent2}>
                The Elders may call special meetings on occasion when they believe necessity requires it. Members should
                seek to participate in the life of the body by being present at these meetings. (Matthew 18:17, Acts
                14:27)
              </p>
              <p className={styles.indent2}>
                <b>6.7.2 Financial Support.</b> Because it is clearly taught in the Scriptures that Christians should
                financially support the work of the Lord by systematic and proportionate giving through the local church
                (Gen 14:19-20 with Heb. 7:4; Mal. 3:8-10; 1 Cor. 16:1-2; 2 Cor. 8,9), all the members of this church are
                expected to conform to this rule of Scripture. The tithe (ten percent of one’s gross personal income) is
                strongly urged upon each member as an expression of worship and the biblical norm for basic giving, to
                which should be added gifts and offerings according to one’s ability and the willingness of one’s heart
                (Gen. 14:19-20 with Heb. 7:4; Exod. 36:2-7; 2 Cor. 8:1-5; 1 Tim. 6:17-19).
              </p>
              <p className={styles.indent2}>
                <b>6.7.3 Promotion of Edification and Peace.</b> Inasmuch as the church is represented in Scripture as a
                body having many members, each of the members having its own particular function and yet having a
                concern for the health and protection of the whole (1 Cor.12:12-27; Eph. 4:4, 11-16), this church
                expects that each of its members will strive for the good of the entire body. As members of this church
                we must actively seek to cultivate acquaintance with one another and maintain mutual transparency and
                honesty so that we may be better able to pray for one another; love, comfort and encourage one another;
                and help one another materially as necessity may require (Gal. 6:10; Eph. 4:25; 1 John 3:16-18). In
                addition, we must discreetly confess our faults one to another (James 5:16), faithfully admonish and
                encourage one another (1 Thess.5:14; Heb. 3:12-13; 10:24-25), and refrain from all backbiting and gossip
                (Prov. 10:31-32; 26:20-22). The Elders may determine that some matters of congregational business are so
                sensitive that the members may be expected to keep such matters confidential (Prov. 11:13).
              </p>
              <p className={styles.indent2}>
                <b>6.7.4 Support of and Submission to the Leadership.</b> All who come into the membership of this
                church are expected to recognize and to submit to the overseers, or Elders, of the church. Supporting
                God’s servants necessitates praying for them and their labors (Eph. 6:18-19); cultivating personal
                acquaintances with them, loving and esteeming them highly for their work’s sake (1 Thess. 5:12-13);
                standing by them and not forsaking them in their afflictions and in all good causes (2 Tim. 1:15-18);
                and defending rather than prejudicing or damaging their good name (Acts 23:5; 1 Tim. 5:19). Submitting
                to God’s servants necessitates imitating their Christian graces, faith and godly principles as they also
                imitate Christ (1 Cor. 11:1; Heb. 13:7; 1 Pet. 5:3); receiving their teaching with all readiness of mind
                and teachableness of spirit, yet with ultimate allegiance to the Word of God (Acts 17:11; 1 Thess.
                2:13); humbly heeding their Scriptural rebukes and warnings as from those appointed to watch for the
                souls entrusted to them and committed to labor to present them complete and mature in Christ (Col.
                1:28); seeking and carefully considering their counsel as being from those counted faithful by the Lord
                (1 Cor. 7:10, 25); and lovingly embracing and abiding by their decisions regarding corporate policy in
                God’s house, which is His church (1 Thess. 5:12-13; 1 Tim. 3:5, Heb. 13:17) without creating division
                even when personally differing from their judgment. This submission will include willingly scheduling an
                oversight meeting with an Elder(s) when requested.
              </p>
              <p className={styles.indent2}>
                <b>6.7.5 A Godly Christian Life.</b> All who come into the membership of this church are expected to
                walk worthily of the Lord (Eph.4:1ff). Therefore, every member is expected to practice and cultivate
                godliness in the following areas:
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.1 Personal Devotion.</b> Each member is expected to make use of the various private means of
                grace such as daily private prayer (Ps. 55:17; Dan. 6:10; Mt. 6-6-13); daily and habitual reading and
                meditating on God’s Word (Ps 1:2; 119:11, 97); and careful and spiritual observance of the Lord’s Day
                (Gen.2:1-3; Ex.20:8-11; Isaiah 58:13-14; Lk. 2:27; Acts 20:7; 1 Cor. 16:2; Rev. 1:10).
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.2 Family Life.</b> The church expects its members to obey the teachings of Scripture in respect
                to the life and government of the home. As the God-appointed head of the family, the husband must rule
                over his household with gentleness and love, but also with wisdom and firmness (Eph. 5:25ff; 1 Tim.
                3:4-5; 1 Pet. 3:7). The wife must be in subjection to her husband in all things according to the rule of
                Scripture (Eph. 5:22-24; 1 Pet. 3:1-6). The Bible clearly teaches that man and woman are equal in
                created dignity, native depravity and redemptive privileges. The functional subordination of the
                Christian wife does not imply inferiority. The leadership of the husband in the home should be such that
                it allows for the full development of the wife’s gifts and graces (Prov. 31:10-31). The husband and wife
                must bring up their children in the nurture and admonition of the Lord (Eph. 6:1-4). This includes
                setting a godly example before them, consistently instructing them from the Scriptures (Deut. 6:4-9),
                and by wise, firm and loving discipline, including corporal punishment and verbal reproof when necessary
                (Prov. 13:24; 22:15; 29:15; Heb. 12:7-8).
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.3 Personal Evangelism.</b> It is the duty of every Christian, as an individual and as a member
                of a local church, to labor by prayer, word, and deed for the extension of the kingdom of God in ever
                widening circles, beginning at home and stretching forth to the ends of the earth (Isa. 54:1-3; Acts
                1:8). Therefore, every member of this church is expected prayerfully to recognize and seize every
                opportunity to bear witness to his faith in Christ, both by consistent Christian conduct and by the
                testimony of his lips (Col. 4:5-6; 1 Pet. 3:14-15).
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.4 Christian Liberty.</b> Each member of the church is required to render in his daily life
                loyal obedience to all the moral precepts established by the Word of God (Rom. 8:3,4). If God has not
                condemned or forbidden a practice in His Word, a Christian is at liberty to participate in it. The
                exercise of Christian liberty, however, must at all times be governed by an earnest desire to walk in
                the fear of God and to glorify Him in all things (1 Cor. 10:31; 1 Pet. 1:15-19), a loving regard for the
                consciences of weaker brethren (Rom. 15:1-31; 1 Cor. 8:7-12), a compassion for the lost (1 Cor.
                9:19-22), and a zealous regard for the health of one’s own soul (Rom. 13:14; 1 Cor. 6:12; 9:24-27; Gal.
                5:22-24; 1 Pet. 2:16).
              </p>
              <p className={styles.indent3}>
                <b>6.7.5.5 Separation from the World.</b> God never intended the glorious blessing of Christian liberty
                which we enjoy to become an excuse and covering for worldliness (Gal 5:13; 1 Pet. 2:16). To the
                contrary, we have been liberated from bondage to our former sins in order that we might be a people
                distinct from this present evil age (Gal. 1:4) and set apart unto God (Lev. 18:1-30; Titus 2:11-14; 1
                Peter 1:14-16). Accordingly, we are called upon to identify and abhor the attitudes and practices of
                this age that are contrary to the will of God (Rom. 12:9; Eph. 4:17-22; 5:7-12; 1 Thess. 5:21-22; Titus
                2:12; 3:3; 1 Pet. 4:3-4). We are also called upon personally to resist the molding influence of a
                godless society (Prov. 1:10-19; Rom. 12:1-2; James 1:27). Therefore, all the members of this church are
                expected to separate from the wicked practices and attitudes of such society while still living in and
                ministering to it (1 Cor. 5:9-11).
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.1 The Things of this Life.</b> We are expected to resist the worldly attitude that deems the
                things of this life to be of primary importance (1 John 2:15-17). This means that we are not to become
                so absorbed with work, material possessions, politics, recreations, or any other person or thing, that
                it rivals our primary attachment to Christ (Matt. 6:24).
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.2 Values and Relativism.</b> We are not to adopt the distorted values of this world, such as:
                considering self-worth to be rooted in attainments, social standing, heritage, or usefulness to society.
                It means, too, that we are not to adopt the moral relativism of the world, especially respecting the
                sanctity of life and truth.
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.3 Gender Roles.</b> We are expected to resist a worldly attitude about masculinity and
                femininity. This means that we must reject the carnal notions that male and female roles are arbitrary
                and reversible; that sexual orientation is a matter of preference rather than morality; and that
                marriage is oppressive unless it is egalitarian. This also means that we must regard a wife’s primary
                role to be that of a helper to her husband (Gen. 2:18), her basic (but not necessarily exclusive) sphere
                of labor to be domestic (Prov. 31:10-31; 1 Tim. 5:14; Titus 2:4-5), and her estate as wife and mother to
                be noble and honorable, rather than degrading and unfulfilling. It means too that we must regard a
                husband’s role not only to be that of provider and protector (Eph. 5:23, 28; 1 Tim. 5:8), but also to be
                that of giving personal nurture to his children (Eph. 6:4; 1 Thess.2:11; Heb. 12:9) and considerate
                nurture to his wife (1 Peter 3:7; Gen. 18:6-8).
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.4 Vices.</b> We are expected not to indulge in any of those vices which surround us:
                drunkenness, drug abuse, gluttony, viewing pornographic materials, fornication, homosexuality and other
                such sins.
              </p>
              <p className={styles.indent4}>
                <b>6.7.5.5.5 Relationships.</b> Respecting the world’s influence, we are expected to refrain from all
                entangling relationships with the ungodly, such as intimate, frequent companionship with them (Prov.
                12:26; James 4:4), pursuing romantic interest in them (Judges 16:4-5; 1 Kings 11:1-4, 9; Prov. 2:16-17;
                6:23-25), and contracting marriages with them (2 Cor. 6:14; 1 Cor. 7:39). Similarly, we are expected
                carefully to seek to discern and resist any wicked influence of this godless society upon our souls and
                our families, whether it is exerted by means of television, video tapes, movies, music, literature,
                schools, or any other means (Rom. 12:1-2; 1 Pet. 2:11-12).
              </p>
              <p className={styles.indent}>
                <b>6.8 RECORDS OF MEMBERSHIP.</b> The Elders shall keep a file of all past and present members. This
                file shall have three divisions: regular members, associate members, and former members. The file of
                former members shall include a date and reason church membership was terminated, as well as any other
                necessary information (see Section 6.5).
              </p>
            </div>
            <div id="article7">
              <h4 className="header">ARTICLE 7 – CHURCH DISCIPLINE</h4>
              <p className={styles.indent}>
                <b>7.1 FORMATIVE DISCIPLINE.</b> Every disciple of Christ is disciplined by Him personally and without
                intervening mediators (Acts 5:1-11; 1 Cor. 11:29-32; 1 Thess. 4:6; Heb. 12:5-11; Rev. 2:22-23); and
                mediately, through the church (Matt. 18:15-20; 1 Thess. 5:12-15; Heb. 3:12-13; 10:24-25). Mutual
                submission to one another and to the overseers whom the Lord has set over His church (1 Pet. 5:5) will
                result in the sanctification of each member individually and of the whole body of the church
                collectively. There are occasions, however, when informal discipline alone is insufficient and formal
                corrective discipline becomes necessary.
              </p>
              <p className={styles.indent}>
                <b>7.2 CORRECTIVE DISCIPLINE</b>
              </p>
              <p className={styles.indent2}>
                <b>7.2.1 General Statement.</b> Corrective discipline becomes necessary when heretical doctrine or
                disorderly, immoral, or scandalous conduct appears among the members of the church. As a general rule
                and whenever feasible, an effort must be made to resolve difficulty, correct error, and remove offense
                through counsel and admonition before more serious steps are taken (Gal. 6:1; James 5:19-20). The
                principles given in Matt. 18:15-20, Rom. 16:17-20, 1 Cor. 5:1-13, 2 Thess. 3:6-15, 1 Tim. 5:19-22,
                6:3-5, and Titus 3:10 must be carefully followed and applied to each case of corrective discipline as
                appropriate. In some cases, public admonition and/or public repentance may be warranted (Matt. 18:17; 1
                Tim. 5:20). In other cases, some of the privileges of membership may need to be suspended and
                appropriate stricture imposed (Rom. 16:17-20; 2 Thess. 3:14-15). In the most extreme cases
                excommunication from the membership of the church may be necessary (Matt. 18:17; Rom. 16:17; 1 Cor.
                5:1-13; 1 Tim. 1:20; Titus 3:10). All the members of the church are obliged to submit to and enforce as
                appropriate the decision of the church in acts of corrective discipline.
              </p>
              <p className={styles.indent2}>
                Since the church is a spiritual and religious institution, the punishments (2 Cor. 2:6) implemented by
                the church in corrective discipline are also spiritual. They include public verbal reproof (Matt. 18:17;
                1 Tim. 5:20), social avoidance and withdrawal of distinctively Christian fellowship (Rom. 16:17; 1 Cor.
                5:9-11; 2 Thess. 3:6, 14) and removal from the membership of the church (Matt. 18:17; 1 Cor. 5:13). They
                are intended to effect repentance through a sense of sorrow and shame (2 Cor. 2:7; 2 Thess. 3:14). The
                church has no right, however, to confiscate goods, revoke conjugal rights, or inflict corporal
                punishment of any kind. Nevertheless, a member guilty of criminal actions may be delivered to the civil
                authorities according to the rule of Scripture (Rom. 12:17b; 13:1-7; Titus 3:1; 1 Pet. 4:15).
              </p>
              <p className={styles.indent2}>
                The goals of corrective discipline are always the glory of God, the welfare and purity of the church (1
                Cor. 5:6; 1 Tim. 5:20) and the restoration and spiritual growth of the offender (1 Cor. 5:5, 2 Cor.
                2:5-8; 1 Tim. 1:20).
              </p>
              <p className={styles.indent2}>
                <b>7.2.2 Public Reproof or Censure.</b> Public reproof consists of a pastoral effort, before the
                gathered church, to call an impenitent church member to repentance for sin too serious to be covered
                with a blanket of love; or to deal with serious sin even where there may have been repentance. The
                Elders may administer public censure whenever, in their judgment, either public misconduct (Gal.
                2:11-14; 1 Tim. 5:20), patterns of sin (Titus 1:12-13), or serious doctrinal error (Titus 1:10-13) pose
                a significant threat to the godliness, unity or testimony of the congregation. Those who humbly receive
                the word of public reproof, own and confess their sin, and manifest a transformed life (Prov. 28:13)
                shall afterward be publicly commended for their godly repentance (2 Cor. 7:7-11). If the reproof is not
                heeded, further discipline may be imposed.
              </p>
              <p className={styles.indent2}>
                <b>7.2.3 Suspension of Privileges.</b> Some misconduct on the part of a member is so detrimental to the
                unity, holiness and testimony of the church that the Lord required public reproof to be accompanied with
                the suspension of some of the privileges of membership according to the nature and gravity of the
                offense (Rom. 16:17-20; 2 Thess. 3:6-15). In all cases of suspension the offending person is still
                regarded as a brother in Christ and as a member of the church. Severe reproof is to be expressed (Matt.
                18:17) and the suspension is to be enforced (Rom. 16:17-20; 2 Thess. 3:6-15) by the entire church, and
                not merely by the Elders representing the church. Therefore, when appropriate, the Elders shall, at a
                duly convened business meeting of the church, recommend that the offending member be suspended. The
                Elders shall specify the grounds of the discipline, the privileges to be revoked, and the stricture to
                be imposed. To be valid, an act of suspension must have the approval of at least two-thirds of the
                members present and voting. In the interest of maintaining a climate of holiness and peace, the Elders
                shall have the right, at their sole discretion, to impose a temporary suspension upon a member during
                the brief interval between their determination to recommend suspension and the congregational vote. A
                member under suspension shall be treated by the congregation according to the specific applications of
                the general principle of social avoidance (Rom. 16:17-20; 2 Thess. 3:14-15) determined by the Elders.
                Those who humbly submit to the imposed discipline shall afterwards be forgiven, have their privileges
                restored, and be publicly received back into the full fellowship of the church (Matt. 18:15; 2 Cor.
                2:5-11).
              </p>
              <p className={styles.indent2}>The general categories of sin which require suspension are as follows:</p>
              <p className={styles.indent3}>
                <b>7.2.3.1 A Stubborn Private Offender</b> (Matt. 18:15-17). When a private offense remains unresolved
                even after the method prescribed by our Lord in Matt. 18:15-16 has been graciously and prayerfully
                followed, it is considered an aggravated offense. The brethren involved shall bring the matter to the
                Elders who, if they judge the matter to be serious and cannot persuade the brother to repent, shall
                report the situation to the church, and recommend that the stubborn brother be suspended (Matt. 18:17;
                Rom. 16:17). If, even after a period of suspension, the person remains adamant in his sin,
                excommunication may be enacted according to the procedure outlined in Section 7.2.4. (Matt. 18:17b).
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.2 Divisive Teachings or Behavior</b> (Rom. 16:17-20; Titus 3:10). When a member deliberately
                persists in the propagation of serious doctrinal error contrary to the Scripture or our Confession, or
                attempts to sow discord among the membership contrary to the Scripture or this Constitution, he may be
                suspended as a factious man. Since every member is responsible to help preserve the unity of the Spirit
                (Eph. 4:1ff), no one is to conceal such divisive behavior, but rather to reprove it, and disclose it to
                the Elders (Deut. 13:6f; 1 Cor. 1:10,11). Whenever the Elders become aware of such divisive behavior,
                they are to confront it meekly and patiently according to the Word of God (1 Cor. 1:10ff; Titus 3:10).
                If, even after receiving repeated admonition from the Elders, a member persists in such behavior, the
                Elders shall report the situation to the church and recommend that the divisive brother be suspended.
                If, even after a period of suspension, the person remains impenitent, excommunication may be enacted
                according to the procedure outlined in Section 7.2.4.
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.3 Disorderly Behavior</b> (2 Thess. 3:6-16; 1 Thess. 4:11-12; 5:14). Some kinds of conduct and
                doctrine are categorized as disorderly. If a member deliberately persists in conduct which displays a
                flagrant or public disregard for the order appointed by God for all mankind in the creation ordinances,
                namely: work, Sabbath and marriage (Gen. 2:1-3, 15, 18-24; Exod. 20:8-11; Matt. 19:4-6; 1 Cor. 7:1-17,
                39; 2 Thess. 3:6-15; 1 Tim. 5:8; Titus 2:5), he may be suspended as a disorderly man (2 Thess. 3:6).
                Similarly, a member may be suspended as a disorderly man if he deliberately persists in conduct contrary
                to the order established by Christ for His church in Scripture (1 Cor. 11:17-34; 14:37-40; 1 Tim. 3:14,
                15) as recognized by our church in this Constitution. Whenever the Elders become aware that, in spite of
                the admonitions of formative discipline (1 Thess. 5:14), a member is behaving disorderly, they are to
                confront him meekly and patiently according to the Word of God (2 Thess. 3:14-15). Disorderly conduct is
                to be distinguished from traitorous conduct. A disorderly member is not immediately to be
                excommunicated. Rather he is to be suspended in accordance with the directions of 2 Thess. 3:6-15 and
                not regarded as an enemy but admonished as a brother. However, if even after receiving such admonition
                from the Elders, a member persists in this behavior, the Elders shall report the situation to the church
                and recommend that the disorderly brother be suspended (2 Thess. 3:14-15). If, even after the period of
                suspension, the person remains impenitent, excommunication may be enacted according to the procedure
                outlined in Section 7.2.4.
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.4 A Scandalous Sin.</b> If a member has sinned scandalously but shows hopeful signs of
                repentance, including submission to the Elders, excommunication would be unwarranted. However, it may
                still be prudent to suspend him for a time so that he may realize the gravity of the offense and clearly
                manifest repentance (Matt. 3:8), so that reproach not be brought upon the Name of Christ and the church
                (Isa. 52:5; Ezk. 36:20-21; Rom. 2:24), and so that others may not be emboldened to sin (1 Tim. 5:20). If
                fruits worthy of repentance are not forthcoming, the Elders may recommend to the church at a later date
                that this person be excommunicated according to the procedure outlined in Section 7.2.4.
              </p>
              <p className={styles.indent3}>
                <b>7.2.3.5 Contempt of Church Discipline.</b> If a person is accused or suspected of an offense
                requiring corrective discipline, yet absents himself from the meetings of the church, or refuses to meet
                with the Elders so that the matter may be investigated, such a person may be suspended (Matt. 18:17;
                Num. 16:1-12, 23-27). The Elders may recommend to the church at a later date that this person be
                excommunicated according to the procedure outlined in Section 7.2.4.
              </p>
              <p className={styles.indent2}>
                <b>7.2.4. Excommunication</b>
              </p>
              <p className={styles.indent3}>
                <b>7.2.4.1 Occasions Requiring.</b> In addition to the excommunication of those who have been previously
                suspended, some expressions of sin (ethical or doctrinal) are so gross and heinous in nature that
                preliminary actions like public reproof and suspension are inappropriate. In such cases, the guilty
                member may be immediately excommunicated by the church (1 Cor. 5:1-5). This severe measure is to be
                employed when both aggravated lawlessness is discovered, and there are no hopeful signs of repentance.
                This severe measure is designed to purge the lawbreaker of his lethal attachment to his sin, unto a
                sincere and enduring repentance (1 Cor. 5:5). The Elders, therefore, having made earnest but
                unsuccessful efforts to bring the offender to true repentance and reformation, shall report the same to
                the church and recommend that the offender be excommunicated.
              </p>
              <p className={styles.indent3}>
                <b>7.2.4.2 Church Consent in.</b> All acts of excommunication must be executed by the gathered (Matt.
                18:17; 1 Cor. 5:4). To be valid, an act of excommunication must have the approval of at least two thirds
                of the members present and voting at a duly convened business meeting of the church.
              </p>
              <p className={styles.indent3}>
                <b>7.2.4.3 Following Suspension.</b> Members who have been suspended may be excommunicated if they
                continue to be impenitent.
              </p>
              <p className={styles.indent2}>
                <b>7.2.5 Restoration.</b> The purposes of church discipline are to restore a fallen brother or sister
                and to keep the church pure. Therefore, it is the duty of the church to forgive and to restore to full
                membership a suspended or excommunicated member who gives satisfactory evidence of his repentance (2
                Cor. 2:6-8; Matt. 18:18-20). This shall be done in a duly convened business meeting of the church by a
                two thirds majority of the members present and voting.
              </p>
            </div>
            <div id="article8">
              <h4 className="header">ARTICLE 8 – ORDINANCES</h4>
              <p className={styles.indent}>
                <b>8.1 GENERAL STATEMENT.</b>There are two ordinances of special significance that our Lord has
                commanded us to observe, namely, baptism and the Lord’s Supper. Neither of them has saving merit, nor is
                any grace imparted to the recipient through the water of baptism or through the bread and the cup of the
                supper. These ordinances are not means of “special grace, “ but they are special “means of grace” and
                powerful aids to the faith of the believers who participate in them.
              </p>
              <p className={styles.indent}>
                <b>8.2 BAPTISM</b>
              </p>
              <p className={styles.indent2}>
                <b>8.2.1 Its Candidates.</b> Only confessed disciples of our Lord Jesus Christ are proper candidates for
                baptism, and all such persons should be baptized and joined to the church (Matt. 28:19-21; Acts 2:38,
                41, 47; 5:13, 14).
              </p>
              <p className={styles.indent2}>
                <b>8.2.2 Its Necessity for Membership.</b> Believing that baptism in water is the God-ordained sign of
                one’s personal union with Christ in His death, burial, and resurrection, and the door of entrance into
                the visible community of the people of God, normally we shall receive into the membership of the church
                only those who have been baptized as believers “in the name of the Father and of the Son and of the Holy
                Spirit” (Matt. 28:19).
              </p>
              <p className={styles.indent2}>
                <b>8.2.3 Its Mode.</b> Immersion in water is the biblical mode of baptism, is necessary for its due
                administration, and is the only mode to be administered by this church.
              </p>
              <p className={styles.indent}>
                <b>8.3 THE LORD’S SUPPER</b>
              </p>
              <p className={styles.indent}>
                Whereas baptism is the initiatory ordinance by which one enters the visible church, and should be
                observed only once by each believer, the Lord’s Supper should be celebrated frequently by the assembled
                church (1 Cor. 11:26). While this is a most holy ordinance and should be observed with solemnity and
                dignity, the bread and the cup of the supper are and remain only symbols of the broken body and the shed
                blood of our Lord Jesus Christ. In order to maintain the purity of this ordinance, the Elders will
                faithfully seek to insure that only true believers are admitted to the table.
              </p>
            </div>
            <div id="article9">
              <h4 className="header">ARTICLE 9 – OFFICERS</h4>
              <p className={styles.indent}>
                <b>9.1 GENERAL STATEMENT</b>
              </p>
              <p className={styles.indent}>
                Jesus Christ alone is the Head of His Church (Col. 1:18). He has ordained that individual churches
                should be governed by Himself through officers whom He appoints, who are endowed by His Spirit with the
                gifts and graces needed to accomplish their work. Christ has ordained that local churches are to be
                administered by Elders and Deacons. Beside these two offices the Scriptures acknowledge no office which
                continues in the church today (Phil. 1:1; 1 Tim. 3:1-13).
              </p>
              <p className={styles.indent}>
                <b>9.2 PREREQUISITES</b>
              </p>
              <p className={styles.indent2}>
                <b>9.2.1 Conformity.</b> Any individual set apart to one of these offices must conscientiously be able
                to affirm his agreement with the Church’s Confession of Faith and Constitution. If he should at any time
                move from this position, he would be under spiritual and moral obligation to immediately make that fact
                known to the Elders in an orderly manner.
              </p>
              <p className={styles.indent2}>
                <b>9.2.2 Gender.</b> While we acknowledge the valuable gifts which God has given women and the valuable
                assistance they may render to the officers of the church (Rom. 16:1-16; Phil. 4:3; 1 Tim. 3:11), the
                Bible prohibits women from holding either the office of Deacon or Elder in the church (1 Cor. 14:33b-35;
                1 Tim. 2:8-15; 3:1-7). Women, therefore, shall not be nominated, elected, or ordained to either of these
                offices in the church. It is also contrary to Scripture for any woman to exercise headship of leadership
                in a formal meeting of the church where adult men are present, either by leading in prayer, conducting
                the worship, reading the Scripture, leading the singing, administering the sacraments, or ministering
                the Word of God (1 Cor. 14:33b-35; 1 Tim. 2:8-15). Since it is also a violation of the Scriptures for a
                woman to exercise authority over a man in spiritual things outside a meeting of the church, no woman
                shall be appointed to a teaching or authoritative function in a ministry of the church where adult men
                would be regularly under her ministry. Nevertheless, we acknowledge and encourage the valuable gifts and
                assistance of women in the formal instruction of children and other women (Titus 2:3-5), in the informal
                instruction even of men (1 Cor. 11:5; Acts 18:26), and in the diaconal and especially the benevolent
                ministries of the church (1 Tim. 5:9-10).
              </p>
              <p className={styles.indent}>
                <b>9.3 ELDERS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.3.1 Terminology.</b> Those who have been called of God to rule and teach in the church are called
                Elders, Pastors, or Overseers (sometimes translated “bishops”). These three titles are interchangeable
                and designate one and the same office in a New Testament church (Acts 20:17, 28: Eph. 4:11,12; Titus
                1:5, 7).
              </p>
              <p className={styles.indent2}>
                <b>9.3.2 Qualifications.</b> Anyone desiring the office of an Elder must evidence to God’s people the
                personal, domestic, and ministerial qualifications that are set forth in the Scripture (1 Tim. 3:1-7;
                Titus 1:5-9).
              </p>
              <p className={styles.indent2}>
                <b>9.3.3 Authority.</b> Because the authority of the Elders of the church is human authority exercised
                in the house of God, it has both high prerogatives and important limitations:
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.1 It is Limited Authority.</b> The authority of the Elders does not include the right to make
                certain decisions unilaterally. In major decisions of church life (such as those having to do with the
                admission of new members, corrective discipline, recognition of officers, and the sale or purchase of a
                church building or real estate), the local church as a whole has a voice (Acts 6:2-6; 9:26; 1 Cor.
                5:4-5; 13; 2 Cor. 2:6). Yet the Elders must provide definitive leadership to the church in the making of
                such decisions.
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.2 It is Local Authority.</b> The authority of the Elders is limited to the sphere of the local
                church. Thus, they will not require punishments for sin beyond those of biblical church discipline, will
                not invade the biblically-defined spheres of other divinely-ordained human authorities (husbands,
                fathers, civil rulers, and employers), and will not command God’s people regarding matters not specified
                in Scripture except to order the house of God by the application of His Word (Matt. 22:21; Luke
                12:13-14; Acts 20:28; Rom. 13: 1-7; 1 Cor. 7:25-28, 35-40; Eph. 5:22-6:9; 1 Pet. 5:2-3).
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.3 It is Accountable Authority.</b> The authority of Elders is conditioned by the fact that they
                are themselves members of the local church. While Elders are shepherds over the flock, they are also
                members of the flock. Therefore, each individual Elder is entitled to the same privileges, is obligated
                by the same responsibilities, and is subject to the same discipline as are all the other members of the
                church. Thus, each individual Elder is both under the oversight of his fellow Elders and accountable to
                the church as a whole (Matt. 18:15-17; 23:8-9; Gal. 2:11; 3 John 1, 9-10).
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.4 It is Shared Authority.</b> The authority of every Elder is the same. Thus, every Elder has
                equal rule in the church. Though gifts possessed and functions performed will vary from Elder to Elder,
                this diversity must not undermine real parity among the Elders (Acts 20:17, 28; Gal. 2:11; 1 Pet. 5:1-2;
                1 Tim. 5:17).
              </p>
              <p className={styles.indent3}>
                <b>9.3.3.5 It is Real Authority.</b> The Scriptures designate the Elders as those who “rule” in the
                church (1 Tim. 5:17; Heb. 13:17; note also the scriptural titles and functions of the office). God’s
                people are, therefore, required to submit when such authority is biblically exercised.
              </p>
              <p className={styles.indent2}>
                <b>9.3.4 Pastoral Visits.</b> One crucial aspect of the Elders’ duties is personally overseeing the
                flock of God. Fulfillment of this duty shall include regularly and systematically meeting with each
                member of the church on at least an annual basis, except when physically impossible due to distance.
              </p>
              <p className={styles.indent2}>
                <b>9.3.5 Remuneration.</b> Elders will be maintained in material necessities and disentangled from the
                cares of another vocation according to their gifts, the needs and capability of the church, and the
                direction of Christ her Head (1 Cor. 9:4-14; 1 Tim. 5:17-18).
              </p>
              <p className={styles.indent2}>
                <b>9.3.6 Plurality.</b> Though a plurality of Elders is a New Testament norm for every church, the New
                Testament does not specify the number of Elders each church should have, nor does it dictate the length
                of an Elder’s term of office. One truly called to this office is usually called to it for life. He is a
                gift of Christ to the church, and the gifts of God are without repentance. Only when an Elder fails to
                meet the necessary scriptural qualifications for his office does he disqualify himself from being an
                Elder. An Elder may honorably resign from his office for a time if he is providentially hindered from
                properly discharging his pastoral duties. He may, at the discretion of the Elders, without
                re-examination by the congregation, re-assume office when those hindrances have been removed.
              </p>
              <p className={styles.indent2}>
                <b>9.3.7 Absence of.</b> Should the office of Elder be vacant, the Deacons shall call a church meeting
                over which one of their members shall preside. At this meeting the church, by vote, shall place herself
                under the temporary guidance of the Elders of a like-minded Reformed Baptist church. This guidance shall
                remain in effect until removed by congregational vote.
              </p>
              <p className={styles.indent}>
                <b>9.4 DEACONS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.4.1 Purpose.</b> The office of Deacon was ordained by the Apostles to enable the ministers of the
                Word to concentrate on the specific functions of their office, namely preaching, teaching, counseling
                and prayer (Acts 6:1-6; 20:20, 31; Eph. 4:11-13). Deacons are primarily responsible to administer the
                benevolent and business affairs of the church.
              </p>
              <p className={styles.indent2}>
                <b>9.4.2 Qualifications.</b> The qualifications for the office of Deacon are almost identical with those
                for the office of Elder (1 Tim. 3:1-13; Titus 1:5). The only contrast between the qualifying standards
                for both offices is that the Elders must be “able to teach” (1 Tim. 3:2).
              </p>
              <p className={styles.indent2}>
                <b>9.4.3 Relationship to the Elders.</b> Scripture indicates that Elders have authority over diaconal
                concerns (Acts 6:2-4; 11:30). The Deacons, therefore, must fulfill the duties of their office in
                cooperation with, and in subjection to, the Elders.
              </p>
              <p className={styles.indent2}>
                <b>9.4.4 Number.</b> The number of Deacons shall not be fixed. The church shall choose as many as are
                needed from among the men who evidence the scriptural qualifications for the office (Acts 6:1-6; 1 Tim.
                3:8-13).
              </p>
              <p className={styles.indent}>
                <b>9.5 APPOINTMENT OF OFFICERS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.5.1 General Statement.</b> The appointment of Elders and Deacons is the prerogative of the Lord
                Jesus Christ alone. He has ordained, however, that each local church exercise the responsibility of
                recognizing those whom He is appointing to be Elders and Deacons in that particular church. Elders and
                Deacons are ordained, or appointed, to office by the laying on of hands by the Eldership (Acts 6:6; 1
                Tim. 4:14). This is an expression of approval for which the Elders are responsible (1 Tim. 5:22).
                Therefore, each officer must have the approval, not only of the church as a whole, but of the Eldership
                in particular. The Lord’s appointment of an individual to either of these offices is recognized by means
                of that individual’s possession of those graces and gifts required by Scripture for the particular
                office and his own conviction that the Lord is calling him to minister in that office (1 Tim. 3:1-7).
                The recognition of officers is a matter of such importance that it should never be pursued without much
                prayerful waiting upon God, an honest perusal of the relevant passages of Scripture, and a frank
                evaluation of those who are being considered. Each member of the church has a spiritual responsibility
                to be intelligently informed regarding these matters.
              </p>
              <p className={styles.indent2}>
                <b>9.5.2 Procedure of Appointment.</b> The recognition of those whom the Lord has appointed to bear
                office in this church is executed in three steps: nomination, election and, ordination.
              </p>
              <p className={styles.indent3}>
                <b>9.5.2.1 Nomination.</b> Nominations to either office are made by the Eldership. At least once every
                year at the annual business meeting an advisory ballot shall be taken. On this ballot every voting
                member may write the name of any male member(s) and the office for which he believes that member to be
                qualified. Those men named by twenty-five percent or more of the members will then be considered by the
                Elders. If the Elders agree that the nominees are suitable, they will be notified and asked whether they
                will accept the nomination.
              </p>
              <p className={styles.indent3}>
                <b>9.5.2.2 Election.</b> Any church meeting for the election of officers shall be announced on four
                consecutive Lord’s Days previous to its being held. The nominees shall be separately discussed and voted
                upon. During the discussion the nominee under consideration and members of his immediate family shall
                leave the presence of the assembly. The scriptural qualifications shall be read and expounded, and the
                nominee’s qualifications openly discussed in the fear of God and with due respect for the reputation of
                the nominee. Concerning the vote, the church should seek unity of mind, but should such unity not be
                fully realized, no fewer than three-fourths of those ballots cast shall be required for election. This
                vote shall take place by written ballot subsequent to a full and free discussion oriented to the
                relevant scriptural passages.
              </p>
              <p className={styles.indent3}>
                <b>9.5.2.3 Ordination.</b> Following the election of an officer there shall be a portion of a regular
                worship service set aside at which time the officer shall be ordained by the laying on of the hands of
                the Eldership. This solemn act should always be accompanied by the special prayers of the whole church
                (Acts 13:1-3). The laying on of the Elders’ hands shall signify their approval of an officer-elect.
              </p>
              <p className={styles.indent3}>
                <b>9.5.2.4 Chairmanship.</b> The Elders shall choose one from their number to serve as chairman of their
                meetings. The Deacons shall do the same.
              </p>
              <p className={styles.indent}>
                <b>9.6 REVIEW OF OFFICERS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.6.1 Regular Review.</b> There shall be a review of each officer’s qualifications for office four
                years after his ordination into the church and every fourth year thereafter for as long as he occupies
                the office in this church. The procedure for review is outlined in Section 9.6.3.
              </p>
              <p className={styles.indent2}>
                <b>9.6.2 Untimely Review.</b> There may arise reasons that would require an officer to be reviewed
                before the regularly scheduled time. Such a review meeting may be called by a majority of the Elders (or
                a majority of the other Elders in the case of an Elder). The members may also request such a meeting.
                This request can only be made after two or three witnesses (1 Tim. 5:19) have met with the officer in an
                attempt to resolve the issues of concern. The request must be set forth in writing with the signatures
                of one-third of the voting members in good standing. It must be presented to the Elders, who shall, in
                turn, make the proper announcement of the meeting. Such a review meeting would also follow the procedure
                of Section 9.6.3.
              </p>
              <p className={styles.indent2}>
                <b>9.6.3 Procedure for Review.</b> Any meeting for the review of an officer shall be announced on four
                consecutive Lord’s Days prior to its being held. There shall be discussion and a vote by written ballot
                regarding the office under review. During the discussion the officer under consideration and members of
                his immediate family shall leave the presence of the assembly. The scriptural qualifications shall be
                read and expounded, and the officer’s qualifications openly discussed in the fear of God and with due
                respect for the reputation of the officer. Any member who publicly suggests in such a meeting that the
                officer being reviewed is unqualified for his office must have previously spoken with the officer
                himself and informed the Elders of the church of his concerns (1 Tim. 5:19). He must also present
                biblical and factual warrant for his concerns at the review meeting. Just as it is wrong for a church to
                retain an officer who is not biblically qualified, so also it is rebellion against the Head of the
                church to reject an officer for any but biblical grounds. Additionally, any officer about whom such
                concerns are raised must be permitted, if he wishes, to return to the meeting and defend himself.
                Concerning the vote, the church should seek unity of mind, but should such unity not be fully realized,
                no fewer than three-fourths of those ballots cast shall be required for the confirmation of an officer
                in his office. Any officer failing of confirmation no longer holds office in the church.
              </p>
              <p className={styles.indent2}>
                <b>9.6.4 Resignation.</b> An officer may resign his office without prejudice if he does so in an orderly
                fashion and for good and valid reasons. This resignation together with its reasons and the date upon
                which he wishes his resignation to be effective shall be submitted in writing to the Elders of the
                church.
              </p>
              <p className={styles.indent}>
                <b>9.7 FULL SUPPORT OF ELDERS</b>
              </p>
              <p className={styles.indent2}>
                <b>9.7.1 General Considerations.</b> Though all Elders are equal as to the authority of their office,
                not all Elders possess qualifications warranting full financial support in the office. The Bible teaches
                that special ability in ruling the church and, more especially, in public teaching and preaching are
                gifts worthy of full support (1 Cor. 9:1-14; Gal. 6:6; 1 Tim. 5:17). Thus, before it undertakes his full
                support, the church must recognize that an Elder or nominee to the Eldership possesses special
                ministerial gifts and that he is excelling in the employment of those gifts for the benefit of the
                church, in ways appropriate to his opportunities. Special caution should be exercised in giving full
                support to an Elder for the following reasons: (1) full support necessitates his removal from a secular
                vocation, which, in the interests of Christ’s Kingdom and of his family, might be a more advantageous
                position for him to occupy; (2) a major portion of the church’s financial stewardship is involved, for
                which its Head will hold it accountable; and (3) a fully supported Elder has a greater influence upon
                the church, for good or ill. The provisions of this section apply to any proportion of financial support
                required by an increase of ministry that would hinder an Elder’s full-time employment in a secular
                vocation.
              </p>
              <p className={styles.indent2}>
                <b>9.7.2 Procedures.</b> The Elders may recommend to the church that an existing Elder or a nominee to
                the Eldership be fully supported.
              </p>
              <p className={styles.indent3}>
                <b>9.7.2.1 For a Nominee.</b> In the case of a nominee, full support may be considered in conjunction
                with the consideration of his qualifications for the Eldership. In such a case, the Elders will inform
                the church of their recommendation when the business meeting for this purpose is announced. A distinct
                discussion and vote for both election to the office and full support in the office is not necessary.
              </p>
              <p className={styles.indent3}>
                <b>9.7.2.2 For an Existing Elder.</b> In the case of an existing Elder who is being recommended for full
                support, a church meeting to consider this recommendation shall be announced on four consecutive Lord’s
                Days prior to its being held. Such a recommendation may be considered in conjunction with the review of
                the Elder involved. A distinct discussion and vote for both confirmation in the office and full support
                in the office is not necessary.
              </p>
              <p className={styles.indent3}>
                <b>9.7.2.3 Conduct of the Meeting.</b> During any meeting where full support is being considered,
                special attention shall be given to the relevant teaching of Scripture (1 Cor. 9:1-14; Gal. 6:6; 1 Tim.
                5:17). During the discussion the man under consideration and members of his immediate family shall leave
                the presence of the assembly. Such discussion must at all times reflect the fear of God, the claims of
                truth, and the gravity of the matter. Any vote upon full support requires no fewer than three-fourths of
                those ballots cast for approval.
              </p>
              <p className={styles.indent3}>
                <b>9.7.2.4 Review of.</b> The full support of Elders as well as their continuation in office will be
                subject to review (see Section 9.6). Normally, a review of full support will take place in conjunction
                with the review of an Elder’s qualification for office, whether at regular intervals or at special
                review meetings. However, circumstances may arise in which an Elder’s full support may need to be
                reviewed as an issue separate from his continuation in office. In such cases, a review shall follow the
                applicable procedures outlined above. Continuance of full support shall require no fewer than
                three-fourths of the ballots cast.
              </p>
            </div>
            <div id="article10">
              <h4 className="header">ARTICLE 10 – LEGAL OFFICERS</h4>
              <p className={styles.indent}>
                <b>10.1 PURPOSE.</b> In order to comply with California State law governing non-profit organizations,
                this church shall have such officers as the law requires to represent the church before the state.
              </p>
              <p className={styles.indent}>
                <b>10.2 QUALIFICATIONS.</b> Legal officers shall be regular members in good standing (Section 6.3.1).
              </p>
              <p className={styles.indent}>
                <b>10.3 AUTHORITY.</b> Legal officers operate under the authority of the Elders (or their designates).
                Since their office is not Biblically mandated, they have no role in governing the affairs of this
                church.
              </p>
              <p className={styles.indent}>
                <b>10.4 APPOINTMENT.</b> Appointment of legal officers shall be the responsibility of the Elders. The
                duration of their term of office shall be for one year, but may be renewed indefinitely.
              </p>
            </div>
            <div id="article11">
              <h4 className="header">ARTICLE 11 – CHURCH BUSINESS MEETINGS</h4>
              <p className={styles.indent}>
                <b>11.1 GENERAL STATEMENT.</b> All congregational meetings shall be called by the Elders. There shall be
                an annual business meeting of the church held near the end of January for the hearing of reports, the
                taking of the advisory ballot (Section 9.5.2.1), and the transaction of other business which the Elders
                bring before the church. Special business meetings may be called at other times at the discretion of the
                Elders.
              </p>
              <p className={styles.indent}>
                <b>11.2 NOTICE OF MEETINGS.</b> Notice of all congregational meetings in which business is to be
                transacted shall be announced at regular services for at least two (2) successive Sundays. Other
                business meetings at which there is no business transacted by vote may be called at the discretion of
                the Elders without such notice.
              </p>
              <p className={styles.indent}>
                <b>11.3 QUORUM.</b> Fifty percent of the voting membership shall constitute a quorum for the transaction
                of business.
              </p>
              <p className={styles.indent}>
                <b>11.4 CHAIRMANSHIP.</b> The Elders shall appoint one from their number to preside at all business
                meetings.
              </p>
              <p className={styles.indent}>
                <b>11.5 VOTING.</b> All regular and temporary members who have reached the age of eighteen years except
                those suspended by the church shall constitute the voting membership of the church. All voting members
                should regard their presence at a duly called church meeting with the same seriousness with which they
                would regard their attendance at a stated service of worship. The Elders shall determine whether
                absentee votes will be permitted on a case-by-case basis as members make known their reason for absence.
                Unanimity of heart and mind shall at all times be sought and prayed for (Acts 6:5), but when such
                unanimity is not realized, no less than a two-thirds majority of those voting will make a resolution
                valid. In other matters wherein the Constitution requires a different proportionate vote, this
                two-thirds figure will be overridden by the express statements of the Constitution regarding those
                categories of business.
              </p>
            </div>
            <div id="article12">
              <h4 className="header">ARTICLE 12 – CONSTITUTIONAL AUTHORITY</h4>
              <p className={styles.indent}>
                <b>12.1 EXTENT.</b> The Constitution, as with any other non-inspired document, is not infallible. It
                does, however, reflect an earnest and sincere attempt to apply the Scriptures in ordering the life of
                this local church. Furthermore, we as members of this church, including the Elders, have solemnly
                committed ourselves to follow this Constitution in ordering the life of this church (see the Preamble).
                Therefore, the demands of the ninth commandment, and the sanctity of truth in general, require that the
                Elders and all other members of this church abide by our mutual commitment.
              </p>
              <p className={styles.indent}>
                <b>12.2 LIMITATIONS.</b> Only when we must obey God rather than the provisions of this Constitution may
                its requirements be disregarded (Acts 5:29). If at any time a member of this church becomes aware that
                adherence to this Constitution would violate biblical principle he should make it known to the Elders.
                If the Elders conclude that biblical principle requires disregarding a provision of this Constitution,
                they are obligated to communicate this together with the reason(s) for their conclusion to the church
                within one month at a duly called meeting of the church. Furthermore, relevant amendments to this
                Constitution must be submitted to the church and acted upon in accordance with the provisions of Section
                12.3 within one year following this informational meeting.
              </p>
              <p className={styles.indent}>
                <b>12.3 AMENDMENTS.</b> Amendments to this Constitution may be adopted by a three-fourths majority of
                members present and voting at a duly convened church meeting. Proposed amendments shall be distributed
                to the congregation in written form at least two weeks prior to such a meeting.
              </p>
            </div>
            <div id="appendix1">
              <h4 className="header">APPENDIX 1 – STATEMENT ON MARRIAGE, GENDER, AND SEXUALITY</h4>
              <p>
                We believe that God has wisely and immutably ascribed to each person his or her specific gender (male or
                female.) These two distinct, complementary genders together reflect the image and nature of God (Gen.
                1:26-27). Rejection of one’s biological sex is therefore a rejection of the design of God for that
                person.{" "}
              </p>
              <p>
                We believe that the term “marriage” has only one meaning: the uniting of one man and one woman in a
                single, exclusive union, as presented in Scripture (Gen. 2:18-25). We believe that God intends sexual
                intimacy to occur only between a man and a woman who are married to each other (1 Cor. 6:18; 7:25; Heb.
                13:4). We believe that God has commanded that no intimate sexual activity be engaged in outside of a
                biblical marriage. Any deviation from God’s design for sex is sinful and offensive to God (Matt
                15:18-20; 1 Cor 6:9-10).{" "}
              </p>
              <p>
                While we believe that every person must be afforded compassion, love, kindness, respect, and dignity
                (Mark 12:28-31; Luke 6:31), we also believe that to graciously identify sexual actions or lifestyles
                that are contrary to the Word of God as sinful is not hateful or harassing but it is to act in
                accordance with Holy Scripture and is a genuine expression of love towards both God and our neighbor,
                especially when it is accompanied by the hope of the gospel.
              </p>
              <p>
                We believe that God offers redemption and restoration to all who confess and forsake their sin, seeking
                His mercy and forgiveness through Jesus Christ (Acts 3:19-21; Rom. 10:9-10; 1 Cor. 6:9-11).{" "}
              </p>
              <p>
                We believe that in order to preserve the function and integrity of The Reformed Baptist Church of
                Riverside (RBCR) as a Bible-believing Christian church, we may only facilitate and recognize as
                legitimate those marriages that are in accordance with this statement, and that it is imperative that
                all persons employed by RBCR in any capacity, or who serve as volunteers, agree to and abide by this
                Statement on Marriage, Gender, and Sexuality (Matt. 5:16; Phil. 2:14-16; 1 Thess. 5:22).
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="about-rbcr/church-constitution/" header="Church Constitution" content={this.content()} />;
  }
}

export default Constitution;
