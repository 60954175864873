import React, { Component } from "react";
import styles from "../SpanishConfession/SpanishConfession.module.scss";
import Page from "../Page/Page";

class SpanishConfession extends Component {
  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <p className={styles.intro_content}>
                En Inglaterra, durante la década de 1630 y de 1640, surgieron de la Iglesia Anglicana grupos
                congregacionalistas y bautistas. Sus primeros años de existencia se caracterizaron por repetidos ciclos
                de persecución por parte del Parlamento y la religión oficial del país. El infausto Código de Clarendon
                fue adoptado en la década de 1660 para aplastar todo disenso de la religión oficial del estado. Los
                períodos de rigurosa aplicación y los intervalos de relajamiento de estas leyes coercitivas angustiaban
                a presbiterianos, congregacionalistas y bautistas por igual.
                <br />
                <br />
                Los presbiterianos y congregacionalistas no sufrieron menos que los bautistas bajo este acoso. Su frente
                unido en un acuerdo doctrinal fue una de las razones principales de su relativo éxito al resistir la
                tiranía gubernamental. Todos los presbiterianos se mantuvieron fieles a la Confesión de Westminster de
                1647. Los congregacionalistas adoptaron prácticamente los mismos artículos de fe en la Declaración de
                Savoy de 1658.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.articleContent}>
          <div className={styles.container}>
            <div className={styles.article_text}>
              <p>
                <strong>
                  <span className="s2">Confesión de Westminster (1647)</span>
                </strong>
              </p>
              <p>
                <span>
                  Mientras la Guerra Civil en Inglaterra &nbsp;entre &nbsp;el Parlamento &nbsp;puritano &nbsp;y el rey
                  (1642-1649) estaba en pleno auge, se hicieron cambios en la Iglesia Anglicana. En 1643, el
                  Parlamento&nbsp; abolió la forma episcopal &nbsp;de gobierno eclesiástico, con sus parroquias y
                  obispos jerárquicos. También pidió una nueva estructura. &nbsp;Ésta sería formulada por una asamblea
                  de ciento veintiún clérigos (los “teólogos”) y treinta laicos: 10 miembros de la Cámara de los Lores y
                  20 miembros de la Cámara de los Comunes. Esta “Asamblea Westminster de Teólogos” se reunió en la
                  histórica abadía de Westminster (contigua al Parlamento) en Londres, de la cual la decla- ración
                  deriva su nombre.
                </span>
              </p>
              <p>
                <span>
                  La mayoría de los presentes en la Asamblea reunidos en 1643 eran presbiterianos &nbsp;puritanos.
                  &nbsp;Se permitió la participación de ocho comisionados escoceses en las reuniones, como muestra de
                  aprecio por su ayuda en la lucha contra el rey. Aunque los repre- sentantes escoceses no tenían ningún
                  &nbsp;rol oficial en las reuniones, su presencia fue influyente. La Asamblea realizó 1.163 sesiones
                  entre el 1 de julio de 1643 y el 22 de febrero de 1649. Se requería un quórum de 40 miembros.
                </span>
              </p>
              <p>
                <span>
                  Al ir avanzando el trabajo, se preparó un Directorio de Culto para reemplazar el libro de oración
                  episcopal. Además, se redactó una nueva declaración de fe para la Iglesia Anglicana. La Confesión de
                  Westminster llegó a ser el credo protestante más importante de la época de la Reforma. Se empezó a
                  trabajar en esta confesión en julio de 1645, siguiendo con muchas interrupciones &nbsp;hasta ser
                  terminada en diciembre de 1646. La confesión o declaración fue presentada a ambas Cámaras del
                  Parlamento en 1647 bajo el título: “El humilde consejo de la Asamblea de Teólogos, con citas y pasajes
                  de las Escrituras anexadas, presentado por ellos a ambas Cáma- ras del Parlamento.”
                </span>
              </p>
              <p>
                <span>
                  La Confesión de Westminster es un resumen de las principales creencias cristianas en treinta y tres
                  capítulos. Está saturada de la teología bíblica reformada clásica, con énfasis en las relaciones de
                  pacto entre Dios y el hombre. En cuanto al gobierno de la igle- sia, presenta el concepto
                  presbiteriano: con presbíteros (o sínodos) que supervisan a las congregaciones locales. En cuanto al
                  bau- tismo, conserva al bautismo infantil, en concordancia con el concepto de pacto de la herencia
                  cristiana. Éste mantiene &nbsp;que Dios con frecuencia salva a familias enteras, y que el infante es
                  considerado parte del pacto a través de sus padres creyentes, mientras no pruebe lo contrario por
                  medio de sus decisiones relacionadas con su estilo de vida.
                </span>
              </p>
              <p>
                <span>
                  A fin de explicar la declaración, la Asamblea de Westminster preparó un Catecismo Mayor para ser
                  enseñado públicamente por los pastores desde el púlpito. Se publicó un Catecismo Menor para la
                  instrucción de los niños.
                </span>
              </p>
              <p>
                <span>
                  Aunque la Confesión de Westminster fue usada sólo brevemente por la Iglesia Anglicana, fue adoptada
                  por la Asamblea general de la Iglesia de Escocia en 1647 para uso general. &nbsp;La Confesión de
                  Westminster sigue siendo hasta hoy la declaración de fe autori- tativa de la mayoría de las iglesias
                  presbiterianas.
                </span>
              </p>
              <p>
                <strong>
                  <span className="s2">
                    Declaraci<i>ó</i>n de Savoy (1658)
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  Muchos cristianos evangélicos conservadores consideraban que la Confesión de Westminster era una
                  afirmación correcta de la fe según las Escrituras, pero no coincidían con las afirmaciones sobre el
                  gobierno de la iglesia y el bautismo. Éstos formaron dos grupos: los congregacionalistas y los
                  bautistas.
                </span>
              </p>
              <p>
                <span>
                  A fin de mantener el crecimiento del que disfrutaban, el 29 de septiembre de 1658 se reunieron en el
                  Palacio Savoy en Londres, en una asamblea de líderes congregacionalistas. El sínodo adoptó una
                  “Declaración de fe y orden, observados y practicados en las iglesias congregacionalistas.” Basada
                  mayormente &nbsp;en la Confesión de Westminster, la Declaración de Savoy incluía una sección sobre “La
                  institución &nbsp;de iglesias y el orden establecido en ellas por Jesucristo”. Defendía la forma
                  congregacional para el gobierno de la iglesia.
                </span>
                <span>&nbsp;</span>
              </p>
              <p className="p6">
                <strong>
                  <span className="s2">
                    Confesi<i>ó</i>n Bautista de Londres de 1677
                  </span>
                </strong>
              </p>
              <p>
                <span>
                  Los que consideraban que las Escrituras enseñan el bautismo del creyente también necesitaban una
                  declaración de fe clara. A éstos los conocían como “bautistas”. Sintiéndose sustancialmente
                  &nbsp;unidos con el sufrimiento de los presbiterianos y los congrega- cionalistas bajo la misma
                  injusticia cruel, los bautistas se reunieron &nbsp;para publicar su armonía sustancial con ellos en
                  cuestiones de doctrina.
                </span>
              </p>
              <p>
                <span>
                  Se envió una carta circular a las Iglesias Bautistas Particulares en Inglaterra y Gales, pidiendo a
                  cada congregación que enviara representantes &nbsp;a la reunión &nbsp;en Londres en 1677. Se aprobó y
                  publicó una declaración inspirada en la Confesión de Westminster. Desde entonces lleva el nombre de
                  Confesi<i>ó</i>n de Londres de 1677. Debido a que este documento &nbsp;fue desarrollado en las oscuras
                  horas de opresión, fue lanzado bajo el anonimato.
                </span>
              </p>
              <p>
                <span>
                  El prefacio de la publicación original de 1677 dice en parte: “Han pasado ya muchos años1 &nbsp;desde
                  que varios de nosotros… sen- timos la necesidad de publicar nuestra confesión de fe, para la
                  información y satisfacción de quienes no entendían cabalmente cuá- les eran nuestros principios, o que
                  habían tenido prejuicios contra nuestra profesión…
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  1&nbsp; &nbsp;Habían pasado 33 años desde que la Declaración de Londres anterior había sido expedida
                  (en 1644) por siete congregaciones bautistas en Londres. Aquel documento había sido desarrollado para
                  distinguir a las iglesias bautistas reformadas (que siguieron la fe evangélica histórica de la
                  Reforma), de los anabautistas y los reciente- mente formados bautistas arminianos.
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  “En vista de que en la actualidad esta confesión1 &nbsp;no está al alcance de todos, y de que muchos
                  otros también han aceptado la misma verdad que contiene; juzgamos necesario juntarnos &nbsp;para dar
                  un testimonio &nbsp;al mundo de nuestra &nbsp;adherencia firme a estos sanos principios…
                </span>
              </p>
              <p>
                <span>
                  “Llegamos a la conclusión de que era necesario declararnos más plena y decididamente… y no encontrando
                  &nbsp;defecto en este sentido en la adoptada por la Asamblea [de Westminster], y después de ella por
                  los congregacionalistas, llegamos a la conclusión que sería mejor retener el mismo orden en nuestra
                  presente declaración de confesión… mayormente sin ninguna variación en los términos… haciendo uso de
                  las mismas palabras de ambos… Esto hicimos para… convencer a todos que no tenemos ningún deseo de
                  dificultar la religión con palabras nuevas, sino de esa manera dar nuestro &nbsp;inmediato
                  consentimiento &nbsp;a las palabras sanas que han sido usadas por otros antes que nosotros… En
                  aquellas cosas en las que diferimos con otros, nos hemos expresado con toda candidez y sencillez…
                  Nuestro propósito dista de querer crear una polémica en todo lo que hemos hecho en esta cuestión.”
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <strong>
                  <span>Confesion de fe de Londres de 1689</span>
                </strong>
              </p>
              <p>
                <span>
                  William y Mary subieron al trono de Inglaterra en 1689. El 24 de mayo de ese año se promulgó la Ley de
                  Tolerancia. &nbsp;A los dos meses, varios pastores londinenses pidieron una reunión &nbsp;general de
                  bautistas procedentes de Inglaterra &nbsp;y Gales. Se reunieron &nbsp;en Londres representantes
                  &nbsp;de ciento siete congregaciones desde el 3 al 12 de septiembre. Adoptaron la Confesion de Londres
                  de 1677 con algunas importantes correcciones.
                </span>
              </p>
              <p>
                <span>
                  Una de las razones del crecimiento &nbsp;de las congregaciones bautistas eran las
                  características&nbsp; particulares &nbsp;del movimiento. Los bautistas no reconocían los
                  sacramentos&nbsp; como tales, como los reconocían los anglicanos y los católicos romanos. Creían en
                  dos ordenanzas: la Cena del Señor y el bautismo de los que profesaban ser creyentes. Los primeros
                  bautistas preferían ser bautizados por inmersión &nbsp;en “aguas vivas”; agua que corría en un río o
                  arroyo. En el gobierno eclesiástico bautista, &nbsp;la congregación tenía completa autoridad. Podía
                  llamar a su pastor y despedirlo. No había obispos ni superintendentes en la estructura &nbsp;bautista.
                  Ningún grupo tenía poder gubernamental &nbsp;sobre otras congregaciones individuales.
                </span>
              </p>
              <p>
                <span>
                  En resumen, &nbsp;las interpretaciones &nbsp;de fe cristiana&nbsp; evangélica tal como las proclaman
                  las Escrituras &nbsp;fueron presentadas en la Confesión de Westminster en 1647. Se realizaron
                  actualizaciones 1) para el gobierno eclesiástico congregacional en la Declaraci<i>ó</i>n de Savoy en
                  1658 y 2) para el bautismo del creyente en la (primera) Confesi<i>ó</i>n &nbsp;de Londres de 1677.
                </span>
              </p>
              <p>
                <span>
                  El mensaje principal de la Confesión de Westminster fue nuevamente preservada en la Confesi<i>ó</i>n
                  de fe de Londres de 1689, que incorporó las revisiones menores tanto de la Declaraci<i>ó</i>n de Savoy
                  y la primera Confesi<i>ó</i>n &nbsp;de Londres. La presente Confesi<i>ó</i>n Bau- tista Fe de 1689 ha
                  pasado la prueba del tiempo y ha llegado a ser una de las afirmaciones más importantes de la fe
                  evangélica en la historia de la iglesia. Es utilizada en la actualidad por miles de congregaciones
                  alrededor del mundo.
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <strong>
                  <span>CONFESIÓN &nbsp;BAUTISTA DE FE DE 1689</span>
                </strong>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 1, De las Santas Escrituras.</p>

              <p>
                <span>
                  1. La Santa Escritura es la única regla suficiente, segura e infalible de todo conocimiento, fe y
                  obediencia salvadores.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  Aunque la luz de la naturaleza y las obras de la creación y de la providencia manifiestan de tal
                  manera la bondad, sabiduría y poder de Dios que dejan a los hombres sin excusa
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  , no son, sin embargo, suficientes para dar aquel conocimiento de Dios y de su voluntad que es
                  necesario para la salvación.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;Por tanto, agradó al Señor, en distintas épocas y de diversas maneras, revelarse a sí mismo y
                  declarar su voluntad a su Iglesia;
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;y posteriormente, para conservar y propagar mejor la verdad y para un establecimiento y consuelo
                  más seguros de la Iglesia contra la corrupción de la carne y la malicia de Satanás y del mundo, le
                  agradó poner por escrito esa revelación en su totalidad, lo cual hace a las Santas Escrituras muy
                  necesarias,
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;habiendo cesado ya aquellas maneras anteriores por las cuales Dios reveló su voluntad a su
                  pueblo.
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 2 Ti. 3:15-17; Is. 8:20; Lc. 16:29,31; Ef. 2:20
                  <br />
                  2. Ro. 1:19-21,32; Ro. 2:12a,14,15; Sal. 19:1-3
                  <br />
                  3. Sal. 19:1-3 con vv. 7-11; Ro. 1:19-21; 2:12a,14,15 con 1:16,17 y 3:21
                  <br />
                  4. He. 1:1,2a
                  <br />
                  5. Pr. 22:19-21; Lc. 1:1-4; 2 P. 1:12-15; 3:1; Dt. 17:18ss.; 31:9ss.,19ss.;
                  <br />
                  1 Co. 15:1;2 Ts. 2:1,2,15; 3:17; Ro. 1:8-15; G. 4:20; 6:11; 1 Ti. 3:14ss.;
                  <br />
                  Ap. 1:9,19; 2:1, etc.; Ro. 15:4; 2 P. 1:19-21
                  <br />
                  6. He. 1:1,2a; Hch. 1:21,22; 1 Co. 9:1; 15:7,8; Ef. 2:20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Bajo el nombre de la Santa Escritura, o la Palabra de Dios escrita, están ya contenidos todos los
                  libros del Antiguo y Nuevo Testamento, que son éstos: [A continuación están los nombres de los treinta
                  y nueve libros del Antiguo Testamento y los veintisiete del Nuevo.] Todos ellos fueron dados por
                  inspiración de Dios para ser la regla de fe y de vida.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. 2 Ti. 3:16 con 1 Ti. 5:17,18; 2 P. 3:16</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Los libros comúnmente llamados Apócrifos, no siendo de inspiración divina, no forman parte del
                  canon o regla de la Escritura y, por tanto, no tienen autoridad para la Iglesia de Dios, ni deben
                  aceptarse ni usarse excepto de la misma manera que otros escritos humanos.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Lc. 24:27,44; Ro. 3:2</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. La autoridad de la Santa Escritura, por la que ésta debe ser creída, no depende del testimonio de
                  ningún hombre o iglesia,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sino enteramente de Dios (quien es la verdad misma), el autor de ella; por tanto, debe ser recibida
                  porque es la Palabra de Dios.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Lc. 16:27-31; G. 1:8,9; Ef. 2:20
                  <br />
                  2. 2 Ti. 3:15; Ro. 1:2; 3:2; Hch. 2:16; 4:25; Mt. 13:35; Ro. 9:17; G. 3:8;
                  <br />
                  Ro. 15:4; 1 Co. 10:11; Mt. 22:32; Lc. 16:17; Mt. 22:41ss; Jn. 10:35; G. 3:16;
                  <br />
                  Hch. 1:16; 2:24ss; 13:34,35; Jn. 19:34-36; 19:24; Lc. 22:37; Mt. 26:54;
                  <br />
                  Jn. 13:18; 2 Ti. 3:16; 2 P. 1:19-21; Mt.5:17,18; 4:1-11
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. El testimonio de la Iglesia de Dios puede movernos e inducirnos a tener una alta y reverente estima
                  por las Santas Escrituras;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y el carácter celestial del contenido, la eficacia de la doctrina, la majestad del estilo, la armonía
                  de todas las partes, el fin que se propone alcanzar en todo su conjunto (que es el de dar toda la
                  gloria a Dios), la plena revelación que dan del único camino de salvación para el hombre, y muchas
                  otras incomparables excelencias y plenas perfecciones de las mismas, son argumentos por los cuales dan
                  abundante evidencia de ser la Palabra de Dios.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;A pesar de ello, sin embargo, nuestra plena persuasión y certeza de su verdad infalible y su
                  autoridad divina provienen de la obra interna del Espíritu Santo, quien da testimonio en nuestros
                  corazones por medio de la Palabra y con ella.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 2 Ti. 3:14,15
                  <br />
                  2. Jer. 23:28,29; Lc. 16:27-31; Jn. 6:63; 1 P. 1:23-25; He. 4:12,13; Dt. 31:11-13;
                  <br />
                  Jn. 20:31; G. 1:8,9; Mr. 16:15,16
                  <br />
                  3. Mt. 16:17; 1 Co. 2:14ss.; Jn. 3:3; 1 Co. 2:4,5; 1 Ts. 1:5,6; 1 Jn. 2:20,21
                  <br />
                  con v. 27
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. Todo el consejo de Dios tocante a todas las cosas necesarias para su propia gloria, la salvación
                  del hombre, la fe y la vida, está expresamente expuesto o necesariamente contenido en la Santa
                  Escritura; a la cual nada, en ningún momento, ha de añadirse, ni por nueva revelación del Espíritu ni
                  por las tradiciones de los hombres.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>
                  Sin embargo, reconocemos que la iluminación interna del Espíritu de Dios es necesaria para un
                  entendimiento salvador de aquellas cosas que están reveladas en la Palabra,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y que hay algunas circunstancias tocantes a la adoración de Dios y al gobierno de la Iglesia,
                  comunes a las acciones y sociedades humanas, que han de determinarse conforme a la luz de la
                  naturaleza y de la prudencia cristiana, según las normas generales de la Palabra, que han de guardarse
                  siempre.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 2 Ti. 3:15-17; Dt. 4:2; Hch. 20:20,27; Sal. 19:7; 119:6,9,104,128
                  <br />
                  2. Jn. 6:45; 1 Co. 2:9-14
                  <br />
                  3. 1 Co. 14:26,40
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>7. No todas las cosas contenidas en las Escrituras son igualmente claras en sí mismas</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>ni son igualmente claras para todos;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;sin embargo, las cosas que necesariamente han de saberse, creerse y guardarse para salvación, se
                  proponen y exponen tan claramente en uno u otro lugar de la Escritura que no solo los eruditos, sino
                  los que no lo son, pueden adquirir un entendimiento suficiente de tales cosas por el uso adecuado de
                  los medios ordinarios.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 2 P. 3:16
                  <br />
                  2. 2 Ti. 3:15-17
                  <br />
                  3. 2 Ti. 3:14-17; Sal. 19:7-8; 119:105; 2 P. 1:19; Pr. 6:22,23; Dt.30:11-14
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  8. El Antiguo Testamento en hebreo (que era el idioma nativo del pueblo de Dios antiguamente),
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y el Nuevo Testamento en griego (que en el tiempo en que fue escrito era el idioma más generalmente
                  conocido entre las naciones), siendo inspirados inmediatamente por Dios y mantenidos puros a lo largo
                  de todos los tiempos por su especial cuidado y providencia, son, por tanto, auténticos;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;de tal forma que, en toda controversia religiosa, la Iglesia debe apelar a ellos en última
                  instancia.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;Pero debido a que estos idiomas originales no son conocidos por todo el pueblo de Dios, que
                  tiene derecho a las Escrituras e interés en las mismas, y se le manda leerlas y escudriñarlas
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  en el temor de Dios, se sigue que han de traducirse a la lengua vulgar [es decir, común] de toda
                  nación a la que sean llevadas,
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;para que morando abundantemente la Palabra de Dios en todos, puedan adorarle de manera aceptable
                  y para que, por la paciencia y consolación de las Escrituras, tengan esperanza.
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 3:2
                  <br />
                  2. Mt. 5:18
                  <br />
                  3. Is. 8:20; Hch. 15:15; 2 Ti. 3:16,17; Jn. 10:34-36
                  <br />
                  4. Dt. 17:18-20; Pr. 2:1-5; 8:34; Jn. 5:39,46
                  <br />
                  5. 1 Co. 14:6,9,11,12,24,28
                  <br />
                  6. Col. 3:16; Ro. 15:4
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  9. La regla infalible de interpretación de la Escritura es la propia Escritura; y, por consiguiente,
                  cuando surge una duda respecto al verdadero y pleno sentido de cualquier Escritura (que no es
                  múltiple, sino único), éste se debe buscar por medio de otros pasajes que hablen con más claridad.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Is. 8:20; Jn. 10:34-36; Hch. 15:15,16</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  10. El juez supremo, por el que deben decidirse todas las controversias religiosas, y por el que deben
                  examinarse todos los decretos de concilios, las opiniones de autores antiguos, las doctrinas de
                  hombres y espíritus particulares, y cuya sentencia debemos acatar, no puede ser otro sino la Santa
                  Escritura entregada por el Espíritu. A dicha Escritura así entregada, se reduce nuestra fe en última
                  instancia.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Mt. 22:29,31,32; Ef. 2:20; Hch. 28:23-25</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 2, De Dios y de la Santa Trinidad</p>
              <p>
                <span>1. El Señor nuestro Dios es un Dios único, vivo y verdadero;</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>cuya subsistencia está en El mismo y es de El mismo, infinito en ser y perfección;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;cuya esencia no puede ser comprendida por nadie sino por El mismo;</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;es espíritu purísimo, invisible, sin cuerpo, miembros o pasiones, el único que tiene
                  inmortalidad y que habita en luz inaccesible;
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;es inmutable, inmenso, eterno, incomprensible, todopoderoso, infinito en todos los sentidos,
                  santísimo, sapientísimo, libérrimo, absoluto;
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;que hace todas las cosas según el consejo de su inmutable y justísima voluntad, para su propia
                  gloria;
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>
                  &nbsp;es amantísimo, benigno, misericordioso, longánimo, abundante en bondad y verdad, perdonando la
                  iniquidad, la transgresión y el pecado;
                </span>
                <span className="s4">
                  <sup>7</sup>
                </span>
                <span>
                  &nbsp;galardonador de los que le buscan con diligencia, y sobre todo justísimo y terrible en sus
                  juicios, que odia todo pecado y que de ninguna manera dará por inocente al culpable.
                </span>
                <span className="s4">
                  <sup>8</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Dt. 6:4; Jer. 10:10; 1 Co. 8:4,6; 1 Ts. 1:9
                  <br />
                  2. Is. 48:12
                  <br />
                  3. Ex. 3:14; Job 11:7,8; 26:14; Sal. 145:3; Ro. 11:33,34
                  <br />
                  4. Jn. 4:24; 1 Ti. 1:17; Dt. 4:15,16; Lc. 24:39; Hch. 14:11,15; Stg. 5:17
                  <br />
                  5. Mal. 3:6; Stg. 1:17; 1 R. 8:27; Jer.23:23,24; Sal. 90:2; 1 Ti. 1:17; Gn. 17:1;
                  <br />
                  Ap. 4:8; Is. 6:3; Ro. 16:27; Sal. 115:3; Ex. 3:14
                  <br />
                  6. Ef. 1:11; Is. 46:10; Pr. 16:4; Ro. 11:36
                  <br />
                  7. Ex. 34:6,7; 1 Jn. 4:8
                  <br />
                  8. He. 11:6; Neh. 9:32,33; Sal. 5:5,6; Nah. 1:2,3; Ex. 34:7
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Teniendo Dios en sí mismo y por sí mismo toda vida, gloria, bondad y bienaventuranza, es todo
                  suficiente en sí mismo y respecto a sí mismo, no teniendo necesidad de ninguna de las criaturas que El
                  ha hecho, ni derivando ninguna gloria de ellas, sino que solamente manifiesta su propia gloria en
                  ellas, por ellas, hacia ellas y sobre ellas;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  El es la única fuente de todo ser, de quien, por quien y para quien son todas las cosas, teniendo
                  sobre todas las criaturas el más soberano dominio para hacer mediante ellas, para ellas y sobre ellas
                  todo lo que le agrade;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;todas las cosas están desnudas y abiertas a sus ojos; su conocimiento es infinito, infalible e
                  independiente de la criatura, de modo que para El no hay ninguna cosa contingente o incierta.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;Es santísimo en todos sus consejos, en todas sus obras y en todos sus mandatos;</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;a El se le debe, por parte de los ángeles y los hombres, toda adoración, servicio u obediencia
                  que como criaturas deben al Creador, y cualquier cosa adicional que a El le placiera demandar de
                  ellos.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 5:26; Hch. 7:2; Sal. 148:13; 119:68; 1 Ti. 6:15; Job 22:2,3; Hch. 17:24,25
                  <br />
                  2. Ap. 4:11; 1 Ti. 6:15; Ro. 11:34-36; Dn. 4:25,34,35
                  <br />
                  3. He. 4:13; Ro. 11:33,34; Sal. 147:5; Hch. 15:18; Ez. 11:5
                  <br />
                  4. Sal. 145:17; Ro. 7:12
                  <br />
                  5. Ap. 5:12-14
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. En este Ser divino e infinito hay tres subsistencias, el Padre, el Verbo o Hijo y el Espíritu
                  Santo,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  de una sustancia, poder y eternidad, teniendo cada uno toda la esencia divina, pero la esencia
                  indivisa:
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;el Padre no es de nadie, ni por generación ni por procesión; el Hijo es engendrado eternamente
                  del Padre, y el Espíritu Santo procede del Padre y del Hijo;
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;todos ellos son infinitos, sin principio y, por tanto, son un solo Dios, que no ha de ser
                  dividido en naturaleza y ser, sino distinguido por varias propiedades relativas peculiares y
                  relaciones personales; dicha doctrina de la Trinidad es el fundamento de toda nuestra comunión con
                  Dios y nuestra consoladora dependencia de El.
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 3:16,17; 28:19; 2 Co. 13:14
                  <br />
                  2. Ex. 3:14; Jn.14:11; 1 Co. 8:6
                  <br />
                  3. Pr. 8:22-31; Jn. 1:1-3,14,18; 3:16; 10:36; 15:26; 16:28; He. 1:2; 1 Jn. 4:14; Gá. 4:4-6
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 3, Del decreto de Dios</p>

              <p>
                <span>
                  1. Dios, desde toda la eternidad, por el sapientísimo y santísimo consejo de su propia voluntad, ha
                  decretado en sí mismo, libre e inalterablemente,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>todas las cosas, todo lo que sucede;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;sin embargo, de tal manera que por ello Dios ni es autor del pecado ni tiene comunión con nadie
                  en el mismo;
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;ni se hace violencia a la voluntad de la criatura, ni se quita la libertad o contingencia de las
                  causas secundarias, sino que más bien se las establece;
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;en lo cual se manifiesta su sabiduría en disponer todas las cosas, y su poder y fidelidad en
                  efectuar su decreto.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Pr. 19:21; Is. 14:24-27; 46:10,11; Sal. 115:3; 135:6; Ro. 9:19
                  <br />
                  2. Dn. 4:34,35; Ro. 8:28; 11:36; Ef. 1:11
                  <br />
                  3. Gn. 18:25; Stg. 1:13; 1 Jn. 1:5
                  <br />
                  4. Gn. 50:20; 2 S. 24:1; Is. 10:5-7; Mt. 17:12; Jn. 19:11; Hch. 2:23; 4:27,28
                  <br />
                  5. Nm. 23:19; Ef. 1:3-5
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Aunque Dios sabe todo lo que pudiera o puede pasar en todas las condiciones que se puedan suponer,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sin embargo nada ha decretado porque lo previera como futuro o como aquello que había de suceder en
                  dichas condiciones.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 S. 23:11,12; Mt. 11:21,23; Hch. 15:18
                  <br />
                  2. Is. 40:13,14; Ro. 9:11-18; 11:34; 1 Co. 2:16
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Por el decreto de Dios, para la manifestación de su gloria, algunos hombres y ángeles son
                  predestinados, o preordenados, a vida eterna por medio de Jesucristo, para alabanza de la gloria de su
                  gracia;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  a otros se les deja actuar en su pecado para su justa condenación, para alabanza de la gloria de su
                  justicia.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Ti. 5:21; Mt. 25:34; Ef. 1:5,6
                  <br />
                  2. n. 12:37-40; Ro. 9:6-24; 1 P. 2:8-10; Jud. 4
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. Estos ángeles y hombres así predestinados y preordenados están designados particular e
                  inalterablemente, y su número es tan cierto y definido que no se puede ni aumentar ni disminuir.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Mt. 22:1-14; Jn. 13:18; Ro. 11:5,6; 1 Co. 7:20-22; 2 Ti. 2:19</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. A aquellos de la humanidad que están predestinados para vida, Dios (antes de la fundación del
                  mundo, según su propósito eterno e inmutable y el consejo secreto y beneplácito de su voluntad) los ha
                  escogido en Cristo para gloria eterna, meramente por su libre gracia y amor,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>sin que ninguna otra cosa en la criatura, como condición o causa, le moviera a ello.</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 8:30; Ef. 1:4-6,9; 2 Ti. 1:9
                  <br />
                  2. Ro. 9:11-16; 11:5,6
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. Así como Dios ha designado a los elegidos para la gloria, de la misma manera, por el propósito
                  eterno y libérrimo de su voluntad, ha preordenado todos los medios para ello;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>por tanto, los que son elegidos, habiendo caído en Adán, son redimidos por Cristo,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;eficazmente llamados a la fe en Cristo por su Espíritu obrando a su debido tiempo, son
                  justificados, adoptados, santificados
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;y guardados por su poder, mediante la fe, para salvación;</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;tampoco es nadie más redimido por Cristo, o eficazmente llamado, justificado, adoptado,
                  santificado y salvado, sino solamente los elegidos.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 P. 1:2; 2 Ts. 2:13; Ef. 1:4; 2:10
                  <br />
                  2. 1 Ts. 5:9,10; Tit. 2:14
                  <br />
                  3. Ro. 8:30; Ef. 1:5; 2 Ts. 2:13
                  <br />
                  4. 1 P. 1:5
                  <br />
                  5. Jn. 6:64,65; 8:47; 10:26; 17:9; Ro. 8:28; 1 Jn. 2:19
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  7. La doctrina del profundo misterio de la predestinación debe tratarse con especial prudencia y
                  cuidado,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  para que los hombres, al atender a la voluntad de Dios revelada en su Palabra y, al prestar obediencia
                  a la misma, puedan, por la certeza de su llamamiento eficaz, estar seguros de su elección eterna;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;de este modo, esta doctrina proporcionará motivo de alabanza, reverencia y admiración a Dios,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;y de humildad,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;diligencia</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;y abundante Consuelo</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>&nbsp;a todos los que sinceramente obedecen al evangelio.</span>
              </p>
              <p>
                <span>
                  1. Dt. 29:29; Ro. 9:20; 11:33
                  <br />
                  2. 1 Ts. 1:4,5; 2 P. 1:10
                  <br />
                  3. Ef. 1:6; Ro. 11:33
                  <br />
                  4. Ro. 11:5,6,20; Col. 3:12
                  <br />
                  5. 2 P. 1:10
                  <br />
                  6. Lc. 10:20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 4, De la Creación</p>

              <p>
                <span>1. En el principio agradó a Dios Padre, Hijo y Espíritu Santo,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>para la manifestación de la gloria de su poder, sabiduría y bondad eternos,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>crear o hacer el mundo y todas las cosas que en él hay, ya sean visibles o invisibles,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;en el lapso de seis días,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;y todas muy buenas.</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. He. 1:2; Jn. 1:2,3; Gn. 1:2; Job 26:13; 33:4
                  <br />
                  2. Ro. 1:20; Jer. 10:12; Sal. 104:24; 33:5,6; Pr. 3:19; Hch. 14:15,16
                  <br />
                  3. Gn. 1:1; Jn. 1:2; Col. 1:16
                  <br />
                  4. Gn. 2:1-3; Ex. 20:8-11
                  <br />
                  5. Gn. 1:31; Ec. 7:29; Ro. 5:12
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Después que Dios hubo creado todas las demás criaturas, creó al hombre, varón y hembra, con almas
                  racionales e inmortales, haciéndolos aptos para aquella vida para con Dios para la cual fueron
                  creados;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>siendo hechos a imagen de Dios, en conocimiento, justicia y santidad de la verdad;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;teniendo la ley de Dios escrita en sus corazones, y el poder para cumplirla y, sin embargo, con
                  la posibilidad de transgredirla, por haber sido dejados a la libertad de su propia voluntad, que era
                  mutable.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 1:27; 2:7; Stg. 2:26; Mt. 10:28; Ec. 12:7
                  <br />
                  2. Gn. 1:26,27; 5:1-3; 9:6; Ec. 7:29; 1 Co. 11:7; Stg. 3:9; Col. 3:10; Ef. 4:24
                  <br />
                  3. Ro. 1:32; 2:12a,14,15; Gn. 3:6; Ec. 7:29; Ro. 5:12
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3.Además de la ley escrita en sus corazones, recibieron un mandato de no comer del árbol del
                  conocimiento del bien y del mal; y, mientras lo guardaron, fueron felices en su comunión con Dios y
                  tuvieron dominio sobre las criaturas.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Gn. 1:26,28; 2:17</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 5, De la Divina Providencia</p>

              <p>
                <span>1. Dios, el buen Creador de todo,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>en su infinito poder y sabiduría,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;sostiene, dirige, dispone y gobierna</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;a todas las criaturas y cosas, desde la mayor hasta la más pequeña,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;por su sapientísima y santísima providencia,</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;con el fin para el cual fueron creadas,</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>&nbsp;según su presciencia infalible, y el libre e inmutable consejo de su propia voluntad;</span>
                <span className="s4">
                  <sup>7</sup>
                </span>
                <span>
                  &nbsp;para alabanza de la gloria de su sabiduría, poder, justicia, infinita bondad y misericordia.
                </span>
                <span className="s4">
                  <sup>8</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 1:31; 2:18; Sal. 119:68
                  <br />
                  2. Sal. 145:11; Pr. 3:19; Sal. 66:7
                  <br />
                  3. He. 1:3; Is. 46:10,11; Dn. 4:34,35; Sal. 135:6; Hch. 17:25-28; Job 38-41
                  <br />
                  4. Mt. 10:29-31
                  <br />
                  5. Pr. 15:3; Sal. 104:24; 145:17
                  <br />
                  6. Col. 1:16,17; Hch. 17:24-28
                  <br />
                  7. Sal. 33:10,11; Ef. 1:11
                  <br />
                  8. Is. 63:14; Ef. 3:10; Ro. 9:17; Gn. 45:7; Sal. 145:7
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Aunque en relación a la presciencia y el decreto de Dios, la causa primera, todas las cosas suceden
                  inmutable e infaliblemente, de modo que nada ocurre a nadie por azar o sin su providencia;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sin embargo, por la misma providencia, las ordena de manera que ocurran según la naturaleza de las
                  causas secundarias, ya sea necesaria, libre o contingentemente.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 2:23; Pr. 16:33
                  <br />
                  2. Gn. 8:22; Jer. 31:35; Ex. 21:13; Dt. 19:5; Is. 10:6,7; Lc. 13;3,5; Hch. 27:31;
                  <br />
                  Mt. 5:20,21; Fil. 1:19; Pr. 20:18; Lc. 14:25ss.; Pr. 21:31; 1 R. 22:28,34; Rt. 2:3
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>3. Dios, en su providencia ordinaria, hace uso de medios;</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>sin embargo, El es libre de obrar sin ellos,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;por encima de ellos</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;y contra ellos,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>según le plazca.</span>
              </p>
              <p>
                <span>
                  1.3 Hch. 27:22,31,44; Is. 55:10,11; Os. 2:21,22
                  <br />
                  2. Os. 1:7; Lc. 1:34,35
                  <br />
                  3. Ro. 4:19-21
                  <br />
                  4. Ex. 3:2,3; 2 R. 6:6; Dn. 3:27
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. El poder omnipotente, la sabiduría inescrutable y la bondad infinita de Dios se manifiestan en su
                  providencia hasta tal punto que su consejo determinado se extiende aun hasta la primera Caída y a
                  todas las demás acciones pecaminosas, tanto de los ángeles como de los hombres
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  (y eso no por un mero permiso), las cuales El sapientísima y poderosamente limita, y asimismo ordena y
                  gobierna de múltiples maneras para sus santísimos fines;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;sin embargo, de tal modo que la pecaminosidad de sus acciones procede sólo de las criaturas, y
                  no de Dios, quien siendo justísimo y santísimo, no es, ni puede ser, autor del pecado ni lo aprueba.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1.Ro. 11:32-34; 2 S. 24:1; 1 Cr. 21:1; 1 R. 22:22,23; 2 S. 16:10; Hch. 2:23; 4:27,28
                  <br />
                  2. Hch. 14:16; 2 R. 19:28; Gn. 50:20; Is. 10:6,7,12
                  <br />
                  3. Stg. 1:13,14,17; 1 Jn. 2:16; Sal. 50:21
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. El Dios sapientísimo, justísimo y clementísimo a menudo deja por algún tiempo a sus propios hijos
                  en diversas tentaciones y en las corrupciones de sus propios corazones, a fin de disciplinarlos por
                  sus pecados anteriores o para revelarles la fuerza oculta de la corrupción y el engaño de sus
                  corazones, para que sean humillados; y para llevarlos a una dependencia de El más íntima y constante
                  para su apoyo; y para hacerlos más vigilantes contra todas las ocasiones futuras de pecado, y para
                  otros fines santos y justos.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  Por consiguiente, todo lo que ocurre a cualquiera de sus elegidos es por su designio, para su gloria y
                  para el bien de ellos.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 2 Cr. 32:25,26,31; 2 S. 24:1; Lc. 22:34,35; Mr. 14:66-72; Jn. 21:15-17
                  <br />
                  2. Ro. 8:28
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. En cuanto a aquellos hombres malvados e impíos a quienes Dios, como juez justo, ciega y endurece a
                  causa de su pecado anterior,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  no sólo les niega su gracia, por la cual El podría haber iluminado su entendimiento y obrado en sus
                  corazones,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;sino que también algunas veces les retira los dones que tenían,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;y los deja expuestos a aquellas cosas que su corrupción convierte en ocasión de pecado;
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;y, a la vez, los entrega a sus propias concupiscencias, a las tentaciones del mundo y al poder
                  de Satanás,
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;por lo cual sucede que se endurecen bajo los mismos medios que Dios emplea para ablandar a
                  otros.
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 1:24-26,28; 11:7,8
                  <br />
                  2. Dt. 29:4
                  <br />
                  3. Mt. 13:12; 25:29
                  <br />
                  4. Dt. 2:30; 2 R. 8:12,13
                  <br />
                  5. Sal. 81:11,12; 2 Ts. 2:10-12
                  <br />
                  6. Ex. 7:3; 8:15,32; 2 Co. 2:15,16; Is. 6:9,10; 8:14; 1 P. 2:7; Hch. 28:26,27; Jn. 12:39,40
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  7. Del mismo modo que la providencia de Dios alcanza en general a todas las criaturas, así también de
                  un modo más especial cuida de su Iglesia y dispone todas las cosas para el bien de la misma.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Pr. 2:7,8; Am. 9:8,9; 1 Ti. 4:10; Ro. 8:28; Ef. 1:11,22; 3:10,11,21; Is. 43:3-5,14</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 6, De la Caída del hombre, del pecado y su castigo</p>

              <p>
                <span>
                  1. Si bien Dios creó al hombre recto y perfecto, y le dio una ley justa, que hubiera sido para vida si
                  la hubiera guardado, y amenazó con la muerte su transgresión, sin embargo no permaneció mucho tiempo
                  en este honor,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  usando Satanás la sutileza de la serpiente para subyugar a Eva y entonces a través de ella seduciendo
                  a Adán, quien sin ninguna coacción, deliberadamente transgredió la ley bajo la cual habían sido
                  creados y también el mandato que les había sido dado, al comer del fruto prohibido,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;lo cual agradó a Dios, conforme a su sabio y santo consejo, permitir, habiéndose propuesto
                  disponerlo para su propia gloria.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ec. 7:29; Ro. 5:12a, 14,15; Gn. 2:17; 4:25-5:3
                  <br />
                  2. Gn. 3:1-7; 2 Co. 11:3; 1 Ti. 2:14
                  <br />
                  3. Ro. 11:32-34; 2 S. 24:1; 1 Cr. 21:1; 1 R. 22:22,23; 2 S. 16:10; Hch. 2:23; 4:27,28
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Por este pecado, nuestros primeros padres cayeron de su justicia original y de su comunión con
                  Dios, y nosotros en ellos, por lo que la muerte sobrevino a todos;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  viniendo a estar todos los hombres muertos en pecado, y totalmente corrompidos en todas las facultades
                  y partes del alma y del cuerpo.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 3:22-24; Ro. 5:12ss.; 1Co. 15:20-22; Sal. 51:4,5; 58:3; Ef. 2:1-3; Gn. 8:21; Pr. 22:15
                  <br />
                  2. Gn. 2:17; Ef. 2:1; Tit. 1:15; Gn. 6:5; Jer. 17:9; Ro. 3:10-18; 1:21; Ef. 4:17-19; Jn. 5:40; Ro. 8:7
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Siendo ellos la raíz de la raza humana, y estando por designio de Dios en lugar de toda la
                  humanidad, la culpa del pecado fue imputada y la naturaleza corrompida transmitida a su posteridad
                  descendiente de ellos mediante generación ordinaria, siendo ahora concebidos en pecado, y por
                  naturaleza hijos de ira, siervos del pecado, sujetos a la muerte y a todas las demás desgracias –
                  espirituales, temporales y eternas –, a no ser que el Señor Jesús los libere.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 5:12ss.; 1 Co. 15:20-22; Sal. 51:4,5; 58:3; Ef. 2:1-3; Gn. 8:21; Pr. 22:15; Job 14:4; 15:14
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. De esta corrupción original, por la cual estamos completamente impedidos, incapaces y opuestos a
                  todo bien y enteramente inclinados a todo mal,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>proceden todas las transgresiones en sí.</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 7:17,18; 12:33-35; Lc. 6:43-45; Jn. 3:3,5; 6:37,39,40,44,45,65; Ro. 3:10-12; 5:6; 7:18; 8:7,8;
                  1 Co. 2:14
                  <br />
                  2. Mt. 7:17-20; 12:33-35; 15:18-20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. La corrupción de la naturaleza permanece durante esta vida en aquellos que son regenerados;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y, aunque sea perdonada y mortificada por medio de Cristo, sin embargo ella misma y sus primeros
                  impulsos son verdadera y propiamente pecado.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Jn. 1:8-10; 1 R. 8:46; Sal. 130:3; 143:2; Pr. 20:9; Ec. 7:20; Ro. 7:14-25; Stg. 3:2
                  <br />
                  2. Sal. 51:4,5; Pr. 22:15; Ef. 2:3; Ro. 7:5,7,8,17,18,25; 8:3-13; Gá. 5:17-24; Pr. 15:26; 21:4; Gn.
                  8:21; Mt. 5:27,28
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 7, Del pacto de Dios</p>

              <p>
                <span>
                  1. La distancia entre Dios y la criatura es tan grande que aun cuando las criaturas racionales le
                  deben obediencia como a su Creador, sin embargo éstas nunca podrían haber logrado la recompensa de
                  vida a no ser por alguna condescendencia voluntaria por parte de Dios, que a El le ha placido expresar
                  en forma de pacto.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Job 35:7,8; Sal. 113:5,6; Is. 40:13-16; Lc. 17:5-10; Hch. 17:24,25</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Además, habiéndose el hombre acarreado la maldición de la ley por su Caída, agradó al Señor hacer
                  un pacto de gracia,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  en el que gratuitamente ofrece a los pecadores vida y salvación por Jesucristo, requiriéndoles la fe
                  en El para que puedan ser salvos,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y prometiendo dar su Espíritu Santo a todos aquellos que están ordenados para vida eterna, a fin
                  de darles disposición y capacidad para creer.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 3:15; Sal. 110:4 (con He. 7:18-22; 10:12-18); Ef. 2:12 (con Ro. 4:13-17 y Gá. 3:18-22); He.
                  9:15
                  <br />
                  2. Jn. 3:16; Ro. 10:6,9; Gá. 3:11
                  <br />
                  3. Ez. 36:26,27; Jn. 6:44,45
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Este pacto se revela en el evangelio; en primer lugar, a Adán en la promesa de salvación a través
                  de la simiente de la mujer, y luego mediante pasos adicionales hasta completarse su plena revelación
                  en el Nuevo Testamento;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y está fundado en aquella transacción federal y eterna que hubo entre el Padre y el Hijo acerca de la
                  redención de los elegidos;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y es únicamente a través de la gracia de este pacto como todos los descendientes del Adán caído
                  que son salvados obtienen vida y bendita inmortalidad, siendo el hombre ahora totalmente incapaz de
                  ser aceptado por Dios bajo aquellas condiciones en las que estuvo Adán en su estado de inocencia.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 3:15; Ro. 16:25-27; Ef. 3:5; Tit. 1:2; He. 1:1,2
                  <br />
                  2. Sal. 110:4; Ef. 1:3-11; 2 Ti. 1:9
                  <br />
                  3. Jn. 8:56; Ro. 4:1-25; Gá. 3:18-22; He. 11:6,13,39,40
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 8, De Cristo el Mediador</p>

              <p>
                <span>1. Agradó a Dios,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>en su propósito eterno,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;escoger y ordenar al Señor Jesús, su unigénito Hijo, conforme al pacto hecho entre ambos,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  para que fuera el mediador entre Dios y el hombre; profeta, sacerdote, y rey; cabeza y salvador de la
                  Iglesia, el heredero de todas las cosas, y juez del mundo;
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;a quien dio, desde toda la eternidad, un pueblo para que fuera su simiente y para que a su
                  tiempo lo redimiera, llamara, justificara, santificara y glorificara.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Is. 42:1; Jn. 3:16
                  <br />
                  2. 1 P. 1:19
                  <br />
                  3. Sal. 110:4; He. 7:21,22
                  <br />
                  4. 1 Ti. 2:5; Hch. 3:22; He. 5:5,6; Sal. 2:6; Lc. 1:33; Ef. 1:22,23; 5:23; He. 1:2; 17:31
                  <br />
                  5. Ro. 8:30; Jn. 17:6; Is. 53:10; Sal. 22:30; 1 Ti. 2:6; Is. 55:4,5; 1 Co. 1:30
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. El Hijo de Dios, la segunda persona en la Santa Trinidad, siendo verdadero y eterno Dios, el
                  resplandor de la gloria del Padre, consustancial con aquel que hizo el mundo e igual a El, y quien
                  sostiene y gobierna todas las cosas que ha hecho,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>cuando llegó la plenitud del tiempo,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;tomó sobre sí la naturaleza del hombre, con todas sus propiedades esenciales</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;y con sus debilidades concomitantes,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>aunque sin pecado;</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;siendo concebido por el Espíritu Santo en el vientre de la Virgen María, al venir sobre ella el
                  Espíritu Santo y cubrirla el Altísimo con su sombra; y así fue hecho de una mujer de la tribu de Judá,
                  de la simiente de Abraham y David según las Escrituras;,
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>
                  &nbsp;de manera que, dos naturalezas completas, perfectas y distintas se unieron inseparablemente en
                  una persona, pero sin conversión, composición o confusión alguna. Esta persona es verdadero Dios
                </span>
                <span className="s4">
                  <sup>7</sup>
                </span>
                <span>&nbsp;y verdadero hombre,</span>
                <span className="s4">
                  <sup>8</sup>
                </span>
                <span>&nbsp;aunque un solo Cristo, el único mediador entre Dios y el hombre.</span>
                <span className="s4">
                  <sup>9</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 8:58; Jl. 2:32 con Ro. 10:13; Sal. 102:25 con He. 1:10; 1 P. 2:3 con Sal. 34:8; Is. 8:12,13 con
                  3:15;
                  <br />
                  Jn. 1:1; 5:18; 20:28; Ro. 9:5; Tit. 2:13; He. 1:8,9; Fil. 2:5,6; 2 P. 1:1; 1 Jn. 5:20
                  <br />
                  2. Gá. 4:4
                  <br />
                  3. He. 10:5; Mr. 14:8; Mt. 26:12,26; Lc. 7:44-46; Jn. 13:23; Mt. 9:10-13; 11:19;
                  <br />
                  Lc. 22:44; He. 2:10; 5:8; 1 P. 3:18; 4:1; Jn. 19:32-35; Mt. 26:36-44; Stg. 2:26;
                  <br />
                  Jn. 19:30; Lc. 23:46; Mt. 26:39; 9:36; Mr. 3:5; 10:14; Jn. 11:35; Lc. 19:41-44;
                  <br />
                  10:21; Mt. 4:1-11; He. 4:15 con Stg. 1:13; Lc. 5:16; 6:12; 9:18,28; 2:40,52; He. 5:8,9
                  <br />
                  4. Mt. 4:2; Mr. 11:12; Mt. 21:18; Jn. 4:7; 19:28; 4:6; Mt. 8:24; Ro. 8:3; He. 5:8; 2:10,18; Gá. 4:4
                  <br />
                  5. Is. 53:9; Lc. 1:35; Jn. 8:46; 14:30; Ro. 8:3; 2 Co. 5:21; He. 4:15; 7:26; 9:14;
                  <br />
                  1 P. 1:19; 2:22; 1 Jn. 3:5
                  <br />
                  6. Ro. 1:3,4; 9:5
                  <br />
                  7. Ver ref. 1 arriba
                  <br />
                  8. Hch. 2:22; 13:38; 17:31; 1 Co. 15:21; 1 Ti. 2:5
                  <br />
                  9. Ro. 1:3,4; Gá. 4:4,5; Fil. 2:5-11
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. El Señor Jesús, en su naturaleza humana así unida a la divina, en la persona del Hijo, fue
                  santificado y ungido con el Espíritu Santo sin medida, teniendo en sí todos los tesoros de la
                  sabiduría y del conocimiento, en quien agradó al Padre que habitase toda plenitud, a fin de que siendo
                  santo, inocente y sin mancha, y lleno de gracia y de verdad, fuese del todo apto para desempeñar el
                  oficio de mediador y fiador;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  el cual no tomó para sí, sino que fue llamado para el mismo por su Padre, quien también puso en sus
                  manos todo poder y juicio, y le ordenó que lo cumpliera.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Sal. 45:7; Col. 1:19; 2:3; He. 7:26; Jn. 1:14; Hch. 10:38; He. 7:22
                  <br />
                  2. He. 5:5; Jn. 5:22,27; Mt. 28:18; Hch. 2:36
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>4. El Señor Jesús asumió de muy buena voluntad este oficio,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>y para desempeñarlo, nació bajo la ley,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;la cumplió perfectamente y sufrió el castigo que nos correspondía a nosotros, el cual deberíamos
                  haber llevado y sufrido,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;siendo hecho pecado y maldición por nosotros;</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  soportando las más terribles aflicciones en su alma y los más dolorosos sufrimientos en su cuerpo;
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;fue crucificado y murió, y permaneció en el estado de los muertos, aunque sin ver corrupción.
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>&nbsp;Al tercer día resucitó de entre los muertos con el mismo cuerpo en que sufrió,</span>
                <span className="s4">
                  <sup>7</sup>
                </span>
                <span>&nbsp;con el cual también ascendió al cielo,</span>
                <span className="s4">
                  <sup>8</sup>
                </span>
                <span>&nbsp;y allí está sentado a la diestra de su Padre intercediendo,</span>
                <span className="s4">
                  <sup>9</sup>
                </span>
                <span>&nbsp;y regresará para juzgar a los hombres y a los ángeles al final del mundo.</span>
                <span className="s4">
                  <sup>10</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Sal. 40:7,8 con He. 10:5-10; Jn. 10:18; Fil. 2:8
                  <br />
                  2. Gá. 4:4
                  <br />
                  3. Mt. 3:15; 5:17
                  <br />
                  4. Mt. 26:37,38; Lc. 22:44; Mt. 27:46
                  <br />
                  5. Mt. 26-27
                  <br />
                  6. Fil. 2:8; Hch. 13:37
                  <br />
                  7. Jn. 20:25,27
                  <br />
                  8. Hch. 1:9-11
                  <br />
                  9. Ro. 8:34; He. 9:24
                  <br />
                  10. Hch. 10:42; Ro. 14:9,10; Hch. 1:11; Mt. 13:40-42; 2 P. 2:4; Jud. 6
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>5. El Señor Jesús, por su perfecta obediencia y el sacrificio de sí mismo</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>que ofreció a Dios una sola vez por el Espíritu eterno,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;ha satisfecho plenamente la justicia de Dios,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;ha conseguido la reconciliación</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;y ha comprado una herencia eterna en el reino de los cielos</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>para todos aquellos que el Padre le ha dado.</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 5:19; Ef. 5:2
                  <br />
                  2. He. 9:14,16; 10:10,14
                  <br />
                  3. Ro. 3:25,26; He. 2:17; 1 Jn. 2:2; 4:10
                  <br />
                  4. 2 Co. 5:18,19; Col. 1:20-23
                  <br />
                  5. He. 9:15; Ap. 5:9,10
                  <br />
                  6. Jn. 17:2
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. Aun cuando el precio de la redención no fue realmente pagado por Cristo hasta después de su
                  encarnación, sin embargo la virtud, la eficacia y los beneficios de la misma fueron comunicados a los
                  elegidos en todas las épocas transcurridas desde el principio del mundo,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  en las promesas, tipos y sacrificios y por medio de los mismos, en los cuales fue revelado y señalado
                  como la simiente que heriría la cabeza de la serpiente,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>y como el Cordero inmolado desde la fundación del mundo,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;siendo el mismo ayer, hoy y por los siglos.</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gá. 4:4,5; Ro. 4:1-9
                  <br />
                  2. Gn. 3:15; 1 P. 1:10,11
                  <br />
                  3. Ap. 13:8
                  <br />
                  4. He. 13:8
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  7. Cristo, en la obra de mediación, actúa conforme a ambas naturalezas, haciendo por medio de cada
                  naturaleza lo que es propio de ella; aunque, por razón de la unidad de la persona, lo que es propio de
                  una naturaleza algunas veces se le atribuye en la Escritura a la persona denominada por la otra
                  naturaleza.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Jn. 3:13; Hch. 20:28</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  8. A todos aquellos para quienes Cristo ha obtenido eterna redención, cierta y eficazmente les aplica
                  y comunica la misma,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>haciendo intercesión por ellos,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;uniéndoles a sí mismo por su Espíritu,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;revelándoles en la Palabra y por medio de ella el misterio de la salvación,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>persuadiéndoles a creer y obedecer,</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;gobernando sus corazones por su Palabra y Espíritu,</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>&nbsp;y venciendo a todos sus enemigos por su omnipotente poder y sabiduría,</span>
                <span className="s4">
                  <sup>7</sup>
                </span>
                <span>
                  &nbsp;de tal manera y forma que sea más de acuerdo con su maravillosa e inescrutable dispensación;
                </span>
                <span className="s4">
                  <sup>8</sup>
                </span>
                <span>
                  &nbsp;y todo por su gracia libre y absoluta, sin prever ninguna condición en ellos para granjearla.
                </span>
                <span className="s4">
                  <sup>9</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn.6:37,39; 10:15,16; 17:9
                  <br />
                  2. 1 Jn. 2:1,2; Ro. 8:34
                  <br />
                  3. Ro. 8:1,2
                  <br />
                  4. Jn. 15:13,15; 17:6; Ef. 1:7-9
                  <br />
                  5. 1 Jn. 5:20
                  <br />
                  6. Jn. 14:16; He. 12:2; Ro. 8:9,14; 2 Co. 4:13; Ro. 15:18,19; Jn. 17:17
                  <br />
                  7. Sal. 110:1; 1 Co. 15:25,26; Col. 2:15
                  <br />
                  8. Ef. 1:9-11
                  <br />
                  9. 1 Jn. 3:8; Ef. 1:8
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  9. Este oficio de mediador entre Dios y el hombre es propio sólo de Cristo, quien es el Profeta,
                  Sacerdote y Rey de la Iglesia de Dios; y no puede, ya sea parcial o totalmente, ser transferido de El
                  a ningún otro.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. 1 Ti. 2:5</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  10. Este número y orden de oficios es necesario; pues, por nuestra ignorancia, tenemos necesidad de su
                  oficio profético;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y por nuestra separación de Dios y la imperfección del mejor de nuestros servicios, necesitamos su
                  oficio sacerdotal para reconciliarnos con Dios y presentarnos aceptos para con El;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y por nuestra indisposición y total incapacidad para volver a Dios y para nuestro rescate y
                  protección de nuestros adversarios espirituales, necesitamos su oficio real para convencernos,
                  subyugarnos, atraernos, sostenernos, librarnos y preservarnos para su reino celestial.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 1:18
                  <br />
                  2. Col. 1:21; Gá. 5:17; He. 10:19-21
                  <br />
                  3. Jn. 16:8; Sal. 110:3; Lc. 1:74,75
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 9, Del libre albedrío</p>

              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  1. Dios ha dotado la voluntad del hombre de una libertad natural y de poder para actuar por elección
                  propia, que no es forzada ni determinada a hacer bien o mal por ninguna necesidad de la naturaleza.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Mt. 17:12; Stg. 1:14; Dt. 30:19</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. El hombre, en su estado de inocencia, tenía libertad y poder para querer y hacer lo que era bueno y
                  agradable a Dios,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>pero era mudable y podía caer de dicho estado.</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ec. 7:29
                  <br />
                  2. Gn. 3:6
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. El hombre, por su Caída en un estado de pecado, ha perdido completamente toda capacidad para querer
                  cualquier bien espiritual que acompañe a la salvación; por consiguiente, como hombre natural que está
                  enteramente opuesto a ese bien y muerto en el pecado, no puede por sus propias fuerzas convertirse a
                  sí mismo o prepararse para ello.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 6:16,20; Jn. 8:31-34; Ef. 2:1; 2 Co. 3:14; 4:3,4; Jn. 3:3; Ro. 7:18; 8:7;
                  <br />
                  1 Co. 2:14; Mt. 7:17,18; 12:33-37; Lc. 6:43-45; Jn. 6:44; Jer. 13:23; Jn. 3:3,5;
                  <br />
                  5:40; 6:37,39,40,44,45,65; Hch. 7:51; Ro. 3:10-12; Stg. 1:18; Ro. 9:16-18;
                  <br />
                  Jn. 1:12,13; Hch. 11:18; Fil. 1:29; Ef. 2:8,9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. Cuando Dios convierte a un pecador y lo traslada al estado de gracia, lo libra de su servidumbre
                  natural bajo el pecado y, por su sola gracia, lo capacita para querer y obrar libremente lo que es
                  espiritualmente bueno;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sin embargo, por razón de la corrupción que todavía le queda, no quiere, ni perfecta ni únicamente, lo
                  que es bueno, sino que también quiere lo que es malo.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Col. 1:13; Jn. 8:36; Fil. 2:13
                  <br />
                  2. Ro. 7:14-25; Gá. 5:17
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. Esta voluntad del hombre es hecha perfecta e inmutablemente libre sólo para el bien, únicamente en
                  el estado de gloria.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Ef. 4:13; He. 12:23</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 10, Del llamamiento eficaz</p>

              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>1. A aquellos a quienes Dios</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>ha predestinado para vida,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;tiene a bien en su tiempo señalado y aceptado,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;llamar eficazmente</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;por su Palabra</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;y Espíritu,</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>
                  &nbsp;así sacándolos del estado de pecado y muerte en que están por naturaleza y llevándolos a la
                  gracia y la salvación por Jesucristo;
                </span>
                <span className="s4">
                  <sup>7</sup>
                </span>
                <span>
                  &nbsp;iluminando de modo espiritual y salvador sus mentes, a fin de que comprendan las cosas de Dios;
                </span>
                <span className="s4">
                  <sup>8</sup>
                </span>
                <span>&nbsp;quitándoles el corazón de piedra y dándoles un corazón de carne,</span>
                <span className="s4">
                  <sup>9</sup>
                </span>
                <span>
                  &nbsp;renovando sus voluntades y, por su poder omnipotente, induciéndoles a lo que es bueno, y
                  llevándoles eficazmente a Jesucristo;
                </span>
                <span className="s4">
                  <sup>10</sup>
                </span>
                <span>
                  &nbsp;pero de modo que van con total libertad, habiendo recibido por la gracia de Dios la disposición
                  para hacerlo.
                </span>
                <span className="s4">
                  <sup>11</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 8:28,29
                  <br />
                  2. Ro. 8:29,30; 9:22-24; 1 Co. 1:26-28; 2 Ts. 2:13,14; 2 Ti. 1:9
                  <br />
                  3. Jn. 3:8; Ef. 1:11
                  <br />
                  4. Mt. 22:14; 1 Co. 1:23,24; Ro. 1:6; 8:28; Jud. 1; Sal. 29; Jn. 5:25; Ro. 4:17
                  <br />
                  5. 2 Ts. 2:14; 1 P. 1:23-25; Stg. 1:17-25; 1 Jn. 5:1-5; Ro. 1:16,17; 10:14; He. 4:12
                  <br />
                  6. Jn. 3:3,5,6,8; 2 Co. 3:3,6
                  <br />
                  7. Ro. 8:2; 1 Co. 1:9; Ef. 2:1-6; 2 Ti. 1:9,10
                  <br />
                  8. Hch. 26:18; 1 Co. 2:10,12; Ef. 1:17,18
                  <br />
                  9. Ez. 36:26
                  <br />
                  10. Dt. 30:6; Ez. 36:27; Jn. 6:44,45; Ef. 1:19; Fil. 2:13
                  <br />
                  11. Sal. 110:3; Jn. 6:37; Ro. 6:16-18
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Este llamamiento eficaz proviene solamente de la gracia libre y especial de Dios, no de ninguna
                  cosa prevista en el hombre, ni por ningún poder o instrumentalidad en la criatura,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  siendo el hombre en esto enteramente pasivo, al estar muerto en delitos y pecados, hasta que es
                  vivificado y renovado por el Espíritu Santo;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;es capacitado de este modo para responder a este llamamiento y para recibir la gracia ofrecida y
                  transmitida en él, y esto por un poder no menor que el que resucitó a Cristo de los muertos.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 2 Ti. 1:9; Tit. 3:4,5; Ef. 2:4,5,8,9; Ro. 9:11
                  <br />
                  2. 1 Co. 2:14; Ro. 8:7; Ef. 2:5
                  <br />
                  3. Ef. 1:19,20; Jn. 6:37; Ez. 36:27; Jn. 5:25
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Los niños elegidos* que mueren en la infancia son regenerados y salvados por Cristo por medio del
                  Espíritu, quien obra cuando, donde y como quiere;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  así lo son también todas las personas elegidas que sean incapaces de ser llamadas externamente por el
                  ministerio de la Palabra.
                </span>
              </p>
              <p>
                <span>* Elegidos no aparece en algunas ediciones de la Confesión, pero sí en la original</span>
              </p>
              <p>
                <span>1. Jn. 3:8</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. Otras personas no elegidas, aunque sean llamadas por el ministerio de la Palabra y tengan algunas
                  de las operaciones comunes del Espíritu,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  como no son eficazmente traídas por el Padre, no quieren ni pueden venir verdaderamente a Cristo y,
                  por lo tanto, no pueden ser salvas;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;mucho menos pueden ser salvos los que no reciben la religión cristiana, por muy diligentes que
                  sean en ajustar sus vidas a la luz de la naturaleza y a la ley de la religión que profesen.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 22:14; Mt. 13:20,21; He. 6:4,5; Mt. 7:22
                  <br />
                  2. Jn. 6:44,45,64-66; 8:24
                  <br />
                  3. Hch. 4:12; Jn. 4:22; 17:3
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 11, De la justificación</p>

              <p>
                <span>1. A quienes Dios llama eficazmente, también justifica gratuitamente,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  no infundiendo justicia en ellos sino perdonándoles sus pecados, y contando y aceptando sus personas
                  como justas;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;no por nada obrado en ellos o hecho por ellos, sino solamente por causa de Cristo;</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;no imputándoles la fe misma, ni la acción de creer, ni ninguna otra obediencia evangélica como
                  justicia; sino imputándoles la obediencia activa de Cristo a toda la ley y su obediencia pasiva en su
                  muerte para la completa y única justicia de ellos por la fe, la cual tienen no de sí mismos; es don de
                  Dios.
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 3:24; 8:30
                  <br />
                  2. Ro. 4:5-8; Ef. 1:7
                  <br />
                  3. 1 Co. 1:30,31; Ro. 5:17-19
                  <br />
                  4. Fil. 3:9; Ef. 2:7,8; 2 Co. 5:19-21; Tit. 3:5,7; Ro. 3:22-28; Jer. 23:6; Hch. 13:38,39
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. La fe que así recibe a Cristo y descansa en El y en su justicia es el único instrumento de la
                  justificación;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sin embargo, no está sola en la persona justificada, sino que siempre va acompañada por todas las
                  demás virtudes salvadoras, y no es una fe muerta sino que obra por el amor.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 1:17; 3:27-31; Fil. 3:9; Gá. 3:5
                  <br />
                  2. Gá. 5:6; Stg. 2:17,22,26
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Cristo, por su obediencia y muerte, saldó totalmente la deuda de todos aquellos que son
                  justificados; y por el sacrificio de sí mismo en la sangre de su cruz, sufriendo en el lugar de ellos
                  el castigo que merecían, hizo una satisfacción adecuada, real y completa a la justicia de Dios en
                  favor de ellos;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>sin embargo, por cuanto Cristo fue dado por el Padre para ellos,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;y su obediencia y satisfacción fueron aceptadas en lugar de las de ellos,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;y ambas gratuitamente y no por nada en ellos, su justificación es solamente de pura gracia,
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;a fin de que tanto la precisa justicia como la rica gracia de Dios fueran glorificadas en la
                  justificación de los pecadores.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 5:8-10,19; 1 Ti. 2:5,6; He. 10:10,14; Is. 53:4-6,10-12
                  <br />
                  2. Ro. 8:32
                  <br />
                  3. 2 Co. 5:21; Mt. 3:17; Ef. 5:2
                  <br />
                  4. Ro. 3:24; Ef. 1:7
                  <br />
                  5. Ro. 3:26; Ef. 2:7
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>4. Desde la eternidad, Dios decretó justificar a todos los elegidos;</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y en el cumplimiento del tiempo, Cristo murió por los pecados de ellos, y resucitó para su
                  justificación;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;sin embargo, no son justificados personalmente hasta que, a su debido tiempo, Cristo les es
                  realmente aplicado por el Espíritu Santo.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 P. 1:2,19,20; Gá. 3:8; Ro. 8:30
                  <br />
                  2. Ro. 4:25; Gá. 4:4; 1 Ti. 2:6
                  <br />
                  3. Col. 1:21,22; Tit. 3:4-7; Gá. 2:16; Ef. 2:1-3
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>5. Dios continúa perdonando los pecados de aquellos que son justificados,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>y aunque ellos nunca pueden caer del estado de justificación,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  sin embargo pueden, por sus pecados, caer en el desagrado paternal de Dios; y, en esa condición, no
                  suelen tener la luz de su rostro restaurada sobre ellos, hasta que se humillen, confiesen sus pecados,
                  pidan perdón y renueven su fe y arrepentimiento.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 6:12; 1 Jn. 1:7–2:2; Jn. 13:3-11
                  <br />
                  2. Lc. 22:32; Jn. 10:28; He. 10:14
                  <br />
                  3. Sal. 32:5; 51:7-12; Mt. 26:75; Lc. 1:20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  7. La justificación de los creyentes bajo el Antiguo Testamento fue, en todos estos sentidos, una y la
                  misma que la justificación de los creyentes bajo el Nuevo Testamento.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Gá. 3:9; Ro. 4:22-24</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 12, De la adopción</p>

              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>1. A todos aquellos que son justificados,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>Dios se dignó,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;en su único Hijo Jesucristo y por amor de éste,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;hacerles partícipes de la gracia de la adopción, por la cual son incluidos en el número de los
                  hijos de Dios y gozan de sus libertades y privilegios, tienen su nombre escrito sobre ellos,
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;reciben el espíritu de adopción, tienen acceso al trono de la gracia con confianza, se les
                  capacita para clamar: “Abba, Padre,”
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;se les compadece, protege, provee y corrige como por un Padre, pero nunca se les desecha, sino
                  que son sellados para el día de la redención,
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>&nbsp;y heredan las promesas como herederos de la salvación eterna.</span>
                <span className="s4">
                  <sup>7</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gá. 3:24-26
                  <br />
                  2. 1 Jn. 3:1-3
                  <br />
                  3. Ef. 1:5; Gá.4:4,5; Ro. 8:17,29
                  <br />
                  4. Ro. 8:17; Jn. 1:12; 2 Co. 6:18; Ap. 3:12
                  <br />
                  5. Ro. 8:15; Ef. 3:12; Ro. 5:2; Gá. 4:6; Ef. 2:18
                  <br />
                  6. Sal. 103:13; Pr. 14:26; Mt. 6:30,32; 1 P. 5:7; He. 12:6; Is. 54:8,9; Lm. 3:31; Ef. 4:30
                  <br />
                  7. Ro. 8:17; He. 1:14; 9:15
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 13, De la santificación</p>

              <p>
                <span>
                  1. Aquellos que son unidos a Cristo, llamados eficazmente y regenerados, teniendo un nuevo corazón y
                  un nuevo espíritu, creados en ellos en virtud de la muerte y la resurrección de Cristo,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>son aún más santificados de un modo real y personal,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;mediante la misma virtud,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;por su Palabra y Espíritu que moran en ellos;</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;el dominio del cuerpo entero del pecado es destruido, y las diversas concupiscencias del mismo
                  son debilitadas y mortificadas más y más, y ellos son más y más vivificados y fortalecidos en todas
                  las virtudes salvadoras, para la práctica de toda verdadera santidad,
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;sin la cual nadie verá al Señor.</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 3:3-8; 1 Jn. 2:29; 3:9,10; Ro. 1:7; 2 Co. 1:1; Ef. 1:1; Fil. 1:1; Col. 3:12;
                  <br />
                  Hch. 20:32; 26:18; Ro. 15:16; 1 Co. 1:2; 6:11; Ro. 6:1-11
                  <br />
                  2. 1 Ts. 5:23; Ro. 6:19,22
                  <br />
                  3. 1 Co. 6:11; Hch. 20:32; Fil. 3:10; Ro. 6:5,6
                  <br />
                  4. Jn. 17:17; Ef. 5:26; 3:16-19; Ro. 8:13
                  <br />
                  5. Ro. 6:14; Gá. 5:24; Ro. 8:13; Col. 1:11; Ef. 3:16-19; 2 Co. 7:1; Ro. 6:13; Ef. 4:22-25; Gá. 5:17
                  <br />
                  6. He. 12:14
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Esta santificación se efectúa en todo el hombre, aunque es incompleta en esta vida; todavía quedan
                  algunos remanentes de corrupción en todas partes,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>de donde surge una continua e irreconciliable guerra:</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;la carne lucha contra el Espíritu, y el Espíritu contra la carne.</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Ts. 5:23; 1 Jn. 1:8,10; Ro. 7:18,23; Fil. 3:12
                  <br />
                  2. 1 Co. 9:24-27; 1 Ti. 1:18; 6:12; 2 Ti. 4:7
                  <br />
                  3. Gá. 5:17; 1 P. 2:11
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>3. En dicha guerra, aunque la corrupción que aún queda prevalezca mucho por algún tiempo,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  la parte regenerada triunfa a través de la continua provisión de fuerzas por parte del Espíritu
                  santificador de Cristo;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y así los santos crecen en la gracia, perfeccionando la santidad en el temor de Dios,
                  prosiguiendo una vida celestial, en obediencia evangélica a todos los mandatos que Cristo, como Cabeza
                  y Rey, les ha prescrito en su Palabra.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 7:23
                  <br />
                  2. Ro. 6:14; 1 Jn. 5:4; Ef. 4:15,16
                  <br />
                  3. 2 P. 3:18; 2 Co. 7:1; 3:18; Mt. 28:20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 14, De la fe salvadora</p>

              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  1. La gracia de la fe, por la cual se capacita a los elegidos para creer para la salvación de sus
                  almas, es la obra del Espíritu de Cristo en sus corazones, y ordinariamente se realiza por el
                  ministerio de la Palabra;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  por la cual, y por la administración del bautismo y la Cena del Señor, la oración y otros medios
                  designados por Dios, esa fe aumenta y se fortalece.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 6:37, 44; Hch. 11:21,24; 13:48; 14:27; 15:9; 2 Co. 4:13; Ef. 2:8; Fil. 1:29; 2 Ts. 2:13; 1 P.
                  1:2
                  <br />
                  2. Ro. 10:14,17; Lc. 17:5; Hch. 20:32; Ro. 4:11; 1 P. 2:2
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Por esta fe, el cristiano cree que es verdadero todo lo revelado en la Palabra por la autoridad de
                  Dios mismo, y también percibe en ella una excelencia superior a todos los demás escritos y todas las
                  cosas en el mundo, pues muestra la gloria de Dios en sus atributos, la excelencia de Cristo en su
                  naturaleza y oficios, y el poder y la plenitud del Espíritu Santo en sus obras y operaciones; y de
                  esta forma, el cristiano es capacitado para confiar su alma a la verdad así creída;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y también actúa de manera diferente según sea el contenido de cada pasaje en particular: produciendo
                  obediencia a los mandatos,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;temblando ante las amenazas,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;y abrazando las promesas de Dios para esta vida y para la venidera;</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;pero las principales acciones de la fe salvadora tienen que ver directamente con Cristo:
                  aceptarle, recibirle y descansar sólo en El para la justificación, santificación y vida eterna, en
                  virtud del pacto de gracia.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 24:14; 1 Ts. 2:13; Sal. 19:7-10; 119:72
                  <br />
                  2. Jn. 15:14; Ro. 16:26
                  <br />
                  3. Is. 66:2
                  <br />
                  4. 1 Ti. 4:8; He. 11:13
                  <br />
                  5. Jn. 1:12; Hch. 15:11; 16:31; Gá. 2:20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>3. Esta fe, aunque sea diferente en grados y pueda ser débil o fuerte,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  es, sin embargo, aun en su grado mínimo, diferente en su clase y naturaleza (como lo es toda otra
                  gracia salvadora) de la fe y la gracia común de aquellos creyentes que sólo lo son por un tiempo;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y consecuentemente, aunque muchas veces sea atacada y debilitada, resulta, sin embargo,
                  victoriosa,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;creciendo en muchos hasta obtener la completa seguridad</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;a través de Cristo, quien es tanto el autor como el consumador de nuestra fe.</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 6:30; 8:10,26; 14:31; 16:8; Mt. 17:20; He. 5:13,14; Ro. 4:19,20
                  <br />
                  2. Stg. 2:14; 2 P. 1:1; 1 Jn. 5:4
                  <br />
                  3. Lc. 22:31,32; Ef. 6:16; 1 Jn. 5:4,5
                  <br />
                  4. Sal. 119:114; He. 6:11,12; 10:22,23
                  <br />
                  5. He. 12:2
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 15, Del arrepentimiento para vida y salvación</p>

              <p>
                <span>
                  1. A aquellos de los elegidos que se convierten en la madurez, habiendo vivido por algún tiempo en el
                  estado natural,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y habiendo servido en el mismo a diversas concupiscencias y placeres, Dios, al llamarlos eficazmente,
                  les da arrepentimiento para vida.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Tit. 3:2-5
                  <br />
                  2. 2 Cr. 33:10-20; Hch. 9:1-19; 16:29,30
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>2. Si bien no hay nadie que haga el bien y no peque,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y los mejores hombres, mediante el poder y el engaño de la corrupción que habita en ellos, junto con
                  el predominio de la tentación, pueden caer en grandes pecados y provocaciones,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;Dios, en el pacto de gracia, ha provisto misericordiosamente que los creyentes que pequen y
                  caigan de esta manera sean renovados mediante el arrepentimiento para salvación.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Sal. 130:3; 143:2; Pr.20:9; Ec. 7:20
                  <br />
                  2. 2 S. 11:1-27; Lc. 22:54-62
                  <br />
                  3. Jer. 32:40; Lc. 22:31,32; 1 Jn. 1:9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>3. Este arrepentimiento para salvación es una gracia evangélica</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  por la cual una persona a quien el Espíritu hace consciente de las múltiples maldades de su pecado,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;mediante la fe en Cristo</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;se humilla por él con una tristeza que es según Dios, abominación de él y aborrecimiento de sí
                  mismo, orando por el perdón y las fuerzas que proceden de la gracia,
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;con el propósito y empeño, mediante la provisión del Espíritu, de andar delante de Dios para
                  agradarle en todo.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 5:31; 11:18; 2 Ti. 2:25
                  <br />
                  2. Sal. 51:1-6; 130:1-3; Lc. 15:17-20; Hch. 2:37,38
                  <br />
                  3. Sal. 130:4; Mt. 27:3-5; Mr. 1:15
                  <br />
                  4. Ez. 16:60-63; 36:31,32; Zc. 12:10; Mt. 21:19; Hch. 15:19; 20:21; 26:20;
                  <br />
                  2 Co. 7:10,11; 1 Ts. 1:9
                  <br />
                  5. Pr. 28:13; Ez. 36:25; 18:30,31; Sal. 119:59,104,128; Mt. 3:8; Lc. 3:8; Hch. 26:20; 1 Ts. 1:9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. Puesto que el arrepentimiento ha de continuar a lo largo de toda nuestra vida, debido al cuerpo de
                  muerte y sus inclinaciones,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  es por tanto el deber de cada hombre arrepentirse específicamente de los pecados concretos que
                  conozca.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ez. 16:60; Mt. 5:4; 1 Jn. 1:9
                  <br />
                  2. Lc. 19:8; 1 Ti. 1:13,15
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. Tal es la provisión que Dios ha hecho a través de Cristo en el pacto de gracia para la preservación
                  de los creyentes para salvación que, si bien no hay pecado tan pequeño que no merezca la condenación,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  no hay, sin embargo, pecado tan grande que acarree condenación a aquellos que se arrepienten, lo cual
                  hace necesaria la predicación constante del arrepentimiento.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Sal. 130:3; 143:2; Ro. 6:23
                  <br />
                  2. Is. 1:16-18; 55:7; Hch. 2:36-38
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 16, De las buenas obras</p>

              <p>
                <span>1. Las buenas obras son solamente aquellas que Dios ha ordenado en su santa Palabra</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y no las que, sin la autoridad de ésta, han inventado los hombres por un fervor ciego o con cualquier
                  pretexto de buenas intenciones.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1.&nbsp;Mi. 6:8; Ro. 12:2; He. 13:21; Col. 2:3; 2 Ti. 3:16,17
                  <br />
                  2. Mt. 15:9 con Is. 29:13; 1 P. 1:18; Ro. 10:2; Jn. 16:2; 1 S. 15:21-23; 1 Co. 7:23;
                  <br />
                  Gá. 5:1; Col. 2:8,16-23
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Estas buenas obras, hechas en obediencia a los mandamientos de Dios, son los frutos y evidencias de
                  una fe verdadera y viva;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>y por ellas los creyentes manifiestan su gratitud,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;fortalecen su seguridad,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;edifican a sus hermanos,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;adornan la profesión del evangelio,</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;tapan la boca de los adversarios,</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>&nbsp;y glorifican a Dios, cuya hechura son, creados en Cristo Jesús para ello,</span>
                <span className="s4">
                  <sup>7</sup>
                </span>
                <span>&nbsp;para que teniendo por fruto la santificación, tengan como fin la vida eterna.</span>
                <span className="s4">
                  <sup>8</sup>
                </span>
              </p>
              <p>
                <span>
                  1.&nbsp;Stg. 2:18,22; Gá. 5:6; 1 Ti. 1:5
                  <br />
                  2. Sal. 116:12-14; 1 P. 2:9,12; Lc. 7:36-50 con Mt. 26:1-11
                  <br />
                  3. 1 Jn. 2:3,5; 3:18,19; 2 P. 1:5-11
                  <br />
                  4. 2 Co. 9:2; Mt. 5:16
                  <br />
                  5. Mt. 5:16; Tit. 2:5,9-12; 1 Ti. 6:1; 1 P. 2:12
                  <br />
                  6. 1 P. 2:12,15; Tit. 2:5; 1 Ti. 6:1
                  <br />
                  7. Ef. 2:10; Fil. 1:11; 1 Ti. 6:1; 1 P. 2:12; Mt. 5:16
                  <br />
                  8. Ro. 6:22; Mt. 7:13,14,21-23
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. La capacidad que tienen los creyentes para hacer buenas obras no es de ellos mismos en ninguna
                  manera, sino completamente del Espíritu de Cristo. Y para que ellos puedan tener esta capacidad,
                  además de las virtudes que ya han recibido, se necesita una influencia efectiva del mismo Espíritu
                  Santo para obrar en ellos tanto el querer como el hacer por su buena voluntad;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sin embargo, no deben volverse negligentes por ello, como si no estuviesen obligados a cumplir deber
                  alguno aparte de un impulso especial del Espíritu, sino que deben ser diligentes en avivar la gracia
                  de Dios que está en ellos.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ez. 36:26,27; Jn. 15:4-6; 2 Co. 3:5; Fil. 2:12,13; Ef. 2:10
                  <br />
                  2. Ro. 8:14; Jn. 3:8; Fil. 2:12,13; 2 P. 1:10; He. 6:12; 2 Ti. 1:6; Jud. 20,21
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. Quienes alcancen el mayor grado de obediencia posible en esta vida quedan tan lejos de llegar a un
                  grado supererogatorio, y de hacer más de lo que Dios requiere, que les falta mucho de lo que por deber
                  están obligados a hacer.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 8:46; 2 Cr. 6:36; Sal. 130:3; 143:2; Pr. 20:9; Ec. 7:20; Ro. 3:9,23; 7:14 ss.;
                  <br />
                  Gá. 5:17; 1 Jn. 1:6-10; Lc. 17:10
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. Nosotros no podemos, por nuestras mejores obras, merecer el perdón del pecado o la vida eterna de
                  la mano de Dios, a causa de la gran desproporción que existe entre nuestras obras y la gloria que ha
                  de venir,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y por la distancia infinita que hay entre nosotros y Dios, a quien no podemos beneficiar por dichas
                  obras, ni satisfacer la deuda de nuestros pecados anteriores; pero cuando hemos hecho todo lo que
                  podemos, no hemos sino cumplido con nuestro deber y somos siervos inútiles;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;y tanto en cuanto son buenas proceden de su Espíritu;</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;y en cuanto son hechas por nosotros, son impuras y están mezcladas con tanta debilidad e
                  imperfección que no pueden soportar la severidad del castigo de Dios.
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 8:18
                  <br />
                  2. Job 22:3; 35:7; Lc. 17:10; Ro. 4:3; 11:3
                  <br />
                  3. Gá. 5:22,23
                  <br />
                  4. 1 R. 8:46; 2 Cr. 6:36; Sal. 130:3; 143:2; Pr. 20:9; Ec. 7:20; Ro. 3:9,23; 7:14ss.;
                  <br />
                  Gá. 5:17; 1 Jn. 1:6-10
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. No obstante, por ser aceptadas las personas de los creyentes por medio de Cristo, sus buenas obras
                  también son aceptadas en El;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  no como si fueran en esta vida enteramente irreprochables e irreprensibles a los ojos de Dios;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;sino que a El, mirándolas en su Hijo, le place aceptar y recompensar aquello que es sincero aun
                  cuando esté acompañado de muchas debilidades e imperfecciones.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ex. 28:38; Ef. 1:6,7; 1 P. 2:5
                  <br />
                  2. 1 R. 8:46; 2 Cr. 6:36; Sal. 130:3; 143:2; Pr. 20:9; Ec. 7:20; Ro. 3:9,23; 7;14ss.;
                  <br />
                  Gá. 5:17; 1 Jn. 1:6-10
                  <br />
                  3. He. 6:10; Mt. 25:21,23
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  7. Las obras hechas por hombres no regenerados, aunque en sí mismas sean cosas que Dios ordena, y de
                  utilidad tanto para ellos como para otros,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>sin embargo, por no proceder de un corazón purificado por la fe</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;y no ser hechas de una manera correcta de acuerdo con la Palabra,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;ni para un fin correcto (la gloria de Dios</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  ), son, por tanto, pecaminosas, y no pueden agradar a Dios ni hacer a un hombre digno de recibir
                  gracia por parte de Dios.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;Y a pesar de esto, el hecho de que descuiden las buenas obras es más pecaminoso y desagradable a
                  Dios.
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 R. 21:27-29; 2 R. 10:30,31; Ro. 2:14; Fil. 1:15-18
                  <br />
                  2. Gn. 4:5 con He. 11:4-6; 1 Ti. 1:5; Ro. 14:23; Gá. 5:6
                  <br />
                  3. 1 Co. 13:3; Is. 1:12
                  <br />
                  4. Mt. 6:2,5,6; 1 Co. 10:31
                  <br />
                  5. Ro. 9:16; Tit. 1:15; 3:5
                  <br />
                  6. 1 R. 21:27-29; 2 R. 10:30,31; Sal. 14:4; 36:3
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 17, De la perseverancia de los santos</p>

              <p>
                <span>
                  1. Aquellos a quienes Dios ha aceptado en el Amado, y ha llamado eficazmente y santificado por su
                  Espíritu, y a quienes ha dado la preciosa fe de sus elegidos, no pueden caer ni total ni
                  definitivamente del estado de gracia, sino que ciertamente perseverarán en él hasta el fin, y serán
                  salvos por toda la eternidad, puesto que los dones y el llamamiento de Dios son irrevocables, por lo
                  que El continúa engendrando y nutriendo en ellos la fe, el arrepentimiento, el amor, el gozo, la
                  esperanza y todas las virtudes del Espíritu para inmortalidad;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y aunque surjan y les azoten muchas tormentas e inundaciones, nunca podrán, sin embargo, arrancarles
                  del fundamento y la roca a que por la fe están aferrados; a pesar de que, por medio de la incredulidad
                  y las tentaciones de Satanás, la visión perceptible de la luz y el amor de Dios puede nublárseles y
                  oscurecérseles por un tiempo,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;El, sin embargo, es aún el mismo, y ellos serán guardados, sin duda alguna, por el poder de Dios
                  para salvación, en la que gozarán de su posesión adquirida, al estar ellos esculpidos en las palmas de
                  sus manos y sus nombres escritos en el libro de la vida desde toda la eternidad.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 10:28,29; Fil. 1:6; 2 Ti. 2:19; 2 P.1:5-10; 1 Jn. 2:19
                  <br />
                  2. Sal. 89:31,32; 1 Co. 11:32; 2 Ti. 4:7
                  <br />
                  3. Sal. 102:27; Mal. 3:6; Ef. 1:14; 1 P. 1:5; Ap. 13:8
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>2. Esta perseverancia de los santos depende no de su propio libre albedrío,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>sino de la inmutabilidad del decreto de elección,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;que fluye del amor libre e inmutable de Dios el Padre, sobre la base de la eficacia de los
                  méritos y la intercesión de Jesucristo y la unión con El,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;del juramento de Dios,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;de la morada de su Espíritu, de la simiente de Dios que está en los santos,</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;y de la naturaleza del pacto de gracia,</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>de todo lo cual surgen también la certeza y la infalibilidad de la perseverancia.</span>
              </p>
              <p>
                <span>
                  1. Fil. 2:12,13; Ro. 9:16; Jn. 6:37,44
                  <br />
                  2. Mt. 24:22,24,31; Ro. 8:30; 9:11,16; 11:2,29; Ef. 1:5-11
                  <br />
                  3. Ef. 1:4; Ro. 5:9,10; 8:31-34; 2 Co. 5:14; Ro. 8:35-38; 1 Co. 1:8,9; Jn. 14:19; 10:28,29
                  <br />
                  4. He. 6:16-20
                  <br />
                  5. 1 Jn. 2:19,20,27; 3:9; 5:4,18; Ef. 1:13; 4:30; 2 Co. 1:22; 5:5; Ef. 1:14
                  <br />
                  6. Jer. 31:33,34; 32:40; He. 10:11-18; 13:20,21
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Y aunque los santos (mediante la tentación de Satanás y del mundo, el predominio de la corrupción
                  que queda en ellos y el descuido de los medios para su preservación) caigan en pecados graves y por
                  algún tiempo permanezcan en ellos
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>(por lo que incurren en el desagrado de Dios y entristecen a su Espíritu Santo,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;se les dañan sus virtudes y consuelos,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;se les endurece el corazón y se les hiere la conciencia,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;lastiman y escandalizan a otros,</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;y se acarrean juicios temporalis</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>
                  ), sin embargo, renovarán su arrepentimiento y serán preservados hasta el fin mediante la fe en Cristo
                  Jesús.
                </span>
                <span className="s4">
                  <sup>7</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 26:70,72,74
                  <br />
                  2. Sal. 38:1-8; Is. 64:5-9; Ef. 4:30; 1 Ts. 5:14
                  <br />
                  3. Sal. 51:10-12
                  <br />
                  4. Sal. 32:3,4; 73:21,22
                  <br />
                  5. 2 S. 12:14; 1 Co. 8:9-13; Ro. 14:13-18; 1 Ti. 6:1,2; Tit. 2:5
                  <br />
                  6. 2 S. 12:14ss.; Gn. 19:30-38; 1 Co. 11:27-32
                  <br />
                  7. Lc. 22:32,61,62; 1 Co. 11:32; 1 Jn. 3:9; 5:18
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 18, De la seguridad de la gracia y de la salvación</p>

              <p>
                <span>
                  1. Aunque los creyentes que lo son por un tiempo y otras personas no regeneradas vanamente se engañen
                  a sí mismos con esperanzas falsas y presunciones carnales de hallarse en el favor de Dios y en estado
                  de salvación (pero la esperanza de ellos perecerá
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  ), sin embargo, los que creen verdaderamente en el Señor Jesús y le aman con sinceridad, esforzándose
                  por andar con toda buena conciencia delante de El, pueden en esta vida estar absolutamente seguros de
                  hallarse en el estado de gracia, y pueden regocijarse en la esperanza de la gloria de Dios; y tal
                  esperanza nunca les avergonzará.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jer. 17:9; Mt. 7:21-23; Lc. 18:10-14; Jn. 8:41; Ef. 5:6,7; Gá. 6:3,7-9
                  <br />
                  2. Ro. 5:2,5; 8:16; 1 Jn. 2:3; 3:14,18,19,24; 5:13; 2 P. 1:10
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Esta certeza no es una mera persuasión conjetural y probable, fundada en una esperanza falible,
                  sino que es una seguridad infalible de fe
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>basada en la sangre y la justicia de Cristo reveladas en el evangelio;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y también en la evidencia interna de aquellas virtudes del Espíritu a las cuales se les hacen
                  promesas,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;y en el testimonio del Espíritu de adopción testificando con nuestro espíritu que somos hijos de
                  Dios;
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;y, como fruto suyo, mantiene el corazón humilde y santo.</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 5:2,5; He. 6:11,19,20; 1 Jn. 3:2,14; 4:16; 5:13,19,20
                  <br />
                  2. He. 6:17,18; 7:22; 10:14,19
                  <br />
                  3. Mt. 3:7-10; Mr. 1:15; 2 P. 1:4-11; 1 Jn. 2:3; 3:14,18,19,24; 5:13
                  <br />
                  4. Ro. 8:15,16; 1 Co. 2:12; Gá. 4:6,7
                  <br />
                  5. 1 Jn. 3:1-3
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Esta seguridad infalible no pertenece a la esencia de la fe hasta tal punto que un verdadero
                  creyente no pueda esperar mucho tiempo y luchar con muchas dificultades antes de ser partícipe de tal
                  seguridad;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sin embargo, siendo capacitado por el Espíritu para conocer las cosas que le son dadas gratuitamente
                  por Dios, puede alcanzarla,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;sin una revelación extraordinaria, por el uso adecuado de los medios; y por eso es el deber de
                  cada uno ser diligente para hacer firme su llamamiento y elección; para que así su corazón se ensanche
                  en la paz y en el gozo en el Espíritu Santo, en amor y gratitud a Dios, y en fuerza y alegría en los
                  deberes de la obediencia, que son los frutos propios de esta seguridad: así está de lejos esta
                  seguridad de inducir a los hombres a la disolución.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 16:30-34; 1 Jn. 5:13
                  <br />
                  2. Ro. 8:15,16; 1 Co. 2:12; Gá. 4:4-6 con 3:2; 1 Jn. 4:13; Ef. 3:17-19; He. 6:11,12; 2 P. 1:5-11
                  <br />
                  3. 2 P. 1:10; Sal. 119:32; Ro. 15:13; Neh. 8:10; 1 Jn. 4:19,16; Ro. 6:1,2,11-13; 14:17; Tit. 2:11-14;
                  Ef. 5:18
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. La seguridad de la salvación de los verdaderos creyentes puede ser, de diversas maneras,
                  zarandeada, disminuida e interrumpida; como por la negligencia en conservarla,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>por caer en algún pecado especial que hiera la conciencia y contriste al Espíritu,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;por alguna tentación repentina o vehemente,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;por retirarles Dios la luz de su rostro, permitiendo, aun a los que le temen, que caminen en
                  tinieblas, y no tengan luz;
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;sin embargo, nunca quedan destituidos de la simiente de Dios, y de la vida de fe, de aquel amor
                  de Cristo y de los hermanos, de aquella sinceridad de corazón y conciencia del deber, por los cuales,
                  mediante la operación del Espíritu, esta seguridad puede ser revivida con el tiempo; y por los cuales,
                  mientras tanto, los verdaderos creyentes son preservados de caer en total desesperación.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. He. 6:11,12; 2 P. 1:5-11
                  <br />
                  2. Sal. 51:8,12,14; Ef. 4:30
                  <br />
                  3. Sal. 30:7; 31:22; 77:7,8; 116:11
                  <br />
                  4. Is. 50:10
                  <br />
                  5. 1 Jn. 3:9; Lc. 22:32; Ro. 8:15,16; Gá. 4:5; Sal. 42:5,11
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 19, De la ley de Dios</p>

              <p>
                <span>1. Dios dio a Adán una ley de obediencia universal escrita en su corazón,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y un precepto en particular de no comer del fruto del árbol del conocimiento del bien y del mal;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;por lo cual le obligó a él y a toda su posteridad a una obediencia personal completa, exacta y
                  perpetua; prometió la vida por su cumplimiento de la ley, y amenazó con la muerte su infracción; y le
                  dotó también del poder y la capacidad para guardarla.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 1:27; Ec. 7:29; Ro. 2:12a, 14,15
                  <br />
                  2. Gn. 2:16,17
                  <br />
                  3. Gn. 2:16,17; Ro. 10:5; Gá. 3:10,12
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. La misma ley que primeramente fue escrita en el corazón del hombre continuó siendo una regla
                  perfecta de justicia después de la Caída;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>y fue dada por Dios en el monte Sinaí,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;en diez mandamientos, y escrita en dos tablas; los cuatro primeros mandamientos contienen
                  nuestros deberes para con Dios, y los otros seis, nuestros deberes para con los hombres.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Para el Cuarto Mandamiento, Gn. 2:3; Ex. 16; Gn. 7:4; 8:10,12; para
                  <br />
                  el Quinto Mandamiento, Gn. 37:10; para el Sexto Mandamiento, Gn. 4:3-15; para el
                  <br />
                  Séptimo Mandamiento, Gn. 12:17; para el Octavo Mandamiento, Gn. 31:30;
                  <br />
                  44:8; para el Noveno Mandamiento, Gn. 27:12; para el Décimo Mandamiento, Gn. 6:2; 13:10,11
                  <br />
                  2. Ro. 2:12a, 14,15
                  <br />
                  3. Ex. 32:15,16; 34:4,28; Dt. 10:4
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Además de esta ley, comúnmente llamada ley moral, agradó a Dios dar al pueblo de Israel leyes
                  ceremoniales que contenían varias ordenanzas típicas; en parte de adoración, prefigurando a Cristo,
                  sus virtudes, acciones, sufrimientos y beneficios;1y en parte proponiendo diversas instrucciones sobre
                  los deberes morales.2&nbsp;Todas aquellas leyes ceremoniales, habiendo sido prescritas solamente hasta
                  el tiempo de reformar las cosas, han sido abrogadas y quitadas por Jesucristo, el verdadero Mesías y
                  único legislador, quien fue investido con poder por parte del Padre para ese fin.3
                </span>
              </p>
              <p>
                <span>
                  1. He. 10:1; Col. 2:16,17
                  <br />
                  2. 1 Co. 5:7; 2 Co. 6:17; Jud. 23
                  <br />
                  3. Col. 2:14,16,17; Ef. 2:14-16
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. Dios también les dio a los israelitas diversas leyes civiles, que expiraron juntamente con el
                  Estado de aquel pueblo, no obligando ahora a ningún otro en virtud de aquella institución;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>solamente sus principios de equidad son utilizables en la actualidad.</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Lc. 21:20-24; Hch. 6:13,14; He. 9:18,19 con 8:7,13; 9:10; 10:1
                  <br />
                  2. 1 Co. 5:1; 9:8-10
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. La ley moral obliga para siempre a todos, tanto a los justificados como a los demás, a que se la
                  obedezca;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y esto no sólo en consideración a su contenido, sino también con respecto a la autoridad de Dios, el
                  Creador, quien la dio.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;Tampoco Cristo, en el evangelio, en ninguna manera cancela esta obligación sino que la refuerza
                  considerablemente.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 19:16-22; Ro. 2:14,15; 3:19,20; 6:14; 7:6; 8:3; 1 Ti. 1:8-11; Ro. 13:8-10;
                  <br />
                  1 Co. 7:19 con Gá. 5:6; 6:15; Ef. 4:25–6:4; Stg. 2:11,12
                  <br />
                  2. Stg. 2:10,11
                  <br />
                  3. Mt. 5:17-19; Ro. 3:31; 1 Co. 9:21; Stg. 2:8
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. Aunque los verdaderos creyentes no están bajo la ley como pacto de obras para ser por ella
                  justificados o condenados,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sin embargo ésta es de gran utilidad tanto para ellos como para otros, en que como regla de vida les
                  informa de la voluntad de Dios y de sus deberes, les dirige y obliga a andar en conformidad con ella,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;les revela también la pecaminosa contaminación de sus naturalezas, corazones y vidas; de tal
                  manera que, al examinarse a la luz de ella, puedan llegar a una convicción más profunda de su pecado,
                  a sentir humillación por él y odio contra él; junto con una visión más clara de la necesidad que
                  tienen de Cristo, y de la perfección de su obediencia.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;También la ley moral es útil para los regenerados a fin de restringir su corrupción, en cuanto
                  que prohíbe el pecado; y sus amenazas sirven para mostrar lo que aun sus pecados merecen, y qué
                  aflicciones pueden esperar por ellos en esta vida, aun cuando estén libres de la maldición y el puro
                  rigor de la ley.
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;Asimismo sus promesas manifiestan a los regenerados que Dios aprueba la obediencia y cuáles son
                  las bendiciones que pueden esperar por el cumplimiento de la misma,
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;aunque no como si la ley se lo debiera como pacto de obras;</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>
                  &nbsp;de manera que si alguien hace lo bueno y se abstiene de hacer lo malo porque la ley le manda lo
                  uno y le prohíbe lo otro, no por ello se demuestra que esté bajo la ley y no bajo la gracia.
                </span>
                <span className="s4">
                  <sup>7</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 13:39; Ro. 6:14; 8:1; 10:4; Gá. 2:16; 4:4,5
                  <br />
                  2. Ro. 7:12,22,25; Sal. 119:4-6; 1 Co. 7:19
                  <br />
                  3. Ro. 3:20; 7:7,9,14,24; 8:3; Stg. 1:23-25
                  <br />
                  4. Stg. 2:11; Sal. 119:101,104,128
                  <br />
                  5. Ef. 6:2,3; Sal. 37:11; Mt. 5:6; Sal. 19:11
                  <br />
                  6. Lc. 17:10
                  <br />
                  7. Véase el libro de Proverbios; Mt. 3:7; Lc. 13:3,5; Hch. 2:40; He. 11:26; 1 P. 3:8-13
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  7. Los usos de la ley ya mencionados tampoco son contrarios a la gracia del evangelio, sino que
                  concuerdan armoniosamente con él; pues el Espíritu de Cristo subyuga y capacita la voluntad del hombre
                  para que haga libre y alegremente lo que requiere la voluntad de Dios, revelada en la ley.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Gá. 3:21; Jer. 31:33; Ez. 36:27; Ro. 8:4; Tit. 2:14</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 20, Del Evangelio y del alcance de su gracia</p>

              <p>
                <span>
                  1. Habiendo sido quebrantado el pacto de obras por el pecado y habiéndose vuelto inútil para dar vida,
                  agradó a Dios dar la promesa de Cristo, la simiente de la mujer, como el medio para llamar a los
                  elegidos, y engendrar en ellos la fe y el arrepentimiento. En esta promesa, el evangelio, en cuanto a
                  su sustancia, fue revelado, y es en ella eficaz para la conversión y salvación de los pecadores.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Gn. 3:15 con Ef.2:12; Gá. 4:4; He. 11:13; Lc. 2:25,38; 23:51; Ro. 4:13-16; Gá. 3:15-22</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Esta promesa de Cristo, y la salvación por medio de El, es revelada solamente por la Palabra de
                  Dios.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  Tampoco las obras de la creación o la providencia, con la luz de la naturaleza, revelan a Cristo, o la
                  gracia que es por medio de El, ni siquiera en forma general u oscura;
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;mucho menos hacen que los hombres destituidos de la revelación de El por la promesa, o
                  evangelio, sean capacitados así para alcanzar la fe salvadora o el arrepentimiento.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 4:12; Ro. 10:13-15
                  <br />
                  2. Sal. 19; Ro. 1:18-23
                  <br />
                  3. Ro. 2:12a; Mt. 28:18-20; Lc. 24:46,47 con Hch. 17:29,30; Ro. 3:9-20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. La revelación del evangelio a los pecadores (hecha en diversos tiempos y distintas partes, con la
                  adición de promesas y preceptos para la obediencia requerida en aquél, en cuanto a las naciones y
                  personas a quienes es concedido), es meramente por la voluntad soberana y el beneplácito de Dios;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  no apropiándosela en virtud de promesa alguna referida al buen uso de las capacidades naturales de los
                  hombres, ni en virtud de la luz común recibida aparte de él, lo cual nadie hizo jamás ni puede hacer.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;Por tanto, en todas las épocas, la predicación del evangelio ha sido concedida a personas y
                  naciones, en cuanto a su extensión o restricción, con gran variedad, según el consejo de la voluntad
                  de Dios.
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 11:20
                  <br />
                  2. Ro. 3:10-12; 8:7,8
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. Aunque el evangelio es el único medio externo para revelar a Cristo y la gracia salvadora, y es,
                  como tal, completamente suficiente para este fin,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  sin embargo, para que los hombres que están muertos en sus delitos puedan nacer de nuevo, ser
                  vivificados o regenerados, es además necesaria una obra eficaz e invencible del Espíritu Santo en toda
                  el alma, con el fin de producir en ellos una nueva vida espiritual; sin ésta, ningún otro medio puede
                  efectuar su conversión a Dios.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 1:16,17
                  <br />
                  2. Jn. 6:44; 1 Co. 1:22-24; 2:14; 2 Co. 4:4,6
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>
                Capítulo 21, De la libertad cristiana y de la libertad de conciencia
              </p>

              <p>
                <span>
                  1. La libertad que Cristo ha comprado para los creyentes bajo el evangelio consiste en su libertad de
                  la culpa del pecado, de la ira condenatoria de Dios y de la severidad y maldición de la ley,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y en ser librados de este presente siglo malo, de la servidumbre de Satanás y del dominio del pecado,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;del mal de las aflicciones, del temor y aguijón de la muerte, de la victoria del sepulcro y de
                  la condenación eterna,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;y también consiste en su libre acceso a Dios, y en rendirle obediencia a El, no por temor
                  servil, sino con un amor filial y una mente dispuesta.
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
              </p>
              <p>
                <span>Todo esto era sustancialmente común también a los creyentes bajo la ley;</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;pero bajo el Nuevo Testamento la libertad de los cristianos se ensancha mucho más porque están
                  libres del yugo de la ley ceremonial a que estaba sujeta la Iglesia judaica, y tienen ahora mayor
                  confianza para acercarse al trono de gracia, y experiencias más plenas del libre Espíritu de Dios que
                  aquellas de las que participaron generalmente los creyentes bajo la ley.
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 3:36; Ro. 8:33; Gá. 3:13
                  <br />
                  2. Gá. 1:4; Ef. 2:1-3; Col. 1:13; Hch. 26:18; Ro. 6:14-18; 8:3
                  <br />
                  3. Ro. 8:28; 1 Co. 15:54-57; 1 Ts. 1:10; He. 2:14,15
                  <br />
                  4. Ef. 2:18; 3:12; Ro. 8:15; 1 Jn. 4:18
                  <br />
                  5. Jn. 8:32; Sal. 19:7-9; 119:14,24,45,47,48,72,97; Ro. 4:5-11; Gá. 3:9; He. 11:27,33,34
                  <br />
                  6. Jn. 1:17; He. 1:1,2a; 7:19,22; 8:6; 9:23; 11:40; Gá. 2:11ss.; 4:1-3;
                  <br />
                  Col. 2:16,17; He. 10:19-21; Jn. 7:38,39
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>2. Sólo Dios es el Señor de la conciencia,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  y la ha hecho libre de las doctrinas y los mandamientos de los hombres que sean en alguna manera
                  contrarios a su Palabra o que no estén contenidos en ésta.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;Así que, creer tales doctrinas u obedecer tales mandamientos por causa de la conciencia es
                  traicionar la verdadera libertad de conciencia,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;y el requerir una fe implícita y una obediencia ciega y absoluta es destruir la libertad de
                  conciencia y también la razón.
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Stg. 4:12; Ro. 14:4; Gá. 5:1
                  <br />
                  2. Hch. 4:19; 5:29; 1 Co. 7:23; Mt. 15:9
                  <br />
                  3. Col. 2:20,22,23; Gá. 1:10; 2:3-5; 5:1
                  <br />
                  4. Ro. 10:17; 14:23; Hch. 17:11; Jn. 4:22; 1 Co. 3:5; 2 Co. 1:24
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Aquellos que bajo el pretexto de la libertad cristiana practican cualquier pecado o abrigan
                  cualquier concupiscencia, al pervertir así el propósito principal de la gracia del evangelio para su
                  propia destrucción,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  destruyen completamente, por tanto, el propósito de la libertad cristiana, que consiste en que, siendo
                  librados de las manos de todos nuestros enemigos, sirvamos al Señor sin temor, en santidad y justicia
                  delante de El, todos los días de nuestra vida.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 6:1,2
                  <br />
                  2. Lc. 1:74,75; Ro. 14:9; Gá. 5:13; 2 P. 2:18,21
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 22, De la adoración religiosa y del día de reposo</p>

              <p>
                <span>
                  1. La luz de la naturaleza muestra que hay un Dios, que tiene señorío y soberanía sobre todo; es
                  justo, bueno y hace bien a todos; y que, por tanto, debe ser temido, amado, alabado, invocado, creído,
                  y servido con toda el alma, con todo el corazón y con todas las fuerzas.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  Pero el modo aceptable de adorar al verdadero Dios está instituido por El mismo, y está de tal manera
                  limitado por su propia voluntad revelada que no se debe adorar a Dios conforme a las imaginaciones e
                  invenciones de los hombres o a las sugerencias de Satanás, ni bajo ninguna representación visible ni
                  en ningún otro modo no prescrito en las Santas Escrituras.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jer. 10:7; Mr. 12:33
                  <br />
                  2. Gn. 4:1-5; Ex. 20:4-6; Mt. 15:3,8,9; 2 R. 16:10-18; Lv. 10:1-3; Dt. 17:3; 4:2;
                  <br />
                  12:29-32; Jos. 1:7; 23:6-8; Mt. 15:13; Col. 2:20-23; 2 Ti. 3:15-17
                </span>
              </p>
              <p>
                <span>
                  2. La adoración religiosa ha de tributarse a Dios Padre, Hijo y Espíritu Santo, y a El solamente;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>no a los ángeles, ni a los santos, ni a ninguna otra criatura;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y desde la Caída, no sin un mediador; ni por la mediación de ningún otro, sino solamente de
                  Cristo.
                </span>
                <span className="s4">
                  <sup>3&nbsp;</sup>
                </span>
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1. Mt. 4:9,10; Jn. 5:23; 2 Co.
                  13:14
                  <br />
                  2. Ro. 1:25; Col. 2:18; Ap. 19:10
                  <br />
                  3. Jn. 14:6; Ef. 2:18; Col. 3:17; 1 Ti. 2:5
                </span>
              </p>
              <p>
                <span>
                  3. Siendo la oración, con acción de gracias, una parte de la adoración natural, la exige Dios de todos
                  los hombres.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  Pero para que pueda ser aceptada, debe hacerse en el nombre del Hijo,2 con la ayuda del Espíritu,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;conforme a su voluntad,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;con entendimiento, reverencia, humildad, fervor, fe, amor y perseverancia;</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;y cuando se hace con otros, en una lengua conocida.</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Sal. 95:1-7; 100:1-5
                  <br />
                  2. Jn. 14:13,14
                  <br />
                  3. Ro. 8:26
                  <br />
                  4. 1 Jn. 5:14
                  <br />
                  5. Sal. 47:7; Ec. 5:1,2; He. 12:28; Gn. 18:27; Stg. 5:16; 1:6,7; Mr. 11:24;Mt. 6:12,14,15; Col. 4:2;
                  Ef. 6:18
                  <br />
                  6. 1 Co. 14:13-19,27,28
                </span>
              </p>
              <p>
                <span>
                  4. La oración ha de hacerse por cosas lícitas, y a favor de toda clase de personas vivas, o que
                  vivirán más adelante;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  pero no a favor de los muertos ni de aquellos de quienes se pueda saber que han cometido el pecado de
                  muerte.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 5:14; 1 Ti. 2:1,2; Jn. 17:20
                  <br />
                  2. 2 S. 12:21-23; Lc. 16:25,26; Ap. 14:13; 1 Jn. 5:16
                </span>
              </p>
              <p>
                <span>5. La lectura de las Escrituras,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>la predicación y la audición de la Palabra de Dios,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;la instrucción y la amonestación los unos a los otros por medio de salmos, himnos y cantos
                  espirituales, el cantar con gracia en el corazón al Señor,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;como también la administración del bautismo</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;y la Cena del Señor:</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;todas estas cosas son parte de la adoración religiosa a Dios que ha de realizarse en obediencia
                  a El, con entendimiento, fe, reverencia y temor piadoso; además, la humillación solemne,
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>
                  &nbsp;con ayunos, y las acciones de gracia en ocasiones especiales, han de usarse de una manera santa
                  y piadosa.
                </span>
                <span className="s4">
                  <sup>7</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 15:21; 1 Ti. 4:13; Ap. 1:3
                  <br />
                  2. 2 Ti. 4:2; Lc. 8:18
                  <br />
                  3. Col. 3:16; Ef. 5:19
                  <br />
                  4. Mt. 28:19,20
                  <br />
                  5. 1 Co. 11:26
                  <br />
                  6. Est. 4:16; Jl. 2:12; Mt. 9:15; Hch. 13:2,3; 1 Co. 7:5
                  <br />
                  7. Ex. 15:1-19; Sal. 107
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. Ahora, bajo el evangelio, ni la oración ni ninguna otra parte de la adoración religiosa están
                  limitadas a un lugar, ni son más aceptables por el lugar en que se realizan, o hacia el cual se
                  dirigen;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>sino que Dios ha de ser adorado en todas partes en espíritu y en verdad;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;tanto en cada familia en particular</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;diariamente,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;como cada uno en secreto por sí solo;</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;así como de una manera más solemne en las reuniones públicas,</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>
                  &nbsp;las cuales no han de descuidarse ni abandonarse voluntariamente o por negligencia, cuando Dios
                  por su Palabra o providencia nos llama a ellas.
                </span>
                <span className="s4">
                  <sup>7</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 4:21
                  <br />
                  2. Mal. 1:11; 1 Ti. 2:8; Jn. 4:23,24
                  <br />
                  3. Dt. 6:6,7; Job 1:5; 1 P. 3:7
                  <br />
                  4. Mt. 6:11
                  <br />
                  5. Mt. 6:6
                  <br />
                  6. Sal. 84:1,2,10; Mt. 18:20; 1 Co. 3:16; 14:25; Ef. 2:21,22
                  <br />
                  7. Hch. 2:42; He. 10:25
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  7. Así como es la ley de la naturaleza que, en general, una proporción de tiempo, por designio de Dios
                  se dedique a la adoración de Dios, así en su Palabra, por un mandamiento positivo, moral y perpetuo
                  que obliga a todos los hombres en todas las épocas, Dios ha señalado particularmente un día de cada
                  siete como día de reposo, para que sea guardado santo para El;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  el cual desde el principio del mundo hasta la resurrección de Cristo fue el último día de la semana y
                  desde la resurrección de Cristo fue cambiado al primer día de la semana, que es llamado el Día del
                  Señor y debe ser perpetuado hasta el fin del mundo como el día de reposo cristiano, siendo abolida la
                  observancia del último día de la semana.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 2:3; Ex. 20:8-11; Mr. 2:27,28; Ap. 1:10
                  <br />
                  2. Jn. 20:1; Hch. 2:1; 20:7; 1 Co. 16:1; Ap. 1:10; Col. 2:16,17
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  8. El día de reposo se guarda santo para el Señor cuando los hombres, después de la debida preparación
                  de su corazón y arreglados de antemano todos sus asuntos cotidianos, no solamente observan un santo
                  descanso durante todo el día de sus propias labores, palabras y pensamientos
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  acerca de sus ocupaciones y diversiones seculares; sino que también se dedican todo el tiempo al
                  ejercicio público y privado de la adoración de Dios, y a los deberes de necesidad y de misericordia.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1. Ex. 20:8-11; Neh. 13:15-22;
                  Is. 58:13,14; Ap. 1:10
                  <br />
                  2. Mt. 12:1-13; Mr. 2:27,28
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 23, De los juramentos y votos lícitos</p>

              <p>
                <span>
                  1. Un juramento lícito es una parte de la adoración religiosa en la cual la persona que jura con
                  verdad, justicia y juicio, solemnemente pone a Dios como testigo de lo que jura, y para que le juzgue
                  conforme a la verdad o la falsedad de lo que jura.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Dt. 10:20; Ex. 20:7; Lv. 19:12; 2 Cr. 6:22,23; 2 Co. 1:23</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Sólo por el nombre de Dios deben jurar los hombres, y al hacerlo han de usarlo con todo temor santo
                  y reverencia. Por lo tanto, jurar vana o temerariamente por este nombre glorioso y temible, o
                  simplemente el jurar por cualquier otra cosa, es pecaminoso y debe aborrecerse.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;Sin embargo, como en asuntos de peso y de importancia, para confirmación de la verdad y para
                  poner fin a toda contienda, un juramento está justificado por la Palabra de Dios, por eso, cuando una
                  autoridad legítima exija un juramento lícito para tales asuntos, este juramento debe hacerse.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Dt. 6:13; 28:58; Ex. 20:7; Jer. 5:7
                  <br />
                  2. He. 6:13-16; Gn. 24:3; 47:30,31; 50:25; 1 R. 17:1; Neh. 13:25; 5:12; Esd. 10:5;
                  <br />
                  Nm. 5:19,21; 1 R. 8:31; Ex. 22:11; Is. 45:23; 65:16; Mt. 26:62-64; Ro. 1:9; 2 Co. 1:23; Hch. 18:18
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Todo aquel que haga un juramento justificado por la Palabra de Dios debe considerar seriamente la
                  gravedad de un acto tan solemne, y no afirmar en el mismo nada sino lo que sepa que es verdad, porque
                  por juramentos temerarios, falsos y vanos se provoca al Señor y por razón de ello la tierra se enluta.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;1. Ex. 20:7; Lv. 19:12; Nm. 30:2; Jer. 4:2; 23:10</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. Un juramento debe hacerse en el sentido claro y común de las palabras, sin equívocos o reservas
                  mentales.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Sal. 24:4; Jer. 4:2</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>5. Un voto (que no ha de hacerse a ninguna criatura, sino sólo a Dios</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>) ha de hacerse y cumplirse con todo cuidado piadoso y fidelidad;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;pero los votos monásticos papistas de celibato perpetuo, pretendida pobreza y obediencia a las
                  reglas eclesiásticas, distan tanto de ser grados de perfección superior que son más bien trampas
                  supersticiosas y pecaminosas en las que ningún cristiano debe enredarse.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Nm. 30:2,3; Sal. 76:11; Jer. 44:25,26
                  <br />
                  2. Nm. 30:2; Sal. 61:8; 66:13,14; Ec. 5:4-6; Is. 19:21
                  <br />
                  3. 1 Co. 6:18 con 7:2,9; 1 Ti. 4:3; Ef. 4:28; 1 Co. 7:23; Mt. 19:11,12
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 24, De las autoridades civiles</p>

              <p>
                <span>
                  1. Dios, el supremo Señor y Rey del mundo entero, ha instituido autoridades civiles para estarle
                  sujetas y gobernar al pueblo
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;para la gloria de Dios y el bien público;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y con este fin, les ha provisto con el poder de la espada, para la defensa y el ánimo de los que
                  hacen lo bueno, y para el castigo de los malhechores.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Sal. 82:1; Lc. 12:48; Ro. 13:1-6; 1 P. 2:13,14
                  <br />
                  2. Gn. 6:11-13 con 9:5,6; Sal. 58:1,2; 72:14; 82:1-4; Pr. 21:15; 24:11,12;
                  <br />
                  29:14,26; 31:5; Ez. 7:23; 45:9; Dn. 4:27; Mt. 22:21; Ro. 13:3,4; 1 Ti. 2:2; 1 P. 2:14
                  <br />
                  3. Gn. 9:6; Pr. 16:14; 19:12; 20:2; 21:15; 28:17; Hch. 25:11; Ro. 13:4; 1 P. 2:14
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Es lícito para los cristianos aceptar cargos dentro de la autoridad civil cuando sean llamados para
                  ello;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;en el desempeño de dichos cargos deben mantener especialmente la justicia y la paz, según las
                  buenas leyes de cada reino y Estado; y así, con este propósito, ahora bajo el Nuevo Testamento, pueden
                  hacer lícitamente la guerra en ocasiones justas y necesarias.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ex. 22:8,9,28,29; Daniel; Nehemías; Pr. 14:35; 16:10,12; 20:26,28; 25:2; 28:15,16; 29:4,14; 31:4,5;
                  Ro. 13:2,4,6
                  <br />
                  2. Lc. 3:14; Ro. 13:4
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Habiendo sido instituidas por Dios las autoridades civiles con los fines ya mencionados, se les
                  debe rendir sujeción
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;en el Señor en todas las cosas lícitas</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;que manden, no sólo por causa de la ira sino también de la conciencia; y debemos ofrecer
                  súplicas y oraciones a favor de los reyes y de todos los que están en autoridad, para que bajo su
                  gobierno podamos vivir una vida tranquila y sosegada en toda piedad y honestidad.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Pr. 16:14,15; 19:12; 20:2; 24:21,22; 25:15; 28:2; Ro. 13:1-7; Tit. 3:1; 1 P. 2:13,14
                  <br />
                  2. Dn. 1:8; 3:4-6,16-18; 6:5-10,22; Mt. 22:21; Hch. 4:19,20; 5:29
                  <br />
                  3. Jer. 29:7; 1 Ti. 2:1-4
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 25, Del matrimonio</p>

              <p>
                <span>
                  1. El matrimonio ha de ser entre un hombre y una mujer; no es lícito para ningún hombre tener más de
                  una esposa, ni para ninguna mujer tener más de un marido.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Gn. 2:24 con Mt. 19:5,6; 1 Ti. 3:2; Tit. 1:6</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>2. El matrimonio fue instituido para la mutua ayuda de esposo y esposa;</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;para multiplicar el género humano por medio de una descendencia legítima</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;y para evitar la impureza.</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 2:18; Pr. 2:17; Mal. 2:14
                  <br />
                  2. Gn. 1:28; Sal. 127:3-5; 128:3,4
                  <br />
                  3. 1 Co. 7:2,9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Pueden casarse lícitamente toda clase de personas capaces de dar su consentimiento en su sano
                  juicio;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;sin embargo, es deber de los cristianos casarse en el Señor. Y, por tanto, los que profesan la
                  verdadera fe no deben casarse con incrédulos o idólatras; ni deben los que son piadosos unirse en yugo
                  desigual, casándose con los que sean malvados en sus vidas o que sostengan herejías condenables.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Co. 7:39; 2 Co. 6:14; He. 13:4; 1 Ti. 4:3
                  <br />
                  2. 1 Co. 7:39; 2 Co. 6:14
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. El matrimonio no debe contraerse dentro de los grados de consanguinidad o afinidad prohibidos en la
                  Palabra, ni pueden tales matrimonios incestuosos legalizarse jamás por ninguna ley humana, ni por el
                  consentimiento de las partes, de tal manera que esas personas puedan vivir juntas como marido y mujer.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Lv. 18:6-18; Am. 2:7; Mr. 6:18; 1 Co. 5:1</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 26, De la Iglesia</p>

              <p>
                <span>1. La Iglesia católica o universal,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;que (con respecto a la obra interna del Espíritu y la verdad de la gracia) puede llamarse
                  invisible, se compone del número completo de los elegidos que han sido, son o serán reunidos en uno
                  bajo Cristo, su cabeza; y es la esposa, el cuerpo, la plenitud de Aquel que llena todo en todos.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 16:18; 1 Co. 12:28; Ef. 1:22; 4:11-15; 5:23-25,27,29,32; Col. 1:18,24; He. 12:23
                  <br />
                  2. Ef. 1:22; 4:11-15; 5:23-25,27,29,32; Col. 1:18,24; Ap. 21:9-14
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Todas las personas en todo el mundo que profesan la fe del evangelio y obediencia a Dios por Cristo
                  conforme al mismo, que no destruyan su propia profesión mediante errores fundamentales o conductas
                  impías, son y pueden ser llamados santos visibles;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;y de tales personas todas las congregaciones locales deben estar compuestas.</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Co. 1:2; Ro. 1:7,8; Hch. 11:26; Mt. 16:18; 28:15-20; 1 Co. 5:1-9
                  <br />
                  2. Mt. 18:15-20; Hch. 2:37-42; 4:4; Ro. 1:7; 1 Co. 5:1-9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>3. Las iglesias más puras bajo el cielo están sujetas a la impureza y al error,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;y algunas han degenerado tanto que han llegado a ser no iglesias de Cristo sino sinagogas de
                  Satanás.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;Sin embargo, Cristo siempre ha tenido y siempre tendrá un reino en este mundo, hasta el fin del
                  mismo, compuesto de aquellos que creen en El y profesan su nombre.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Co. 1:11; 5:1; 6:6; 11:17-19; 3 Jn. 9,10; Ap. 2 y 3<br />
                  2. Ap. 2:5 con 1:20; 1 Ti. 3:14,15; Ap. 18:2
                  <br />
                  3. Mt. 16:18; 24:14; 28:20; Mr. 4:30-32; Sal. 72:16-18; 102:28; Is. 9:6,7; Ap. 12:17; 20:7-9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. La Cabeza de la Iglesia es el Señor Jesucristo, en quien, por el designio del Padre, todo el poder
                  requerido para el llamamiento, el establecimiento, el orden o el gobierno de la Iglesia, está suprema
                  y soberanamente investido.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  No puede el papa de Roma ser cabeza de ella en ningún sentido, sino que él es aquel Anticristo, aquel
                  hombre de pecado e hijo de perdición, que se ensalza en la Iglesia contra Cristo y contra todo lo que
                  se llama Dios, a quien el Señor destruirá con el resplandor de su venida.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Col. 1:18; Ef. 4:11-16; 1:20-23; 5:23-32; 1 Co. 12:27,28; Jn. 17:1-3;
                  <br />
                  Mt. 28:18-20; Hch. 5:31; Jn. 10:14-16
                  <br />
                  2. 2 Ts. 2:2-9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. En el ejercicio de este poder que le ha sido confiado, el Señor Jesús, a través del ministerio de
                  su Palabra y por su Espíritu, llama a sí mismo del mundo a aquellos que le han sido dados por su Padre
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;para que anden delante de El en todos los caminos de la obediencia que El les prescribe en su
                  Palabra.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;A los así llamados, El les ordena andar juntos en congregaciones concretas, o iglesias, para su
                  edificación mutua y la debida observancia del culto público, que El requiere de ellos en el mundo.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 10:16,23; 12:32; 17:2; Hch. 5:31,32
                  <br />
                  2. Mt. 28:20
                  <br />
                  3. Mt. 18:15-20; Hch. 14:21-23; Tit. 1:5; 1 Ti. 1:3; 3:14-16; 5:17-22
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. Los miembros de estas iglesias son santos por su llamamiento, y en una forma visible manifiestan y
                  evidencian (por su profesión de fe y su conducta) su obediencia al llamamiento de Cristo;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;y voluntariamente acuerdan andar juntos, conforme al designio de Cristo, dándose a sí mismos al
                  Señor y mutuamente, por la voluntad de Dios, profesando sujeción a los preceptos del evangelio.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 28:18-20; Hch. 14:22,23; Ro. 1:7; 1 Co. 1:2 con los vv. 13-17; 1 Ts. 1:1 con los vv. 2-10; Hch.
                  2:37-42; 4:4; 5:13,14
                  <br />
                  2. Hch. 2:41,42; 5:13,14; 2 Co. 9:13
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  7. A cada una de estas iglesias así reunidas, el Señor, conforme a su mente declarada en su Palabra,
                  ha dado todo el poder y autoridad en cualquier sentido necesarios para realizar ese orden en la
                  adoración y en la disciplina que El ha instituido para que lo guarden; juntamente con mandatos y
                  reglas para el ejercicio propio y correcto y la ejecución del mencionado poder.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Mt. 18:17-20; 1 Co. 5:4,5,13; 2 Co. 2:6-8</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  8. Una iglesia local, reunida y completamente organizada de acuerdo a la mente de Cristo, está
                  compuesta por oficiales y miembros; y los oficiales designados por Cristo para ser escogidos y
                  apartados por la iglesia (así llamada y reunida), para la particular administración de las ordenanzas
                  y el ejercicio del poder o el deber, que El les confía o al que les llama, para que continúen hasta el
                  fin del mundo, son los obispos o ancianos, y los diáconos.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Fil. 1:1; 1 Ti. 3:1-13; Hch. 20:17,28; Tit. 1:5-7; 1 P. 5:2</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  9. La manera designada por Cristo para el llamamiento de cualquier persona que ha sido cualificada y
                  dotada por el Espíritu Santo
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;para el oficio de obispo o anciano en una iglesia, es que sea escogido para el mismo por común
                  sufragio de la iglesia misma,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;y solemnemente apartado mediante ayuno y oración con la imposición de manos de los ancianos de
                  la iglesia, si es que hay algunos constituidos anteriormente en ella;
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;y para un diácono, que sea escogido por el mismo sufragio y apartado mediante oración y la misma
                  imposición de manos.
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ef. 4:11; 1 Ti. 3:1-13
                  <br />
                  2. Hch. 6:1-7; 14:23 con Mt. 18:17-20; 1 Co. 5:1-13
                  <br />
                  3. 1 Ti. 4:14; 5:22
                  <br />
                  4. Hch. 6:1-7
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  10. Siendo la obra de los pastores atender constantemente al servicio de Cristo, en sus iglesias, en
                  el ministerio de la Palabra y la oración, velando por sus almas, como aquellos que han de dar cuenta a
                  El,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;es la responsabilidad de las iglesias a las que ellos ministran darles no solamente todo el
                  respeto debido, sino compartir también con ellos todas sus cosas buenas, según sus posibilidades,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;de manera que tengan una provisión adecuada, sin que tengan que enredarse en actividades
                  seculares,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;y puedan también practicar la hospitalidad hacia los demás.</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;Esto lo requiere la ley de la naturaleza y el mandato expreso de nuestro Señor Jesús, quien ha
                  ordenado que los que predican el evangelio vivan del evangelio.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 6:4; 1 Ti. 3:2; 5:17; He. 13:17
                  <br />
                  2. 1 Ti. 5:17,18; 1 Co. 9:14; Gá. 6:6,7
                  <br />
                  3. 2 Ti. 2:4
                  <br />
                  4. 1 Ti. 3:2
                  <br />
                  5. 1 Co. 9:6-14; 1 Ti. 5:18
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  11. Aunque sea la responsabilidad de los obispos o pastores de las iglesias, según su oficio, estar
                  constantemente dedicados a la predicación de la Palabra, sin embargo la obra de predicar la Palabra no
                  está tan particularmente limitada a ellos, sino que otros también dotados y cualificados por el
                  Espíritu Santo para ello y aprobados y llamados por la iglesia, pueden y deben desempeñarla.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Hch. 8:5; 11:19-21; 1 P. 4:10,11</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  12. Todos los creyentes están obligados a unirse a iglesias locales cuando y donde tengan la
                  oportunidad de hacerlo. Asimismo todos aquellos que son admitidos a los privilegios de una iglesia
                  también están sujetos a la disciplina y el gobierno de la misma iglesia, conforme a la norma de
                  Cristo.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. 1 Ts. 5:14; 2 Ts. 3:6,14,15; 1 Co. 5:9-13; He. 13:17</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  13. Ningún miembro de iglesia, en base a alguna ofensa recibida, habiendo cumplido el deber requerido
                  de él hacia la persona que le ha ofendido, debe perturbar el orden de la iglesia, o ausentarse de las
                  reuniones de la iglesia o de la administración de ninguna de las ordenanzas en base a tal ofensa de
                  cualquier otro miembro, sino que debe esperar en Cristo mientras prosigan las actuaciones de la
                  iglesia.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp; &nbsp; 1. Mt. 18:15-17; Ef. 4:2,3; Col. 3:12-15; 1 Jn. 2:7-11,18,19; Ef. 4:2,3; Mt. 28:20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  14. Puesto que cada iglesia, y todos sus miembros, están obligados a orar continuamente por el bien y
                  la prosperidad de todas las iglesias de Cristo en todos los lugares, y en todas las ocasiones ayudar a
                  cada una dentro de los límites de sus áreas y vocaciones, en el ejercicio de sus dones y virtudes,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;así las iglesias, cuando estén establecidas por la providencia de Dios de manera que puedan
                  gozar de la oportunidad y el beneficio de ello,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>deben tener comunión entre sí, para su paz, crecimiento en amor y edificación mutua.</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 13:34,35; 17:11,21-23; Ef. 4:11-16; 6:18; Sal. 122:6; Ro. 16:1-3; 3 Jn. 8-10 con 2 Jn. 5-11;
                  Ro. 15:26; 2 Co. 8:1-4,16-24; 9:12-15; Col. 2:1 con 1:3,4,7 y 4:7,12
                  <br />
                  2. Gá. 1:2,22; Col. 4:16; Ap. 1:4; Ro. 16:1,2; 3 Jn. 8-10
                  <br />
                  3. 1 Jn. 4:1-3 con 2 y 3 Juan; Ro. 16:1-3; 2 Co. 9:12-15; Jos. 22
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 27, De la comunión de los santos</p>

              <p>
                <span>1.0 15.00 Todos los santos que están unidos a Jesucristo,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;su cabeza, por su Espíritu y por la fe</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;(aunque no por ello vengan a ser una persona con El</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>), participan en sus virtudes, padecimientos, muerte, resurrección y gloria;</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;y, estando unidos unos a otros en amor, participan mutuamente de sus dones y virtudes,
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>
                  &nbsp;y están obligados al cumplimiento de tales deberes, públicos y privados, de manera ordenada, que
                  conduzcan a su mutuo bien, tanto en el hombre interior como en el exterior.
                </span>
                <span className="s4">
                  <sup>6</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ef. 1:4; Jn. 17:2,6; 2 Co. 5:21; Ro. 6:8; 8:17; 8:2; 1 Co. 6:17; 2 P. 1:4
                  <br />
                  2. Ef. 3:16,17; Gá. 2:20; 2 Co. 3:17,18
                  <br />
                  3. 1 Co. 8:6; Col. 1:18,19; 1 Ti. 6:15,16; Is. 42:8; Sal. 45:7; He. 1:8,9
                  <br />
                  4. 1 Jn. 1:3; Jn. 1:16; 15:1-6; Ef. 2:4-6; Ro. 4:25; 6:1-6; Fil. 3:10; Col. 3:3,4
                  <br />
                  5. Jn. 13:34,35; 14:15; Ef. 4:15; 1 P. 4:10; Ro. 14:7,8; 1 Co. 3:21-23; 12:7,25-27
                  <br />
                  6. Ro. 1:12; 12:10-13; 1 Ts. 5:11,14; 1 P. 3:8; 1 Jn. 3:17,18; Gá. 6:10
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Los santos, por su profesión, están obligados a mantener entre sí un compañerismo y comunión santos
                  en la adoración a Dios y en el cumplimiento de los otros servicios espirituales que tiendan a su
                  edificación mutua,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;así como a socorrerse los unos a los otros en las cosas externas según sus posibilidades y
                  necesidades.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;Según la norma del evangelio, aunque esta comunión deba ejercerse especialmente en las
                  relaciones en que se encuentren, ya sea en las familias o en las iglesias,
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;no obstante, debe extenderse, según Dios dé la oportunidad, a toda la familia de la fe, es
                  decir, a todos los que en todas partes invocan el nombre del Señor Jesús.
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>
                  &nbsp;Sin embargo, su comunión mutua como santos no quita ni infringe el derecho o la propiedad que
                  cada hombre tiene sobre sus bienes y posesiones.
                </span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. He. 10:24,25; 3:12,13
                  <br />
                  2. Hch. 11:29,30; 2 Co. 8,9; Gá. 2; Ro. 15
                  <br />
                  3. 1 Ti. 5:8,16; Ef. 6:4; 1 Co. 12:27
                  <br />
                  4. Hch. 11:29,30; 2 Co. 8,9; Gá. 2; 6:10; Ro. 15
                  <br />
                  5. Hch. 5:4; Ef. 4:28; Ex. 20:15
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. En casos de dificultades o diferencias respecto a la doctrina o el gobierno de la iglesia, en que
                  bien las iglesias en general o bien una sola iglesia están concernidas en su paz, unión y edificación;
                  o uno o varios miembros de una iglesia son dañados por procedimientos disciplinarios que no sean de
                  acuerdo a la verdad y al orden, es conforme a la mente de Cristo que muchas iglesias que tengan
                  comunión entre sí, se reúnan a través de sus representantes para considerar y dar su consejo sobre los
                  asuntos en disputa, para informar a todas las iglesias concernidas.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;Sin embargo, a los representantes congregados no se les entrega ningún poder eclesiástico
                  propiamente dicho ni jurisdicción sobre las iglesias mismas para ejercer disciplina sobre cualquiera
                  de ellas o sus miembros, o para imponer sus decisiones sobre ellas o sus oficiales.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Gá. 2:2; Pr. 3:5-7; 12:15; 13:10
                  <br />
                  2. 1 Co. 7:25,36,40; 2 Co. 1:24; 1 Jn. 4:1
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 28, Del bautismo y la Cena del Señor</p>

              <p>
                <span>
                  1. El bautismo y la Cena del Señor son ordenanzas que han sido positiva y soberanamente instituidas
                  por el Señor Jesús, el único legislador,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;para que continúen en su Iglesia hasta el fin del mundo.</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 28:19,20; 1 Co. 11:24,25
                  <br />
                  2. Mt. 28:18-20; Ro. 6:3,4; 1 Co. 1:13-17; Gá. 3:27; Ef. 4:5; Col. 2:12; 1 P. 3:21; 1 Co. 11:26; Lc.
                  22:14-20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Estas santas instituciones han de ser administradas solamente por aquellos que estén cualificados y
                  llamados para ello, según la comisión de Cristo.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. Mt. 24:45-51; Lc. 12:41-44; 1 Co. 4:1; Tit. 1:5-7</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 29, Del bautismo</p>

              <p>
                <span>
                  1. El bautismo es una ordenanza del Nuevo Testamento instituida por Jesucristo, con el fin de ser para
                  la persona bautizada una señal de su comunión con El en su muerte y resurrección, de estar injertado
                  en El,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;de la remisión de pecados</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;y de su entrega a Dios por medio de Jesucristo para vivir y andar en novedad de vida.</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 6:3-5; Col. 2:12; Gá. 3:27
                  <br />
                  2. Mr. 1:4; Hch. 22:16
                  <br />
                  3. Ro. 6:4
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. Los que realmente profesan arrepentimiento para con Dios y fe en nuestro Señor Jesucristo y
                  obediencia a El son los únicos sujetos adecuados de esta ordenanza.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 3:1-12; Mr. 1:4-6; Lc. 3:3-6; Mt. 28:19,20; Mr. 16:15,16; Jn. 4:1,2;
                  <br />
                  1 Co. 1:13-17; Hch. 2:37-41; 8:12,13,36-38; 9:18; 10:47,48; 11:16;
                  <br />
                  15:9; 16:14,15,31-34; 18:8; 19:3-5; 22:16; Ro. 6:3,4; Gá. 3:27; Col. 2:12;
                  <br />1 P. 3:21; Jer. 31:31-34; Fil. 3:3; Jn. 1:12,13; Mt. 21:43
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. El elemento exterior que debe usarse en esta ordenanza es el agua, en la cual ha de ser bautizada
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;la persona en el nombre del Padre, del Hijo y del Espíritu Santo.</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>1. Mt. 3:11; Hch. 8:36,38; 22:16</span>
              </p>
              <p>
                <span>2. Mt. 28:18-20</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  4. La inmersión de la persona en el agua es necesaria para la correcta administración de esta
                  ordenanza.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 2 R. 5:14; Sal. 69:2; Is. 21:4; Mr. 1:5,8-9; Jn. 3:23; Hch. 8:38; Ro. 6:4;
                  <br />
                  Col. 2:12; Mr. 7:3,4; 10:38,39; Lc. 12:50; 1 Co. 10:1,2; Mt. 3:11; Hch. 1:5,8; 2:1-4,17
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 30, De la Cena del Señor</p>

              <p>
                <span>1. La Cena del Señor Jesús fue instituida por El la misma noche en que fue entregado,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;para que se observara en sus Iglesias</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;hasta el fin del mundo,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;para el recuerdo perpetuo y para la manifestación del sacrificio de sí mismo en su muerte,
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;para confirmación de la fe de los creyentes en todos los beneficios de la misma,</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
                <span>&nbsp;para su alimentación espiritual y crecimiento en El,</span>
                <span className="s4">
                  <sup>6</sup>
                </span>
                <span>&nbsp;para un mayor compromiso en todas las obligaciones que le deben a El,</span>
                <span className="s4">
                  <sup>7</sup>
                </span>
                <span>&nbsp;y para ser un vínculo y una prenda de su comunión con El y entre ellos mutuamente.</span>
                <span className="s4">
                  <sup>8</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Co. 11:23-26; Mt. 26:20-26; Mr. 14:17-22; Lc. 22:19-23
                  <br />
                  2. Hch. 2:41,42; 20:7; 1 Co. 11:17-22,33,34
                  <br />
                  3. Mr. 14:24,25; Lc. 22:17-22; 1 Co. 11:24-26
                  <br />
                  4. 1 Co. 11:24-26; Mt. 26:27,28; Lc. 22:19,20
                  <br />
                  5. Ro. 4:11
                  <br />
                  6. Jn. 6:29,35,47-58
                  <br />
                  7. 1 Co. 11:25
                  <br />
                  8. 1 Co. 10:16,17
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. En esta ordenanza Cristo no es ofrecido a su Padre, ni se hace en absoluto ningún verdadero
                  sacrificio para la remisión del pecado ni de los vivos ni de los muertos; sino que solamente es un
                  memorial de aquel único ofrecimiento de sí mismo y por sí mismo en la cruz, una sola vez para siempre,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;y una ofrenda espiritual de toda la alabanza posible a Dios por el mismo.</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;Así que el sacrificio papal de la misa, como ellos la llaman, es sumamente abominable e
                  injurioso para con el sacrificio mismo de Cristo, la única propiciación por todos los pecados de los
                  elegidos.
                </span>
              </p>
              <p>
                <span>
                  1. Jn. 19:30; He. 9:25-28; 10:10-14; Lc. 22:19; 1 Co. 11:24,25
                  <br />
                  2. Mt. 26:26,27,30 con He. 13:10-16
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. El Señor Jesús, en esta ordenanza, ha designado a sus ministros para que oren y bendigan los
                  elementos del pan y del vino, y que los aparten así del uso común para el uso sagrado; que tomen y
                  partan el pan, y tomen la copa y (participando también ellos mismos) den ambos a los participantes.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
              </p>
              <p>
                <span>1. 1 Co. 11:23-26; Mt. 26:26-28; Mr. 14:24,25; Lc. 22:19-22</span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>4. El negar la copa al pueblo,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;el adorar los elementos, el elevarlos o llevarlos de un lugar a otro para adorarlos y el
                  guardarlos para cualquier pretendido uso religioso,
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;es contrario a la naturaleza de esta ordenanza y a la institución de Cristo.</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 26:27; Mr. 14:23; 1 Co. 11:25-28
                  <br />
                  2. Ex. 20:4,5
                  <br />
                  3. Mt. 15:9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  5. Los elementos externos de esta ordenanza, debidamente separados para el uso ordenado por Cristo,
                  tienen tal relación con El crucificado que en un sentido verdadero, aunque en términos figurativos, se
                  llaman a veces por el nombre de las cosas que representan, a saber: el cuerpo y la sangre de Cristo;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;no obstante, en sustancia y en naturaleza, esos elementos siguen siendo verdadera y solamente
                  pan y vino, como eran antes.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Co. 11:27; Mt. 26:26-28
                  <br />
                  2. 1 Co. 11:26-28; Mt. 26:29
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  6. Esa doctrina que sostiene un cambio de sustancia del pan y del vino en la sustancia del cuerpo y la
                  sangre de Cristo (llamada comúnmente transustanciación), por la consagración de un sacerdote, o de
                  algún otro modo, es repugnante no sólo a la Escritura
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;sino también al sentido común y a la razón; echa abajo la naturaleza de la ordenanza; y ha sido
                  y es la causa de muchísimas supersticiones y, además, de crasas idolatrías.
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 26:26-29; Lc. 24:36-43,50,51; Jn. 1:14; 20:26-29; Hch. 1:9-11; 3:21;
                  <br />1 Co. 11:24-26; Lc. 12:1; Ap. 1:20; Gn. 17:10,11; Ez. 37:11; Gn. 41:26,27
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>7. Los que reciben dignamente esta ordenanza,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;participando externamente de los elementos visibles, también participan interiormente, por la
                  fe, de una manera real y verdadera, aunque no carnal ni corporal, sino alimentándose espiritualmente
                  de Cristo crucificado y recibiendo todos los beneficios de su muerte.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;El cuerpo y la sangre de Cristo no están entonces ni carnal ni corporal sino espiritualmente
                  presentes en aquella ordenanza a la fe de los creyentes, tanto como los elementos mismos lo están para
                  sus sentidos corporales.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Co. 11:28
                  <br />
                  2. Jn. 6:29,35,47-58
                  <br />
                  3. 1 Co. 10:16
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  8. Todos los ignorantes e impíos, no siendo aptos para gozar de la comunión con Cristo, son por tanto
                  indignos de la mesa del Señor y, mientras permanezcan como tales, no pueden, sin pecar grandemente
                  contra El, participar de estos sagrados misterios o ser admitidos a ellos;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;además, quienquiera que los reciba indignamente es culpable del cuerpo y la sangre del Señor,
                  pues come y bebe juicio para sí.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Mt. 7:6; Ef. 4:17-24; 5:3-9; Ex. 20:7,16; 1 Co. 5:9-13; 2 Jn. 10; Hch. 2:41,42; 20:7; 1 Co.
                  11:17-22,33,34
                  <br />
                  2. 1 Co. 11:20-22,27-34
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>
                Capítulo 31, Del estado del hombre después de la muerte y de la resurrección de los muertos
              </p>

              <p>
                <span>1. Los cuerpos de los hombres vuelven al polvo después de la muerte y ven la corrupción,</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;pero sus almas (que ni mueren ni duermen), teniendo una subsistencia inmortal, vuelven
                  inmediatamente a Dios que las dio.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;Las almas de los justos, siendo entonces hechas perfectas en santidad, son recibidas en el
                  Paraíso donde están con Cristo, y contemplan la faz de Dios en luz y gloria, esperando la plena
                  redención de sus cuerpos.
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;Las almas de los malvados son arrojadas al infierno, donde permanecen atormentadas y envueltas
                  en densas tinieblas, reservadas para el juicio del gran día.4 Fuera de estos dos lugares para las
                  almas separadas de sus cuerpos, la Escritura no reconoce ningún otro.
                </span>
              </p>
              <p>
                <span>
                  1. Gn. 2:17; 3:19; Hch. 13:36; Ro. 5:12-21; 1 Co. 15:22
                  <br />
                  2. Gn. 2:7; Stg. 2:26; Mt. 10:28; Ec. 12:7
                  <br />
                  3. Sal. 23:6; 1 R. 8:27-49; Is. 63:15; 66:1; Lc. 23:43; Hch. 1:9-11; 3:21;
                  <br />
                  2 Co. 5:6-8; 12:2-4; Ef. 4:10; Fil. 1:21-23; He. 1:3; 4:14,15; 6:20; 8:1; 9:24; 12:23; Ap. 6:9-11;
                  14:13; 20:4-6
                  <br />
                  4. Lc. 16:22-26; Hch. 1:25; 1 P. 3:19; 2 P. 2:9
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2.Los santos que se encuentren vivos en el último día no dormirán, sino que serán transformados,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;y todos los muertos serán resucitados</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;con sus mismos cuerpos, y no con otros,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;aunque con diferentes cualidades,</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;y éstos serán unidos otra vez a sus almas para siempre.</span>
                <span className="s4">
                  <sup>5</sup>
                </span>
              </p>
              <p>
                <span>
                  1. 1 Co. 15:50-53; 2 Co. 5:1-4; 1 Ts. 4:17
                  <br />
                  2. Dn. 12:2; Jn. 5:28,29; Hch. 24:15
                  <br />
                  3. Job 19:26,27; Jn. 5:28,29; 1 Co. 15:35-38,42-44
                  <br />
                  4. 1 Co. 15:42-44,52-54
                  <br />
                  5. Dn. 12:2; Mt. 25:46
                </span>
              </p>
              <p>
                <span>3. Los cuerpos de los injustos, por el poder de Cristo, serán resucitados para deshonra;</span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;los cuerpos de los justos, por su Espíritu,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>&nbsp;para honra,</span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;y serán hechos entonces semejantes al cuerpo glorioso de Cristo.</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
              </p>
              <p>
                <span>
                  Dn. 12:2; Jn. 5:28,29
                  <br />
                  2. Ro. 8:1,11; 1 Co. 15:45; Gá. 6:8
                  <br />
                  3. 1 Co. 15:42-49
                  <br />
                  4. Ro. 8:17,29,30; 1 Co. 15:20-23,48,49; Fil., 3:21; Col. 1:18; 3:4; 1 Jn. 3:2; Ap. 1:5
                </span>
                <span>&nbsp;</span>
              </p>
              <p className={styles.article_title}>Capítulo 32, Del juicio final</p>

              <p>
                <span>
                  1. Dios ha establecido un día en el cual juzgará al mundo con justicia por Jesucristo, a quien todo
                  poder y juicio ha sido dado por el Padre.
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;En aquel día, no sólo los ángeles apóstatas serán juzgados,</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;sino que también todas las personas que han vivido sobre la tierra comparecerán delante del
                  tribunal de Cristo
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>
                  &nbsp;para dar cuenta de sus pensamientos, palabras y acciones, y para recibir conforme a lo que hayan
                  hecho mientras estaban en el cuerpo, sea bueno o malo.
                </span>
                <span className="s4">
                  <sup>4</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Hch. 17:31; Jn. 5:22,27
                  <br />
                  2. 1 Co. 6:3; Jud. 6<br />
                  3. Mt. 16:27; 25:31-46; Hch. 17:30,31; Ro. 2:6-16; 2 Ts. 1:5-10; 2 P. 3:1-13; Ap. 20:11-15
                  <br />
                  4. 2 Co. 5:10; 1 Co. 4:5; Mt. 12:36
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  2. El propósito de Dios al establecer este día es la manifestación de la gloria de su misericordia en
                  la salvación eterna de los elegidos, y la de su justicia en la condenación eterna de los réprobos, que
                  son malvados y desobedientes;
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>
                  &nbsp;pues entonces entrarán los justos a la vida eterna y recibirán la plenitud de gozo y gloria con
                  recompensas eternas en la presencia del Señor; pero los malvados, que no conocen a Dios ni obedecen al
                  evangelio de Jesucristo, serán arrojados al tormento eterno y castigados con eterna perdición, lejos
                  de la presencia del Señor y de la gloria de su poder.
                </span>
                <span className="s4">
                  <sup>2</sup>
                </span>
              </p>
              <p>
                <span>
                  1. Ro. 9:22,23
                  <br />
                  2. Mt. 18:8; 25:41,46; 2 Ts. 1:9; He. 6:2; Jud. 6; Ap. 14:10,11; Lc. 3:17;
                  <br />
                  Mr. 9:43,48; Mt. 3:12; 5:26; 13:41,42; 24:51; 25:30
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  3. Así como Cristo quiere que estemos ciertamente persuadidos de que habrá un día de juicio, tanto
                  para disuadir a todos los hombres de pecar,
                </span>
                <span className="s4">
                  <sup>1</sup>
                </span>
                <span>&nbsp;como para el mayor consuelo de los piadosos en su adversidad;</span>
                <span className="s4">
                  <sup>2</sup>
                </span>
                <span>
                  &nbsp;así también quiere que ese día sea desconocido para los hombres, para que se desprendan de toda
                  seguridad carnal y estén siempre velando porque no saben a qué hora vendrá el Señor;
                </span>
                <span className="s4">
                  <sup>3</sup>
                </span>
                <span>&nbsp;y estén siempre preparados para decir: Ven, Señor Jesús; ven pronto.</span>
                <span className="s4">
                  <sup>4</sup>
                </span>
                <span>&nbsp;Amén.</span>
              </p>
              <p>
                <span>
                  1. 2 Co. 5:10,11
                  <br />
                  2. 2 Ts. 1:5-7
                  <br />
                  3. Mr. 13:35-37; Lc. 12:35-40
                  <br />
                  4. Ap. 22:20
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span>
                  “Este pequeño tomo no se presenta como una regla autoritativa ni como un código de fe, sino como una
                  ayuda en casos de controversia, una confirmación en la fe y un medio para edificación en justicia. En
                  él los miembros más jóvenes de nuestra iglesia tendrán un conjunto resumido de enseñanzas divinas, y
                  por medio de pruebas bíblicas, estarán preparados para dar razón de la esperanza que hay en ellos. No
                  te avergüences de tu fe; recuerda que es el antiguo evangelio de los mártires, confesores,
                  reformadores y santos. Sobre todo, es la verdad de Dios, contra la que las puertas del infierno no
                  pueden prevalecer. Haz que tu vida adorne tu fe, haz que tu ejemplo adorne tus creencias. Sobre todo,
                  vive en Cristo Jesús, y permanece en él, no creyendo ninguna enseñanza que no haya sido
                  manifiestamente aprobada por él y sea propia del Espíritu Santo. Aférrate a la Palabra de Dios que
                  aquí es explicada para ti.”—
                  <i>Charles Haddon Spurgeon&nbsp;</i>
                  (1834-1892)
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page permalink="about-rbcr/confession-de-fe/" header="Confesión Bautista de Fe 1689" content={this.content()} />
    );
  }
}

export default SpanishConfession;
