import React, { Component } from "react";
import styles from "../Membership/Membership.module.scss";
import Page from "../Page/Page";

class Membership extends Component {
  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <p className={styles.intro_content}>
                Many churches don’t have an official church membership. It is a “come as you please” atmosphere. We
                believe that church membership offers many benefits and really helps to create a healthy church body.
                Below are some reasons we believe in church membership.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.doctrineGrid_start}>
          <div className={styles.doctrineGrid_2}>
            <h4 className={styles.doctrine_title}>Example</h4>
            <p className={styles.doctrine_content}>
              {" "}
              The early Christians in the bible set the example for us today. After receiving the Word of God and being
              baptized, they joined in fellowship with other believers. (Acts 2:40-74)
            </p>
          </div>
          <div className={styles.rbcr_baptism}></div>
        </div>
        <div className={styles.doctrineGrid}>
          <div className={styles.membership}></div>
          <div className={styles.doctrineGrid_1}>
            <h4 className={styles.doctrine_titleWhite}>Encouragement</h4>
            <p className={styles.doctrine_contentWhite}>
              The apostles encouraged fellow believers to “build up the body of Christ” and “not to forsake our own
              assembling together”. Believers did not distance themselves from churches, but became energetically
              involved in them. Keep in mind that most of the New Testament was written to churches rather than
              individuals. (Ephesians 4:12 and Hebrews 10:25)
            </p>
          </div>
        </div>
        <div className={styles.doctrineGrid_end}>
          <div className={styles.doctrineGrid_2}>
            <h4 className={styles.doctrine_title}>Pastoral Care</h4>
            <p className={styles.doctrine_content}>
              There is nothing more reassuring than to have someone watching over your soul. Though Christ is our
              shepherd, he has called certain men to care for His flock by feeding them the Word of God and guarding
              them from wolves. (Hebrews 13:17)
            </p>
          </div>
          <div className={styles.membership_2}></div>
        </div>
        <div className={styles.doctrineGrid}>
          <div className={styles.membership_3}></div>
          <div className={styles.doctrineGrid_1}>
            <h4 className={styles.doctrine_titleWhite}>Accountability</h4>
            <p className={styles.doctrine_contentWhite}>
              Finally, we know all to well how deceitful sin can be. As we become accountable to one another in the
              Lord, we can “stir up love and good works” by encouraging and exhorting one another in the way we should
              walk. By ourselves, our sinful flesh would lie and deceive us, but united in Christ we can resist the
              devil and glorify God. Hebrews 3:13 and Hebrews 10:24 <br /> <br />
              Those interested in membership are encouraged to take their time to get to know us. We encourage you to
              pray and pursue getting involved in the various actives we offer throughout the month. Once you feel as if
              the Lord might be leading you towards membership, talk with one the elders and express your interest.
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="about-rbcr/church-membership/" header="Church Membership" content={this.content()} />;
  }
}

export default Membership;
