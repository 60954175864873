import React, { Component } from "react";
import Fab from "@material-ui/core/Fab";
import Snackbar from "@material-ui/core/Snackbar";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AnchorLink from "react-anchor-link-smooth-scroll";

class Button extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props,
      sermon: {
        series: {},
        preacher: {}
      }
    };
  }

  render() {
    return (
      <AnchorLink offset={() => 150} href={this.state.link}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={true}
        >
          <Fab color="primary">
            <ArrowUpwardIcon fontSize="large"></ArrowUpwardIcon>
          </Fab>
        </Snackbar>
      </AnchorLink>
    );
  }
}

export default Button;
