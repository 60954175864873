import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styles from "./Page.module.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ProgressBar from "../ProgressBar/ProgressBar";
import Slide from "@material-ui/core/Slide";
import GA from "../GA/GA";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      props: props,
      loading: true
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  // componentDidUpdate(prevProps, d) {
  //   // // Typical usage (don't forget to compare props):
  //   // if (this.props.userID !== prevProps.userID) {
  //   //   this.fetchData(this.props.userID);
  //   // }
  //   console.log("my props")
  //   console.log(prevProps)
  //   console.log(d)
  // }
  //
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.loading) {
  //     this.setState({ loading: nextProps.loading });
  //     return true;
  //
  //   }
  //
  //   return false;
  //
  //   console.log("shouldComponentUpdate", nextProps, nextState)
  //
  // }

  // static getDerivedStateFromProps(props, state) {
  //   console.log("props", props)
  //   console.log("state",state)
  //
  //   if (typeof props.loading === 'boolean') {
  //     state.loading = props.loading;
  //   }
  //
  //
  //   return {
  //     props,
  //     state,
  //   }
  // }

  render() {
    const siteName = "Reformed Baptist Church of Riverside";
    const hostName = process.env.REACT_APP_HOSTNAME;

    return (
      <div>
        <Helmet>
          <title>{`${this.props.header} | ${siteName}`}</title>
          <link rel="canonical" href={`${hostName}/${this.props.permalink}/`} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={`${this.props.header} | ${siteName}`} />
          <meta property="og:url" content={`${hostName}/${this.props.permalink}/`} />
          <meta property="og:site_name" content={siteName} />
        </Helmet>
        <Header />
        <div className={styles.page}>
          <div className={styles.header}>
            <Slide direction="up" in={true} timeout={{ enter: 500 }}>
              <h1>{this.props.header}</h1>
            </Slide>
          </div>
          {this.state.loading && <ProgressBar />}
          <div className={styles.content}>{this.props.content}</div>
        </div>
        <Footer />
        <GA title={`${this.props.header} | ${siteName}`} />
      </div>
    );
  }
}

export default Page;
