import React, { Component } from "react";
import styles from "./Hero.module.scss";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import "../../variables.scss";
import { isMobile } from "react-device-detect";

class Hero extends Component {
  render() {
    return (
      <div className={styles.hero}>
        <div className={styles.content}>
          {!isMobile && (
            <video playsInline autoPlay muted loop className={styles.video}>
              <source src={`${process.env.PUBLIC_URL}/rbcr-720-24.mp4`} type="video/mp4" />
              <source src={`${process.env.PUBLIC_URL}/rbcr-1020-24.webm`} type="video/webm" />
            </video>
          )}
          <div className={styles.overlay}>
            <ScrollAnimation animateIn="customFadeInUp" className={styles.title}>
              <h1>Welcome to Reformed Baptist Church of Riverside</h1>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
