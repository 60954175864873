import React from "react";
import "./DrawerToggleButton.css";

const drawerToggleButton = (props) => (
  <button
    className={"hamburger hamburger--spin " + (props.active ? "is-active" : "")}
    type="button"
    onClick={props.click}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
);

export default drawerToggleButton;
