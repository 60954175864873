import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import emailMask from "text-mask-addons/dist/emailMask";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiAlert from "@material-ui/lab/Alert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
import MailIcon from "@material-ui/icons/Mail";
import NavigationIcon from "@material-ui/icons/Navigation";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MaskedInput from "react-text-mask";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faPlane } from "@fortawesome/free-solid-svg-icons";
import ReactDOM from "react-dom";
import { Parser } from "html-to-react";

import Page from "../Page/Page";
import { PaymentService } from "../../services/PaymentService";
import { PagesService } from "../../services/PagesService";
import { YouthConferenceService } from "../../services/YouthConferenceService";
import "./YouthConference.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PhoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"_"}
      showMask
    />
  );
}

function EmailMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={emailMask}
      showMask
    />
  );
}

PhoneMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

EmailMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

class YouthConference extends Component {
  pagesService = new PagesService();
  paymentService = new PaymentService();
  youthConferenceService = new YouthConferenceService();

  constructor(props) {
    super(props);

    this.state = {
      props: props,
      submitting: false,
      submitted: false,
      loading: true,
      errorPopUpOpen: false,
      errorMessage: "",
      hasErrors: false,
      firstName: "",
      lastName: "",
      age: "",
      gender: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      attendeeType: "",
      church: "",
      shirtSize: "",
      pastorsEmail: "",
      pastorsName: "",
      product: "",
      guardian: "",
      insurance: "",
      policyHolder: "",
      policyNumber: "",
      emergencyContact: "",
      emergencyPhone: "",
      allergies: "",
      disclaimer: false,
      verified: false
    };
  }

  async verifyApplication() {
    const urlParameters = new URLSearchParams(this.state.props.location.search);
    const payed = urlParameters.get("payed");
    const amountPayed = urlParameters.get("amt");
    const st = urlParameters.get("st");
    const transactionId = urlParameters.get("tx");
    const attendeeId = Number(localStorage.getItem("attendeeId"));
    const form = localStorage.getItem(`form_${attendeeId}`);

    if (payed === "true" && amountPayed && st && transactionId && attendeeId && form) {
      this.setState({ submitted: true, loading: true, ...JSON.parse(form) });

      // const result = await this.paymentService.verifyPayment(attendeeId, transactionId, "YOUTH_CONFERENCE");
      const result = await this.youthConferenceService.updateApplication({
        id: attendeeId,
        product: this.state.product,
        amountPayed,
        transactionId
      });

      this.setState({ loading: false });

      if (result) {
        this.setState({ verified: true });
        localStorage.clear();
      }
    }
  }

  handleChange = (event) => {
    const state = {};
    state[event.target.name] = event.target.value;
    this.setState(state);
  };

  handleCheckBoxChange = (event) => {
    const state = {};
    state[event.target.name] = event.target.checked;
    this.setState(state);
  };

  isValidPhoneFormat(value) {
    return new RegExp(/\(\d{3}\) \d{3}-\d{4}/).test(value);
  }

  isValidEmailFormat(value) {
    return new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value);
  }

  isValidAge(value) {
    return value >= 13;
  }

  showFormError(item, format = "") {
    if (format === "phone") {
      return this.state.hasErrors && (!this.state[item] || !this.isValidPhoneFormat(this.state[item]));
    }

    if (format === "email") {
      return this.state.hasErrors && (!this.state[item] || !this.isValidEmailFormat(this.state[item]));
    }

    if (format === "age") {
      return this.state.hasErrors && (!this.isValidAge(this.state[item]));
    }

    return this.state.hasErrors && !this.state[item];
  }

  showRequiredFormError(item, isTextField = false, format = "") {
    if (isTextField && this.showFormError(item, format)) {
      return "Required";
    }

    if (this.showFormError(item)) {
      return <FormHelperText>Required</FormHelperText>;
    }
  }

  getDisclaimer() {
    if (this.isChaperone()) {
      return "I give permission to the Reformed Baptist Church of Riverside conference staff to seek medical attention if necessary in the unlikely event that I should become injured or ill. This liability statement is effective June 28th through June 30th, 2024.";
    }

    return "I give permission to the Reformed Baptist Church of Riverside conference staff to seek medical attention if necessary in the unlikely event that my child should become injured or ill. I also give permission to the chaperones from our church to transport my child to/from the conference. If anyone under 21 is driving, I have given them a permission slip to carry allowing my child to drive with them to/from the conference and in the city of Riverside or Jurupa Valley, California. This liability form is effective June 28th through June 30th, 2024.";
  }

  isChaperone() {
    return this.state.attendeeType === "Chaperone";
  }

  validate() {
    const requiredFields = [
      "firstName",
      "lastName",
      "age",
      "gender",
      "address",
      "city",
      "state",
      "zip",
      "phone",
      "email",
      "attendeeType",
      "church",
      "shirtSize",
      "product",
      "emergencyContact",
      "emergencyPhone",
      "disclaimer"
    ];

    let isValid = false;

    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (
        !this.state[field] ||
        ((field === "phone" || field === "emergencyPhone") && !this.isValidPhoneFormat(this.state[field])) ||
        (field === "email" && !this.isValidEmailFormat(this.state[field])) ||
        (field === "age" && !this.isValidAge(this.state[field]))
      ) {
        isValid = false;
        break;
      }

      isValid = true;
    }

    if (!isValid) {
      this.setState({ hasErrors: true, errorPopUpOpen: true, errorMessage: "Missing Required Form Fields" });
      return false;
    }

    return true;
  }

  async submit() {
    if (!this.validate()) {
      return;
    }

    this.setState({ hasErrors: false, errorPopUpOpen: false, submitting: true, loading: true });

    const { id } = await this.youthConferenceService.submitApplication({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      age: this.state.age,
      gender: this.state.gender,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      phone: this.state.phone,
      email: this.state.email,
      attendeeType: this.state.attendeeType,
      church: this.state.church,
      shirtSize: this.state.shirtSize,
      pastorsEmail: this.state.pastorsEmail,
      pastorsName: this.state.pastorsName,
      product: this.state.product,
      guardian: this.state.guardian,
      insurance: this.state.insurance,
      policyHolder: this.state.policyHolder,
      policyNumber: this.state.policyNumber,
      emergencyContact: this.state.emergencyContact,
      emergencyPhone: this.state.emergencyPhone,
      allergies: this.state.allergies
    });

    if (!id) {
      this.setState({
        submitting: false,
        submitted: true,
        errorPopUpOpen: true,
        errorMessage: "Something went wrong while submitting your application."
      });
      return;
    }

    this.setState({ submitted: true });

    const formState = this.state;
    delete formState.props;
    delete formState.submitting;
    delete formState.loading;
    delete formState.errorPopUpOpen;
    delete formState.errorMessage;
    delete formState.hasErrors;

    localStorage.setItem("attendeeId", id);
    localStorage.setItem(`form_${id}`, JSON.stringify(formState));

    // window.location.href = `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${this.state.product}`;

    if (this.state.product === "2") {
      window.location.href = 'https://swipesimple.com/links/lnk_678db195' 
    }

    if (this.state.product === "3") {
      window.location.href = 'https://swipesimple.com/links/lnk_dedcf3a1' 
    }
  }

  async componentDidMount() {
    this.setState({ submitting: false, loading: false });
    const pageResults = await this.pagesService.getBySlug("youth-conference");

    if (pageResults) {
      ReactDOM.hydrate(new Parser().parse(pageResults.content), document.getElementById("content"));
    }

    await this.verifyApplication();
  }

  content() {
    return (
      <div className="youth-conference">
        <div className="container">
          <div id="content" className="content"></div>
          <div className="form">
            {/* <form>
               <fieldset disabled={this.state.submitted}>
                <legend>
                  <AccountCircleIcon fontSize="large" />
                  Personal
                </legend>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      label="First Name"
                      name="firstName"
                      required
                      variant="outlined"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      error={this.showFormError("firstName")}
                      helperText={this.showRequiredFormError("firstName", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      label="Last Name"
                      name="lastName"
                      required
                      variant="outlined"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      error={this.showFormError("lastName")}
                      helperText={this.showRequiredFormError("lastName", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      label="Age (Must be 13+ to attend)"
                      name="age"
                      required
                      variant="outlined"
                      type="number"
                      inputProps={{min: 13, max: 99}}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 2);
                      }}
                      min={10}
                      value={this.state.age}
                      onChange={this.handleChange}
                      error={this.showFormError("age", "age")}
                      helperText={this.showRequiredFormError("age", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <FormControl required error={this.showFormError("gender")} variant="outlined">
                      <InputLabel id="gender">Gender</InputLabel>
                      <Select
                        labelId="gender"
                        id="gender"
                        name="gender"
                        labelWidth={50}
                        onChange={this.handleChange}
                        value={this.state.gender}
                        disabled={this.state.submitted}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                      {this.showRequiredFormError("gender")}
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      label="Address"
                      name="address"
                      variant="outlined"
                      required
                      value={this.state.address}
                      onChange={this.handleChange}
                      error={this.showFormError("address")}
                      helperText={this.showRequiredFormError("address", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      label="City"
                      name="city"
                      variant="outlined"
                      required
                      value={this.state.city}
                      onChange={this.handleChange}
                      error={this.showFormError("city")}
                      helperText={this.showRequiredFormError("city", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <FormControl required error={this.showFormError("state")} variant="outlined">
                      <InputLabel id="state">State</InputLabel>
                      <Select
                        labelId="state"
                        id="state"
                        name="state"
                        labelWidth={36}
                        value={this.state.state}
                        onChange={this.handleChange}
                        disabled={this.state.submitted}
                      >
                        <MenuItem value="AL">Alabama</MenuItem>
                        <MenuItem value="AK">Alaska</MenuItem>
                        <MenuItem value="AZ">Arizona</MenuItem>
                        <MenuItem value="AR">Arkansas</MenuItem>
                        <MenuItem value="CA">California</MenuItem>
                        <MenuItem value="CO">Colorado</MenuItem>
                        <MenuItem value="CT">Connecticut</MenuItem>
                        <MenuItem value="DE">Delaware</MenuItem>
                        <MenuItem value="DC">District Of Columbia</MenuItem>
                        <MenuItem value="FL">Florida</MenuItem>
                        <MenuItem value="GA">Georgia</MenuItem>
                        <MenuItem value="HI">Hawaii</MenuItem>
                        <MenuItem value="ID">Idaho</MenuItem>
                        <MenuItem value="IL">Illinois</MenuItem>
                        <MenuItem value="IN">Indiana</MenuItem>
                        <MenuItem value="IA">Iowa</MenuItem>
                        <MenuItem value="KS">Kansas</MenuItem>
                        <MenuItem value="KY">Kentucky</MenuItem>
                        <MenuItem value="LA">Louisiana</MenuItem>
                        <MenuItem value="ME">Maine</MenuItem>
                        <MenuItem value="MD">Maryland</MenuItem>
                        <MenuItem value="MA">Massachusetts</MenuItem>
                        <MenuItem value="MI">Michigan</MenuItem>
                        <MenuItem value="MN">Minnesota</MenuItem>
                        <MenuItem value="MS">Mississippi</MenuItem>
                        <MenuItem value="MO">Missouri</MenuItem>
                        <MenuItem value="MT">Montana</MenuItem>
                        <MenuItem value="NE">Nebraska</MenuItem>
                        <MenuItem value="NV">Nevada</MenuItem>
                        <MenuItem value="NH">New Hampshire</MenuItem>
                        <MenuItem value="NJ">New Jersey</MenuItem>
                        <MenuItem value="NM">New Mexico</MenuItem>
                        <MenuItem value="NY">New York</MenuItem>
                        <MenuItem value="NC">North Carolina</MenuItem>
                        <MenuItem value="ND">North Dakota</MenuItem>
                        <MenuItem value="OH">Ohio</MenuItem>
                        <MenuItem value="OK">Oklahoma</MenuItem>
                        <MenuItem value="OR">Oregon</MenuItem>
                        <MenuItem value="PA">Pennsylvania</MenuItem>
                        <MenuItem value="RI">Rhode Island</MenuItem>
                        <MenuItem value="SC">South Carolina</MenuItem>
                        <MenuItem value="SD">South Dakota</MenuItem>
                        <MenuItem value="TN">Tennessee</MenuItem>
                        <MenuItem value="TX">Texas</MenuItem>
                        <MenuItem value="UT">Utah</MenuItem>
                        <MenuItem value="VT">Vermont</MenuItem>
                        <MenuItem value="VA">Virginia</MenuItem>
                        <MenuItem value="WA">Washington</MenuItem>
                        <MenuItem value="WV">West Virginia</MenuItem>
                        <MenuItem value="WI">Wisconsin</MenuItem>
                        <MenuItem value="WY">Wyoming</MenuItem>
                      </Select>
                      {this.showRequiredFormError("state")}
                    </FormControl>
                  </div>
                  <div className="col-sm">
                    <TextField
                      label="Zip"
                      name="zip"
                      required
                      variant="outlined"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 5);
                      }}
                      min={9999}
                      value={this.state.zip}
                      onChange={this.handleChange}
                      error={this.showFormError("zip")}
                      helperText={this.showRequiredFormError("zip", true)}
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset disabled={this.state.submitted}>
                <legend>
                  <MailIcon fontSize="large" /> Contact
                </legend>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      label="Phone"
                      name="phone"
                      required
                      InputProps={{
                        inputComponent: PhoneMaskCustom
                      }}
                      variant="outlined"
                      value={this.state.phone}
                      onChange={this.handleChange}
                      error={this.showFormError("phone", "phone")}
                      helperText={this.showRequiredFormError("phone", true, "phone")}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      label="Email"
                      name="email"
                      variant="outlined"
                      required
                      InputProps={{
                        inputComponent: EmailMaskCustom
                      }}
                      value={this.state.email}
                      onChange={this.handleChange}
                      error={this.showFormError("email", "email")}
                      helperText={this.showRequiredFormError("email", true, "email")}
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset disabled={this.state.submitted}>
                <legend>
                  <InfoIcon fontSize="large" /> Attendee Options
                </legend>
                <div className="row">
                  <div className="col-sm">
                    <FormControl required error={this.showFormError("attendeeType")} variant="outlined">
                      <InputLabel id="attendeeType">I'm a</InputLabel>
                      <Select
                        labelId="attendeeType"
                        id="attendeeType"
                        name="attendeeType"
                        labelWidth={38}
                        value={this.state.attendeeType}
                        onChange={this.handleChange}
                        disabled={this.state.submitted}
                      >
                        <MenuItem value="Youth">Youth</MenuItem>
                        <MenuItem value="Chaperone">Chaperone</MenuItem>
                      </Select>
                      {this.showRequiredFormError("attendeeType")}
                    </FormControl>
                  </div>
                  <div className="col-sm">
                    <FormControl required error={this.showFormError("church")} variant="outlined">
                      <InputLabel id="attendeeType">Church</InputLabel>
                      <Select
                        labelId="attendeeType"
                        id="attendeeType"
                        name="church"
                        labelWidth={55}
                        value={this.state.church}
                        onChange={this.handleChange}
                        disabled={this.state.submitted}
                      >
                        <MenuItem value="Abounding Grace Church - Visalia">
                          Abounding Grace Church – Visalia, CA
                        </MenuItem>
                        <MenuItem value="Bethany Church on the Hill - Thousand Oaks">
                          Bethany Church on the Hill – Thousand Oaks, CA
                        </MenuItem>
                        <MenuItem value="Christ Reformed Baptist Church - Vista, CA">
                          Christ Reformed Baptist Church – Vista, CA
                        </MenuItem>
                        <MenuItem value="Community Bible Church - Reno, NV">Community Bible Church – Reno, NV</MenuItem>
                        <MenuItem value="Cornerstone Bible Church - Ridgecrest, CA">
                          Cornerstone Bible Church – Ridgecrest, CA
                        </MenuItem>
                        <MenuItem value="Cornerstone Bible Fellowship Church - Mesa, AZ">
                          Cornerstone Bible Fellowship Church – Mesa, AZ
                        </MenuItem>
                        <MenuItem value="Emmaus Christian Fellowship - Hemet, CA">
                          Emmaus Christian Fellowship – Hemet, CA
                        </MenuItem>
                        <MenuItem value="Faith Community Church - Oxnard, CA">
                          Faith Community Church – Oxnard, CA
                        </MenuItem>
                        <MenuItem value="Free Grace Church - Lancaster, CA">Free Grace Church – Lancaster, CA</MenuItem>
                        <MenuItem value="Free Grace Church - Clovis, CA">Free Grace Church – Clovis, CA</MenuItem>
                        <MenuItem value="Gateway Church - Livermore, CA">Gateway Church – Livermore, CA</MenuItem>
                        <MenuItem value="God's Garden Church, Victorville, CA">
                          God’s Garden Church, Victorville, CA
                        </MenuItem>
                        <MenuItem value="Grace Bible Church - Escondido, CA">
                          Grace Bible Church – Escondido, CA
                        </MenuItem>
                        <MenuItem value="Grace Bible Church - Moorpark, CA">Grace Bible Church – Moorpark, CA</MenuItem>
                        <MenuItem value="Grace Community Church - Minden, NV">
                          Grace Community Church – Minden, NV
                        </MenuItem>
                        <MenuItem value="Grace Covenant Church - Gilbert, AZ">
                          Grace Covenant Church – Gilbert, AZ
                        </MenuItem>
                        <MenuItem value="Grace Reformed Baptist Church - Palmdale, CA">
                          Grace Reformed Baptist Church – Palmdale, CA
                        </MenuItem>
                        <MenuItem value="Heritage Valley Bible Church - Fillmore, CA">
                          Heritage Valley Bible Church – Fillmore, CA
                        </MenuItem>
                        <MenuItem value="Immanuel Baptist Church - Sacramento, CA">
                          Immanuel Baptist Church – Sacramento, CA
                        </MenuItem>
                        <MenuItem value="Juanita Community Church - Kirkland, WA">
                          Juanita Community Church – Kirkland, CA
                        </MenuItem>
                        <MenuItem value="King's Church - Glendale, AZ">King's Church - Glendale, AZ</MenuItem>
                        <MenuItem value="King's Cross Church - Kirkland, WA">King's Cross Church - Kirkland, WA</MenuItem>
                        <MenuItem value="The Master's Bible Church - Sedona, AZ">
                          The Master’s Bible Church – Sedona, AZ
                        </MenuItem>
                        <MenuItem value="Miller Valley Baptist Church - Prescott, AZ">
                          Miller Valley Baptist Church – Prescott, AZ
                        </MenuItem>
                        <MenuItem value="NorthStar Baptist Church – Hayden, ID">
                          NorthStar Baptist Church – Hayden, ID
                        </MenuItem>
                        <MenuItem value="Portico Church - Orange, CA">Portico Church, Orange, CA</MenuItem>
                        <MenuItem value="Providence Bible Church - Sunnyvale">
                          Providence Bible Church – Sunnyvale, CA
                        </MenuItem>
                        <MenuItem value="Providence Reformed Church - Las Vegas">
                          Providence Reformed Church – Las Vegas, NV
                        </MenuItem>
                        <MenuItem value="Redeemer Church - Apple Valley, CA">
                          Redeemer Church - Apple Valley, CA
                        </MenuItem>
                        <MenuItem value="Reformed Baptist Church - Riverside, CA">
                          Reformed Baptist Church – Riverside, CA
                        </MenuItem>
                        <MenuItem value="Roy Bible Church - Roy, UT">Roy Bible Church, Roy, UT</MenuItem>
                        <MenuItem value="Sovereign Grace Baptist Church - Ontario, CA">
                          Sovereign Grace Baptist Church – Ontario, CA
                        </MenuItem>
                        <MenuItem value="Sovereign Grace Bible Church - Phoenix, AZ">
                          Sovereign Grace Bible Church – Phoenix, AZ
                        </MenuItem>
                        <MenuItem value="Sovereign Grace Fellowship - Nampa, ID">
                          Sovereign Grace Fellowship - Nampa, ID
                        </MenuItem>
                        <MenuItem value="Thousand Oaks Bible Church - Thousand Oaks, CA">
                          Thousand Oaks Bible Church – Thousand Oaks, CA
                        </MenuItem>
                        <MenuItem value="Trinity Bible Church - Powell, WY">Trinity Bible Church – Powell, WY</MenuItem>
                        <MenuItem value="Trinity Bible Church - Felton, CA">Trinity Bible Church – Felton, CA</MenuItem>
                        <MenuItem value="Trinity Reformed Baptist Church - La Mirada, CA">
                          Trinity Reformed Baptist Church – La Mirada, CA
                        </MenuItem>
                        <MenuItem value="Valley Center Community Church - Valley Center, CA">
                          Valley Center Community Church – Valley Center, CA
                        </MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                      {this.showRequiredFormError("church")}
                    </FormControl>
                  </div>
                  <div className="col-sm">
                    <FormControl required error={this.showFormError("shirtSize")} variant="outlined">
                      <InputLabel id="shirtSize">Shirt Size</InputLabel>
                      <Select
                        labelId="shirtSize"
                        id="shirtSize"
                        name="shirtSize"
                        labelWidth={70}
                        value={this.state.shirtSize}
                        onChange={this.handleChange}
                        disabled={this.state.submitted}
                      >
                        <MenuItem value="S">S</MenuItem>
                        <MenuItem value="M">M</MenuItem>
                        <MenuItem value="L">L</MenuItem>
                        <MenuItem value="XL">XL</MenuItem>
                        <MenuItem value="XXL">XXL</MenuItem>
                      </Select>
                      {this.showRequiredFormError("shirtSize")}
                    </FormControl>
                  </div>
                </div>
                {this.isChaperone() && (
                  <div className="row">
                    <div className="col-sm">
                      <TextField
                        label="Pastor's name"
                        name="pastorsName"
                        variant="outlined"
                        required
                        value={this.state.pastorsName}
                        onChange={this.handleChange}
                        error={this.showFormError("pastorsName")}
                        helperText={this.showRequiredFormError("pastorsName", true)}
                      />
                    </div>
                    <div className="col-sm">
                      <TextField
                        label="Pastor's email"
                        name="pastorsEmail"
                        variant="outlined"
                        required
                        InputProps={{
                          inputComponent: EmailMaskCustom
                        }}
                        value={this.state.pastorsEmail}
                        onChange={this.handleChange}
                        error={this.showFormError("pastorsEmail")}
                        helperText={this.showRequiredFormError("pastorsEmail", true)}
                      />
                    </div>
                  </div>
                )}
              </fieldset>
              <fieldset disabled={this.state.submitted}>
                <legend>
                  <FontAwesomeIcon icon={faPlane} size="lg" /> Travel & Flight Information
                </legend>
                <div className="row">
                  <div className="col-sm">
                    <p>
                      If you are traveling from Northern California or from out of state and need housing for Sunday
                      night, please note there is an extra fee for the third night. If you require a Sunday night stay,
                      choose one of the “3 Nights” options from the Conference Fees section. If you are flying, please
                      book your flights into <b>Ontario International Airport (ONT)</b> and do your best to schedule
                      your arrival between 12pm and 5pm on Friday and your departure for Monday morning.
                      <strong>We only provide pickup and departure to Ontario International Airport.</strong>
                      <b>
                        If you need transportation to/from Ontario airport, please email us at
                        RBCRyouthconference@gmail.com in advance and let us know.
                      </b>
                    </p>
                  </div>
                </div>
              </fieldset>
              <fieldset disabled={this.state.submitted}>
                <legend>
                  <MonetizationOnIcon fontSize="large" /> Conference Fees (including meals!)
                </legend>
                <div className="row">
                  <div className="col-sm">
                    <FormControl component="fieldset" error={this.showFormError("product")}>
                      <RadioGroup aria-label="fees" name="product" required onChange={this.handleChange}>
                        {/* <FormControlLabel
                          value="27TDSZ4PQ425L"
                          control={<Radio color="primary" />}
                          label="$110 – 2 Nights"
                          checked={this.state.product === "27TDSZ4PQ425L"}
                        />
                        <FormControlLabel
                          value="L6GCPH5S8TJ74"
                          control={<Radio color="primary" />}
                          label="$120 – 3 Nights"
                          checked={this.state.product === "L6GCPH5S8TJ74"}
                        /> */}{/*
                        <FormControlLabel
                          value="2"
                          control={<Radio color="primary" />}
                          label="$120 – 2 Nights"
                          checked={this.state.product === "2"}
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio color="primary" />}
                          label="$130 – 3 Nights"
                          checked={this.state.product === "3"}
                        />
                      </RadioGroup>
                      {this.showRequiredFormError("product")}
                    </FormControl>
                  </div>
                </div>
              </fieldset>
              <fieldset disabled={this.state.submitted}>
                <legend>
                  <FontAwesomeIcon icon={faHospital} size="lg" /> Medical Liability & Emergency Contact
                </legend>
                <div className="row">
                  {!this.isChaperone() && (
                    <div className="col-sm">
                      <TextField
                        label="Parent/Guardian Name"
                        name="guardian"
                        variant="outlined"
                        value={this.state.guardian}
                        onChange={this.handleChange}
                        error={this.showFormError("guardian")}
                        helperText={this.showRequiredFormError("guardian", true)}
                      />
                    </div>
                  )}
                  <div className="col-sm">
                    <TextField
                      id="outlined-helperText"
                      label="Insurance Provider"
                      name="insurance"
                      variant="outlined"
                      value={this.state.insurance}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      id="outlined-helperText"
                      label="Policyholder Name"
                      name="policyHolder"
                      variant="outlined"
                      value={this.state.policyHolder}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      id="outlined-helperText"
                      label="Policy #"
                      name="policyNumber"
                      variant="outlined"
                      value={this.state.policyNumber}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      label="Emergency Contact"
                      name="emergencyContact"
                      variant="outlined"
                      value={this.state.emergencyContact}
                      onChange={this.handleChange}
                      error={this.showFormError("emergencyContact")}
                      helperText={this.showRequiredFormError("emergencyContact", true)}
                      required
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      label="Emergency Phone"
                      name="emergencyPhone"
                      InputProps={{
                        inputComponent: PhoneMaskCustom
                      }}
                      variant="outlined"
                      value={this.state.emergencyPhone}
                      onChange={this.handleChange}
                      error={this.showFormError("emergencyPhone", "phone")}
                      helperText={this.showRequiredFormError("emergencyPhone", true, "phone")}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      multiline
                      name="allergies"
                      label="Please list any allergies, medications, or any other medical conditions we should be aware of"
                      variant="outlined"
                      value={this.state.allergies}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <FormControl required error={this.showFormError("disclaimer")} component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="disclaimer"
                              checked={this.state.disclaimer}
                              color="primary"
                              onChange={this.handleCheckBoxChange}
                            />
                          }
                          label={this.getDisclaimer()}
                        />
                      </FormGroup>
                      {this.showRequiredFormError("disclaimer")}
                    </FormControl>
                  </div>
                </div>
              </fieldset>
              <div className="submit">
                <Fab
                  color="primary"
                  variant="extended"
                  disabled={this.state.submitting || this.state.submitted}
                  onClick={() => this.submit()}
                >
                  <NavigationIcon />
                  Submit
                  {this.state.submitting && <CircularProgress size={30} />}
                </Fab>
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                open={this.state.errorPopUpOpen}
                autoHideDuration={6000}
                onClose={(event, reason) => {
                  if (reason === "clickaway") {
                    return;
                  }

                  this.setState({ errorPopUpOpen: false });
                }}
              >
                <Alert severity="error">{this.state.errorMessage}</Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                open={this.state.submitted && this.state.verified}
               >
                <Alert severity="success">{`Thank you for registering for Youth Conference ${new Date().getFullYear()}`}</Alert>
              </Snackbar>
            </form>  */}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page
        permalink="youth-conference/"
        header={`Youth Conference ${new Date().getFullYear()}`}
        content={this.content()}
        loading={this.state.loading}
      />
    );
  }
}

export default YouthConference;
