import React, { Component } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    "0": "#0d5e8b",
    "1.0": "#0d5e8b"
  },
  shadowBlur: 5
});

class ProgressBar extends Component {
  render() {
    return <TopBarProgress />;
  }
}

export default ProgressBar;
