import React, { Component } from "react";
import styles from "../Videos/Videos.module.scss";
import Page from "../Page/Page";
import Button from "@material-ui/core/Button";

class Videos extends Component {
  content() {
    return (
      <div className={styles.home}>
        <div className={styles.film}>
          <div className={styles.container}>
            <div className={styles.videoGrid}>
              <div className={styles.videoGrid_1}>
                <h2 className={styles.videoGrid_title}>Does Man Have Free Will?</h2>
                <hr className={styles.vg_hr} />
                <p className={styles.videoGrid_paragraph}>
                  This short video explains the simple, yet sometimes confusing, truth about man’s free will and God’s
                  sovereignty.
                </p>
              </div>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="free-will"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/p0HQ-_iNbq0"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filmCalvin}>
          <div className={styles.container}>
            <div className={styles.videoGrid}>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="john-calvin"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/Fqc_byG7--c"
                  frameBorder="0"
                  allowFullScreen
                  scrolling="no"
                ></iframe>
              </div>
              <div className={styles.videoGrid_2}>
                <h2 className={styles.videoGrid_titleWhite}>John Calvin</h2>
                <hr className={styles.vg_hrBlack} />
                <p className={styles.videoGrid_paragraphWhite}>
                  Watch the French Reformer like you’ve never seen him before. His story can not be summed up in five
                  points, but it can be in just under 20 minutes. Produced by the same team that brought you Martin
                  Luther and The Bride of Martin Luther – both told by littles! This is our third film in the series.
                </p>
                <a href="http://fivesolasfilms.com/" rel="noopener noreferrer" target="_blank">
                  <Button variant="contained" size="large" color="primary" style={{ backgroundColor: "#094161" }}>
                    Learn more
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filmBride}>
          <div className={styles.container}>
            <div className={styles.videoGrid}>
              <div className={styles.videoGrid_1}>
                <h2 className={styles.videoGrid_title}>The Bride of Martin Luther</h2>
                <hr className={styles.vg_hr} />
                <p className={styles.videoGrid_paragraph}>
                  What do a dozen nuns on the run, a rogue monk, and the Reformation have in common? Discover the true
                  story of how Martin Luther met his match and bride, Katharina von Bora.
                </p>
                <a href="http://fivesolasfilms.com/" rel="noopener noreferrer" target="_blank">
                  <Button variant="contained" size="large" color="primary" style={{ backgroundColor: "#094161" }}>
                    Learn more
                  </Button>
                </a>
              </div>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="bride-of-luther"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/7nA_ApmgkMk"
                  frameBorder="0"
                  allowFullScreen
                  scrolling="no"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filmCalvin}>
          <div className={styles.container}>
            <div className={styles.videoGrid}>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="martin-luther"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/0NhrXmk0lrI"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <div className={styles.videoGrid_2}>
                <h2 className={styles.videoGrid_titleWhite}>Martin Luther (As Told by Littles)</h2>
                <hr className={styles.vg_hrBlack} />
                <p className={styles.videoGrid_paragraphWhite}>
                  What in the world is a theses? Does it have anything to do with a Diet of Worms? Can you be good
                  enough to get to heaven? Find out the answers to these questions in this fun and inspiring short film
                  about the life of Martin Luther (As Told By Littles).
                </p>
                <a href="http://fivesolasfilms.com/" rel="noopener noreferrer" target="_blank">
                  <Button variant="contained" size="large" color="primary" style={{ backgroundColor: "#094161" }}>
                    Learn more
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.visitUs}>
          <div className={styles.container}>
            <div className={styles.videoGrid}>
              <div className={styles.videoGrid_1}>
                <iframe
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13229.033397118246!2d-117.4417612!3d34.0115789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3589eb3702595433!2sReformed%20Baptist%20Church%20of%20Riverside!5e0!3m2!1sen!2sus!4v1576124844684!5m2!1sen!2sus"
                  className={styles.map}
                  style={{ border: 0 }}
                  width="600"
                  height="350"
                  frameBorder="0"
                  allowFullScreen
                  scrolling="no"
                ></iframe>
              </div>
              <div className={styles.videoGrid_2}>
                <div className={styles.videoGrid_titleBlock}>
                  <h2 className={styles.videoGrid_title}>Visit Us</h2>
                  <hr className={styles.vg_hr} />
                </div>
                <p className={styles.videoGrid_paragraph}>
                  We'd love to hear from you!
                  <br />
                  <br />
                  Have a question or a comment? <br />
                  <br />
                  We want to hear it! Feel free to call, write, or email us. Or better yet, come join us for one of our
                  Sunday services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="videos/" header="Videos" content={this.content()} />;
  }
}

export default Videos;
