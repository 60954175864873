import BaseService from "./BaseService";

export class PagesService extends BaseService {
  async getBySlug(slug) {
    try {
      return this.get(`/pages/${slug}`);
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}
