import React, { Component } from "react";
import Page from "../Page/Page";
import { SermonsService } from "../../services/SermonsService";
import { SeriesService } from "../../services/SeriesService";
import { PreachersService } from "../../services/PreachersService";
import moment from "moment";
import "../../index.scss";
import "./Sermon.scss";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import SpeakerNotes from "@material-ui/icons/SpeakerNotes";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import { FacebookProvider, EmbeddedVideo } from "react-facebook";

class Sermon extends Component {
  sermonsService = new SermonsService();
  seriesService = new SeriesService();
  preachersService = new PreachersService();

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.match.params,
      sermon: {
        series: {},
        preacher: {}
      }
    };
  }

  async componentDidMount() {
    const sermons = await this.sermonsService.getFilteredSermons({ permalink: this.state.permalink });
    const sermon = sermons[0];
    sermon.series = await this.seriesService.getById(sermon.series_id);
    sermon.preacher = await this.preachersService.getById(sermon.preacher_id);
    this.setState({ sermon });
    this.sermonsService.postSermonView(sermon.id);
  }

  content() {
    const { sermon } = this.state;
    const scriptureAndSeries = () => {
      const scripture = () => {
        if (sermon.bible_passage) {
          return (
            <span>
              Scripture: <i>{sermon.bible_passage}</i>
            </span>
          );
        }
      };

      const series = () => {
        if (sermon.series_id > 0) {
          return (
            <span>
              Series: <Link to={`/series/${sermon.series.slug}`}>{sermon.series.name}</Link>
            </span>
          );
        }
      };

      return (
        <p>
          {scripture()} {scripture() && series() && ` | `} {series()}
        </p>
      );
    };

    const youtubeOptions = {
      playerVars: {
        rel: 0
      }
    };

    const showDownloads = sermon.audio_file_src || sermon.notes_src;

    return (
      <div className="sermon">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              {sermon.title && <h2>{`"${sermon.title}"`}</h2>}
              <h3>
                <Link to={`/preacher/${sermon.preacher.slug}`}>{sermon.preacher.name}</Link>
              </h3>
              <h5>{moment(sermon.date).format("MMMM Do, YYYY")}</h5>
              {scriptureAndSeries()}
              {(sermon.youtube_video_code || sermon.facebook_video_code) && (
                <div className="video-player-container">
                  <div className="video-container">
                    {sermon.youtube_video_code && (
                      <div className="video-responsive">
                        <YouTube videoId={sermon.youtube_video_code} opts={youtubeOptions} />
                      </div>
                    )}
                    {!sermon.youtube_video_code && sermon.facebook_video_code && (
                      <div className="video-responsive">
                        <FacebookProvider appId={`${process.env.FACEBOOK_APP_ID}`}>
                          <EmbeddedVideo
                            href={"https://www.facebook.com/ReformedBaptistChurch/videos/" + sermon.facebook_video_code}
                          />
                        </FacebookProvider>
                        ;
                      </div>
                    )}
                  </div>
                </div>
              )}
              {sermon.audio_file_src && <audio src={sermon.audio_file_src} controls />}
              <div className="downloads">
                {showDownloads && <h4>Downloads</h4>}
                {sermon.audio_file_src && (
                  <p>
                    <span className="icon">
                      <VolumeUpIcon fontSize="default"></VolumeUpIcon>
                    </span>
                    <a href={sermon.audio_file_src} download>
                      MP3
                    </a>
                  </p>
                )}
                {sermon.notes_src && (
                  <p>
                    <span className="icon">
                      <SpeakerNotes fontSize="default"></SpeakerNotes>
                    </span>
                    <a download href={sermon.notes_src}>
                      Notes
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page permalink={`sermons/${this.state.permalink}`} header={this.state.sermon.title} content={this.content()} />
    );
  }
}

export default Sermon;
