import BaseService from "./BaseService";

export class ServicesService extends BaseService {
  async getAll() {
    try {
      return this.get(`/services`);
    } catch (e) {
      return {};
    }
  }

  async getAllService() {
    try {
      const services = await this.getAll();
      const getTime = (name) => {
        return services
          .find((item) => item.name === name)
          .time.toLowerCase()
          .replace(" ", "");
      };

      return {
        spanish: getTime("Spanish"),
        sundaySchool: getTime("Sunday School"),
        morning: getTime("Morning"),
        evening: getTime("Evening")
      };
    } catch (e) {
      return {};
    }
  }
}
