import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import emailMask from "text-mask-addons/dist/emailMask";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiAlert from "@material-ui/lab/Alert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NavigationIcon from "@material-ui/icons/Navigation";
import MaskedInput from "react-text-mask";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital } from "@fortawesome/free-solid-svg-icons";

import Page from "../Page/Page";
import { VBSService } from "../../services/VBSService";
import "./VBS.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PhoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
          placeholderChar={"_"}
          showMask
      />
  );
}

function EmailMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={emailMask}
          showMask
      />
  );
}

PhoneMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

EmailMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};


class VBS extends Component {
  vbsService = new VBSService();

  constructor(props) {
    super(props);

    this.state = {
      props: props,
      submitting: false,
      submitted: false,
      loading: true,
      successPopUpOpen: false,
      errorPopUpOpen: false,
      errorMessage: "",
      hasErrors: false,
      firstName: "",
      guardianFirstName: "",
      lastName: "",
      guardianLastName: "",
      age: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      authorizedPickup: "",
      grade: "",
      insurance: "",
      policyHolder: "",
      policyNumber: "",
      allergies: "",
      comments: "",
    };
  }

  handleChange = (event) => {
    const state = {};
    state[event.target.name] = event.target.value;
    this.setState(state);
  };

  isValidPhoneFormat(value) {
    return new RegExp(/\(\d{3}\) \d{3}-\d{4}/).test(value);
  }

  showFormError(item, format = "") {
    if (format === "phone") {
      return this.state.hasErrors && (!this.state[item] || !this.isValidPhoneFormat(this.state[item]));
    }

    return this.state.hasErrors && !this.state[item];
  }

  showRequiredFormError(item, isTextField = false, format = "") {
    if (isTextField && this.showFormError(item, format)) {
      return "Required";
    }

    if (this.showFormError(item)) {
      return <FormHelperText>Required</FormHelperText>;
    }
  }

  validate() {
    const requiredFields = [
      "firstName",
      "guardianFirstName",
      "lastName",
      "guardianLastName",
      "age",
      "grade",
      "address",
      "city",
      "state",
      "zip",
      "phone",
      "insurance",
      "policyHolder",
      "policyNumber",
    ];


    let isValid = false;

    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (
        !this.state[field] ||
        ((field === "phone") && !this.isValidPhoneFormat(this.state[field]))
      ) {
        isValid = false;
        break;
      }

      isValid = true;
    }

    if (!isValid) {
      this.setState({ hasErrors: true, errorPopUpOpen: true, errorMessage: "Missing Required Form Fields" });
      return false;
    }

    return true;
  }

  async submit() {
    if (!this.validate()) {
      return;
    }

    this.setState({ hasErrors: false, errorPopUpOpen: false, submitting: true, loading: true });

    const { id } = await this.vbsService.submitApplication({
      firstName: this.state.firstName,
      guardianFirstName: this.state.guardianFirstName,
      lastName: this.state.lastName,
      guardianLastName: this.state.guardianLastName,
      age: this.state.age,
      grade: this.state.grade,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      phone: this.state.phone,
      insurance: this.state.insurance,
      policyHolder: this.state.policyHolder,
      policyNumber: this.state.policyNumber,
      allergies: this.state.allergies,
      comments: this.state.comments,
      authorizedPickup: this.state.authorizedPickup,
    });

    if (!id) {
      this.setState({
        submitting: false,
        submitted: true,
        errorPopUpOpen: true,
        errorMessage: "Something went wrong while submitting your application."
      });
      return;
    }

    this.setState({ submitted: true, submitting: false, loading: false, firstName: "", lastName: "", age: "", grade: "", allergies: "", comments: "", successPopUpOpen: true });

    document.getElementsByClassName("row")[0].scrollIntoView({ block: "center" });

    const formState = this.state;
    delete formState.props;
    delete formState.submitting;
    delete formState.loading;
    delete formState.errorPopUpOpen;
    delete formState.errorMessage;
    delete formState.hasErrors;

    setTimeout(() => {
      this.setState({successPopUpOpen: false})
    }, 5000)

  }

  async componentDidMount() {
    this.setState({ submitting: false, loading: false });
  }

  content() {
    return (
      <div className="vbs">
        <div className="container">
          <div id="content" className="content">
            <h1>Gospel Games: Running the Race For the Glory of God</h1>
            <div className="top-image">
              <img src="https://rbcr-prod.s3.us-west-1.amazonaws.com/uploads/2024/06/29/gospel_games_6c8c02919b-gospel-games.jpeg" alt=""/>
            </div>
            <p><b>Date: <span style={{color: "rgb(13, 94, 139)"}}>July 15-19, 2024 6:00pm to 8:00pm</span></b></p>
            <p><b>Overview:</b></p>
            <p><em>Ready, set, go! During this year's Gospel Games VBS, children will learn about the Christian race through the Five Solas. With daily skits, bible studies, Church History stories, and activities, each night they'll see how Scripture Alone teaches us that we are saved by Faith Alone through Grace Alone in Christ Alone! And all of life is for the Glory of God Alone.</em></p>
            <p><b>Note:</b></p>
            <p>
              <em>All children are welcome from <b>Pre-K through 12-years-old</b>.  As always, there is no charge to attend.  We encourage you to invite your friends, family, neighbors, co-workers, etc.  For more information, please contact <b>Pastor Troy Lamberth</b> @ <a href="tel:951-212-9545">951-212-9545</a> or <a href="mailto:troylamberth@gmail.com">troylamberth@gmail.com</a></em>
            </p>
          </div>
          <div className="form">
            <form>
              <fieldset >
                <legend>
                  <AccountCircleIcon fontSize="large" />
                  Child
                </legend>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      label="First Name"
                      name="firstName"
                      required
                      variant="outlined"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      error={this.showFormError("firstName")}
                      helperText={this.showRequiredFormError("firstName", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      label="Last Name"
                      name="lastName"
                      required
                      variant="outlined"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      error={this.showFormError("lastName")}
                      helperText={this.showRequiredFormError("lastName", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      label="Age"
                      name="age"
                      required
                      variant="outlined"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 2);
                      }}
                      min={10}
                      value={this.state.age}
                      onChange={this.handleChange}
                      error={this.showFormError("age")}
                      helperText={this.showRequiredFormError("age", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <FormControl required error={this.showFormError("grade")} variant="outlined">
                      <InputLabel id="attendeeType">Grade in Fall</InputLabel>
                      <Select
                          labelId="grade"
                          id="grade"
                          name="grade"
                          labelWidth={95}
                          value={this.state.grade}
                          onChange={this.handleChange}

                      >
                        <MenuItem value="Pre-K">
                          Pre-K
                        </MenuItem>
                        <MenuItem value="Kindergarten">
                          Kindergarten
                        </MenuItem>
                        <MenuItem value="First">
                          First
                        </MenuItem>
                        <MenuItem value="Second">
                          Second
                        </MenuItem>
                        <MenuItem value="Third">
                          Third
                        </MenuItem>
                        <MenuItem value="Fourth">
                          Fourth
                        </MenuItem>
                        <MenuItem value="Fifth">
                          Fifth
                        </MenuItem>
                        <MenuItem value="Sixth">
                          Sixth
                        </MenuItem>
                      </Select>
                      {this.showRequiredFormError("church")}
                    </FormControl>
                  </div>
                </div>
              </fieldset>
              <fieldset >
                <legend>
                  <AccountCircleIcon fontSize="large" /> Parent/Guardian
                </legend>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                        label="First Name"
                        name="guardianFirstName"
                        required
                        variant="outlined"
                        value={this.state.guardianFirstName}
                        onChange={this.handleChange}
                        error={this.showFormError("guardianFirstName")}
                        helperText={this.showRequiredFormError("guardianFirstName", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                        label="Last Name"
                        name="guardianLastName"
                        required
                        variant="outlined"
                        value={this.state.guardianLastName}
                        onChange={this.handleChange}
                        error={this.showFormError("guardianLastName")}
                        helperText={this.showRequiredFormError("guardianLastName", true)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                        label="Address"
                        name="address"
                        variant="outlined"
                        required
                        value={this.state.address}
                        onChange={this.handleChange}
                        error={this.showFormError("address")}
                        helperText={this.showRequiredFormError("address", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                        label="City"
                        name="city"
                        variant="outlined"
                        required
                        value={this.state.city}
                        onChange={this.handleChange}
                        error={this.showFormError("city")}
                        helperText={this.showRequiredFormError("city", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <FormControl required error={this.showFormError("state")} variant="outlined">
                      <InputLabel id="state">State</InputLabel>
                      <Select
                          labelId="state"
                          id="state"
                          name="state"
                          labelWidth={36}
                          value={this.state.state}
                          onChange={this.handleChange}

                      >
                        <MenuItem value="AL">Alabama</MenuItem>
                        <MenuItem value="AK">Alaska</MenuItem>
                        <MenuItem value="AZ">Arizona</MenuItem>
                        <MenuItem value="AR">Arkansas</MenuItem>
                        <MenuItem value="CA">California</MenuItem>
                        <MenuItem value="CO">Colorado</MenuItem>
                        <MenuItem value="CT">Connecticut</MenuItem>
                        <MenuItem value="DE">Delaware</MenuItem>
                        <MenuItem value="DC">District Of Columbia</MenuItem>
                        <MenuItem value="FL">Florida</MenuItem>
                        <MenuItem value="GA">Georgia</MenuItem>
                        <MenuItem value="HI">Hawaii</MenuItem>
                        <MenuItem value="ID">Idaho</MenuItem>
                        <MenuItem value="IL">Illinois</MenuItem>
                        <MenuItem value="IN">Indiana</MenuItem>
                        <MenuItem value="IA">Iowa</MenuItem>
                        <MenuItem value="KS">Kansas</MenuItem>
                        <MenuItem value="KY">Kentucky</MenuItem>
                        <MenuItem value="LA">Louisiana</MenuItem>
                        <MenuItem value="ME">Maine</MenuItem>
                        <MenuItem value="MD">Maryland</MenuItem>
                        <MenuItem value="MA">Massachusetts</MenuItem>
                        <MenuItem value="MI">Michigan</MenuItem>
                        <MenuItem value="MN">Minnesota</MenuItem>
                        <MenuItem value="MS">Mississippi</MenuItem>
                        <MenuItem value="MO">Missouri</MenuItem>
                        <MenuItem value="MT">Montana</MenuItem>
                        <MenuItem value="NE">Nebraska</MenuItem>
                        <MenuItem value="NV">Nevada</MenuItem>
                        <MenuItem value="NH">New Hampshire</MenuItem>
                        <MenuItem value="NJ">New Jersey</MenuItem>
                        <MenuItem value="NM">New Mexico</MenuItem>
                        <MenuItem value="NY">New York</MenuItem>
                        <MenuItem value="NC">North Carolina</MenuItem>
                        <MenuItem value="ND">North Dakota</MenuItem>
                        <MenuItem value="OH">Ohio</MenuItem>
                        <MenuItem value="OK">Oklahoma</MenuItem>
                        <MenuItem value="OR">Oregon</MenuItem>
                        <MenuItem value="PA">Pennsylvania</MenuItem>
                        <MenuItem value="RI">Rhode Island</MenuItem>
                        <MenuItem value="SC">South Carolina</MenuItem>
                        <MenuItem value="SD">South Dakota</MenuItem>
                        <MenuItem value="TN">Tennessee</MenuItem>
                        <MenuItem value="TX">Texas</MenuItem>
                        <MenuItem value="UT">Utah</MenuItem>
                        <MenuItem value="VT">Vermont</MenuItem>
                        <MenuItem value="VA">Virginia</MenuItem>
                        <MenuItem value="WA">Washington</MenuItem>
                        <MenuItem value="WV">West Virginia</MenuItem>
                        <MenuItem value="WI">Wisconsin</MenuItem>
                        <MenuItem value="WY">Wyoming</MenuItem>
                      </Select>
                      {this.showRequiredFormError("state")}
                    </FormControl>
                  </div>
                  <div className="col-sm">
                    <TextField
                        label="Zip"
                        name="zip"
                        required
                        variant="outlined"
                        type="number"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 5);
                        }}
                        min={9999}
                        value={this.state.zip}
                        onChange={this.handleChange}
                        error={this.showFormError("zip")}
                        helperText={this.showRequiredFormError("zip", true)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                        label="Phone"
                        name="phone"
                        required
                        InputProps={{
                          inputComponent: PhoneMaskCustom
                        }}
                        variant="outlined"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        error={this.showFormError("phone", "phone")}
                        helperText={this.showRequiredFormError("phone", true, "phone")}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                        multiline
                        name="authorizedPickup"
                        label="Names of the adults allowed to pick up children"
                        variant="outlined"
                        value={this.state.authorizedPickup}
                        onChange={this.handleChange}
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset >
                <legend>
                  <FontAwesomeIcon icon={faHospital} size="lg" /> Medical Insurance
                </legend>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      id="outlined-helperText"
                      label="Insurance Provider"
                      required
                      name="insurance"
                      variant="outlined"
                      value={this.state.insurance}
                      onChange={this.handleChange}
                      error={this.showFormError("insurance")}
                      helperText={this.showRequiredFormError("insurance", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      id="outlined-helperText"
                      label="Policyholder Name"
                      required
                      name="policyHolder"
                      variant="outlined"
                      value={this.state.policyHolder}
                      onChange={this.handleChange}
                      error={this.showFormError("policyHolder")}
                      helperText={this.showRequiredFormError("policyHolder", true)}
                    />
                  </div>
                  <div className="col-sm">
                    <TextField
                      id="outlined-helperText"
                      label="Policy #"
                      required
                      name="policyNumber"
                      variant="outlined"
                      value={this.state.policyNumber}
                      onChange={this.handleChange}
                      error={this.showFormError("policyNumber")}
                      helperText={this.showRequiredFormError("policyNumber", true)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      multiline
                      name="allergies"
                      label="Please list any allergies, medications, or any other medical conditions we should be aware of"
                      variant="outlined"
                      value={this.state.allergies}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                        multiline
                        name="comments"
                        label="Comments"
                        variant="outlined"
                        value={this.state.comments}
                        onChange={this.handleChange}
                    />
                  </div>
                </div>
              </fieldset>
              <div className="submit">
                <Fab
                  color="primary"
                  variant="extended"
                  disabled={this.state.submitting}
                  onClick={() => this.submit()}
                >
                  <NavigationIcon />
                  Submit
                  {this.state.submitting && <CircularProgress size={30} />}
                </Fab>
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                open={this.state.errorPopUpOpen}
                autoHideDuration={6000}
                onClose={(event, reason) => {
                  if (reason === "clickaway") {
                    return;
                  }

                  this.setState({ errorPopUpOpen: false });
                }}
              >
                <Alert severity="error">{this.state.errorMessage}</Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                open={this.state.successPopUpOpen}
                autoHideDuration={5000}
              >
                <Alert severity="success">{`Thank you for registering for VBS ${new Date().getFullYear()}`}</Alert>
              </Snackbar>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page
        permalink="vbs/"
        header={`Vacation Bible School ${new Date().getFullYear()}`}
        content={this.content()}
        loading={this.state.loading}
      />
    );
  }
}

export default VBS;
