import React, { Component } from "react";
import styles from "../Belief/Belief.module.scss";
import Page from "../Page/Page";

class Belief extends Component {
  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <p className={styles.intro_content}>
                At RBCR, our desire is to grow in the love and knowledge of God. Much time has been spent in prayer and
                Bible study so that we may stand fast in the knowledge that what we proclaim is the truth. The core
                beliefs that we as a church subscribe to have been found in the pages of scripture and are held dear to
                our hearts.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.belief}></div>
        <div className={styles.container}>
          <div className={styles.doctrineFlex}>
            <h4 className={styles.doctrine_title}>The Authority of Scripture</h4>
            <p className={styles.doctrine_content}>
              One of the major tenets held by RBCR is that the Bible is the authority governing all aspects of our
              personal and corporate lives. We affirm the Bible to be the inspired and inerrant word of the living God,
              profitable for instruction and reproof. The Bible is God’s revealed will to man, and we hold it to be of
              divine authorship. We have searched the scriptures regarding our beliefs and our functions as a local
              church and we believe them to be Biblical. <br />
              <br /> (2 Timothy 3:16-17)
            </p>
          </div>
        </div>
        <div className={styles.confession}>
          <div className={styles.container}>
            <div className={styles.confessionFlex}>
              <div className={styles.confession_1}>
                <h4 className={styles.doctrine_title}>Our Statement of Faith</h4>
                <p className={styles.doctrine_content}>
                  We have adopted the 1689 London Baptist Confession of Faith as our statement of faith. We believe it
                  to be a good and accurate presentation of the doctrines found in the Bible. While we believe this
                  confession to be scriptural and accurate, it is nothing more than a good effort to summarize the
                  doctrines found in scripture, and is in no way looked upon as scripture or inspired by God. All
                  matters regarding our doctrines, beliefs, and practices are submitted to the authority of scripture.
                </p>
              </div>
              <div className={styles.confession_1}>
                <h2 className={styles._1689}>1689</h2>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.doctrineGrid}>
          <div className={styles.rbcr_field}></div>
          <div className={styles.doctrineGrid_1}>
            <h4 className={styles.doctrine_titleWhite}>Doctrines of Grace</h4>
            <p className={styles.doctrine_contentWhite}>
              We hold dear the doctrines found in the scriptures that God is sovereign in all things, sometimes referred
              to as “reformed theology”. Though we are responsible for our actions, the Bible emphatically teaches that
              God is in control of all aspects of our lives. We believe that man is totally depraved and does not seek
              after God on his own. (Romans 3:11 & 23) We believe that God unconditionally elects His people in
              salvation. (Romans 9:15-16) We believe in the definite atonement of the sins of His chosen people through
              the blood of Jesus. (Romans 3:25-26) We believe in the effectual calling of God, and that He will draw all
              who He desires to Himself (John 6:44 & Acts 16:14). We believe in the perseverance of the saints, meaning
              that once in Christ, you are unable to lose your salvation (Hebrews 12:2, Romans 8:28-30 & Jude 24).
            </p>
            <br />
            <h4 className={styles.doctrine_titleWhite}>Corporate Worship</h4>
            <p className={styles.doctrine_contentWhite}>
              With both joy and respect we desire to worship our sovereign God by preaching from His word and honoring
              him with our praise. We consider the singing of praise songs and prayer to be a means of preparing one’s
              heart for the preaching, and yet they are in themselves legitimate means through which we render praise
              and worship to God. The preaching of the Word of God is central to our services and is crucial to the
              health of a church and its members. (Psalms 100:2-4)
            </p>
          </div>
        </div>
        <div className={styles.doctrineGrid_end}>
          <div className={styles.doctrineGrid_2}>
            <h4 className={styles.doctrine_title}>Evangelism</h4>
            <p className={styles.doctrine_content}>
              We hold to the truth that God uses His people as a means to bring the gospel to the world. Jesus
              commissioned us to make disciples of all the nations (Matthew 28:19-20). Paul asks, “How shall they hear
              without a preacher?” (Romans 10:14b) It is with great joy that we desire to reach our community and world
              with the gospel of Jesus Christ. We share His word with confidence and power, believing God is faithful
              and merciful in salvation (Romans 9:6 & Isaiah 55:11).
            </p>
          </div>
          <div className={styles.evangelism}></div>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="about-rbcr/what-we-believe/" header="What We Believe" content={this.content()} />;
  }
}

export default Belief;
