import React, { Component } from "react";
import YouTube from "react-youtube";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import "./SpanishSermons.scss";
import Page from "../Page/Page";
import { SermonsService } from "../../services/SermonsService";
import { PreachersService } from "../../services/PreachersService";
import moment from "moment";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Fab from "@material-ui/core/Fab";
import UpButton from "../ScrollToTop/Button";
import { Link } from "react-router-dom";

class SpanishSermons extends Component {
  sermonsService = new SermonsService();
  preachersService = new PreachersService();

  constructor(props) {
    super(props);
    this.state = {
      sermons: [],
      book: "",
      service: 1,
      preacher: "",
      startDate: null,
      endDate: null,
      keywords: "",
      latestSermon: { youtube_video_embed_html: "" },
      filters: {
        books: [],
        services: [],
        preachers: []
      },
      showScrollButton: false
    };
  }

  async componentDidMount() {
    const [sermons, filters] = await Promise.all([
      this.sermonsService.getFilteredSermons({ service: 1 }),
      this.sermonsService.getSpanishSermonFilters()
    ]);

    const youtubeOptions = {
      playerVars: {
        rel: 0
      }
    };

    const latestSermon = sermons[0];
    latestSermon.youtube = <YouTube videoId={latestSermon.youtube_video_code} opts={youtubeOptions} />;
    latestSermon.audio = <audio src={latestSermon.audio_file_src} controls />;

    this.setState({ sermons, filters, latestSermon });
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll(event) {
    this.setState({ showScrollButton: window.scrollY > 1300 });
  }

  filterSermons = async () => {
    const query = {};
    let sermons;

    if (this.state.book) {
      query.book = this.state.book;
    }

    if (this.state.service) {
      query.service = 1;
    }

    if (this.state.preacher) {
      query.preacher = this.state.preacher;
    }

    if (this.state.startDate) {
      query.startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    }

    if (this.state.endDate) {
      query.endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    }

    if (
      !this.state.book &&
      !this.state.service &&
      !this.state.preacher &&
      !this.state.startDate &&
      !this.state.endDate
    ) {
      sermons = await this.sermonsService.getAllSermons(query);
    } else {
      sermons = await this.sermonsService.getFilteredSermons(query);
    }

    this.setState({ sermons });
  };

  handleSermonClicked = (sermon) => {
    sermon.youtube = <YouTube videoId={sermon.youtube_video_code} />;
    sermon.audio = <audio src={sermon.audio_file_src} controls autoPlay />;
    this.setState({ latestSermon: sermon });
  };

  content() {
    const books = this.state.filters.books.map((book, index) => (
      <MenuItem value={book.id} key={index}>
        {book.name}
      </MenuItem>
    ));

    const preachers = this.state.filters.preachers.map((preacher, index) => (
      <MenuItem value={preacher.id} key={index}>
        {preacher.name}
      </MenuItem>
    ));

    const sermons = this.state.sermons.map((sermon, index) => (
      <tr key={index}>
        <td className="first">{moment(sermon.date).format("MM/DD/YYYY")}</td>
        <td className="third">
          <Link to={`/preacher/${sermon.preacher_slug}`}>{sermon.preacher}</Link>
        </td>
        <td className="fourth">{sermon.bible_passage}</td>
        <td className="fifth">
          <a href={`/sermons/${sermon.permalink}/`}>{sermon.title}</a>
        </td>
        <td className="sixth">
          <a href="#latest-sermon" onClick={(ev) => this.handleSermonClicked(sermon)}>
            <Fab color="primary">
              <PlayArrowIcon fontSize="large"></PlayArrowIcon>
            </Fab>
          </a>
        </td>
      </tr>
    ));

    return (
      <div className="sermons spanish">
        <a name="latest-sermon"></a>
        <div id="latest-sermon" className="outer-container">
          <div className="background-filter">
            <div className="container latest-sermon-container">
              <div className="row">
                {this.state.latestSermon.title && (
                  <div className="col-sm">
                    <h2>{`"${this.state.latestSermon.title}"`}</h2>
                    <h3>{this.state.latestSermon.preacher}</h3>
                    <h5>{moment(this.state.latestSermon.date).format("MMMM Do, YYYY")}</h5>
                    {this.state.latestSermon.bible_passage && (
                      <h5>
                        Sagrada Escritura: <i>{this.state.latestSermon.bible_passage}</i>
                      </h5>
                    )}

                    {this.state.latestSermon.youtube_video_code && (
                      <div className="video-player-container">
                        <div className="video-container">
                          <div className="video-responsive">{this.state.latestSermon.youtube}</div>
                        </div>
                      </div>
                    )}

                    {this.state.latestSermon.audio_file_src && this.state.latestSermon.audio}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div id="sermons-container" className="container sermons-container">
          <div className="filters">
            <div className="row">
              <div className="col-sm">
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="book">Libro</InputLabel>
                  <Select
                    labelId="book"
                    id="book-select"
                    value={this.state.book}
                    onChange={(event) => this.setState({ book: event.target.value })}
                    labelWidth={36}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {books}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm">
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="preacher">Predicador</InputLabel>
                  <Select
                    labelId="preacher"
                    id="preacher-select"
                    value={this.state.preacher}
                    onChange={(event) => this.setState({ preacher: event.target.value })}
                    labelWidth={63}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {preachers}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      clearable
                      disableToolbar
                      margin="normal"
                      id="start-date-picker-dialog"
                      label="Fecha de inicio"
                      value={this.state.startDate}
                      placeholder={moment(new Date()).format("MM/DD/YYYY")}
                      onChange={(date) => this.setState({ startDate: date })}
                      format="MM/dd/yyyy"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-sm">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      clearable
                      disableToolbar
                      margin="normal"
                      id="end-date-picker-dialog"
                      label="Fecha final"
                      value={this.state.endDate}
                      placeholder={moment(new Date()).format("MM/DD/YYYY")}
                      onChange={(date) => this.setState({ endDate: date })}
                      format="MM/dd/yyyy"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-sm">
                <Button variant="contained" size="large" color="primary" onClick={this.filterSermons}>
                  Actualizar
                </Button>
              </div>
            </div>
          </div>
          <table id="sermons-list">
            <tbody>
              <tr>
                <td className="first">Fecha</td>
                <td className="third">Orador</td>
                <td className="fourth">Sagrada Escritura</td>
                <td className="fifth">Título</td>
                <td className="sixth">Escucha</td>
              </tr>
              {sermons}
            </tbody>
          </table>
        </div>
        <div className={this.state.showScrollButton ? "visible" : "hidden"}>
          <UpButton link="#sermons-container" />
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="sermones-en-español" header="Sermones En Español" content={this.content()} />;
  }
}

export default SpanishSermons;
