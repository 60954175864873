import React from "react";
import "./SideDrawer.css";
import { Link } from "react-router-dom";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";

  if (props.show) {
    drawerClasses = "side-drawer open";

    document
      .querySelectorAll(".side-drawer ul li a")
      .forEach((item) =>
        item.addEventListener("click", () => document.getElementsByTagName("body")[0].classList.remove("menu-open"))
      );
  }

  return (
    <nav className={drawerClasses}>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <div className="dropdown">
            <Link to="/about-rbcr/" className="dropdownbtn">
              Who We Are
            </Link>
            <div className="dropdown_content">
              <Link to="/about-rbcr/meet-our-pastors/">Meet Our Pastors</Link>
              <Link to="/about-rbcr/what-we-believe/">What We Believe</Link>
              <Link to="/about-rbcr/church-membership/">Church Membership</Link>
              <Link to="/about-rbcr/church-constitution/">Church Constitution</Link>
              <Link to="/about-rbcr/the-baptist-confession-of-faith-of-1689/">1689 Baptist Confession</Link>
              <a target="_blank" href="https://www.thechurchconnectory.com/app/login">
                Connect
              </a>
            </div>
            <div className="clear"></div>
          </div>
        </li>
        <li>
          <Link to="/sermons/">Sermons</Link>
        </li>
        <li>
          <div className="dropdown">
            <Link to="/spanish/" className="dropdownbtn">
              Ministerio español
            </Link>
            <div className="dropdown_content">
              <Link to="/about-rbcr/confession-de-fe/">Confession De Fe</Link>
              <Link to="/constitucion-de-la-iglesia-bautista-reformada-de-riverside/">Constitución</Link>
              <Link to="/que-es-una-iglesia-bautista-reformada/">¿Qué es una Iglesia Bautista Reformada?</Link>
              <Link to="/sermones-en-español/">Sermones en Español</Link>
            </div>
          </div>
          <div className="clear"></div>
        </li>
        {/* <li>
          <Link to="/youth-conference">Youth Conference</Link>
        </li> */}
        {/*<li>*/}
          {/*<Link to="/vbs">Vacation Bible School</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
          {/*<Link to="/videos/">Videos</Link>*/}
        {/*</li>*/}
        <li>
          <Link to="/contact-us/">Contact Us</Link>
        </li>
        <li>
          <Link to="/giving/">Give</Link>
        </li>
      </ul>
    </nav>
  );
};

export default sideDrawer;
