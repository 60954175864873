import axios from "axios";

export default class Authentication {
  static jwt = process.env.REACT_APP_API_JWT;

  static async verifyToken() {
    try {
      const results = await axios({
        baseURL: `${process.env.REACT_APP_API_HOST}`,
        method: "get",
        url: `/verifytoken`,
        headers: {
          Authorization: `Bearer ${Authentication.jwt}`,
          "Content-Type": "application/json"
        }
      });

      Authentication.jwt = results.data.token;

      return results.data.token;
    } catch (e) {
      return "";
    }
  }
}
