import React, { Component } from "react";
import styles from "../Pastors/Pastors.module.scss";
import pastorRob from "../../assets/images/pastorrob.jpg";
import pastorChristian from "../../assets/images/pastorchristian.jpg";
import pastorTroy from "../../assets/images/pastortroy.jpg";
import pastorDavid from "../../assets/images/pastordavid.jpg";
import pastorRolando from "../../assets/images/pastorrolando.jpg";
import pastorHeader from "../../assets/images/pastors-header.jpg";
import Page from "../Page/Page";
import ScrollAnimation from "react-animate-on-scroll";

class Pastors extends Component {
  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <h5 className={styles.intro_title}>Called to serve</h5>
              <p className={styles.intro_content}>
                “And He Himself gave some to be apostles, some prophets, some evangelists, and some pastors and
                teachers…” – Ephesians 4:11
                <br />
                <br />
                In this day of shady televangelists and social media’s self promoters, it is more important than ever to
                select pastors based on biblical principles; principles such as those outlined in Titus 1 and 1 Timothy
                3. RBCR takes these principles very seriously and would like you to meet our pastors who are leaders
                that have been tested and found worthy of their calling.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <ScrollAnimation animateIn="fadeIn">
            <div style={{ padding: "50px 0" }}>
              <img style={{ width: "100%" }} src={pastorHeader} alt="pastors" />
            </div>
            <div className={styles.pastorGrid}>
              <div className={styles.bgWhite}>
                <div>
                  <img src={pastorRob} className={styles.pastor_img} alt="pastor-robert-elliott" />
                </div>
                <div className={styles.pastor_infoWhite}>
                  <h4 className={styles.pastor_title}>
                    Pastor -<br /> Robert Elliott
                  </h4>
                  <p className={styles.pastor_contact}>
                    Email:{" "}
                    <a href="mailto:robert1689@gmail.com" rel="noopener noreferrer">
                      Robert1689@gmail.com
                    </a>
                  </p>
                </div>
              </div>
              <div className={styles.pastor_1}>
                <p className={styles.pastor_bioBlue}>
                  Pastor Robert Elliott was born in Glasgow, Scotland. Though he was raised in a Christian family, it
                  wasn’t until his mid-teens that he embraced the Lord Jesus as His Savior. Upon becoming aware of the
                  call to preach, and being encouraged by his church to do so, Robert attended seminary at the Free
                  Presbyterian Theological Hall in Northern Ireland. He married Janice, whom he had known for many years
                  in the summer of 1985 and since then they have been blessed by God with three daughters. <br />
                  <br /> Robert has taught at RBCR since July of 1995. He desires that God would glorify Himself through
                  building a community of believers who honor Him by worshipping in spirit and in truth. Robert believes
                  that the local body should be composed of members who love God, one another and who are committed to
                  passionately maintaining and presenting eternal gospel realities to the world.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <div className={styles.pastorGrid_2}>
              <div className={styles.pastor_2}>
                <p className={styles.pastor_bioWhite}>
                  Pastor Christian Poleynard was born and raised in Southern California. By the sheer grace and mercy of
                  God, Christian was brought to faith and repentance towards Christ at the age of 20. It was in the
                  first four years of his Christian experience in a local church that he met his wife, Katy. They were
                  married in April 1999 and have since been blessed with three sons.
                  <br />
                  <br />
                  Christian and Katy served as missionaries in Uganda, East Africa for four years and returned to the
                  United States in 2003. They began attending RBCR in 2006 and became members in 2007. Christian began
                  working on his Masters of Divinity through Reformed Baptist Seminary in 2010 and was elected by the
                  church at RBCR to the office of elder and ordained in 2015. It is his desire to build up and edify the
                  church of Jesus Christ by pointing God’s people to the chief end of man: to glorify God and enjoy Him
                  forever.His prayer would be that of Jesus Christ: “Sanctify them by Your truth, Your word is
                  truth”(John 17:17).
                </p>
              </div>
              <div className={styles.pastorGrid_flip}>
                <div>
                  <img src={pastorChristian} className={styles.pastor_img} alt="pastor-christian-poleynard" />
                </div>
                <div className={styles.pastor_infoBlue}>
                  <h4 className={styles.pastor_title}>
                    Pastor -<br /> Christian Poleynard
                  </h4>
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <div className={styles.pastorGrid_3}>
              <div className={styles.bgWhite}>
                <div>
                  <img src={pastorTroy} className={styles.pastor_img} alt="pastor-troy-lamberth" />
                </div>
                <div className={styles.pastor_infoWhite}>
                  <h4 className={styles.pastor_title}>
                    Pastor -<br /> Troy Lamberth
                  </h4>
                  <p className={styles.pastor_contact}>
                    Email:{" "}
                    <a href="mailto:troylamberth@gmail.com" rel="noopener noreferrer">
                      troylamberth@gmail.com
                    </a>
                  </p>
                </div>
              </div>
              <div className={styles.pastor_3}>
                <p className={styles.pastor_bioBlue}>
                  Pastor Troy Lamberth was saved by God’s grace when he was a young boy. He’s very thankful to have
                  grown up in a Christian home and to have attended a church in Riverside that encouraged him to know
                  Jesus as his Lord and Savior. Troy was ordained as a pastor/elder at the RBCR in 2019. He has attended
                  the church since 2001 and been involved in a wide variety of things like preaching; teaching Sunday
                  School; counseling; coordinating VBS and Reformation Night events; as well as various evangelical
                  outreaches to the church’s community. He has been married to his lovely wife, Melissa, since 2005 and
                  they have three children together.
                  <br />
                  <br />
                  Troy graduated from California Baptist University in 1996 with a B.A, in History and then received his
                  Master in Fine Arts degree in Film & Television Production at Chapman University in 1999. Since then,
                  he has worked on all sorts of film and television projects. He is currently the executive producer of
                  the HAVEN Today Christian radio program, which is heard all across North America. He also works as an
                  adjunct professor at CBU.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <div className={styles.pastorGrid_4}>
              <div className={styles.pastor_4}>
                <p className={styles.pastor_bioWhite}>
                  Pastor Rolando Padilla was born and raised in southern California in a non-Christian home. God, by His grace and mercy, brought him to faith and repentance in
                  Christ at the age of 25. The Lord used one of his coworkers at the time, Maria, to bring
                  him to Christ. Eventually, Rolando and Maria married, and have been blessed with three
                  children. Rolando has been married to his beautiful wife since 2003.
                <br/>
                <br/>
                  Rolando began attending RBCR in 2013 with his family. In 2014 Rolando and
                  Maria became members. He has served in various ministries at the church including
                  adult/children’s Sunday School, preaching, and the Spanish ministry. He was elected to
                  the office of deacon in 2022, then elder in 2024. He desires to be used by God for His
                  glory, and for the saints to “grow in the grace and knowledge of our Lord and Savior
                  Jesus Christ (2 Peter 3:18).”
                </p>
              </div>
              <div className={styles.pastorGrid_flip}>
                <div>
                  <img src={pastorRolando} className={styles.pastor_img} alt="pastor-rolando-padilla" />
                </div>
                <div className={styles.pastor_infoBlue}>
                  <h4 className={styles.pastor_title}>
                    Pastor -<br /> Rolando Padilla
                  </h4>
                  <p className={styles.pastor_contact}>
                    Email:{" "}
                    <a href="mailto:rpadilla1689@gmail.com" rel="noopener noreferrer">
                      rpadilla1689@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="about-rbcr/meet-our-pastors/" header="Meet our pastors" content={this.content()} />;
  }
}

export default Pastors;
