import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Parser } from "html-to-react";
import { PagesService } from "../../services/PagesService";
import Page from "../Page/Page";
import "./About.scss";

class About extends Component {
  pagesService = new PagesService();

  async componentDidMount() {
    const pageResults = await this.pagesService.getBySlug("about");

    if (pageResults) {
      ReactDOM.hydrate(new Parser().parse(pageResults.content), document.getElementById("content"));
    }
  }

  content() {
    return (
      <div className="about">
        <div className="container">
          <div id="content" className="content"></div>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="about-rbcr/" header="About RBCR" content={this.content()} />;
  }
}

export default About;
