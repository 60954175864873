import Authentication from "./Authentication";
import * as axios from "axios";

export default class BaseService {
  get(url) {
    return this.request(url, "get");
  }

  post(url, body) {
    return this.request(url, "post", body);
  }

  patch(url, body) {
    return this.request(url, "patch", body);
  }

  async request(url, method, body) {
    try {
      const jwt = await Authentication.verifyToken();
      const request = {
        baseURL: `${process.env.REACT_APP_API_HOST}`,
        method,
        url,
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json"
        }
      };

      if (body) {
        request.data = body;
      }

      const results = await axios(request);

      return results.data;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
