import BaseService from "./BaseService";

export class SermonsService extends BaseService {
  async getAllSermons() {
    try {
      return this.get("/sermons");
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async getFilteredSermons(query) {
    try {
      return this.get(
        `/sermons/by?${Object.keys(query)
          .map((key) => key + "=" + query[key])
          .join("&")}`
      );
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async getSermonFilters() {
    const [books, services, preachers] = await Promise.all([this.getBooks(), this.getServices(), this.getPreachers()]);

    return {
      books,
      services,
      preachers
    };
  }

  async getSpanishSermonFilters() {
    const [books, preachers] = await Promise.all([this.getBooks(true), this.getSpanishPreachers()]);

    return {
      books,
      preachers
    };
  }

  getBooks(spanish = false) {
    try {

      if (spanish) {
        return this.get("/books?lang=es");
      }

      return this.get("/books");
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  getServices() {
    try {
      return this.get("/services");
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  getPreachers() {
    try {
      return this.get("/preachers");
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  getSpanishPreachers() {
    try {
      return this.get("/preachers?spanish=true");
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  postSermonView(id) {
    try {
      return this.post("/sermons/views", {id});
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}
