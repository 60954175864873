import React, { Component } from "react";
import styles from "../SpanishMinistry/Spanish.module.scss";
import Page from "../Page/Page";

class Spanish extends Component {
  content() {
    return (
      <div className={styles.home}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <div className={styles.introFlex}>
              <p className={styles.intro_content}>
                Reformed Baptist Church of Riverside/Iglesia Bautista Reformada de Riverside:
              </p>
              <p className={styles.intro_content}>
                7250 Mission Boulevard, Jurupa Valley, CA 92509
                Servicio de Adoración en Español a las 9 a.m., todo los domingos.
              </p>
              <p className={styles.intro_content}>
                Somos una congregación que hemos adoptado la Confesión Bautista de Fe de Londres de 1689. Nuestro deseo como iglesia es proclamar el glorioso evangelio de nuestro Señor Jesucristo, y que el pueblo de Dios crece en la gracia y conocimiento del Señor Jesucristo.
              </p>
              <p className={styles.intro_content}>
                Si deseas saber más sobre nuestra iglesia mandar correo electrónico a <a href="mailto:info@rbcr.org">info@rbcr.org</a>.
              </p>
              <p className={styles.intro_content}>
                Venga, alaba, y oiga la proclamación de la Palabra de Dios.
              </p>
              <p className={styles.intro_content}>
                “él es la cabeza del cuerpo que es la iglesia, él que es el principio, el primogénito de entre los muertos, para que en todo tenga la preeminencia” – Colosenses 1:18
              </p>
            </div>
          </div>
        </div>
        <div className={styles.main_header}></div>
        <div className={styles.confession}>
          <div className={styles.container}>
            <div className={styles.confessionFlex}>
              <div className={styles.confession_1}>
                <h4 className={styles.doctrine_title}>Confession De Fe</h4>
                <p className={styles.doctrine_content}>
                  En Inglaterra, durante la década de 1630 y de 1640, surgieron de la Iglesia Anglicana grupos
                  congregacionalistas y bautistas. Sus primeros años de existencia se caracterizaron por repetidos
                  ciclos de persecución por parte del Parlamento y la religión oficial del país. El infausto Código de
                  Clarendon fue adoptado en la década de 1660 para aplastar todo disenso de la religión oficial del
                  estado. Los períodos de rigurosa aplicación y los intervalos de relajamiento de estas leyes
                  coercitivas angustiaban a presbiterianos, congregacionalistas y bautistas por igual.
                </p>
              </div>
              <div className={styles.confession_1}>
                <h2 className={styles._1689}>1689</h2>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.doctrineGrid}>
          <div className={styles.spanish_img1}></div>
          <div className={styles.doctrineGrid_1}>
            <h4 className={styles.doctrine_titleWhite}>¿Qué es una Iglesia Bautista Reformada?</h4>
            <p className={styles.doctrine_contentWhite}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, alias adipisci. Recusandae
              provident corporis quaerat numquam ut reprehenderit nisi sit ducimus non repudiandae tenetur, similique
              ipsam nihil quidem veritatis fugiat vero. Delectus laboriosam, aut commodi vero unde similique illum
              voluptatum mollitia eos placeat quas repellendus ipsa beatae assumenda, ducimus autem quaerat distinctio
              iste iure? Iste impedit hic quod quisquam rem, saepe voluptates facere accusamus ratione expedita, porro
              sed amet illo dolores consectetur repudiandae? Similique inventore voluptates suscipit adipisci quam eaque
              est possimus commodi odio mollitia! Dolor laudantium earum quas? Architecto fugiat aperiam quod obcaecati?
              Id nulla rem unde quam odit.
            </p>
          </div>
        </div>
        <div className={styles.doctrineGrid_end}>
          <div className={styles.doctrineGrid_2}>
            <h4 className={styles.doctrine_title}>¿Qué es una Iglesia Bautista Reformada?</h4>
            <p className={styles.doctrine_content}>
              Si me preguntasen “¿Qué tipo de iglesia son ustedes?” no dudaría en responder “¡Somos una iglesia
              Bautista!”. Creemos en verdades a las que algunas veces se les ha llamado “Distintivos Bautistas”. <br />{" "}
              <br />
              También contestaría que somos una “iglesia Reformada” puesto que apoyamos las grandes doctrinas de la
              Reforma respecto a la salvación del hombre. En este sentido no me molesta que a nuestra iglesia se le
              identifique como una iglesia “Reformada Bautista”, y deseo hablar del tema “¿Qué es una Iglesia Reformada
              Bautista?”
            </p>
          </div>
          <div className={styles.belief}></div>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="spanish-ministry/" header="¡Todos tienen la bienvenida!" content={this.content()} />;
  }
}

export default Spanish;
