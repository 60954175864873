import React, { Component } from "react";
import styles from "../Contact/Contact.module.scss";
import Page from "../Page/Page";
import ScrollAnimation from "react-animate-on-scroll";

class Contact extends Component {
  content() {
    return (
      <div className={styles.home}>
        <div className={styles.contact}>
          <div className={styles.container}>
            <div className={styles.contact_flex}>
              <p className={styles.contact_info}>
                Phone Number <br /> (951) 235-8784
              </p>
              <p className={styles.contact_info}>
                Physical Address <br />
                7250 Mission Boulevard
                <br /> Riverside, CA 92509
              </p>
              <p className={styles.contact_info}>
                Mailing Address <br /> PO Box 3156 <br />
                Riverside, CA 92519
              </p>
            </div>
          </div>
        </div>
        <div className={styles.visitUs}>
          <div className={styles.container}>
            <ScrollAnimation animateIn="fadeIn">
              <div className={styles.videoGrid}>
                <div className={styles.videoGrid_1}>
                  <iframe
                    title="google-map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13229.033397118246!2d-117.4417612!3d34.0115789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3589eb3702595433!2sReformed%20Baptist%20Church%20of%20Riverside!5e0!3m2!1sen!2sus!4v1576124844684!5m2!1sen!2sus"
                    className={styles.map}
                    style={{ border: 0 }}
                    width="600"
                    height="350"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className={styles.videoGrid_2}>
                  <div className={styles.videoGrid_titleBlock}>
                    <h2 className={styles.videoGrid_title}>Visit Us</h2>
                    <hr className={styles.vg_hr} />
                  </div>
                  <p className={styles.videoGrid_paragraph}>
                    We'd love to hear from you!
                    <br />
                    <br />
                    Have a question or a comment? <br />
                    <br />
                    We want to hear it! Feel free to call, write, or email us. Or better yet, come join us for one of
                    our Sunday services.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="contact-us/" header="Contact Us" content={this.content()} />;
  }
}

export default Contact;
