import BaseService from "./BaseService";

export class VBSService extends BaseService {
  async submitApplication(body) {
    try {
      return this.post("/vbs", body);
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
