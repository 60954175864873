import React, { Component } from "react";
import styles from "./Menu.module.scss";
import { Link } from "react-router-dom";

class Menu extends Component {
  render() {
    return (
      <div className={styles.menu}>
        <ul>
          <li>
            <div className={styles.dropdown}>
              <Link to="/about-rbcr/" className={styles.dropdownbtn}>
                About RBCR
              </Link>
              <div className={styles.dropdown_content}>
                <Link to="/about-rbcr/meet-our-pastors/">Meet Our Pastors</Link>
                <Link to="/about-rbcr/what-we-believe/">What We Believe</Link>
                <Link to="/about-rbcr/church-membership/">Church Membership</Link>
                <Link to="/about-rbcr/church-constitution/">Church Constitution</Link>
                <Link to="/about-rbcr/the-baptist-confession-of-faith-of-1689/">1689 Baptist Confession</Link>
                <a target="_blank" href="https://www.thechurchconnectory.com/app/login">
                  Connect
                </a>
              </div>
            </div>
            <div className={styles.clear}></div>
          </li>
          <li>
            <Link to="/sermons">Sermons</Link>
          </li>
          <li>
            <div className={styles.dropdown}>
              <Link to="/spanish" className={styles.dropdownbtn}>
                Ministerio español
              </Link>
              <div className={styles.dropdown_content}>
                <Link to="/about-rbcr/confession-de-fe/">Confession De Fe</Link>
                <Link to="/constitucion-de-la-iglesia-bautista-reformada-de-riverside/">Constitución</Link>
                <Link to="/que-es-una-iglesia-bautista-reformada/">¿Qué es una Iglesia Bautista Reformada?</Link>
                <Link to="/sermones-en-español/">Sermones en Español</Link>
              </div>
            </div>
            <div className={styles.clear}></div>
          </li>
          {/* <li>
            <Link to="/youth-conference">Youth Conference</Link>
          </li> */}
          {/*<li>*/}
            {/*<Link to="/vbs">VBS</Link>*/}
          {/*</li>*/}
          {/*<li>*/}
            {/*<Link to="/videos">Videos</Link>*/}
          {/*</li>*/}
          <li>
            <Link to="/contact-us/">Contact</Link>
          </li>
          <li>
            <Link to="/giving/">Give</Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default Menu;
