import BaseService from "./BaseService";

export class PreachersService extends BaseService {
  async getById(id = 1) {
    try {
      return this.get(`/preachers/${id}`);
    } catch (e) {
      return {};
    }
  }

  async getBySlug(slug, page) {
    try {
      return this.get(`/preachers/${slug}/${page}`);
    } catch (e) {
      return false;
    }
  }

  async getAllSpanish() {
    try {
      return this.get(`/preachers?spanish=true`);
    } catch (e) {
      return [];
    }
  }
}
