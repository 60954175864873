import BaseService from "./BaseService";

export class SeriesService extends BaseService {
  async getById(id = 1) {
    try {
      return this.get(`/series/${id}`);
    } catch (e) {
      return {};
    }
  }
}
