import React, { Component } from "react";
import ReactGA from "react-ga";

class GA extends Component {
  render() {
    return (
      <div>
        {process.env.REACT_APP_ENV === "production" && ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)}
        {process.env.REACT_APP_ENV === "production" &&
          ReactGA.pageview(window.location.pathname + window.location.search, null, this.props.title)}
      </div>
    );
  }
}

export default GA;
