import React, { Component } from "react";
import "./Header.scss";
import Menu from "../Menu/Menu";
import rbcr from "../../assets/images/rbcr.png";
import SideDrawer from "../SideDrawer/SideDrawer";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import { Link } from "react-router-dom";

class Header extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      !prevState.sideDrawerOpen
        ? document.getElementsByTagName("body")[0].classList.add("menu-open")
        : document.getElementsByTagName("body")[0].classList.remove("menu-open");
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  render() {
    return (
      <div className="menuContainer">
        <SideDrawer show={this.state.sideDrawerOpen} />
        <header className="header">
          <nav className="header__navigation">
            <div className="header__logo">
              <Link to="/">
                <img src={rbcr} alt="rbcr-logo" />
              </Link>
            </div>
            <div className="spacer" />
            <div className="header__navigation_items">
              <Menu />
            </div>
            <div className="header__toggle_button">
              <DrawerToggleButton click={this.drawerToggleClickHandler} active={this.state.sideDrawerOpen} />
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default Header;
