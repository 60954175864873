import React, { Component } from "react";
import "./Giving.scss";
import { PagesService } from "../../services/PagesService";
import Page from "../Page/Page";
import ReactDOM from "react-dom";
import { Parser } from "html-to-react";
import Button from "@material-ui/core/Button";

class Giving extends Component {
  pagesService = new PagesService();

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const pageResults = await this.pagesService.getBySlug("giving");

    if (pageResults) {
      ReactDOM.hydrate(new Parser().parse(pageResults.content), document.getElementById("content"));
    }
  }

  content() {
    return (
      <div className="giving">
        <div className="container">
          <div id="content" className="content"></div>
          <a
            target="_blank"
            href="https://swipesimple.com/links/lnk_fe7b8ada"
            rel="noopener noreferrer"
          >
            <Button variant="contained" size="large" color="primary">
              Donate
            </Button>
          </a>
        </div>
      </div>
    );
  }

  render() {
    return <Page permalink="giving/" header="Giving" content={this.content()} />;
  }
}

export default Giving;
