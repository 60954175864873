import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import emailMask from "text-mask-addons/dist/emailMask";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiAlert from "@material-ui/lab/Alert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NavigationIcon from "@material-ui/icons/Navigation";
import MaskedInput from "react-text-mask";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital } from "@fortawesome/free-solid-svg-icons";

import Page from "../Page/Page";
import "./WomensConference.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PhoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
          placeholderChar={"_"}
          showMask
      />
  );
}

function EmailMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={emailMask}
          showMask
      />
  );
}

PhoneMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

EmailMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};


class WomensConference extends Component {

  constructor(props) {
    super(props);

    this.state = {
      props: props,
      submitting: false,
      submitted: false,
      loading: true,
      successPopUpOpen: false,
      errorPopUpOpen: false,
      errorMessage: "",
      hasErrors: false,
      firstName: "",
      guardianFirstName: "",
      lastName: "",
      guardianLastName: "",
      age: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      authorizedPickup: "",
      grade: "",
      insurance: "",
      policyHolder: "",
      policyNumber: "",
      allergies: "",
      comments: "",
    };
  }

  handleChange = (event) => {
    const state = {};
    state[event.target.name] = event.target.value;
    this.setState(state);
  };

  isValidPhoneFormat(value) {
    return new RegExp(/\(\d{3}\) \d{3}-\d{4}/).test(value);
  }

  showFormError(item, format = "") {
    if (format === "phone") {
      return this.state.hasErrors && (!this.state[item] || !this.isValidPhoneFormat(this.state[item]));
    }

    return this.state.hasErrors && !this.state[item];
  }

  showRequiredFormError(item, isTextField = false, format = "") {
    if (isTextField && this.showFormError(item, format)) {
      return "Required";
    }

    if (this.showFormError(item)) {
      return <FormHelperText>Required</FormHelperText>;
    }
  }

  validate() {
    const requiredFields = [
      "firstName",
      "guardianFirstName",
      "lastName",
      "guardianLastName",
      "age",
      "grade",
      "address",
      "city",
      "state",
      "zip",
      "phone",
      "insurance",
      "policyHolder",
      "policyNumber",
    ];


    let isValid = false;

    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (
        !this.state[field] ||
        ((field === "phone") && !this.isValidPhoneFormat(this.state[field]))
      ) {
        isValid = false;
        break;
      }

      isValid = true;
    }

    if (!isValid) {
      this.setState({ hasErrors: true, errorPopUpOpen: true, errorMessage: "Missing Required Form Fields" });
      return false;
    }

    return true;
  }

  async submit() {
    if (!this.validate()) {
      return;
    }

    this.setState({ hasErrors: false, errorPopUpOpen: false, submitting: true, loading: true });

    const { id } = await this.vbsService.submitApplication({
      firstName: this.state.firstName,
      guardianFirstName: this.state.guardianFirstName,
      lastName: this.state.lastName,
      guardianLastName: this.state.guardianLastName,
      age: this.state.age,
      grade: this.state.grade,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      phone: this.state.phone,
      insurance: this.state.insurance,
      policyHolder: this.state.policyHolder,
      policyNumber: this.state.policyNumber,
      allergies: this.state.allergies,
      comments: this.state.comments,
      authorizedPickup: this.state.authorizedPickup,
    });

    if (!id) {
      this.setState({
        submitting: false,
        submitted: true,
        errorPopUpOpen: true,
        errorMessage: "Something went wrong while submitting your application."
      });
      return;
    }

    this.setState({ submitted: true, submitting: false, loading: false, firstName: "", lastName: "", age: "", grade: "", allergies: "", comments: "", successPopUpOpen: true });

    document.getElementsByClassName("row")[0].scrollIntoView({ block: "center" });

    const formState = this.state;
    delete formState.props;
    delete formState.submitting;
    delete formState.loading;
    delete formState.errorPopUpOpen;
    delete formState.errorMessage;
    delete formState.hasErrors;

    setTimeout(() => {
      this.setState({successPopUpOpen: false})
    }, 5000)

  }

  async componentDidMount() {
    this.setState({ submitting: false, loading: false });
  }

  content() {
    return (
      <div className="vbs">
        <div className="container">
          <div id="content" className="content">
            <h1>MORE THAN A GENTLE AND QUIET SPIRIT</h1>
            <div className="top-image">
              <a href="https://rbcr-prod.s3.us-west-1.amazonaws.com/uploads/2024/01/05/RBCR_Mini_Conference_2024_bbdf1d8383-RBCR%20Mini%20Conference%202024.png" target="_blank">
                <img src="https://rbcr-prod.s3.us-west-1.amazonaws.com/uploads/2024/01/05/RBCR_Mini_Conference_2024_bbdf1d8383-RBCR%20Mini%20Conference%202024.png" alt=""/>
              </a>
            </div>
            <p>Click <a href="https://forms.gle/kDbahFi4DSv4akui7" target="_blank">here</a> to register today.</p>
            <p><b>Topic: <span style={{color: "rgb(13, 94, 139)"}}>"Womanly Dominion: More Than A Gentle And Quiet Spirit"</span></b></p>
            <p><b>Date: <span style={{color: "rgb(13, 94, 139)"}}>Saturday Feburary 10, 2024 10:00 AM - 3:00 PM</span></b></p>
            <p><b>Speaker: </b>Mark Chanski has labored as a full-time Pastor since 1986
in churches in Ohio and Michigan. Currently, he is the
Coordinator of Reformed Baptist Network. Mark is married
to his wife Dianne, and has four sons and one daughter.
His other works include the companion edition
of this title,
Manly Dominion and Encouragement.</p>
            <p><b>Cost: </b>$25 per lady</p>
            <p><b>Schedule:</b></p>
            <p>
                Morning Tea and Fellowship <br />
                Session One<br />
                Luncheon<br />
                Session Two<br />
            </p>
            <p><b>Note:</b></p>
            <p>
              <em>For conference questions, please reach out to  <b>Michelle Elliott</b> elliotts4life@gmail.com</em>
            </p>
            <p>
              <em>For registration questions, please reach out to  <b>Tiffany Pope</b> tiffanyrosepope@gmail.com</em>
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page
        permalink="vbs/"
        header={`Womanly Dominion Mini Conference`}
        content={this.content()}
        loading={this.state.loading}
      />
    );
  }
}

export default WomensConference;
