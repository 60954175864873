import BaseService from "./BaseService";

export class PaymentService extends BaseService {
  async verifyPayment(attendeeId, transactionId, product) {
    try {
      return this.post(`/payment/verify`, {
        id: transactionId,
        product,
        attendeeId
      });
    } catch (e) {
      return false;
    }
  }
}
