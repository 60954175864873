import BaseService from "./BaseService";

export class YouthConferenceService extends BaseService {
  async submitApplication(body) {
    try {
      return this.post("/youth-conference", body);
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async updateApplication(body) {
    try {
      return this.patch("/youth-conference", body);
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
