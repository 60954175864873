import React, { Component } from "react";
import styles from "./Application.module.scss";
import ApplicationRouter from "../ApplicationRouter/ApplicationRouter";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "../../assets/fonts/ElegantIcons/scss/style.scss";
import "animate.css/animate.min.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#3d7ea2",
      main: "#0d5e8b",
      dark: "#094161",
      contrastText: "#fff"
    },
    success: {
      main: "#4caf50"
    },
    info: {
      main: "#0d5e8b"
    },
    warning: {
      main: "#ff9800"
    },
    error: {
      main: "#f44336"
    }
  }
});

let lastScrollTop = 0;
let menuHide = false;

class Application extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
  }

  handleScroll = () => {
    let offset = window.pageYOffset || document.documentElement.scrollTop;

    menuHide = offset > 100 && offset > lastScrollTop;
    menuHide
      ? document.getElementsByTagName("body")[0].classList.add("hide-menu")
      : document.getElementsByTagName("body")[0].classList.remove("hide-menu");

    lastScrollTop = offset <= 0 ? 0 : offset; // For Mobile or negative scrolling
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div id="application" className={styles.application}>
          <ApplicationRouter />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Application;
