import React, { Component } from "react";
import "./Footer.scss";
import styles from "./Footer.module.scss";
import { ServicesService } from "../../services/ServicesService";

class Footer extends Component {
  servicesService = new ServicesService();

  constructor(props) {
    super(props);

    this.state = {
      services: [{ name: "", time: "" }]
    };
  }

  async componentDidMount() {
    const services = await this.servicesService.getAllService();

    this.setState({ services });
  }

  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.flexContainer}>
            <div className={styles.flexBox}>
              <h3 className={styles.footer_title}>Connect With Us</h3>
              <div className="connect">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/ReformedBaptistChurch/" target="_blank" rel="noopener noreferrer">
                      <span className="social-box">
                        <span aria-hidden="true" className="social_facebook"></span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/refbapchurch" target="_blank" rel="noopener noreferrer">
                      <span className="social-box">
                        <span aria-hidden="true" className="social_twitter"></span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCU8Nn6VSifR8vkJOH-HQBrg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="social-box">
                        <span aria-hidden="true" className="social_youtube"></span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <p className={styles.mini_paragraph}>
                &copy; {new Date().getFullYear()} Reformed Baptist <br></br>Church of Riverside
              </p>
            </div>
            <div className={styles.flexBox}>
              <h3 className={styles.footer_title}>Service Times</h3>
              <div className={styles.serviceTimeContainer}>
                <div className={styles.serviceTime}>
                  <h5 className={styles.service_title}>SUNDAY</h5>
                  <p className={styles.mini_title}>Service</p>
                  <p className={styles.mini_paragraph}>Spanish Ministry: {this.state.services.spanish}</p>
                  <p className={styles.mini_paragraph}>Sunday School: {this.state.services.sundaySchool}</p>
                  <p className={styles.mini_paragraph}>Worship Service: {this.state.services.morning}</p>
                  <p className={styles.mini_paragraph}>Evening Service: {this.state.services.evening}</p>
                </div>
                <div className={styles.serviceTime}>
                  <h5 className={styles.service_title}>WEDNESDAY</h5>
                  <p className={styles.mini_title}>Service</p>
                  <p className={styles.mini_paragraph}>Prayer Meeting: 7:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.flexBox}>
              <h3 className={styles.footer_title}>Contact Us</h3>
              <p className={styles.mini_paragraph}>Reformed Baptist Church of Riverside</p>
              <p className={styles.mini_paragraph}>
                7250 Mission Boulevard, <br></br>Riverside, CA 92509
              </p>
              <p className={styles.mini_paragraph}>(951) 235-8784</p>
              <p className={styles.mini_paragraph}>PO Box 3156, Riverside, 92509</p>
              <p className={styles.mini_paragraph}>
                Email us at{" "}
                <a href="mailto:info@rbcr.org" rel="noopener noreferrer">
                  info@rbcr.org
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
